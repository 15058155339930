import {url as config, assuredAPIURL, earthidealAPIURL} from '../constants';

export const userService = {
    update,
    register,
    login,
    logout,
    getUserInfo
};

function update (user) {
    //console.log('Update in UserService', user);
    const requestOptions = {
        method:     'POST',
        headers:    { 'Content-Type': 'application/json' },
        body:       JSON.stringify(user),
    };
    return fetch(`${config.apiUrl}/users/update/`, requestOptions).then(handleResponse);
}

function register (user) {
    //console.log('Register in UserService', user);
    const requestOptions = {
        method:     'POST',
        headers:    { 'Content-Type': 'application/json' },
        body:       JSON.stringify(user),
    };
    return fetch(`${config.apiUrl}/users/register/`, requestOptions).then(handleResponse);
}

function login (user) {
    //console.log('user', user)
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch (`${config.apiUrl}/users/login/`, requestOptions)
        .then (handleResponse)
        .then (auth => {
            // login successful if there's a user in the response
            if (auth.status === 200) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                auth.authdata = window.btoa(user.username + ':' + user.password);
                localStorage.setItem('user', JSON.stringify(auth));
            }

            return auth;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function getUserInfo(username){
  const resp = await fetch(`${earthidealAPIURL}/users/getUserInfo/${username}`).then(handleResponse)
  return resp
}


export function getUserById(id) {
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ 'id': id })
    // };
    return fetch (`${config.apiUrl}/users/id/${id}`).then(handleResponse);
}

export function getStripeToken(id) {
    return fetch (`${assuredAPIURL}/users/stripetoken/${id}`).then(handleResponse);
}

export function getStripeId(id) {
    return fetch (`${assuredAPIURL}/users/stripeid/${id}`).then(handleResponse);
}

export function changePassword(id, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'id': id,
            'password': password
        })
    };
    return fetch (`${config.apiUrl}/users/changepassword/`, requestOptions)
        .then (handleResponse);
}

export function setStripeToken(username, token) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'username': username,
            'token': token
        })
    };
    return fetch (`${assuredAPIURL}/users/setstripetoken/`, requestOptions)
        .then (handleResponse);
}

export function setStripeId(username, stripeId) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            'username': username,
            'stripeid': stripeId
        })
    };
    return fetch (`${assuredAPIURL}/users/setstripeid/`, requestOptions)
        .then (handleResponse);
}

export function resetPassword(id) {
    return fetch(`${config.apiUrl}/api/resetpassword/${id}`).then(handleResponse);
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
