import React, {Fragment, Component}  from 'react';
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';
import {url, earthidealAPIURL} from '../../constants';
import SuccessPopup from '../common/Popups';
import DeleteASite from '../common/DeleteASite';
import Conversations from '../Conversations';

import {
  //ApiService,
  HaulCheckService,
  SponsoredSitesService
  //PMGroupsService,
  //MatchesService,
  //ConversationsService
} from '../../services'

import Site from './Site'

export default class SiteArchive extends Component {

	constructor(props){
		super(props);


		this.state = {
			bidGraveyardList: [],
			constructionList: [],
			sponsoredList: [],
			displayTab: "constructionList",
			idsite: 0,
			siteObj: {idsite: 0, name: ""},
			confirmRecoverSite: false, 
			confirmDeleteSponsoredSite: false,
			loading: true,

			conversationsProps: {
				idsite: 0,
				idmatchsiteresource: 0,
				infoshared: 0,
				siteshared: 0,
				groupsListMode: false,
			}
		}
	}

	componentWillMount = async () => {
		await this.getSites();
	}

	getSites = async () => {
		this.setState({loading: true});
		let sites = await HaulCheckService.getArchiveSites();
		console.log("getArchiveSites result:", sites);
		this.setState(sites);
		this.setState({loading: false});
	}

	getMaterials = async (idsite, index) => {
		let sites = this.state[this.state.displayTab];

		if (this.state.displayTab == "sponsoredList"){
			sites[index].sponsoredSite = true;

		} else if (sites[index].children.matches == undefined){
			sites[index].loading = true;
			this.setState({[this.state.displayTab]: sites});

			let materials = await HaulCheckService.getArchiveMaterials(idsite);
			sites[index].children = materials;
			sites[index].loading = false;
		}

			this.setState({[this.state.displayTab]: sites});
		

	}

	goToSiteMaterials = () => {
		localStorage.setItem('site', JSON.stringify({idsite: this.state.siteObj.idsite, name: this.state.siteObj.name}));
	  window.location.href = "/site-materials";
	}

	goToSandboxMap = () => {
	  window.location.href = "/my-sites";
	}

	recoverSite = async () => {
		let {idsite} = this.state.siteObj;
		console.log("passed idsite to recoverSite:", idsite);
		this.setState({confirmRecoverSite: false});

		if (this.state.displayTab !== "sponsoredList"){
    	await HaulCheckService.recoverArchivedSite(idsite);
    	this.goToSiteMaterials();
		} else {
			await SponsoredSitesService.unarchiveSponsoredSite(idsite);
			this.goToSandboxMap();
		}

		//await this.getSites();
	}

	deleteSponsoredSite = async () => {
		let {idsite} = this.state.siteObj;
		console.log("passed idsite to recoverSite:", idsite);
		this.setState({confirmDeleteSponsoredSite: false});

		await SponsoredSitesService.deleteArchivedSponsoredSite(idsite);

    this.getSites();
		//await this.getSites();
	}

	openMessageThread = (conversationsProps) => {
		this.setState({conversationsProps}, () => {
			document.getElementById('open-message-thread').click();
		});
	}

	render = () => {

		let {displayTab, conversationsProps, loading} = this.state;

		return(<>
			<Navbar showSandbox={true} />
			<main class="main-content">
		        <header class="section-header mt-5 mb-0">
		            <h2><strong>Site Archive</strong></h2>
		        </header>
	          	<div class="section p-2">
	            	<div class="container">

						<div class="col-12 mb-4">
							<ul class="nav nav-tabs" role="tablist">
								<li class="nav-item">
									<a  className={"nav-link" + ( displayTab == "constructionList" ? " active" : "")} 
										data-toggle="tab" 
										href="#" 
										onClick={() => this.setState({displayTab: "constructionList"})}>
										Construction Sites
											<span className={"badge badge-number fs-10 strong pt-1 px-2 ml-1 badge-secondary"}>{this.state.constructionList.length}</span>
									</a>
								</li>
								<li class="nav-item">
									<a  className={"nav-link" + ( displayTab == "bidGraveyardList" ? " active" : "")} 
										data-toggle="tab" 
										href="#" 
										onClick={() => this.setState({displayTab: "bidGraveyardList"})}>
										Bid Graveyard
											<span className={"badge badge-number fs-10 strong pt-1 px-2 ml-1 badge-secondary"}>{this.state.bidGraveyardList.length}</span>
									</a>
								</li>
								<li class="nav-item">
									<a  className={"nav-link" + ( displayTab == "sponsoredList" ? " active" : "")} 
										data-toggle="tab" 
										href="#" 
										onClick={() => this.setState({displayTab: "sponsoredList"})}>
										Sponsored Sites
											<span className={"badge badge-number fs-10 strong pt-1 px-2 ml-1 badge-secondary"}>{this.state.sponsoredList.length}</span>
									</a>
								</li>
							</ul>
						</div>

		                <div class="tab-content p-4">
		                  	<div class="tab-pane fade show active" id="tab-construction">

		                  	{this.state[displayTab].length > 0 ?
		                  		(this.state[displayTab].map((site, index) => {
		                  			return <Site 
		                  				siteObj={site}
		                  				openMessageThread={this.openMessageThread}
		                  				initiateUnarchiveSite={(siteObj) => this.setState({siteObj, confirmRecoverSite: true}, () => this.setState({confirmRecoverSite: false}))}
		                  				initiateDeleteSite={(siteObj) => {
		                  					this.setState({siteObj}, () => {
		                  						if (this.state.displayTab !== "sponsoredList"){
		                  							document.getElementById("open-delete-site-popup").click();
		                  						} else {
		                  							this.setState({siteObj, confirmDeleteSponsoredSite: true})
		                  						}
	                  						});
		                  				}}
		                  				key={site.idsite + "-site"}
		                  				index={index}
		                  				getMaterials={this.getMaterials}
	                  				 />
		                  		}))
		                  		:
		                  		<strong>{loading ? "Loading Sites" : "No archived sites in this category"}</strong>
	                  		}

		                  	</div>
	                  	</div>
                  	</div>
              	</div>

              	<SuccessPopup
                  popupId="confirmDeleteSponsoredSite"
                  title="Permanently Remove Site"
                  message="Permanently remove this sponsored site from your archive, map, and matches?"
                  trigger={this.state.confirmDeleteSponsoredSite}
                  callback={this.deleteSponsoredSite}
                  useButton = {true}
                  useCancelButton = {true}
                  style="danger"
                  buttonLabel = "Yes"
                />

                <SuccessPopup
                  popupId="confirmRecoverSite"
                  title="Recover Site"
                  message="Are you sure you want to recover this site?"
                  trigger={this.state.confirmRecoverSite}
                  callback={this.recoverSite}
                  useButton = {true}
                  useCancelButton = {true}
                  style="info"
                  buttonLabel = "Yes"
                />

                <DeleteASite
                	site={this.state.siteObj}
                	callback={this.getSites}
                	hideArchiveButton={true}
              	/>

              	<Conversations 
				          originsite={conversationsProps.idsite}
				          idmatchsiteresource={conversationsProps.idmatchsiteresource}
				          infoshared={conversationsProps.infoshared}
				          siteshared={conversationsProps.siteshared}
				          switchSidePanel={() => {console.log("Called switch side panel from Conversations")}}
				          groupsListMode={conversationsProps.groupsListMode}
				          updateMatches={() => {return true}}
				        /> 

          	</main>
			<Footer />
		</>)
	}

}