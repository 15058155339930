import _ from 'lodash';

const getScrollPosition = (selector, side = "top") => {
	const element = document.querySelector(selector);
	if (element){
		return element[makeSelector(side)];
	} else {
		return null;
	}
}

const setScrollPosition = (selector, newPos, side = "top") => {
	const element = document.querySelector(selector);
	let newPositionObject = {[side]: newPos}
	console.log("setScrollPosition with params selector", selector, "newPos", newPos, "side", side, "attribute", makeSelector(side), "element", element, "to new position object", newPositionObject)
	//element[makeSelector(side)] = newPos;
	if (element !== null && element !== undefined && !_.isEmpty(element)){
		element.scrollTo(newPositionObject)
	}
}

const makeSelector = (side) => {
	return "scroll" + capitalizeFirstLetter(side);
}

const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}


export {getScrollPosition, setScrollPosition}