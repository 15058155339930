import React, {useEffect, Fragment, useState}  from 'react';

import {
  //ApiService,
  HaulCheckService,
  //MatchesService,
  //ConversationsService
} from '../../../../services';
import {usePrevious} from '../../../../utils'

export default function AddMonth({idsite_resource, material, callback, cancelCallback, customid, openControl}){

    const previousOpenControl = usePrevious(openControl);

    useEffect(() => {

        if (openControl !== undefined && previousOpenControl !== undefined){
           if (previousOpenControl != openControl){
            openPopup();
           }
        }

    }, [openControl]);

    let addMonth = async () => {
        await HaulCheckService.addMonthToPostPeriod(idsite_resource);
        callback();
        closePopup();
    }

    let openPopup = () => {
        let openButton = document.getElementById("open-addMonth" + (customid ? customid : "-site-setting-modal"))
        if (openButton) openButton.click();
    }

    let closePopup = () => {
        let closeButton = document.getElementById("close-addMonth" + (customid ? customid : ""));
        if (closeButton) closeButton.click();
    }

    return(<>
        <button id={"open-addMonth" + (customid ? customid : "-site-setting-modal")} data-toggle="popup" data-target={customid ? "#" + customid : "#add-month"} style={{display: "none"}}>Popup Trigger Button</button>
        <div id={customid ? customid : "add-month"} class="popup col-6 col-md-4 p-0" data-position="top-center" data-animation="slide-down">
            <button type="button" class="close" data-dismiss="popup" aria-label="Close" id={"close-addMonth" + (customid ? customid : "-material")}>
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="media alert-primary p-5">
                <i class="fa fa-calendar-plus-o lead pt-3"></i>
                <div class="media-body fw-200">
                    <p class="lead-3 strong ml-2">Add a Month?</p>
                    <p class="fw-400 lh-2 mb-4 pr-7">Continue posting your material for another month.</p>
                    <button type="button" class="btn btn-primary float-right mx-2" onClick={addMonth}>Add a Month</button>
                    <button type="button" class="btn btn-outline-primary float-right mx-2" onClick={closePopup}>Cancel</button>
                </div>
            </div>
        </div>
    </>)
}