import React, {Fragment} from 'react';

export default function YouBelongToGroup({markAsSeen, name}){

	return (
    <div className="col-12 alert alert-light border-primary alert-dismissible fade show px-4 pt-2 pb-2 mx-auto mt-4" role="alert">
      <div className="col-12 mr-auto alert-light p-1">
        <p className="mb-0"><i className="fa fa-check text-success strong mr-2"></i>You belong to the office group <em>{name}</em></p>
      </div>
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={markAsSeen}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
	)
}