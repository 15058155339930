import React, {useEffect, useState, Fragment}  from 'react';
import _ from 'lodash';
import DisplayRow from "./ui/DisplayRow"
import DisplayDataCell from "./ui/DisplayDataCell"
import sortDataIntoLayers from "./utils/sortDataIntoLayers"
import sortDataIntoTitles from "./utils/sortDataIntoTitles"
import sortDataIntoCSV from "./utils/sortDataIntoCSV"
import exportCSV from "./utils/exportCSV"
import {
  isBrowser
} from 'react-device-detect'
//Precondition: Data should be served with keys already corresponding to data headers

export default function FilterTable({rows, title, csvName, layers, filters}){

	//Digest passed data to coorespond to layers
	//Use MST as reference


	const [data, setData] = useState(null);

	const formatData = () => {

		let formattedData = sortDataIntoLayers({rows, layers});
		let titles = sortDataIntoTitles({rows, layers});
		let csvData = sortDataIntoCSV({rows, titles});

		return {formattedData, titles, csvData}
	}

	useEffect(() => {
		setData(formatData())
	}, [])

	const downloadCSV = () => {
		exportCSV(data.csvData, csvName)
	}

	const getColumnWidth = (str) => {

		let base = Math.floor(str.length / 10);
		let final = -1;

		if (base >= 4) final = 4;
		else if (base > 1.25 && base < 1.75) final = 1.5;
		else final = base + 1;

		if (layers.indexOf(str) !== -1){
			final = final + 2;
		}

		return final;
	}

	const formatTableCell = (cellData) => {
		console.log("cellData:", cellData, " of type", typeof cellData, "parseInt", parseInt(cellData), "parseInt isNaN", isNaN(parseInt(cellData)))

		if (typeof cellData == "object"){
			return "Object"
		} else if (!isNaN(new Date(cellData)) && typeof cellData == "string") {
			return (new Date(cellData)).toISOString().substring(0, 10)
		} else {
			return cellData;
		}
	}


	const renderLayers = () => {
		return data.formattedData.map((dataSlice) => {
			return <div className="mst-container">
				<div className="mst-col">
					<div className="mst-col">
			 			{renderLayer(0, dataSlice)}
			 		</div>
				</div>
			</div>
		})
	}

	const renderLayer = (layerIndex, dataSlice) => {

		let currentLayer = layers[layerIndex]
		let nextLayer = layers[layerIndex + 1]

		let dataToDisplay = dataSlice[currentLayer];
		let nextDataSlices = null;
		if (layerIndex < layers.length && dataSlice[nextLayer]){
			nextDataSlices = dataSlice[nextLayer]
		}

		console.log(
			"Calling renderLayer with data slice", dataSlice, 
			" at layer index ", layerIndex, 
			" with currentLayer", currentLayer, 
			" and nextLayer", nextLayer, 
			"with layers", layers,
			" and dataToDisplay", dataToDisplay,
			" and nextDataSlices", nextDataSlices
		)


		if (nextDataSlices != null){

			let width = getColumnWidth(currentLayer)

			return <>
				{nextDataSlices.map((nextDataSlice, index) => {
					return <DisplayRow>
						<div 
							className={"mst-cell border fixed-"+width}>
							{formatTableCell(dataToDisplay)}
						</div>
						<div className="mst-col">
							{renderLayer(layerIndex + 1, nextDataSlice)}
						</div>
					</DisplayRow>
				})}

			</>

		} else { 

			let sectionTitles = _.cloneDeep(data.titles).slice(layerIndex)
			return <DisplayRow>
				{sectionTitles.map((title, key) => {
					let width = getColumnWidth(title)
					return <DisplayDataCell key={"title"+key} width={width}>
						{formatTableCell(dataSlice[title])}
					</DisplayDataCell>
				})}
			</DisplayRow>

		}
	}



	if (data !== null) return <>
		<div className="section pt-2">
	       	<div className="container-fluid">
	            <div className="row">
	            	<div className="col-12">
                    	<p><strong>{title}</strong>		<button className="btn btn-primary btn-small btn-badge" disabled={data.csvData === undefined} onClick={downloadCSV}>Download .CSV</button> </p>
                    </div>
                    {isBrowser ? <div className="col-12">Click inside the table and use the arrow keys to scroll.</div> : null}

					<div className="mx-3 mb-0 mst-table-outer-div">  

						<div className="master-settings-table">
							<div className="bg-gray mst-container border-bottom mst-title">
								<div className="mst-row">
									{data.titles.map((title) => {
										let width = getColumnWidth(title)
										return 	<div 
											className={"mst-cell border fixed-"+width}>
											{title}
										</div>

									})}
									
								</div>
							</div>

							

										{renderLayers()}
										
									
							
						</div>

					</div>
				</div>
			</div>
		</div>


	</>;

	else return null;
}