import React from 'react';

export default function CartCheckoutButton({onClick, disabled, submitting, label}){

	return <button className="btn btn-block btn-primary" 
		onClick={onClick} 
		disabled={disabled}
	>
		{submitting ? <img src="../../img/app/photoswipe/preloader.gif" className="mr-1" /> : null}
		{label}
	</button>
}