import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {useMySitesPanelChange} from '../useMySitesPanelChange'
import {
	HaulCheckService, SharesDetailsService
} from "../../../services";
import "./materialinfostyles.css";
import MaterialInfo from "../../MaterialInfo"

{/*This component is like. Halfway between being generic and not being generic, 
kind of on purpose, kind of out of not wanting to mess too much with something that's working fine. 
Do with it as needed.*/}

export default function SidePanel({showMaterialInfo, onClose}) {

	const mySitesPanels = useMySitesPanelChange();

	const handleClose = () => {
		mySitesPanels.remove("material-info");
		if (onClose && typeof onClose == "function"){
			onClose()
		}

	}

	return(
		<div className={`
			my-sites-scroll 
			material-info-panel 
			bg-white 
			col-12 
			col-md-6
			px-6
			py-3
			${showMaterialInfo ? "material-info-panel-active" : "material-info-panel-inactive"}
		`}
		>
			<div className="row">
				<div className="col">
					<div className="d-flex justify-content-end">
						<button className="ml-auto p-0 btn btn-white" style={{position: "absolute", zIndex: "1000"}} aria-label="close" onClick={handleClose}>
							<span aria-hidden="true" className="lead">&times;</span>
						</button>
					</div>
				</div>
			</div>

			{showMaterialInfo ? <MaterialInfo onSubmit={handleClose} /> : null }
			
		</div>
	)


}