import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react'

import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import SuccessPopup from '../common/Popups';
import CCForm from '../common/CCForm';
import MaterialInfo from '../MaterialInfo';

import {
  ApiService,
  HaulCheckService,
  //MatchesService,
  //ConversationsService
} from '../../services'

import {
	hcDisplayPrice
} from '../../utils';

import {
	url, 
	//earthidealAPIURL
} from '../../constants';

import CartTable from "./ui/CartTable"
import CartItem from "./ui/CartItem"
import CartTotal from "./ui/CartTotal"
import CartCancelButton from "./ui/CartCancelButton"
import CartCheckoutButton from "./ui/CartCheckoutButton"



export default function Checkout(){

	let [hcPrice, setHCPrice] = useState(false);
	let [hasZeroExternalsPromotion, setHasZeroExternalsPromotion] = useState(false);
	let [hasReferralCredits, setHasReferralCredits] = useState(false);
	let [material, setMaterial] = useState({name: "sitename", type: "Shortage", "resource": "Rock", idsite_resource: 0, idsite: 0, iddiscoverymode: 2})
	let [hasValidPaymentInfo, setHasValidPaymentInfo] = useState(false);
	let [submitting, setSubmitting] = useState(false);
	let [successPopup, setSuccessPopup] = useState(false);


	useLayoutEffect(() => {

   	let loadedPostInfo = localStorage.getItem('postInfo');
   	if (!loadedPostInfo) goBack();

   	let postInfo = JSON.parse(loadedPostInfo);

   	let isBlast = false;

   	if (postInfo.material.isBlast) isBlast = true;

   	console.log("postInfo:", postInfo);

		setMaterial(postInfo.material);
		getHCPrice(isBlast);

		setHasReferralCredits(postInfo.hasReferralCredits && !isBlast);
		if (!isBlast){
			getZeroExternalsData(postInfo.material.idsite_resource);
		}
	}, [])

	let getHCPrice = async (isBlast = false) => {
		if (isBlast){
			setHCPrice(await ApiService.getHCBlastPrice(false))
		} else {
			setHCPrice(await ApiService.getHCPrice(false));
		}
	}

	let getZeroExternalsData = async (idsite_resource) => {
		let tallyData = await HaulCheckService.getExternalMatchTally(idsite_resource);
		console.log("zeroExternals tallyData:", tallyData);
		setHasZeroExternalsPromotion((tallyData.finalExternalMatchTally == 0));
	}

	let cancel = () => {
		goBack();
	}

	let goBack = () => {
		window.location.href = url.frontend + "/app/matches";
	}

	let submit = async () => {
		setSubmitting(true);
		localStorage.removeItem('postInfo');
		await HaulCheckService.toggleStatus(material.idsite_resource, material.isBlast);
		await HaulCheckService.getMatches(material.idsite);
		setSubmitting(false);
		localStorage.setItem("matchesPanel", JSON.stringify({origin: "Checkout", idsite: material.idsite, idsiteresource: material.idsite_resource, groupsListMode: false}));
		setSuccessPopup(true);
	}

	return(<>

		<Navbar />

		<header className="header bg-gray pt-6 pb-4">
			<div className="container">
				<h1 className="display-4">Checkout</h1>
				<p className="lead-2 mt-6">SuperBlasts, Posts & Searches will be applied to your monthly invoice and charged to the credit card on file at the end of the month.</p>
			</div>
		</header>


		<main className="main-content">

			<section className="section">
				<div className="container">

					<div className="row gap-y">
						<div className="col-lg-7">

							<CartTable>

								<CartItem
									style={{width: 'auto'}}
									item={`${material.resource} ${material.type.split('/')[0]}${material.isBlast ? " SuperBlast" : ""} ${material.iddiscoverymode == 1 ? "Bid Search" : "Post"}`}
									description={`Listed on invoice as "${material.iddiscoverymode == 1 ?"Bid Searches in Haul Check" : material.name + " - Haul Check " + (material.isBlast ? "SuperBlast" : "Post")} for ${(new Date()).toLocaleString('default', { month: 'long' })}"`}
									price={hcPrice}
								/>

									{hasReferralCredits ? 
										<CartItem
											item={`Free Post when you Tell Others`}
											description={`Get post for free with 2 referral credits.`}
											price={hcPrice * -1}
										/>
									: null }


									{hasZeroExternalsPromotion && !hasReferralCredits ? 
										<CartItem
											item={material.iddiscoverymode == 1 ? "Free Search when you have no matches" : "Free Post when you have no matches"}
											description={material.iddiscoverymode == 1 ? "Search for free when you have 0 external matches." : "Get post for free when you have 0 external matches."}
											price={hcPrice * -1}
										/>
									 : null }

							</CartTable>

							{hasReferralCredits && !hasZeroExternalsPromotion && hcPrice ? 

								<small className="mb-6"><i>Credit card required in case you want to “Add a Month” later on.</i></small> 

							: hasZeroExternalsPromotion && hcPrice ?

								<small className="mb-6"><i>No credit card required at this time</i></small>

							: null}

							{!hasZeroExternalsPromotion && hcPrice ? <>

								<hr className="my-8" />

								<h5 className={hasReferralCredits ? "mb-0" : "mb-6"}>Credit Card</h5>

								<div className="form-row">
									<CCForm 
										returnValidPaymentInfo={({paymentMethod}) => setHasValidPaymentInfo(paymentMethod)} 
									/>									
								</div>
							</> : null}
						</div>


						<div className="col-lg-5">
							<CartTotal
								titles={["Subtotal", "Promotions"]}
								amounts={[
									hcPrice,
									(hasReferralCredits || hasZeroExternalsPromotion ? (hcPrice * -1) : "None")
								]}
							/>


							<div className="row">
								<div className="col-6">
									<CartCancelButton onClick={cancel} />
								</div>

								<div className="col-6">
									<CartCheckoutButton
									  onClick={submit}
									  disabled={(!hasValidPaymentInfo && !hasZeroExternalsPromotion) || submitting}
										submitting={submitting}
										label={`Accept & ${material.isBlast ? "SuperBlast" : "Post"}`}
									/>

								</div>
							</div>

						</div>



					</div>

				</div>
			</section>
		</main>


		<SuccessPopup
        popupId="checkout-complete"
        title="Checkout Complete"
        message="Your material has been posted!"
        trigger={successPopup}
        callback={() => {
        	setSuccessPopup(false);
        	goBack();
        }}
        style="success"
    />



		<Footer />

	</>)
}