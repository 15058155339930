import _ from 'lodash'


export default function sortDataIntoCSV({rows, titles}){

	console.log("Calling sortDataIntoCSV with rows", rows, "and titles", titles)

	let csv = "";

	for (var i = 0; i < titles.length; i++){
		csv += titles[i] + ","
	}

	csv += "\n";

	for (var i = 0; i < rows.length; i++){
		let row = _.cloneDeep(rows[i])
		
		for (var j = 0; j < titles.length; j++){
			let title = titles[j]

			let data = (rows[i][titles[j]] + "").replace(',','/')

			let dateFromData = new Date(data);

			if (!isNaN(dateFromData)){
				data = data.split("T")[0]
			}

			csv += data;
			

			csv += ","

		}

		csv = csv.slice(0, -1);

		csv += "\n"
	}

	console.log("sortDataIntoCSV data:", csv);



	return csv;

}