import React from 'react';

import {
	hcDisplayPrice
} from '../../../utils';


export default function CartTotal({titles, amounts}){

	return <div className="cart-price">
		<div className="flexbox">
			<div>
				{titles.map((title, index) => {
					return <p key={index}>
						{title}:
					</p>
				})}
			</div>

			<div style={{"textAlign": "right"}}>
				{amounts.map((amount, index) => {
					return <p key={index}>
						{hcDisplayPrice(amount, false)}
					</p>
				})}
			</div>
		</div>

		<hr />

		<div className="flexbox">
			<div>
				<p><strong>Total:</strong></p>
			</div>

			<div>
				<p className="fw-600">{hcDisplayPrice(amounts.reduce((a, b) => a+b, 0), false)}</p>
			</div>
		</div>
	</div>
}