

export function goBack (passedHistory) {

	if (document.referrer.indexOf("earthideal") == -1 && document.referrer.indexOf("haulcheck") == -1){
        window.location.href = "/my-sites";
    } else {
    	if (passedHistory && passedHistory.length > 0){
    		passedHistory.goBack();
    	} else {
    		 window.location.href = "/my-sites";
    	}
        
    }

    return null;
}
