import React, {Fragment} from "react";

export default function Form(props) {

  const renderResourceCombo = (resourcesList) => {
    let type
    return resourcesList.map(item => {
      if (type === 'Export' && item.type === 'Import') {
        type = item.type
        return (
          <Fragment key={item.idresource}>
            <option key='Separator' disabled={ true }>----------------</option>
            <option value={item.idresource} key={item.idresource}>{item.type}: {item.resource}</option>
          </Fragment>
        )
      }
      type = item.type
      return <option value={item.idresource} key={item.idresource}>{item.type}: {item.resource}</option>
    })
  }

  const renderQuantitiesCombo = (quantitiesList) => {
    return quantitiesList.map(item => {
      return (
        <option value={ item.idquantity } key={ item.idquantity }>
          { (item.quantity).toLocaleString('en') }s of CY's
        </option>
      )
    })
  }

  return (
    <form onSubmit={props.onSubmit} style={{ paddingLeft: 40, marginTop: 0 }}>
      <div className="row">

          <select
            className="form-control form-control-sm"
            value={props.value}
            onChange={props.onChange }
          >
            <option>Resource Type</option>
            { renderResourceCombo(props.resourcesList) }
          </select>


          <select
            className="form-control form-control-sm "
            value={props.quantityValue}
            onChange={props.onChangeQuantity }
          >
            <option>Quantity Magnitude</option>
            { renderQuantitiesCombo(props.quantitiesList) }
          </select>
        <button type="submit"> Add </button>
      </div>
    </form>
  );
}
