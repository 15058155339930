import React, { Component } from 'react'
import PropTypes from "prop-types";
import BaseMessageLayout from './BaseMessageLayout'

class ShareLocationConsentMessage extends Component {
    static  propTypes ={
        messagedate: PropTypes.string,
        hours: PropTypes.number,
        minutes: PropTypes.number,
        openModalShareSite: PropTypes.func,
    }

    render() {
        const { messagedate, hours, minutes, resource, type, openModalShareSite} = this.props
        return (
           <BaseMessageLayout
                messagedate={messagedate}
                hours={hours}
                minutes={minutes}
                resource={resource}
                type={type}
                originname={this.props.originname}
                displayoriginname={this.props.displayoriginname}
                >
                  <p class="my-0 mb-2"><strong>Let's share site locations!</strong></p>
                  <button class="btn btn-primary" type="button" onClick={() => openModalShareSite(true) }>Agree to Share</button>
            </BaseMessageLayout>
        )
    }
}

export default ShareLocationConsentMessage
