import React, {useEffect, Fragment, useState}  from 'react';

import {
  //ApiService,
  HaulCheckService,
  //MatchesService,
  //ConversationsService
} from '../../../../services';
import {usePrevious} from '../../../../utils'


export default function GoInternal({idsite_resource, idsite, iddiscoverymode, callback, cancelCallback, customid, openControl = false}){

    const previousOpenControl = usePrevious(openControl);

    useEffect(() => {

        console.log("GoInternal useEffect previousOpenControl:", previousOpenControl, "openControl:", openControl)

        if (openControl !== undefined && previousOpenControl !== undefined){
           if (previousOpenControl != openControl){
            openPopup();
           }
        }

    }, [openControl]);

    let goInternal = async () => {
        console.log("Calling goInternal with params:", idsite_resource, idsite, iddiscoverymode, callback, customid)
        await HaulCheckService.toggleStatus(idsite_resource);
        await HaulCheckService.getMatches(idsite);
        if (callback) callback();
        closePopup();
    }

    let cancel = async () => {
        if (cancelCallback) cancelCallback();
        closePopup();
    }

    let openPopup = () => {
        let openButton = document.getElementById("open-goInternal" + (customid ? customid : "-site-setting-modal"))
        if (openButton) openButton.click();
    }

    let closePopup = () => {
        let closeButton = document.getElementById("close-gointernal" + (customid ? customid : ""));
        if (closeButton) closeButton.click();
    }

    return(<div>
        <button id={"open-goInternal" + (customid ? customid : "-site-setting-modal")} data-toggle="popup" data-target={customid ? "#" + customid : "#remove-post"} style={{display: "none"}}>Popup Trigger Button</button>
        <div id={customid ? customid : "remove-post"} class="popup col-6 col-md-4 p-0" data-position="top-center" data-animation="slide-down">
            <button type="button" class="close" data-dismiss="popup" aria-label="Close" id={"close-gointernal" + (customid ? customid : "")}>
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="media alert-warning p-5">
                <i class="fa fa-calendar-times-o lead pt-3"></i>
                <div class="media-body fw-200">
                    <p class="lead-3 strong ml-2">{iddiscoverymode==2?"Remove your Post?":"End your Bid Search?"}</p>
                    <p class="fw-400 lh-2 mb-4 pr-7">
                        {iddiscoverymode==2?
                            "Are you sure you want to remove your post from external matching?"
                        :
                            "Are you sure you want to stop looking for external matches?"}</p>
                    <button type="button" class="btn btn-warning float-right mx-2" onClick={goInternal}>Yes</button>
                    <button type="button" class="btn btn-outline-warning float-right mx-2" onClick={cancel}>Cancel</button>
                </div>
            </div>
        </div>
    </div>)
}