import React from 'react'
import ModalMenu from './Modal'
import Modal from 'react-modal'
import { url } from '../../constants'

const customStyles = {
  content: {
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    marginRight : '-50%',
    transform   : 'translate(-50%, -50%)'
  }
}

Modal.setAppElement('#root')

const Resource = ({ haul, toggleHaulArchived, toggleHaulAutorenewal, handleModalSave }) => {
  const { resource, type, quantity, newMatches, matchesCount, messagesCount, idsite_resource, enddate } = haul

  const [modalIsOpen, setIsOpen] = React.useState(false)

  const openModal = () => {
    setIsOpen(true)
  }

  const afterOpenModal = () => {
    //subtitle.style.color = '#F00'
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const redirectToMatches = (haul) => {
    console.log(haul)
    localStorage.setItem('newHaulCheckID', JSON.stringify(haul))
    window.location.href = `${url.frontend}/matches-inbox`
  }

  const redirectToShowRoutes = (haul) => {
    console.log(haul)
    localStorage.setItem('newHaulCheckID', JSON.stringify(haul))
    window.open(`${url.frontend}/road`, '_blank');
  }

  let matchClasses = 'badge badge-pale' + newMatches ? 'badge-success' : 'badge-light'
  matchClasses += 'mt-2 mb-4'

  let isInvalid = new Date(enddate) < new Date(); 

  return (
    <div className="row">
      <div className="col-6 col-sm-6 col-md-3 col-lg-2">
        <label className="fs1m mt-1 fw-500">
          {isInvalid ? 
            <del> { resource } { type } </del>
          : 
            <React.Fragment>{ resource } { type }</React.Fragment>
          }
        </label>
      </div>
      <div className="col-6 col-sm-6 col-md-3 col-lg-1">
        <label className="fs1m mt-1 fw-300">
          {isInvalid ?
            <del>{ (quantity || 0).toLocaleString('en') }s of CY's</del>
          :
            <React.Fragment>{ (quantity || 0).toLocaleString('en') }s of CY's</React.Fragment>
          }
        </label>
      </div>
      <div className="col-6 col-sm-6 col-md-3 col-lg-2">
        <span className={ matchClasses }><strong>{ matchesCount } &nbsp;Matches</strong></span>
      </div>
      <div className="col-6 col-sm-6 col-md-3 col-lg-2">
        <span className="badge badge-pale badge-light mt-2 mb-4" 
          style={(matchesCount === 0 ? {color: 'rgb(200,200,200)'} : {})}>{ messagesCount } &nbsp;New Messages</span>
      </div>

      <div className="col-6 col-sm-6 col-md-6 col-lg-2">
        <button className={"btn btn-xs mt-2 mb-5" + (isInvalid ? " btn-light" : " btn-outline-primary")} onClick={() => redirectToMatches(haul)}>&nbsp;VIEW MATCHES&nbsp;</button>
      </div>

      <div className="col-6 col-sm-6 col-md-6 col-lg-2">
        <button className={"btn btn-xs mt-2 mb-5" + (isInvalid ? " btn-light" : " btn-outline-primary")} onClick={() => redirectToShowRoutes(haul)}>&nbsp;SHOW ROUTES&nbsp;</button>
      </div>

      <div className="col-6 col-sm-6 col-md-6 col-lg-1">
        <div className="dropdown mt-2">
          <span className="dropdown-toggle no-caret" onClick={openModal}><i id={idsite_resource} className="ti ti-settings text-lighter" fs-12="true" fw-500="true" p-1="true" aria-hidden="true"></i></span>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Haul Control"
      >
        <ModalMenu
          haul={haul}
          closeModal={closeModal}
          toggleHaulArchived={toggleHaulArchived}
          toggleHaulAutorenewal={toggleHaulAutorenewal}
          handleModalSave={handleModalSave}
        />
      </Modal>
    </div>
  )
}

export default Resource
