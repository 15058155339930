import _ from 'lodash'
import {isJsonString} from './isJsonString';

//This is meant to be used specifically with objects or arrays of objects
//Do not make this function async... this is largely meant for state updates. Use a wrapper if it must be async

export function updateObjectBasedOnKey(prev, data){
  console.log("updateObjectBasedOnKey params:",prev,data);
  let clone = _.cloneDeep(prev)
  if (isJsonString(data)){
    data = JSON.parse(data);
  }
 
  if (Array.isArray(clone) || clone instanceof Array){
    console.log("updateObjectBasedOnKey updating array with prev ", clone);
    clone = updateObjectBasedOnKeyInArray(clone, data)
  } else if (typeof clone == 'object' || clone instanceof Object){
    console.log("updateObjectBasedOnKey updating object with prev", clone);
    clone = updateObjectBasedOnKeyObject(clone, data);
  }

  console.log("updateObjectBasedOnKey returning:", clone);
  return clone;     
}

function updateObjectBasedOnKeyObject(obj, {key, value, amount}){

  if (amount){
    //console.log("updateObjectBasedOnKey data:", data);
    obj[key] = parseInt(obj[key]) + amount;
  } else {
    obj[key] = value;
  }

  return obj;

}

function updateObjectBasedOnKeyInArray(arr, {id, idkey, key, value}){

  if (arr.length == 0) return arr;
  else {
    let index = _.findIndex(arr, {[idkey]: id});

    if (index === -1) return arr;
    else {
      arr[index][key] = value;
      return arr;
    }
  }

}