import { url } from '../constants'

export const MatchesService = {
  getMatchesCount,
  updateReadStatusMatch,
  updateInteractedStatus
}

async function getMatchesCount(id) {
  const resp = await fetch(`${url.backend}/matches/countmatches/${id}`).then(handleResponse)
  return resp.matchesCount
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
async function updateReadStatusMatch(selectedMatch){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      selectedMatch
    })
  }
  const resp = await fetch(`${url.backend}/matches/updateReadStatus`, requestOptions)
      .then(handleResponse)
  return resp
}

async function updateInteractedStatus(idmatchsiteresource){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      idmatchsiteresource
    })
  }
  const resp = await fetch(`${url.backend}/matches/updateInteractedStatus`, requestOptions)
      .then(handleResponse)
  return resp
}