import React, {Fragment, Component} from 'react';
import BaseGuideModal from './BaseGuideModal';

export default class SetupGuideModal extends Component {

  constructor(props){

    super(props)

    this.state={
      pages: [
        {
          img: "../../img/thumb/NameSite1.png",
          title: <strong>Get Started</strong>,
          text: <span>Enter your bids and jobs to see your sites organized and cross-checked for matches.</span>,
        },
        {
          img: "../../img/thumb/OfficeGroupNew.png",
          title: <strong>Invite Your Coworkers!</strong>,
          text: <span>Invite estimators and project managers in your office to cross-check internally</span>,
        },
        {
          img: "../../img/thumb/MatchesPanel.png",
          title: <strong>Find others outside your office.</strong>,
          text: <span>Anonymously search, post, and chat about details with matches outside your company</span>,
        }
      ]
    }
  }

  render(){

    return(<Fragment>

      <BaseGuideModal id="setup" markAsSeen={this.props.markAsSeen} pages={this.state.pages} />

    </Fragment>)

    return null;
  }

}