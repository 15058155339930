import React, { Component, Fragment } from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import Resource from './Resource'
import Archive from './Archive'
import SiteGroup from './SiteGroup'
import ReactTable from 'react-table-6'
import matchSorter from "match-sorter"
import { url } from '../../constants'
import {
  ApiService,
  HaulCheckService,
  MatchesService,
  ConversationsService
} from '../../services'
import '../../assets/css/page.css'
import '../../assets/css/style.min.css'
import '../../assets/css/ei-style.css'
import 'react-table-6/react-table.css'

class HaulChecks extends Component {

  state = {
    username: '',
    customer: 'cus_GQ97iqlxB7ztFM',
    bids: [],
    listResources:[],
    quantities: [],
    posts: [],
    archived: [],
    showArchive: false,
    showFilter: false,
    archiveChecked: {
      value: false,
      id: null
    },
    renewalChecked: {
      value: true,
      id: null
    },
    bidOpen: {},
    postOpen: {},
    change: false
  }

  toggleArchive = e => {
    this.setState({
      ...this.state,
      showArchive: !this.state.showArchive
    })
  }

  toggleBidPanel = e => {
    this.setState({
      ...this.state,
      bidOpen: {
        ...this.state.bidOpen,
        [e.target.id]: !this.state.bidOpen[e.target.id]
      }
    })
  }

  addResources = e => {
    this.setState({
      ...this.state,
      bidOpen: {
        ...this.state.bidOpen,
        [e.target.id]: !this.state.bidOpen[e.target.id]
      }
    })
  }

  togglePostPanel = e => {
    this.setState({
      ...this.state,
      postOpen: {
        ...this.state.postOpen,
        [e.target.id]: !this.state.postOpen[e.target.id]
      }
    })
  }

  toggleFilter = () => {
    this.setState({
      ...this.state,
      showFilter: !this.state.showFilter
    })
  }

  handleModalSave = (sitename) => {
    const { renewalChecked, archiveChecked } = this.state
    const { value: valueRC, id: idRC } = renewalChecked
    const { value: valueAC, id: idAC } = archiveChecked

    this.setHaulRenewal(idRC, valueRC, sitename)

    if (valueAC) {
      this.setHaulArchived(idAC, sitename)
    }
  }

  toggleHaulArchived = (e, id) => {
    this.setState({
      ...this.state,
      archiveChecked: {
        value: e.target.checked,
        id
      }
    })
  }

  toggleHaulAutorenewal = (e, haul) => {
    const id = haul.idsite_resource
    const oldBids = [...this.state.bids]
    const oldPosts = [...this.state.posts]

    const bids = oldBids.map(bid => {
      if (bid.name === haul.name) {
        const { resources } = bid

        bid.resources = resources.map(resource => {
          if (resource.idsite_resource === haul.idsite_resource) {
            resource.renewal = !resource.renewal
          }
          return resource
        })
      }
      return bid
    })

    const posts = oldPosts.map(post => {
      if (post.name === haul.name) {
        const { resources } = post

        post.resources = resources.map(resource => {
          if (resource.idsite_resource === haul.idsite_resource) {
            resource.renewal = !resource.renewal
          }
          return resource
        })
      }
      return post
    })

    this.setState({
      ...this.state,
      bids,
      posts,
      renewalChecked: {
        value: e.target.checked,
        id
      },
      change: true
    })
  }

  setHaulRenewal = async (id, value, sitename) => {
    const oldBids = [...this.state.bids]
    const oldPosts = [...this.state.posts]
    let bids = []
    let posts = []
    let { change } = this.state, bidFlag = false, postFlag = false, regSite = 0, regResource = 0, count1 = 0, count2 = 0

    bids = oldBids.map(bid => {
      if (bid.name === sitename) {
        const { resources } = bid
        bid.resources = resources.map(resource => {
          if (resource.idsite_resource === id && resource.renewal !== value) {
            // change = true
            bidFlag = true
            regResource = count2
            regSite = count1
          }
          count2++
          return resource
        })
      }
      count1++
      return bid
    })

    count1 = count2 = 0

    posts = oldPosts.map(post => {
      if (post.name === sitename) {
        const { resources } = post
        post.resources = resources.map(resource => {
          if (resource.idsite_resource === id && resource.renewal !== value) {
            // change = true
            postFlag = true
            regResource = count2
            regSite = count1
          }
          count2++
          return resource
        })
      }
      count1++
      return post
    })

    console.log(bids[regSite].resources[regResource].renewal = value)
    if (change) {
      if (bidFlag) {
        bids[regSite].resources[regResource].renewal = value
      }
      if (postFlag) {
        posts[regSite].resources[regResource].renewal = value
      }
      if (value) {
        await HaulCheckService.setAutorenewal(id).then(() => {
          this.setState({
            ...this.state,
            bids,
            posts
          })
        })
      } else {
        await HaulCheckService.unsetAutorenewal(id).then(() => {
          this.setState({
            ...this.state,
            bids,
            posts
          })
        })
      }
    }
  }

  setHaulArchived = async (id, sitename) => {
    const oldBids = [...this.state.bids]
    const oldPosts = [...this.state.posts]
    let archived = [...this.state.archived]
    let bids = []
    let posts = []

    await HaulCheckService.setArchived(id).then(() => {
      bids = oldBids.filter(bid => {
        if (bid.name === sitename) {
          console.log('Name', bid);
          const { resources } = bid

          bid.resources = resources.map(resource => {
            if (resource.idsite_resource !== id) {
              return resource
            }
            resource.archived = 1
            archived.push(resource)
            console.log('Resource archived:', resource);
          })
        }
        return bid
      })

      posts = oldPosts.filter(post => {
        if (post.name === sitename) {
          console.log('Name', post);
          const { resources } = post

          post.resources = resources.map(resource => {
            if (resource.idsite_resource !== id) {
              return resource
            }
            resource.archived = 1
            archived.push(resource)
            console.log('Resource archived:', resource);
          })
        }
        return post
      })

      this.setState({
        ...this.state,
        bids,
        posts,
        archived
      })
    })
  }

  unsetHaulArchived = async (id) => {
    let bids = [...this.state.bids]
    let posts = [...this.state.posts]
    let oldArchived = [...this.state.archived]
    let archived = []

    await HaulCheckService.unsetArchived(id).then(() => {
      archived = oldArchived.map(archive => {
        if (archive.idsite_resource !== id) {
          return archive
        }
        archive.archived = 0
        if (archive.iddiscoverymode === 1) {
          bids.push(archive)
        }
        if (archive.iddiscoverymode === 2) {
          posts.push(archive)
        }
      })

      this.setState({
        ...this.state,
        bids,
        posts,
        archived
      })
    })
  }

  renderBidResources = (bids) => {
    //const { bids } = this.state
    return bids.map((bid, index) => {
      if (bid === undefined) {
        return
      }
      return (
        <Fragment key={ bid.idsite_resource }>
          <Resource
            haul={bid}
            toggleHaulArchived={this.toggleHaulArchived}
            toggleHaulAutorenewal={this.toggleHaulAutorenewal}
            handleModalSave={this.handleModalSave}
          />
          { (index < bids.length) ? <hr className="mt-0 mb-2" /> : ''}
        </Fragment>
      )
    })
  }

  renderPostResources = (posts) => {
    // const { posts } = this.state

    return posts.map((post, index) => {
      if (post === undefined) {
        return
      }
      return (
        <Fragment key={ post.idsite_resource }>
          <Resource
            haul={post}
            toggleHaulArchived={this.toggleHaulArchived}
            toggleHaulAutorenewal={this.toggleHaulAutorenewal}
            handleModalSave={this.handleModalSave}
          />
          { (index < posts.length) ? <hr className="mt-0 mb-2" /> : ''}
        </Fragment>
      )
    })
  }

  renderArchive = () => {
    const { archived, showFilter } = this.state
    const archivedHC = archived.map(r => {
      const date = r.lastMatchDate ? new Date(r.lastMatchDate) : false
      return {
        site:            r.name,
        date:            date ? `${date.getFullYear()}/${date.getMonth()}/${date.getDate()}` : '',
        resource:        `${r.resource} ${r.type}`,
        quantity:        `${(r.quantity || 0).toLocaleString('en')}s of CY's`,
        archived:        r.archived,
        idsite_resource: r.idsite_resource
      }
    })
    const columns = [{
      Header: <span><label className="lead mt-3 mb-6 mr-4 fw-500">ARCHIVED RESOURCES</label></span>,
      columns: [{
          Header: <b>Site</b>,
          accessor: 'site',
          filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["site"] }),
          filterAll: true,
          filterable: showFilter,
          style: {textAlign: "Left"}
        }, {
          Header: <b>Date</b>,
          accessor: 'date',
          filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["date"] }),
          filterAll: true,
          filterable: showFilter,
          style: {textAlign: "Left"}
        }, {
          Header: <b>Resource</b>,
          accessor: 'resource',
          filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["resource"] }),
          filterAll: true,
          filterable: showFilter,
          style: {textAlign: "Left"}
        }, {
          Header: <b>Qty OOM</b>,
          accessor: 'quantity',
          filterMethod: (filter, rows) => matchSorter(rows, filter.value, { keys: ["quantity"] }),
          filterAll: true,
          filterable: showFilter,
          style: {textAlign: "Left"}
        }, {
          Cell: row => {
            return (
              <button
                className="btn btn-xs btn-outline-secondary"
                data-toggle="modal"
                data-target="#modal-closed"
                onClick={() => this.redirectToMatches(row.original)}>
                &nbsp;VIEW RECORDS&nbsp;
              </button>
            )
          }
      }]
    }]
    return (
      <div id="collapse-00" data-parent="#accordion-00">
        <div className="card-body">
          {/* <span><label className="lead mt-3 mb-6 mr-4 fw-500">ARCHIVED RESOURCES</label></span> */}

          <div className="flexbox mt-0 mb-4">
            <button className="btn btn-outline-secondary" onClick={this.toggleFilter}><i className="fa fa-filter" aria-hidden="true"></i>&nbsp;FILTER</button>
            <a></a>
          </div>

          <ReactTable
            data={archivedHC}
            columns={columns}
            defaultPageSize={10}
            minRows={1}
            className="-striped -highlight"
            resizable={true}
            style={ {overflowY: 'auto'} }
          />

        </div>
      </div>
    )
  }

  renderArchiveHauls = () => {
    const { archived } = this.state

    return (
      archived.map(archive => {
      return (
        <Fragment key={archive.idsite_resource}>
          <Archive
            haul={ archive }
          />
        </Fragment>
      )
    }))
  }

  renderBidGroups() {
    const { bids } = this.state
    return (
      bids.map(bid => {
        if (bid.resources.length !== 0) {
          return (
            <SiteGroup
              key={ bid.name }
              name={ bid.name }
              resources={ bid.resources }
              resourcesList={this.state.listResources}
              quantities={this.state.quantities}
              open={ this.state.bidOpen[bid.name] }
              renderBidResources={ this.renderBidResources }
              togglePanel={ this.toggleBidPanel.bind(this) }
              addResources={ this.addResources.bind(this) }
              refreshResources = { this.getHaulChecks }
            />
          )
        }
      })
    )
  }

  renderPostGroups() {
    const { posts } = this.state

    return (
      posts.map(post => {
        if (post === undefined) {
          return
        }
        if (post.resources.length !== 0) {
          return (
            <SiteGroup
              key={ post.name }
              name={ post.name }
              resources={ post.resources }
              open={ this.state.postOpen[post.name] }
              renderBidResources={ this.renderPostResources }
              togglePanel={ this.togglePostPanel.bind(this) }
              resourcesList={this.state.listResources}
              quantities={this.state.quantities}
              refreshResources = { this.getHaulChecks }
            />
          )
        }
      })
    )
  }

  matchesAndMessagesCount() {
    const oldBids = this.state.bids
    const oldPosts = this.state.posts
    //console.log('Inside Interval')

    const bids = oldBids.map(bid => {
      const oldResources = bid.resources
      //console.log('Inside Bids')
      const resources = oldResources.map(async resource => {
        await MatchesService.getMatchesCount(resource.idsite_resource).then(async matchesCount => {
          //console.log('MatchesCount', matchesCount)
          await ConversationsService.getMessagesCount(resource.idsite_resource).then(messagesCount => {
            //console.log('MessagesCount', messagesCount)
            resource.matchesCount = matchesCount
            resource.messagesCount = messagesCount
            console.log('Bid:', resource.idsite_resource, messagesCount)
            // this.setState({
            //   ...this.state,
            //   bids
            // })
          })
        })
        return resource
      })
      return bid
    })

    const posts = oldPosts.map(post => {
      const oldResources = post.resources
      //console.log('Inside posts')
      const resources = oldResources.map(async resource => {
        await MatchesService.getMatchesCount(resource.idsite_resource).then(async matchesCount => {
          //console.log('MatchesCount', matchesCount)
          await ConversationsService.getMessagesCount(resource.idsite_resource).then(messagesCount => {
            //console.log('MessagesCount', messagesCount)
            resource.matchesCount = matchesCount
            resource.messagesCount = messagesCount
            console.log('Post:', resource.idsite_resource, messagesCount)
            // this.setState({
            //   ...this.state,
            //   posts
            // })
          })
        })
        return resource
      })
      return post
    })

    this.setState({
      ...this.state,
      bids,
      posts
    })
  }

  async componentDidMount() {
    await this.getHaulChecks();
    
    this.interval = setInterval(() => this.matchesAndMessagesCount(), 60000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getHaulChecks = async () => {
    const username = ApiService.getJWTUsername()
    const resourcesUnasigned = await ApiService.getResources()
    const quantitiesList = await ApiService.getQuantities()

    await HaulCheckService.getBids(username).then(async bids => {
      await HaulCheckService.getPosts(username).then(async posts => {
        await HaulCheckService.getArchived(username).then(archived => {
          bids.map(bid => {
            this.setState({
              ...this.state,
              bidOpen:{
                ...this.state.bidOpen,
                [bid.name]: true
              }
            })
          })
          posts.map(post => {
            this.setState({
              ...this.state,
              postOpen:{
                ...this.state.postOpen,
                [post.name]: true
              }
            })
          })
          this.setState({
            ...this.state,
            username,
            bids,
            posts,
            archived,
            listResources:resourcesUnasigned,
            quantities:quantitiesList

          })
        })
      })
    })
  }

  redirectToMatches = (haul) => {
    localStorage.setItem('newHaulCheckID', JSON.stringify(haul))
    window.location.href = `${url.frontend}/matches-inbox`
  }

  render() {
    return (
      <Fragment>
        <Navbar showSandbox={true}/>
        <main className="main-content">
          <title>Haul Checks</title>
          <header className="section-header mt-7 mb-0">
            <h2><strong>My Searches & Posts</strong></h2>
          </header>
          <div className="section">
            <div className="container">

              <div className="col-lg-10 mx-auto flexbox">
                <a></a>
                <a className="btn btn-primary" href="/resource-check-setup">+ New Haul Check</a>
                <a></a>
              </div>

              { /* BID SEARCHES */}
              <div className="row">
                <div className="col-11 col-lg-12 mx-auto mt-6" >
                  <span><label className="lead mt-5 mb-4 mr-4 ml-7 fw-500">BID SEARCHES</label></span>
                </div>
              </div>

              { /* Site Group */}
              {/* <div className="row">
                <div className="col-10 col-lg-11 mx-auto accordion accordion-arrow-right site-listing" id="accordion-1">
                  <div className="card border mb-4">
                    <label className="lead fw-400 float-left card-title">
                      <div id="1st COMPANY" onClick={e => this.togglePanel.bind(this)(e)} style={{cursor:'pointer'}}>1st COMPANY</div>
                    </label>

                    <div id="collapse-1" className="collapse show" data-parent="#accordion-1">
                      <div className="card-body">

                        { this.state.open['1st COMPANY'] ? this.renderBidResources() : null }

                      </div>
                    </div>
                  </div>
                </div>

              </div> */}

              { this.renderBidGroups() }

              { /* CONSTRUCTION POSTS */ }
              <div className="row">
                <div className="col-11 col-lg-12 mx-auto mt-6" >
                  <span><label className="lead mt-6 mb-4 mr-4 ml-7 fw-500">CONSTRUCTION POSTS</label></span>
                </div>
              </
              div>

              { /* Site Group */ }
              {/* <div className="row">
                <div className="col-10 col-lg-11 mx-auto accordion accordion-arrow-right site-listing" id="accordion-1">
                  <div className="card border mb-4">
                    <label className="lead fw-400 float-left card-title">
                      <div id="2nd COMPANY" onClick={e => this.togglePanel.bind(this)(e)} style={{cursor:'pointer'}}>2nd COMPANY</div>
                    </label>

                    <div id="collapse-1" className="collapse show" data-parent="#accordion-1">
                      <div className="card-body">

                      { this.state.open['2nd COMPANY'] ? this.renderPostResources() : null }

                      </div>
                    </div>
                  </div>
                </div>

              </div> */}

              { this.renderPostGroups() }

              { /* ARCHIVE */ }

              <div className="row">
                <div className="col-10 col-lg-11 mx-auto accordion accordion-light mt-6" id="accordion-00">
                  <div className="card noborder">
                    <h5 className="card-title">
                      <a className="btn btn-sm btn-outline-light mt-2 mb-2" onClick={e => this.toggleArchive.bind(this)(e)}>OPEN ARCHIVE</a>
                    </h5>
                    { this.state.showArchive ? this.renderArchive() : null }
                  </div>

                </div>

              </div>

            </div>
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default HaulChecks
