import React from 'react'
import ModalAddResources from './ModalAddResources'
import Modal from 'react-modal'


const customStyles = {
  content: {
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    marginRight : '-50%',
    transform   : 'translate(-50%, -50%)'

  }
  // Import font-family

}


const SiteGroup = (props) => {
    const {name, resources, open, renderBidResources, togglePanel, resourcesList, quantities, refreshResources} = props
    const [modalIsOpen, setIsOpen] = React.useState(false)
    const [arrayAddResources, setAddArrayResources]=  React.useState([resources])

    const openModal = () => {
      setIsOpen(true)
    }

    const afterOpenModal = () => {
      //subtitle.style.color = '#F00'
    }

    const closeModal = () => {
      setIsOpen(false)
    }

    return (
        <div className="row">
            <div className="col-10 col-lg-11 mx-auto accordion accordion-arrow-right site-listing" id="accordion-1">
                <div className="card border mb-4">
                        <div className="col-12 col-lg-12 mx-auto flexbox card-title" >
                          <div  className="col-lg-8" id={ name } onClick={ e => togglePanel(e) } style={{cursor:'pointer'}}>
                            { name }
                          </div>
                          <div className="col-lg-2">
                            <div className="btn btn-primary" onClick={openModal}>+ Add resource</div>
                          </div>
                          <div className="col-lg-2">
                            <label className="lead fw-400 float-center"></label>
                          </div>
                        </div>
                    <div id="collapse-1" className="collapse show" data-parent="#accordion-1">
                        <div className="card-body">
                            { open ? renderBidResources(resources) : null }
                        </div>
                    </div>
                </div>
                <Modal
                  isOpen={modalIsOpen}
                  onAfterOpen={afterOpenModal}
                  onRequestClose={closeModal}
                  style={customStyles}
                  contentLabel="Haul Control"
                >
                  <ModalAddResources
                    idResource={resources}
                    resourcesList={resourcesList}
                    addResources={arrayAddResources}
                    quantitiesList={quantities}
                    closeModal={closeModal}
                    refreshResources={refreshResources}
                  />
                </Modal>
            </div>

        </div>
    )
}

export default SiteGroup
