import React from 'react';


export default function Key({ }){

	return( <div class="col-12 border bg-light clearfix" id="key">

		<div class="p-2 float-left">
			<span class="marker float-left mr-2">
				<img src="/img/map-markers/selected.svg" />
			</span> Selected Site
		</div>
		<div class="p-2 float-left">
			<span class="marker float-left mr-2">
				<img src="/img/map-markers/construction.svg" />
			</span> Job Sites
		</div>
		<div class="p-2 float-left">
			<span class="marker float-left mr-2">
				<img src="/img/map-markers/bid.svg" />
			</span> Bid Sites
		</div>
		<div class="p-2 float-left">
			<span class="marker float-left mr-2">
				<img src="/img/map-markers/pending.svg" />
			</span> Pending Sites
		</div>
		<div class="p-2 float-left">
			<span class="marker float-left mr-2">
				<img src="/img/map-markers/sponsored.svg" />
			</span> Sponsored Sites
		</div>
		<div class="p-2 float-left">
			<span class="marker float-left mr-2">
				<img src="/img/map-markers/superblast.svg" />
			</span> Superblast Sites
		</div>


	</div>);

}