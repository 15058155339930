import React, { Component } from 'react'
import PropTypes from "prop-types";
import OwnerBaseMessageLayout from './OwnerBaseMessageLayout';
import BaseConnectMessage from './BaseConnectMessage';

class OwnerConnectMessage extends Component {
    static  propTypes ={
        idconversation: PropTypes.number,
        textmessage: PropTypes.string,
        resource: PropTypes.string,
        type: PropTypes.string,
        messagedate: PropTypes.string,
        originusermessage:PropTypes.string,
        username:PropTypes.string,
        name:PropTypes.string,
        mail:PropTypes.string,
        inc:PropTypes.string,
        phonenumber:PropTypes.string,

    }

    render() {
        const { idconversation, textmessage, resource, type, messagedate, hours, minutes, username, originusermessage,name, mail, inc, phonenumber} = this.props
        return (
            <OwnerBaseMessageLayout
                messagedate={messagedate}
                hours={hours}
                minutes={minutes}
                originname={this.props.originname}
                displayoriginname={this.props.displayoriginname}
                >
                    <BaseConnectMessage
                        name={name}
                        mail={mail}
                        inc={inc}
                        phonenumber={phonenumber}
                    />
            </OwnerBaseMessageLayout>
        )
    }
}

export default OwnerConnectMessage
