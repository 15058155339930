import React, { useState, useEffect, Fragment } from 'react';
import {
	url
	//earthidealAPIURL
} from '../../constants';
import _ from "lodash"

export default function CheckoutInterface({render, material}){

	let [state, setState] = useState(null);

	let createLocalStorageToken = (passedMaterial, redirect) => {
		

		let matchesPanelData = JSON.parse(localStorage.getItem("matchesPanel"));

		console.log("createLocalStorageToken test?????")
		console.log("createLocalStorageToken passedMaterial:", passedMaterial.iddiscoverymode);
		console.log("createLocalStorageToken matchesPanelData:", matchesPanelData);

		if (matchesPanelData.idsiteresource != passedMaterial.idsite_resource) {
			let newMatchesPanelData = _.cloneDeep(passedMaterial)
			newMatchesPanelData.idsiteresource = newMatchesPanelData.idsite_resource;
			console.log("createLocalStorageToken newMatchesPanelData:", newMatchesPanelData);
			localStorage.setItem("matchesPanel", JSON.stringify(newMatchesPanelData))
		}

		let postInfo = JSON.stringify({material: _.cloneDeep(passedMaterial)});
		localStorage.setItem('postInfo', postInfo);

		if (passedMaterial.iddiscoverymode == 2){
			localStorage.setItem("checkoutDetailsNext", redirect);
			window.location.href = "/checkout-details";
		} else {
			window.location.href = redirect;
		}
	}

	let goToReferral = () => {
		createLocalStorageToken(material, "/haul-check-invite/post");
	}

	//Implemented for FEA-1308
	let goToBlastReferral = () => {
		createLocalStorageToken(
			setLocalStorageIsBlast(material),
			"/haul-check-invite/blast"
		);
	}


	let goToPost = () => {
		createLocalStorageToken(material, "/checkout");
	}

	let goToBlast = () => {
		createLocalStorageToken(
			setLocalStorageIsBlast(material), 
			"/checkout"
		);
	}

	let setLocalStorageIsBlast = (passedMaterial) => {
		let cloneData = _.cloneDeep(passedMaterial)
		cloneData.isBlast = true;
		return cloneData;
	}


	return render(goToReferral, goToPost, goToBlast, goToBlastReferral, state, setState);
}