import _ from 'lodash';

//This is some syntactic sugar for rare cases where JSX is not available
//and creation of DOM Nodes is required, such as when working inside of
//HERE maps. The JSON better immitates the structure of standard HTML.

//Please do not use this code for building standard UI elements outside
//of these cases.

export function buildElementsFromJSON(elements){

	let toReturn = null;

	if (typeof elements == "object"){
		toReturn = createElement(elements);

	} else if (Array.isArray(elements)){
		toReturn = [];

		for (var i = 0; i < elements.length; i++){
			toReturn.push(createElement(elements[i]));
		}
	}

	return toReturn;

}

function createElement(element) {

	let {elementType, attributes, children, callback} = element;

	let el = document.createElement(elementType);

	if (!_.isEmpty(attributes)){
		let attributeKeys = Object.keys(attributes)

		for (var i = 0; i < attributeKeys.length; i++){
			let attributeType = attributeKeys[i];
			let attributeVal = attributes[attributeKeys[i]];

			el.setAttribute(attributeType, attributeVal);
		}
	}

	if (!_.isEmpty(children)){
		if (typeof children == "string"){
			el.textContent = children;
		} else {
			for (var i = 0; i < children.length; i++){
				let child = createElement(children[i]);
				el.appendChild(child);
			}

		}
	}

	if (callback && typeof callback == "function"){
		el.onclick = callback;
	}
	
	//console.log("buildElementsFromJSON returning element:", el, "with callback", callback, "which is truthy?", !!callback, "is a function?", (typeof callback == "function"))
	return el;
}