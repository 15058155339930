import React, {Component, Fragment}  from 'react';
import {url, earthidealAPIURL} from '../../constants';
import jwtDecode from 'jwt-decode';
import MyMaterial from './MyMaterial';
import MatchList from './MatchListDisplay';
import RowWrapper from './MatchListDisplay/RowWrapper';
import OpenMaterialInfoButton from "./OpenMaterialInfoButton"
import SandboxMap from '../SandboxMap/SandboxWrapper'
import OptInModal from '../SiteMaterials/OptInToPayment/OptInModal'
import MobileDisplaySwitcher from '../common/MobileDisplaySwitcher'
import { Popover, OverlayTrigger } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import '../MySites/styles.css'
import StatusBar from './StatusBar'
import ShareDetailsAllButton from './ShareDetailsAllButton'

import {
  ApiService,
  HaulCheckService,
  PMGroupsService,
  SharesDetailsService,
  PublishSubscribe
  //MatchesService,
  //ConversationsService
} from '../../services'

export default class InHouse extends Component {

  constructor(props){
    super(props);
    const userJWT = localStorage.getItem('token');
    const userDecoded = jwtDecode(userJWT);

    let username = userDecoded['cognito:username'];
    let email = userDecoded.email;

    this.state = {
      email,
      group: {},
      siteData: {geom: {x: null, y: null}},
      modalInviteIsOpen: false,
      archiveMode: false,
      //externalMatches: [],
      reloadSharedDetails: false,
      bidGraveyardList: [],
      sameList: [],
      //matchList: [],
      loading: true,
      lastIdSiteResource: 0,
      toggleDetailsToHidden: true,
      bidsNum: 0,
      postsNum: 0,

      internalsBid: [],
      externalsBid: [],
      internalsPost: [],
      externalsPost: [],
      routes: [],
      routesMapBounds: [],
      tallyData: null,
      postingExternally: false,
      myMaterial: {idsite_resource: 0},
      panelDisplayState: "matches",
      hasExternalAccess: false,

      myMaterialHeaderHeight: 130,

      sortBy: "travelTime", //Or "distanceFromResource"
    } 

    this.client = null;

  }

  componentWillReceiveProps = async (newProps) => {
    let {lastIdSiteResource} = this.state;

    if (newProps.idsite_resource != lastIdSiteResource && newProps.idsite_resource != 0 && newProps.idsite != 0){
      this.refreshPanel(newProps.idsite_resource, newProps.idsite, newProps.matches, "componentWillReceiveProps");
      this.setState({toggleDetailsToHidden: true}, () => console.log("BUG-1046", "componentWillReceiveProps render"));
    }
  }

  componentDidMount = async () => {
    let hasExternalAccess = await ApiService.getUserHasExternalAccess();
    this.setState({hasExternalAccess});

    if (this.props.idsite_resource != 0 && this.props.idsite != 0){
      this.refreshPanel(this.props.idsite_resource, this.props.idsite, this.props.matches, "componentDidMount");
      this.setState({toggleDetailsToHidden: true}, () => console.log("BUG-1046", "componentDidMount render"));
    }

    window.addEventListener('wheel', this.onScroll);

  }

  componentDidUpdate = () => {
    ReactTooltip.rebuild();


  }

  componentWillUnmount = () => {
    console.log("Ghostly Overhang Debug", "Calling InHouse componentWillUnmount and clearing its routes");
    this.setState({routes: []});
    this.removeInterval();
    window.removeEventListener('wheel', this.onScroll);

  }

  createInterval = (idsite_resource, idsite, matches) => {
    //console.log("Calling createInterval with params: ",idsite_resource, idsite, matches)
    //this.removeInterval();

    //this.interval = setInterval(() => {
    //  this.refreshPanel(idsite_resource, idsite, matches, "interval");
    //}, 15 * 1000)

    if (this.client === null){
      this.client = PublishSubscribe.quickSubscribe(
        "InHouse/index.js",
        "HC/materials",
        {idsite_resource},
        this.handleMessage
      )
    }
  }

  removeInterval = () => {
    this.client = PublishSubscribe.closeSubscription(
      "InHouse/index.js",
      "HC/materials",
      {idsite_resource: this.props.idsite_resource}
    );
  }

  handleMessage = (message) => {
    console.log("AMQ calling InHouse handleMessage with message", message)
    message = JSON.parse(message);
    console.log("AMQ calling InHouse handleMessage with message.type", message.type)

    let local_refreshPanel = () => this.refreshPanel(
        this.props.idsite_resource, 
        this.props.idsite, 
        this.props.matches, 
        "handleMessage with message " + message
      )

    if (message.type == "updated-material"){
      let messageData = JSON.parse(message.data);
      if (messageData.key == "matchesPanelCount"){
        local_refreshPanel()
      }
    } else if (message.type == "new-messages" || message.type == "sent-messages"){
        console.log("AMQ calling InHouse handleMessage refresh handler for message")
        setTimeout(local_refreshPanel , 1000);
    }
  }

  refreshPanel = async (idsite_resource, idsite, matches, origin) => {
    console.log("showSiteRoutes debug", "calling refreshPanel with params", idsite_resource, idsite, matches, origin)
    let {lastIdSiteResource, client, sortBy, hasExternalAccess} = this.state;
    this.createInterval(idsite_resource, idsite, matches);

    let tallyViews = (origin == "componentDidMount" || origin == "componentWillReceiveProps")
    //console.log("InHouse lastIdSiteResource:", lastIdSiteResource, "idsite_resource param:", idsite_resource);
    //console.log("InHouse idsite_resource != lastIdSiteResource:", idsite_resource != lastIdSiteResource)
    let loading = (idsite_resource != lastIdSiteResource);
    this.setState({loading, lastIdSiteResource: idsite_resource}, () => console.log("BUG-1046", "refreshPanel render start loading"));

    await this.getDisplayMatches(idsite_resource, idsite, sortBy, hasExternalAccess);

    let tallyData = null;
    if (hasExternalAccess) {
      tallyData = await HaulCheckService.getExternalMatchTally(idsite_resource);
    }

    let oldState = {loading: this.state.loading, tallyData: this.state.tallyData, postingExternally: this.state.postingExternally}
    let newState = {loading: false, tallyData, postingExternally: false}

    if (!_.isEqual(newState, oldState)) {
      this.setState(newState, () => console.log("refreshPanel setState with newState", newState, "and oldState", oldState));
    } else {
      console.log("refreshPanel not setting state with newState", newState, "and oldState", oldState)
    }

  }


  /* Load and process matches */

  getDisplayMatches = async(idsite_resource, idsite, sortBy, hasExternalAccess) => { 
    console.log("Calling getExternalMatches for params ", idsite_resource, idsite);
    let matches = fetch(`${earthidealAPIURL}/displayMatches/get/${this.props.idsite_resource}/${this.state.sortBy}`)


    .then(res =>res.json())
    .then(results => {
      let {myMaterial, matchesLists, matchesListsKeys, routeData} = results;
      let {uniqueDestinations, routes, coordBounds} = routeData;

      console.log("getExternalMatches results:", results);

      let newState = {};

      this.updateStateIfDoesntMatch(myMaterial, "myMaterial")
      this.updateStateIfDoesntMatch(matchesLists, "matchesLists")
      this.updateStateIfDoesntMatch(matchesListsKeys, "matchesListsKeys")
      //this.updateStateIfDoesntMatch(uniqueDestinations, "uniqueDestinations")
      this.updateStateIfDoesntMatch(routes, "routes")
      this.updateStateIfDoesntMatch(coordBounds, "routesMapBounds")

    })

    return matches;
  }

  updateStateIfDoesntMatch = (newVal, key, callback) => {
    let newState = {};
      if (!_.isEqual(this.state[key], newVal)) newState[key] = _.cloneDeep(newVal);


     if (!_.isEmpty(newState)){
        this.setState(newState, () =>{ 

          console.log("updateStateIfDoesntMatch state update with key",key,"and new state", newState)
        })
      } else {
        console.log("Calling updateStateIfDoesntMatch with key",key," but not updating")
      }
  }

  updateMyDetails = (details) => {
    let {myMaterial} = _.cloneDeep(this.state);
    myMaterial.details[0] = details;
    this.setState({myMaterial}, () => console.log("BUG-1046", "updateMyDetails render"));
  }


  toggleSharedDetailsReload = () => {
    this.setState({reloadSharedDetails: !this.state.reloadSharedDetails}, () => console.log("BUG-1046", "toggleSharedDetailsReload render"))
  }


  toggleRouteVisible = (idroutedistance) => {
    if (idroutedistance == null) return;
    else {
      let {routes} = this.state;
      let {idsite_resource} = this.props;

      console.log("BUG-864", "Calling toggleRouteVisible with idroutedistance" + idroutedistance + " and routes " + routes);

      for (var i = 0; i < routes.length; i++){
        if (routes[i].idroutedistance == idroutedistance){
          console.log("BUG-864", "toggling route " + idroutedistance + " to " + !routes[i].visible);
          routes[i].visible = !routes[i].visible;
          routes[i] = _.cloneDeep(routes[i]);
        }
      }

      this.setState({routes: []}, () => this.setState({routes: _.cloneDeep(routes)}, () => console.log("BUG-1046", "toggleRouteVisible render")));
    }
  }

  getRouteExistsAndIsVisible = (idroutedistance) => {

    let exists = false;
    let isVisible = false;

    if (idroutedistance != null) {
      let {routes} = this.state;
      console.log("BUG-864", "Calling getRouteIsVisible with idroutedistance" + idroutedistance + " and routes " + routes);

      for (var i = 0; i < routes.length; i++){
        if (routes[i].idroutedistance == idroutedistance && (routes[i].idsite_origin != routes[i].idsite_destination)){
          exists = true;
          if (routes[i].visible){
            isVisible = true;
          }
        }
      }
    }

    return {exists, isVisible}
  }

    renderHeaderView = (children) => {
      let {isMobile, isBrowser} = this.props;
      let {panelDisplayState} = this.state;

      if (isMobile){
        if (panelDisplayState == "header"){
          return  <section class="section py-0 shadow-2">
            <div class="bg-white">
              <div class="col-12 pt-3 px-lg-6 ">
                <div class="row">
                  <div class="col-12 mx-auto mb-8">  
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </section>
        } else {
          return null;
        }
      } else {
        return children;
      }
    }

    goBack = () => {
      this.props.history.push("/app/sites");
    }

    renderMatchesView = (children) => {

      let {isMobile, isBrowser} = this.props;
      let {panelDisplayState} = this.state;

      if (isMobile){
        if (panelDisplayState == "matches"){
         return  <main className="main-content">
            <section className="section py-0 shadow-2">
              <div className="bg-light">
                <div className="col-12 pt-3 px-lg-6 ">
                  <div className="row">
                    <div className="col-12 mx-auto mb-8">  
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        } else {
          return null;
        }
      }

      else if (isBrowser){
        return <div className="col-6 bg-white my-sites-scroll">
          <div className="col-12">

          <div className="bg-white border-bottom px-2" style={{position: 'sticky',top: '0px',zIndex: 500,backgroundColor: '#fff',height:"45px"}}>
            <p className="lead strong pt-1 pb-2 mb-0"><a className="text-light strong" href="#" onClick={this.goBack}><i className="fa fa-caret-left mr-2" aria-hidden="true"></i>Sites</a></p>
          </div>

            <div className="row">
              <div className="col-12 mx-auto mb-8"> 
                {children}
              </div>
            </div>
          </div>
        </div>
      }
    }

    renderSandboxView = (children) => {

      let {isMobile, isBrowser} = this.props;
      let {panelDisplayState} = this.state;

       if (isMobile){
        if (panelDisplayState == "routes"){
          return <main className="main-content">

            <section className="section py-0 shadow-2">
              <div className="row no-gutters">
    

                <div className="col-12">
                   <section className="section routes-map">
                    {children}
                   </section>
                </div>
              </div>
            </section>
          </main>
        } else {
          return null;
        }
      }

      if (isBrowser){
        return  <div className="col-6 sandbox my-sites-scroll" id={"routes-map-"+this.props.idsite_resource}>
          {children}
        </div>
      }
    }


  renderStatusBar = () => {
    let material = this.state.myMaterial; //Don't put the {} here
    let {isMobile, isBrowser} = this.props;

    if (this.state.hasExternalAccess){

      return <StatusBar
        material={material}
        isBlasted={material.isBlasted == 1}
        idsite_resource={material.idsite_resource}
        idsite={material.idsite}
        iddiscoverymode={material.iddiscoverymode}
        status={material.status}
        has_ever_been_external={material.has_ever_been_external}
        external_start_date={material.external_start_date}
        expiration_date={material.expiration_date}
        finalExternalMatchTally={this.state.tallyData ? this.state.tallyData.finalExternalMatchTally : -1}
        refreshPanel={() => this.refreshPanel(this.props.idsite_resource, this.props.idsite, this.props.matches, "myMaterial")}
        isMobile={isMobile}
        isBrowser={isBrowser}
      />
    } else return null;
  }

  renderHeader = () => {
    let {isMobile, isBrowser} = this.props;

    return <MyMaterial
      passedMaterial={this.state.myMaterial}
      toggleStatus={this.toggleStatus}
      openModalInvite={this.openModalInvite}
      expiration_date={this.props.expiration_date}
      toggleSharedDetailsReload={this.toggleSharedDetailsReload}
      isMobile={isMobile}
      isBrowser={isBrowser}
      backToSandbox={() => this.props.switchSidePanel(isMobile ? "sidebar" : "sandbox")}
      refreshPanel={() => this.getMaterials(this.props.idsite_resource, this.props.idsite, false)}
      refreshExternals={() => this.getExternalMatches(this.props.idsite_resource, this.props.idsite)}

      tallyData={this.state.tallyData}
    />
    
  }

  onScroll = () => {
    let myMaterialHeader = document.querySelector("#my-material-header")
    let myMaterialHeaderHeight = (myMaterialHeader ? myMaterialHeader.offsetHeight : 130)

    if (myMaterialHeaderHeight !== this.state.myMaterialHeaderHeight) this.setState({myMaterialHeaderHeight})
  }

  renderDisclaimer = () => {

    let style = {position: 'sticky',top: (this.state.myMaterialHeaderHeight + 45)+'px',zIndex: 500,backgroundColor: '#fff'}

    if (this.props.isMobile){
      style.top = this.state.myMaterialHeaderHeight;
    }

    return <RowWrapper>
      <p 
        className="border border-warning text-right font-weight-bold font-italic pr-4"
        style={style}
      >
        Time and distance are one-way haul approximations, always verify before using
      </p>

    </RowWrapper>
  }

  renderSortByButton = ({label, val, icon}, key) => {
    let buttonIsSelected = this.state.sortBy === val;

    return <button 
          key={key}
          type="button"
          className={`btn btn${buttonIsSelected ? "" : "-outline"}-primary`}
          onClick={() => {
            this.setState({sortBy: val}, this.getDisplayMatches);
          }}
          disabled={buttonIsSelected}
        > 
          <i class={"fa fa-"+icon}></i> {label}
        </button>
  }

  renderSortBy = () => {
    /*let buttons = [
      {
        label: "Travel Time",
        val: "travelTime",
        icon: "hourglass"
      },
      {
        label: "Distance",
        val: "distanceFromResource",
        icon: "truck"
      }
    ]

    return <RowWrapper>
      <p><label className="lead fs-17 mt-1">Sort By</label></p>

      <div className="btn-group btn-group-lg mb-3" role="group" aria-label="Sort Haul Check Matches">
        {
          buttons.map((button, key) => {
            return this.renderSortByButton(button, key);
          })
        }
        
      </div>
    </RowWrapper>*/

    return null;
  }

  render = () => {
    let {loading, postingExternally, myMaterial, group, siteData, modalInviteIsOpen, archiveMode, matchList, bidGraveyardList, sameList, toggleDetailsToHidden, internalsBid, internalsPost, externalsBid, externalsPost, tallyData, panelDisplayState, matchesLists, matchesListsKeys} = this.state;
    let {title, switchSidePanel, groupsListMode, idsite_resource, idsite, matches, isMobile, isBrowser} = this.props;

    if (loading) return <div className="my-sites-scroll">Getting matches...</div>
    else return (<Fragment>

      {isMobile ? <>

        <div className="bg-gray border-bottom px-2">
          <p className="lead strong pt-1 pb-2 mb-0"><a className="text-light strong"  href="#" onClick={() => switchSidePanel("sidebar")}><i className="fa fa-caret-left mr-2" aria-hidden="true"></i>Sites</a></p>
        </div>

        <MobileDisplaySwitcher
        changeDisplayState={(param) => this.setState({panelDisplayState: param})}
        buttons={[
          {
            icon: "ti ti-clipboard",
            displayState: "header",
            label: "Material",
            isActive: (panelDisplayState == "header"),
          },
          {
            icon: "ti ti-signal",
            displayState: "matches",
            label: "Matches",
            isActive: (panelDisplayState == "matches"),
          },
          {
            icon: "ti ti-truck",
            displayState: "routes",
            label: "Routes",
            isActive: (panelDisplayState == "routes"),
          }

        ]}

      /> 

    </> : null}

    <ReactTooltip />

    {isMobile ? this.renderHeaderView(
      this.renderHeader()
    ) : null}

    {this.renderMatchesView( <>

        <div className="row mb-2">
          <div className="col-12 mx-auto" style={this.props.isMobile ? {"maxHeight": "100vh", "overflow": "auto"} : null}>  

            {isBrowser ? this.renderHeaderView(
              this.renderHeader()
            ) : <div class="mb-0 bg-light" id="my-material-header" style={{position: 'sticky',top: '0px',zIndex: 500}}>
                <div class="p-2">
                  <p class="fw-600 mb-2">{myMaterial.name}</p>
                  <div class="border-bottom clearfix mb-4">
                    <p class="lead-4 float-left mb-0">{myMaterial.resource} {myMaterial.type == "Surplus/Export" ? "Surplus" : "Short"}</p>

                    <OpenMaterialInfoButton className={"btn-sm float-right mt-2"} />

                  </div>
                </div>
              </div>}


              {!_.isEmpty(this.state.matchesLists) && !_.isEmpty(this.state.matchesListsKeys) ? <>
                
                {this.state.matchesListsKeys.map((key, index) => {

                  console.log("Calling matchesListsKeys map with key", key, " and index", index)

                  let {matches, displayIfEmpty} = this.state.matchesLists[key];

                  if (!displayIfEmpty && _.isEmpty(matches)) {
                    return null;
                  } else {

                    return <MatchList
                      key={key}
                      title={key}
                      matches={matches}
                      index={index}
                      isMobile={isMobile}
                      isBrowser={isBrowser}
                      switchSidePanel={this.switchSidePanel}
                      groupsListMode={this.groupsListMode}
                      toggleRouteVisible={this.toggleRouteVisible}
                      getRouteExistsAndIsVisible={this.getRouteExistsAndIsVisible}
                      history={this.props.history}
                      openMessages={this.openMessages}
                      parentMaterial={myMaterial}
                      refreshPanel={this.refreshPanel}
                    >

                    {index == 0 ? <>
                      {this.renderStatusBar()}
                      {this.renderSortBy()}
                      {this.renderDisclaimer()}
                    </> : <></>}

                    </MatchList>
                  }

                                  
                })}


              </> : null}


          </div>
        </div>
    </>)}

    {this.renderSandboxView(
      <SandboxMap 
        locked={true} 
        sitesList={[]}
        groupSitesList={[]}
        getSiteList={() => {return []}} 
        sharedSite={null}
        setMapLocked={() => {return null}}
        sharedRoutes={this.state.routes}
        originSite={this.state.myMaterial}
        idsite_resource={idsite_resource}
        toggleRouteVisible={this.toggleRouteVisible}
        displaySites={false}
        isBrowser={this.props.isBrowser}
        isMobile={this.props.isMobile}
        routesMapBounds={this.state.routesMapBounds}
      />
    )}

    {/*<ModalShareDetails idsite_resource={myMaterial.idsite_resource} parent="matches" callback={this.updateMyDetails} forceReload={this.state.reloadSharedDetails} />*/}

    <OptInModal 
      callback={async (goToBlastReferral) => {
        this.setState({postingExternally: true}, () => console.log("BUG-1046", "Opt in modal rerender"))
        if (goToBlastReferral == true){
          await HaulCheckService.toggleStatus(idsite_resource);
          await HaulCheckService.getMatches(idsite);
          window.location.href = url.frontend + "/haul-check-invite/post";
        } else {
          await HaulCheckService.toggleStatus(idsite_resource);
          await HaulCheckService.getMatches(idsite);
          this.refreshPanel(idsite_resource, idsite, matches, "setStatus");
        }
      }} 
      idsite_resource={idsite_resource}
      iddiscoverymode={myMaterial.iddiscoverymode}
    />

    </Fragment>)
  }

  openOptInModal = () => {
    let {idsite_resource} = this.props;
    let button = document.getElementById("open-premium-"+idsite_resource);
    if (button){
      button.click();
    }
  }

}
