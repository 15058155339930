import React, {Component, Fragment} from 'react'
//import { CustomLogout } from '../Login/customlogout';
import jwtDecode from "jwt-decode";
import {haulcheckURl, assuredURL, assuredAPIURL, earthidealURL, url} from '../../constants';
import ajax from 'superagent';
import LetterAvatar from './LetterAvatar';
import NotificationItem from './NotificationItem';
import SupportGuide from './SupportGuide';
import NavbarCore from './NavbarCore';
import assuredPageUrls from '../../constants/assuredPageUrls';
import {ApiService} from '../../services'

export default class Navbar extends Component {

    constructor(props){
        super(props);
        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        const username = userDecoded ? userDecoded['cognito:username'] : null;

        this.state = {
            username,
            userDecoded,
            iduser: null,
            hasExternalAccess: false
        }
    }

    componentWillMount = async () => {
        let hasExternalAccess = await ApiService.getUserHasExternalAccess();
        this.setState({hasExternalAccess});

        ajax.post(`${assuredAPIURL}/users/auth`)
            .send(this.state.userDecoded)
            .end((error, response) => {
                if (!error && response) {
                    this.setState({iduser: response.body.user.iduser});                    
                } else {
                    console.log('There was an error', error);
                }
            }
        );
    }

    render = () => {
        return(<>
                <NavbarCore
                    haulcheckFrontendURL={url.frontend}
                    assuredFrontendURL={assuredURL}
                    assuredAPIURL={assuredAPIURL}
                    eiFrontendUrl={earthidealURL}
                    myFrontendUrl={url.frontend}
                    assuredPageUrls={assuredPageUrls}
                    app={"HaulCheck" + (this.state.hasExternalAccess ? "" : "-Internal")}
                    iduser={this.state.iduser}
                    username={this.state.username}
                    showSandbox={this.props.showSandbox}
                    showBetaBadge={false}
                    logo="/img/logo/hc-logo.svg"
                >
                    <a class="nav-link text-light fs-15 lh-2" style={{textTransform: "none", fontWeight: 400}} href="https://www.earthideal.com/help-center/haul-check-quick-start-guide" target="_blank">Quick Start Guide</a>
                    <a class="nav-link text-light fs-15 lh-2 cursor" style={{textTransform: "none", fontWeight: 400}} data-toggle="modal" data-target="#account-help-guide-modal">Account Help</a>
                    <a class="nav-link text-light fs-15 lh-2 cursor" style={{textTransform: "none", fontWeight: 400}} data-toggle="modal" data-target="#payment-guide-modal">Payment</a>
                    <a class="nav-link text-light fs-15 lh-2 cursor" style={{textTransform: "none", fontWeight: 400}} data-toggle="modal" data-target="#privacy-guide-modal">Your Privacy</a>
                    <a class="nav-link text-light fs-15 lh-2 cursor" style={{textTransform: "none", fontWeight: 400}} data-toggle="modal" data-target="#feedback-guide-modal">Give us Feedback</a>
                </NavbarCore>

                <SupportGuide />
            </>)
        //return null;
    }
}