import React, { useState, useEffect, Fragment } from "react";
import DatePicker from 'react-datepicker'

const ModalInvite = () => {

    return(<Fragment>

        <div className="modal fade" style={{zIndex: 5000}} id="modal-invite" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Tell others!</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <p className="lead">Would you like to reach out to a few specific people to tell them about your post?</p>
                        <p className="small mb-0">You get one free post every three months, then each external post after that is $15 per month. You can also earn credits toward free posts when you invite new users to Haul Check by telling them about your post.</p>

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">No thanks</button>
                        <a className="btn btn-primary" href="/haul-check-invite">Yes please</a>
                    </div>
                </div>
            </div>
        </div>
        <button style={{display:'none'}} type="button" id="open-modal-invite" data-toggle="modal" data-target="#modal-invite"></button>

    </Fragment>)


}

export default ModalInvite