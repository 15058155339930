import React, { useState, useEffect, Fragment } from "react";
import DatePicker, { registerLocale } from 'react-datepicker'
import {HaulCheckService} from '../../services'
import {hcGetIconForSiteOrMatch} from "../../utils"
import moment from 'moment'
import _ from 'lodash'
import enUS from 'date-fns/locale/en-US'
import '../SiteMaterials/datepickeradjust.css'
import './style.css'

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

import "react-datepicker/dist/react-datepicker.css"


export default function useMatchCriteria({onSubmit, onEdit, onLoad, origin}) {

    const [state, setStateDirect] = useState({loading: true})

    ///General functions for handling state, to help re-create the functionality of the class component
    const handleStateUpdate = (newState, funcCallback) => {

        setState(_.cloneDeep(newState), () => {
            if (onEdit && typeof onEdit == "function"){
               onEdit(newState);
            }

            if (typeof funcCallback == "function"){
                funcCallback()
            }
        })
    }

    const setState = (passedState, callback) => {
        let newState = _.cloneDeep(state);
        _.merge(newState, passedState);

        console.log("Calling useMatchCriteria setState with original state", state, "and passed state", passedState, "merged to create new state", newState)
        setStateDirect(newState);


        if (callback && typeof callback == "function"){
            callback()
        }
    }

    const handleChange = (key, val) => {
        //console.log("Calling handleChange with key: " + key + " and val:",val)
        handleStateUpdate({[key]: val});
    }

    const loadMatchCriteriaMaterial = async (idsite_resource) => {

        let material = await HaulCheckService.getMaterialInfo(idsite_resource, "useMatchCriteria")

        console.log("calling useMatchCriteria with fetched material", material, "from origin", origin);

        if (material && material.length != 0){

            material = _.cloneDeep(material[0])
            let startdate = new Date();
            let enddate = new Date();
            let today = new Date();

            if (material.available_date){
                startdate = new Date(material.available_date);
                startdate.setDate(startdate.getDate() + 1);

                if (material.available_end_date && (material.perennialEndDate === 0 || material.perennialEndDate === "0")){
                    enddate = new Date(material.available_end_date);
                    enddate.setDate(enddate.getDate() + 1);
                } else if (material.available_date && (material.perennialEndDate === 0 || material.perennialEndDate === "0")){
                    enddate = new Date(material.available_date);
                    enddate.setDate(enddate.getFullYear(), enddate.getMonth()+2, 0);
                } else if (material.perennialEndDate === 1 || material.perennialEndDate === "1"){
                    enddate = _.cloneDeep(startdate);
                }
            }

            let newState = {
                startDate: startdate,
                endDate: enddate,
                today,
                loading: false,
                isNewMaterial: false,
                imbalance: material.type,
                materialtype: material.resource
            }

            newState = _.merge(material, newState);

            setState(_.cloneDeep(newState));
            
        } else {
            setState({
                idsite_resource: 0,
                idquantity: 1,
                startDate: new Date(),
                endDate: new Date(),
                maxDistance: 40,
                loading: false,
                perennialEndDate: false,
                today: new Date(),
                isNewMaterial: true,
            })
        }



        if (onLoad && typeof onLoad == "function") onLoad();
    }

  
    let submit = async () => {

        let {idsite_resource, maxdistance, startDate, endDate, perennialEndDate, idquantity, idsite} = state;

        await HaulCheckService.updateMatchCriteria({
            idsite_resource: idsite_resource, 
            maxdistance: parseInt((maxdistance + "").replace(/\D/g,'')), 
            startdate: startDate, 
            enddate: endDate,
            idquantity,
            perennialEndDate
        });
        await HaulCheckService.getMatches(idsite);

        if (onSubmit && typeof onSubmit == "function") onSubmit();
    }

    let cancel = async () => {
        return null; /*Placeholder */
    }

    let renderMatchCriteriaMaterialType = (params = {}) => {
        let {className, render} = params;
        let {materialtype, imbalance, isNewMaterial} = state;

        let handleMaterialType = (materialtype) => setState({materialtype});

        let quantity = matchCriteriaQuantityString()

        if (render){
            return render({materialtype, handleMaterialType, imbalance, isNewMaterial, quantity})
        } else {
            return <p className="lead-4">{materialtype} {imbalance.split('/')[0]}</p>
        }


    }

    let renderMatchCriteriaImbalance = (params = {}) => {
        let {className, render} = params;
        let {imbalance, isNewMaterial} = state;

        let handleImbalance = (imbalance) => setState({imbalance});

        if (render){
            return render({imbalance, handleImbalance, isNewMaterial})
        } else {
            return null;
        }

    }

    let renderMatchCriteriaHeading = (params = {}) => {
        let {className, render} = params;
        let {name, sitename, imbalance, materialtype} = state

        let icon = hcGetIconForSiteOrMatch(state, "myMaterial");

        if (render){
            return render({name, sitename, imbalance, materialtype, icon})
        } else {
            if (isBrowser){
                return<div class="py-2 pr-2">
                    <div class="clearfix">
                        <figure class="marker float-left mr-2">
                            <img src={icon} />
                        </figure>
                        <p class="fw-600">{name || sitename}</p>
                    </div>
                </div>
            } else {
                return <div class="clearfix">
                    <figure class="marker float-left mr-2">
                        <img src={icon} />
                    </figure>
                    <p class="fw-600">{name || sitename}</p>
                </div>
            }
        }    
                        
    }


    let renderMatchCriteriaStartDate = (params = {}) => {
        let {className, render} = params;
        let {startDate, endDate, loading, today} = state;
        let handleStartDate = (startDate) => setState({startDate})

        if (render) {
            return render({startDate, endDate, handleStartDate})
        } else {
            return <DatePicker
                locale="en-US"
                className={className || ""}
                disabled={loading}
                selected={ startDate || today }
                selectsStart
                startDate={ startDate || today }
                endDate={ endDate || today }
                dateFormat="MM/yyyy"
                onChange={ handleStartDate }
                showMonthYearPicker
            />
        }
      
    }

    let renderMatchCriteriaEndDate = (params = {}) => {
        let {className, render} = params;
        let {startDate, endDate, perennialEndDate, loading, today} = state;

        let noEndDate = (perennialEndDate === 1 || perennialEndDate === "1");

        let handleEndDate = (endDate) => setState({endDate})


        if (render){
            return render({endDate, startDate, today, handleEndDate, perennialEndDate})
        } else {
            if (noEndDate) return null
            else return <DatePicker
                locale="en-US"
                className={className || ""}
                disabled={noEndDate || loading}
                selected={ (noEndDate) ? null : (endDate || today) }
                selectsEnd
                startDate={ startDate || today }
                endDate={ endDate || today }
                dateFormat="MM/yyyy"
                onChange={ handleEndDate }
                showMonthYearPicker
            />
        }
    }

    let renderMatchCriteriaPerennialEndDateCheckbox = (params = {}) => {
        let {render, className} = params;
        let {perennialEndDate} = state;

        let handlePerennialEndDate = (perennialEndDate) => setState({perennialEndDate});

        if (render){
            return render({perennialEndDate, handlePerennialEndDate})
        } else {
            return <div className={"custom-control custom-checkbox " + (className || "")} onClick={(e) => {
                e.preventDefault();
                handlePerennialEndDate(perennialEndDate ? 0 : 1)
            }}>
                <input className="custom-control-input" type="checkbox" id={"perennial-checkbox"} checked={perennialEndDate === 1 || perennialEndDate === "1"} />
                <label className="custom-control-label" htmlFor={"perennial-checkbox"}>No End Date</label>
          </div>
        }

    }

    let renderMatchCriteriaMaxDistance = (params = {}) => {
        let {render, className} = params;
        let {maxdistance, loading} = state;

        let handleMaxDistance = (maxdistance) => setState({maxdistance});

        if (render){
            return render({maxdistance, handleMaxDistance})
        } else {
            return <input className={className || ""} type="text" disabled={loading} value={maxdistance} onChange={(e) =>{
                console.log("maxdistance value:", e.target.value);
                handleMaxDistance(e.target.value)
            }} />
        } 
    }

    let renderMatchCriteriaQuantity = (params = {}) => {
        let {render, className} = params;
        let {idquantity, loading} = state;

        console.log("calling renderMatchCriteriaQuantity with idquantity", idquantity);

        let handleQuantity = (idquantity) => setState({idquantity});

        let quantities = [
            {idquantity: 1, quantity: 100},
            {idquantity: 2, quantity: 1000},
            {idquantity: 3, quantity: 10000},
        ]

        if (render){
            return render({quantities: _.cloneDeep(quantities), handleQuantity, idquantity})
        } else {
            return <select className={className || ""}
                value={idquantity}
                onChange={(e) => handleQuantity(e.target.value)}>
                    {quantities.map((item)=> { return(
                         <option value={ item.idquantity } key={ item.idquantity }>
                          { (item.quantity).toLocaleString('en') }s of CY's
                        </option>
                    )})}
                </select>
        } 
    }

    let matchCriteriaQuantityString = () => {

        return renderMatchCriteriaQuantity({
            render: ({idquantity, quantities}) => {

                let info = quantities.find((obj) => {
                    return parseInt(obj.idquantity) == parseInt(idquantity);
                });

                console.log("matchCriteriaQuantityString info:", info)

                if (info === undefined || info.quantity == undefined){
                    return null;
                } else {
                    return info.quantity.toLocaleString('en') + "s CYs"
                }

            }
        })
    }

    let renderMatchCriteriaSubmit = (params = {}) => {
        let {render, hidden} = params;
        let {loading, idsite_resource} = state;
        let submitId = "match-criteria-special-submit-"+idsite_resource

        if (render){
            return ({loading, cancel, submitId, submit, hidden})
        } else {
            if (hidden) {
               return <button className="d-none" id={submitId ? submitId : ""} onClick={submit} />
            } else {
                if (isBrowser){
                    return <div className="col-2 mt-2">
                        <button className="btn btn-xs btn-round btn-secondary mr-4 mb-1" disabled={loading} onClick={submit}>
                            {loading ? <img src="/img/app/photoswipe/preloader.gif" /> : "✓"} Apply
                        </button>
                        <button className="btn btn-xs btn-round btn-outline-secondary mr-4 mb-1" disabled={loading} onClick={cancel}>Cancel</button>
                    </div>
                } else {
                    return <div className="col-12 px-0 mt-2 mb-4 clearfix">
                        <button className="btn btn-xs btn-round btn-secondary float-right" disabled={loading} onClick={submit}>
                            {loading ? <img src="/img/app/photoswipe/preloader.gif" /> : "✓"} Apply
                        </button>
                        <button className="btn btn-xs btn-round btn-outline-secondary float-left" disabled={loading} onClick={cancel}>Cancel</button>
                    </div>
                }
                
            }
        }                                    
    }

    let matchCriteriaForm = () => {
        if (state.loading) return null;
        else {

            //if (isBrowser){

                return(<>

                    <div className="row">
                        <div className="col">
                        {renderMatchCriteriaHeading()}
                        </div>
                    </div>

                    <div class="row pt-1 pb-0 d-flex align-items-start">

                        {state.isNewMaterial ? <>

                            <div class="col-12 col-md-6">
                                {renderMatchCriteriaMaterialType()}
                            </div>

                            <div class="col-12 col-md-6">
                                {renderMatchCriteriaImbalance()}
                            </div>

                        </> : <div class="col-12">
                            {renderMatchCriteriaMaterialType()}
                        </div> }

                    </div>

                    <div class="row pt-1 pb-0 d-flex align-items-start">

                        <div class="col-12 col-md-6 form-group">
                            <label class="text-dark">General Availability</label>

                            <div className="input-daterange input-group">
                                <span className="input-group-addon py-1 pl-2">From</span>
                                {renderMatchCriteriaStartDate({className: "form-control form-control-sm pr-1 bg-white"})}
                                <span className="input-group-addon py-1 px-2">to</span>
                                {renderMatchCriteriaEndDate({className: "form-control form-control-sm pr-1 bg-white"})}
                                {renderMatchCriteriaPerennialEndDateCheckbox({className: "input-group-append p-2"})}
                            </div>

                        </div>


                        {/*<div class="col-3 form-group">
                            <label class="small-4" style={{whiteSpace: 'nowrap'}}>Match Search Radius</label>
                            {renderMatchCriteriaMaxDistance({className: "form-control form-control-sm pr-1 bg-white"})}
                        </div>*/}

                        <div class="col-12 col-md-3 form-group">
                            <label class="text-dark" style={{whiteSpace: 'nowrap'}}>General Quantity</label>
                            {renderMatchCriteriaQuantity({className: "form-control form-control-sm pr-1 bg-white"})}
                        </div>

                        <div class="col-12 col-md-3 form-group">
                            <label class="text-dark" style={{whiteSpace: 'nowrap'}}>Max Distance</label>
                            {renderMatchCriteriaMaxDistance({className: "form-control form-control-sm pr-1 bg-white"})}
                            <em className="small-2 mb-0">Actual driving distance may exceed this straight-line distance.</em> 
                        </div>

                        

                        {renderMatchCriteriaSubmit({hidden: true})}

                    </div>

                </>)

            /*} else if (isMobile){

                return(<>
                    
                    <div className="bg-light border px-2 mb-4 mt-4">
                        <div className="input-line">
                            <div className="px-2 pt-2 clearfix">

                                <label className="fs-14 mb-0">Match Criteria</label>
                                <div className="clearfix">
                                    <div className="col-5 form-group px-0 float-left mb-2">
                                        <label className="small-4 mb-0">Available From</label>
                                        {renderMatchCriteriaStartDate({className: "form-control pr-1"})}
                                    </div>


                                    <div className="col-5 form-group px-0 float-right mb-2">
                                        <label className="small-4 mb-0">Available Thru</label>
                                        {renderMatchCriteriaEndDate({className: "form-control pr-1"})}
                                        {renderMatchCriteriaPerennialEndDateCheckbox({className: "form-control pr-1"})}
                                    </div>
                                </div>
                                <div className="col-6 form-group px-0">
                                    <label className="small-4 mb-0">Match Search Radius</label>
                                    {renderMatchCriteriaMaxDistance({className: "form-control pr-1"})}
                                    
                                </div>

                                <div className="col-6 form-group px-0">
                                    <label className="small-4 mb-0">Quantity</label>
                                    {renderMatchCriteriaQuantity({className: "form-control pr-1"})}
                                    
                                </div>

                                {renderMatchCriteriaSubmit({hidden: true})}

                            </div>
                        </div>


                    </div>

                </>)

                return(<>

                    <div className="row">
                        <div className="col">
                        {renderMatchCriteriaHeading()}
                        </div>
                    </div>

                    <div class="row pt-1 pb-0 d-flex align-items-start">

                        {state.isNewMaterial ? <>

                            <div class="col-6">
                                {renderMatchCriteriaMaterialType()}
                            </div>

                            <div class="col-6">
                                {renderMatchCriteriaImbalance()}
                            </div>

                        </> : <div class="col-12">
                            {renderMatchCriteriaMaterialType()}
                        </div> }

                    </div>

                    <div class="row pt-1 pb-0 d-flex align-items-start">

                        <div class="col-8 form-group">
                            <label class="small-4">General Availability</label>

                            <div className="input-daterange input-group">
                                <span className="input-group-addon py-1 pl-2">From</span>
                                {renderMatchCriteriaStartDate({className: "form-control form-control-sm pr-1 bg-white"})}
                                <span className="input-group-addon py-1 px-2">to</span>
                                {renderMatchCriteriaEndDate({className: "form-control form-control-sm pr-1 bg-white"})}
                                {renderMatchCriteriaPerennialEndDateCheckbox({className: "input-group-append p-2"})}
                            </div>

                        </div>


                        <div class="col-3 form-group">
                            <label class="small-4" style={{whiteSpace: 'nowrap'}}>Match Search Radius</label>
                            {renderMatchCriteriaMaxDistance({className: "form-control form-control-sm pr-1 bg-white"})}
                        </div>

                        <div class="col-4 form-group">
                            <label class="small-4" style={{whiteSpace: 'nowrap'}}>General Quantity</label>
                            {renderMatchCriteriaQuantity({className: "form-control form-control-sm pr-1 bg-white"})}
                        </div>



                        {renderMatchCriteriaSubmit({hidden: true})}

                    </div>

                </>)



            }*/
        }
    }

    
    return {
        matchCriteriaForm, loadMatchCriteriaMaterial, matchCriteriaFormPieces: {
            renderMatchCriteriaEndDate,
            renderMatchCriteriaStartDate,
            renderMatchCriteriaPerennialEndDateCheckbox, 
            renderMatchCriteriaMaxDistance, 
            renderMatchCriteriaSubmit,
            matchCriteriaQuantityString
        }
    }


}