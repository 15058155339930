import React, {Fragment, useState, useEffect, useLayoutEffect, useCallback} from 'react';
import _ from 'lodash';
import {HaulCheckService, SharesDetailsService} from '../../services'
import usePrevious from '../../utils/usePrevious';
import moment from 'moment';
import '../Conversations/index.css'
import DatePicker, { registerLocale } from 'react-datepicker'


export default function useDetails({onEdit, onSubmit, onLoad}) {

	let [state, setStateDirect] = useState({loading: false})

	///General functions for handling state, to help re-create the functionality of the class component
	const handleStateUpdate = (newState, funcCallback) => {

        setState(_.cloneDeep(newState), () => {
            if (onEdit && typeof onEdit == "function"){
               onEdit(newState);
            }

            if (typeof funcCallback == "function"){
                funcCallback()
            }
        })
	}

	const setState = (passedState, callback) => {
		let newState = _.cloneDeep(state);
		_.merge(newState, passedState);

		console.log("Calling useDetails setState with original state", state, "and passed state", passedState, "merged to create new state", newState)
		setStateDirect(newState);


		if (callback && typeof callback == "function"){
			callback()
		}
	}

	const handleChange = (key, val) => {
        //console.log("Calling handleChange with key: " + key + " and val:",val)
        handleStateUpdate({[key]: val});
    }

    const toggleCheckBox = (e, key) => {
        e.preventDefault();
        let val = !state[key]
        //console.log("Calling toggleCheckBox with key " + key + "and val:", !this.state[key])
        handleStateUpdate({[key]: val})
    }


	//Functions for loading data into state
	//Todo: rename
	const loadDetailsFormMaterial = async (idsite_resource) => {

		setState({submitting: false});

        //console.log("Calling loadDetailsFormMaterial with idsite_resource " + idsite_resource);
        let material = await HaulCheckService.getMaterialInfo(idsite_resource, "useDetails");
       // console.log("loadDetailsFormMaterial API call result:", material);

        if (material[0] == undefined) return;
        else material = material[0];

        let end_date = material.available_end_date;
        if (!material.available_end_date){
            end_date = material.available_date
        }

		//Todo: Remove as much derived state as possible
		const newState = {
	        availability: moment(material.available_date).add('days', 1).format("YYYY-MM-DD"),
	        availabilityend: moment(end_date).add('days', 1).endOf('month').format("YYYY-MM-DD"),
	        resourceid:material.idresource,
	        resource: material.resource,
	        resourcetype: material.type,
	        resourcetypeshort: material.type.split('/')[0],
	        //match: material.idmatch,
	        //matchsiteresource: material.idmatchsiteresource,
	        discoverymode: material.iddiscoverymode,
	        sitename: material.sitename,
	        site: material.idsite,
	        saveasdefault: true,
	        showDefaultCheckbox: false,
	        sendDetailsToAllExternalMatches: false,
	        showSendDetailsToAllExternalMatchesCheckbox: /*(this.props.parent == "conversations")*/ false,
	        loadedDetails: true,
	        originalQuantity: material.quantity,
	        idsite_resource
	        
		}

		await loadDefaultDetails(newState, idsite_resource);
	}

	const loadDefaultDetails = async (newState, idsite_resource) => {
		console.log("loadDefaultDetails being called with idsite_resource", idsite_resource)
		let data = await SharesDetailsService.getDefaultMaterialDetail(idsite_resource);

        console.log("loadDefaultDetails details:", data);

        if (data && data.length > 0){
            data[0].startdate = new Date(data[0].startdate);
            data[0].startdate.setDate(data[0].startdate.getDate() + 1);
            if (data[0].enddate){
                data[0].enddate = new Date(data[0].enddate);
                data[0].enddate.setDate(data[0].enddate.getDate() + 1);
            } else {
                data[0].enddate =new Date(data[0].startdate);
                data[0].enddate.setDate(data[0].startdate.getDate() + 1);
            }
            
            if (data[0].quantity == null){
                data[0].quantitydetail = undefined;
            } else {
                data[0].quantitydetail = data[0].quantity;
            }

            if (!data[0].cost){
                data[0].cost = 0;
            }

            data[0].loadedDetails = idsite_resource;
            data[0].loading = false;

            newState = _.merge(newState, data[0])

        } else {
            let startdate = new Date();
            let enddate = new Date();

            if (state.availability){
                startdate = new Date(state.availability);
                startdate.setDate(startdate.getDate() + 1);

                if (state.availabilityend){
                    enddate = new Date(state.availabilityend);
                    enddate.setDate(enddate.getDate() + 1);
                } else if (state.availability){
                    enddate = new Date(state.availability);
                    enddate.setDate(enddate.getFullYear(), enddate.getMonth()+2, 0);
                }
            }

            let initState = {
                quantity: state.originalQuantity,
                startdate,
                enddate,
                quantitydetail: undefined,
                quantitynoinfo:false,
                description:'',
                descriptionnoinfo:false,
                stringstartdate: '',
                stringenddate: '',
                timeframestart:'',
                timeframeend:'',
                timeframenoinfo:false,
                trucking:0,
                truckingtype:'',
                materialtipping: 0,
                materialstatus: '',
                perunitload: 1,
                unitload: 'Per Load',
                cost:'',
          
                sharedetail:0,
                saveasdefault: true,
                loadedDetails: false,
                loading: false
            }

            newState = _.merge(newState, initState)
        }

        setState(_.cloneDeep(newState));

   		if (onLoad && typeof onLoad == "function") onLoad();

	}



	//Functions for updating state in more specific ways
	
	//Update options for pricing
	const handleOptionPer = e => {
		let  desper=''
		if(e.target.value == 1){
			desper='Per Load'
		}
		if(e.target.value == 2){
			desper='Per Ton'
		}
		if(e.target.value == 3){
			desper='Per CY'
		}
		if(e.target.value == 4){
			desper='TBD'
		}
		//console.log(e.target, e.target.value)
		handleStateUpdate({
			perunitload: e.target.value,
			unitload:desper,
		})
	}

	//Update options for who is doing the trucking
	const handleOptionChangeTrucking = (e) => {
		let  destruckingtype=''
		if(e.target.value === "1"){
			destruckingtype='by Others'
		}
		if(e.target.value === "2"){
			destruckingtype='by Me'
		}
		if(e.target.value === "3"){
			destruckingtype='Shared'
		}
		if(e.target.value === "4"){
			destruckingtype='No info'
		}

		handleStateUpdate({
			trucking: e.target.value,
			truckingtype:destruckingtype,
		})
	}

	//Update options for who is tipping
	const handleOptionChangeTipping = (e) => {
		let  desmaterialtipping=''
		if(e.target.value == "1"){
			desmaterialtipping='I\'m charging'
		}
		if(e.target.value == "2"){
			desmaterialtipping='I\'m not charging'
		}
		if(e.target.value == "3"){
			desmaterialtipping='No info'
		}
		handleStateUpdate({
			materialtipping: e.target.value,
			materialstatus:desmaterialtipping,
		})
	}

	//Update dates
  	const handleDateChange = (date, d) => {

		if (d === 0) {
		    handleStateUpdate({
		        startdate: date,
		        stringstartdate: moment(date).format("MMMM D, YYYY"),
		    })
		} else {
		    handleStateUpdate({
		        enddate: date,
		        stringenddate: moment(date).format("MMMM D, YYYY"),
		    })
		}
	}

	//Submit details

	const detailSubmit = async () => {

        setState({submitting: true});

        let data = _.cloneDeep(state);
        console.log("detailSubmit onSubmit:", onSubmit, "with type,", typeof onSubmit)
        if (!data.cost || isNaN(parseInt(data.cost))) data.cost = 0;
        //let {callback, idsite_resource, parent, idpmgroup} = this.props;

        //data.idsite_resource = idsite_resource;

        let returnData = await SharesDetailsService.saveMaterialDetail(data); //Save the details as the main shared details for the material
        if (onSubmit && typeof onSubmit == "function"){ 
            if (returnData){
                returnData.quantitydetail = returnData.quantity;
                returnData.sendDetailsToAllExternalMatches = /*data.sendDetailsToAllExternalMatches*/ false;
                console.log("ModalShareDetails callback with returnData", returnData);
                onSubmit(returnData);
            } else {
                console.log("ModalShareDetails callback with front-end data", data);
                onSubmit(data);
            }
        } //Send the data to the callback to eg update the parent component
        await SharesDetailsService.setStartEndDateForSiteResource(data.startdate, data.enddate, data.idsite_resource); //Update the availability dates for the material
        //await SharesDetailsService //Update the default details, if neeeded
        loadDetailsFormMaterial(data.idsite_resource); //Reset the state
        /*if (!this.state.loadedDetails){
            this.setState({popupTrigger: true})
        }*/

	}

	//Very simple render prop components to help simplify implementation

	const renderDetailsFormDescription = (params = {}) => {

		let {render} = params;
		let {description, idsite_resource} = state;

		let handleDescChange = e => handleChange("description", e.target.value);

		if (render){
			return render({description, handleDescChange})
		} else {
			return <div class="col-12">
	            <label class="text-dark">Description</label>
	            <a tabindex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Describe your need" data-content={"For example, tell your match how granular, cohesive, or compactable your dirt is, or if you need liner clay."}>
	                <i class="fa fa-info-circle" aria-hidden="true"></i>
	            </a>
	            <div class="form-group mb-0">
	                <textarea
		                id={`description_${idsite_resource}`}
		                key={`description_${idsite_resource}`}
		                className="form-control ml-0"
		                placeholder="Provide additional info about your resource"
		                rows="2"
		                value={description}
		                onChange={handleDescChange}
		                ></textarea>
	            </div>
	        </div>
	    }
	}

	const renderDetailsFormSubmit = (params = {}) => {

		let {render, hidden, label} = params;
		let {idsite_resource} = state;

		if (render){
			return render({detailSubmit})
		} else {
			return <button type="button" 
				className={"btn btn-primary" + (hidden ? " d-none" : "")}
				data-dismiss="modal" 
				id={"material-details-special-submit-"+idsite_resource}
				onClick={detailSubmit}>
					{state.submitting ? 
						<img src="/img/app/photoswipe/preloader.gif" /> 
					: null} 

					{label ? label : "save"}
				</button>
	    }		
	}

	const renderDetailsFormSpecificQuantity = (params = {}) => {
		let {render} = params;
		let {quantitydetail, quantitynoinfo, idsite_resource} = state;

		let handleQuantityChange = e => handleChange("quantitydetail", e.target.value);
		let handleNoInfoToggle = (e) => toggleCheckBox(e, "quantitynoinfo");

		if (render){
			return render({quantitydetail, quantitynoinfo, handleQuantityChange, handleNoInfoToggle})
		} else {

			return <div class="col-md-4 col-12 mb-">
                <label class="text-dark">Specific Quantity</label>
                <a tabindex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Specific Quantity" 
                data-content={"This will automatically be shared within your Group. It will not change the General Quantity that is shown to external matches. You can share this with your external matches via messaging."}>                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                </a>
                <div class="col-12 pl-0">
                    <div class="input-group">
                        <input
                        id={`quantity_${idsite_resource}`}
                        name='amount_detail'
                        type="text" className="form-control"
                        aria-label="Amount (to the nearest hundred)"
                        value={quantitydetail}
                        onChange={handleQuantityChange}
                        />
                        <div class="input-group-append">
                            <span class="input-group-text">CYs</span>
                        </div>
                    </div>
                </div>

                <div className="custom-control custom-checkbox pt-2" onClick={handleNoInfoToggle}>
                    <input
                    id={`quantitynoinfo_${idsite_resource}`}
                    type="checkbox"
                    className="custom-control-input"
                    checked={quantitynoinfo}
                    />
                    <label className="custom-control-label fs-11" htmlFor={`quantitynoinfo_${idsite_resource}`}>No info</label>
                </div>
            </div>
        }
	}

	const renderDetailsFormSpecificAvailability = (params = {}) => {

        let today = new Date();
		let {startdate, enddate} = state;
		let handleStartDate = date => handleDateChange(date, 0);
		let handleEndDate = date => handleDateChange(date, 1);
		let {render} = params;

		if (render){
			return render({today, startdate, enddate, handleStartDate, handleEndDate})
		} else {
			return <div class="col-md-8 col-12">
	            <label class="text-dark">Specific Availability</label>
	            <a tabindex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Specific Availability" 
	            data-content={/*(parent == "conversations" ? 
	                "This will be shared with only this match. It will not change the General Availability that is used to filter external matches."
	                :*/
	                "This will automatically be shared within your Group. It will not change the General Availability that is used to filter external matches. You can share this with your external matches via messaging."/*)*/}>
	                <i class="fa fa-info-circle" aria-hidden="true"></i>
	            </a>
	            <div class="input-daterange input-group mr-2" id="datepicker">
	                <span className="input-group-addon p-1">From</span>
	                <DatePicker
	                locale="en-US"
	                className="input-sm form-control"
	                selected={ startdate || today }
	                selectsStart
	                startDate={ startdate || today }
	                endDate={ enddate || today }
	                dateFormat="MM/dd/yyyy"
	                onChange={ handleStartDate }
	                popperModifiers={{
	                    preventOverflow: {
	                      enabled: true,
	                    },
	                  }}
	                />
	                <span className="input-group-addon p-1">to</span>
	                <DatePicker
	                locale="en-US"
	                className="input-sm form-control"
	                selected={ enddate || today }
	                selectsEnd
	                startDate={ startdate || today }
	                endDate={ enddate || today }
	                dateFormat="MM/dd/yyyy"
	                onChange={ handleEndDate }
	                popperModifiers={{
	                    preventOverflow: {
	                      enabled: true,
	                    },
	                  }}
	                />  
	            </div>
	        </div>
	    }
	}

	const renderDetailsFormTrucking = (params = {}) => {

		let {trucking} = state;
		let handleTrucking = (value) => handleOptionChangeTrucking({target: {value}});
		let {render} = params;

		if (render){
			return render({trucking, handleTrucking})
		} else {

			return <div class="col-md-6 col-12">
                <label class="text-dark">Trucking</label>

                <div class="custom-controls-stacked">
                    <div className="custom-control custom-radio" onClick={() => handleTrucking(1)}>
                        <input id="byothers" type="radio" className="custom-control-input" name="trucking"
                        value="1"
                        checked={trucking === '1' || trucking === 1 }
                        />
                        <label className="custom-control-label" htmlFor="byothers">by Others</label>
                    </div>
                    <div className="custom-control custom-radio" onClick={() => handleTrucking(2)}>
                        <input id="byme" type="radio" className="custom-control-input" name="trucking"
                        value="2"
                        checked={trucking === '2' || trucking === 2 }
                        />
                        <label className="custom-control-label" htmlFor="byme">by Me</label>
                    </div>
                    <div className="custom-control custom-radio"  onClick={() => handleTrucking(3)}>
                        <input id="shared" type="radio" className="custom-control-input" name="trucking"
                        value="3"
                        checked={trucking === '3' || trucking === 3 }
                        />
                        <label className="custom-control-label" htmlFor="shared">Shared</label>
                    </div>
                    <div className="custom-control custom-radio"  onClick={() => handleTrucking(4)}>
                        <input id="noinfo" type="radio" className="custom-control-input" name="trucking"
                        value="4"
                        checked={trucking === '4' || trucking === 4 }
                        />
                        <label className="custom-control-label" htmlFor="noinfo">No Info</label>
                    </div>
                </div>
            </div>
        }

	}

	const renderDetailsFormTipping = (params = {}) => {

		let today = new Date();
		let {materialtipping, cost, idsite_resource, perunitload} = state;
		let handleTipping = (value) => handleOptionChangeTipping({target: {value}})
		let handleCost = (value) => handleChange("cost", value)
		let handlePer = (value) => handleOptionPer({target: {value}})
		let {render} = params;

		
		if (render){
			return render({materialtipping, cost, idsite_resource, perunitload, handleTipping, handleCost, handlePer});
		} else {
			return <div class="col-md-6 col-12">
                <label class="text-dark">Materials & Tipping Cost</label>
                <div className="custom-controls-stacked">
                    <div className="custom-control custom-radio" onClick={() => handleTipping(1)}>
                        <input
                        id="charging" name="materialt"
                        type="radio"
                        className="custom-control-input"
                        value="1"
                        onChange={e => handleTipping(e.target.value)}
                        checked={materialtipping === 1 || materialtipping === '1'}
                        />
                        <label className="custom-control-label" htmlFor='charging'>I'm charging...</label>
                    </div>
                    <div className="accordion accordion-light col-12" id="charge-details">
                        <div className="card noborder" style={{borderStyle: 'none'}}>
                            <div id="open-charges" className={"collapse" + (materialtipping === 1 || materialtipping === "1" ? " show" : "")}>
                                <div className="card-body py-2">
                                    <div className="input-group mb-1">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">$</span>
                                        </div>
                                        <input
                                            id={`cost_${idsite_resource}`}
                                            name='cost'
                                            type="text" className="form-control"
                                            aria-label="Amount (to the nearest dollar)"
                                            value={cost}
                                            onChange={e => handleCost(e.target.value)}
                                        />
                                    </div>
                                    <select className="form-control form-control-sm"
                                    id={`per_${idsite_resource}`}
                                    name='per'
                                    value={ perunitload }
                                    onChange={e => handlePer(e.target.value)}>
                                        <option value="1" key='1'>Per Load</option>
                                        <option value="2" key='2'>Per Ton</option>
                                        <option value="3" key='3'>Per CY</option>
                                        <option value="4" key='4'>TBD</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-control custom-radio"  onClick={() => handleTipping(2)}>
                        <input
                        id="notcharging" name="materialt"
                        type="radio"
                        className="custom-control-input"
                        value="2"
                        onChange={ e => handleTipping(e.target.value) }
                        checked={materialtipping === 2 || materialtipping === '2'}
                        />
                        <label className="custom-control-label" htmlFor='notcharging'>I'm not charging</label>
                    </div>

                    <div className="custom-control custom-radio" onClick={() => handleTipping(3)}>
                        <input
                        id="chargingnoinfo" name="materialt"
                        type="radio"
                        className="custom-control-input"
                        value="3"
                        onChange={e => handleTipping(e.target.value)}
                        checked={materialtipping === 3 || materialtipping === '3'  }
                        />
                        <label className="custom-control-label" htmlFor='chargingnoinfo'>No Info</label>
                    </div>
                </div>
            </div>
		}
	}

	let detailsForm = () => {
		console.log("Calling detailsForm with loading", state.loading);
		if (state.loading) return null;
		else {
			return <>

				<div className="row">
					{renderDetailsFormDescription()}
				</div>

				<div class="row mt-4">
					{renderDetailsFormTrucking()}
					{renderDetailsFormTipping()}
					{renderDetailsFormSubmit({hidden: true})}
				</div>

			</>

		}
		
	}


	return {
		detailsForm, loadDetailsFormMaterial, detailsFormPieces: {
			renderDetailsFormDescription, 
			renderDetailsFormSubmit, 
			renderDetailsFormSpecificQuantity, 
			renderDetailsFormSpecificAvailability, 
			renderDetailsFormTrucking, 
			renderDetailsFormTipping
		}

	}

}