import React, { Component, Fragment } from 'react'
import {
  ApiService,
  PMGroupsService } from '../../../services';
import PmForm from './Form';
import SuccessPopup from '../Popups';
import moment from 'moment';
import { url } from '../../../constants'


export default class PMGroup extends Component {

	constructor(props){
    	super(props);

    	this.state = {
    		email: ApiService.getJWTEmail(),
    		idpmgroup: null,
    		pmGroupName: null,
            created: null,
            subscription_expiration: null,
            billing_interval: null,
            username: null,
    		showModal: false,
    		popupTriggerUsersAdded: false,
            popupTriggerUsersRemoved: false,
            popupTriggerUsersUpdated: false,
            popupTriggerGroupCreated: false,
            rejectedUsers: [],
    	};

	}

    componentWillMount = async() => {
        let {pmGroup} = this.props;

        let idpmgroup = null;
        let pmGroupName = null;
        let subscription_expiration = null;
        let billing_interval = null;

        if (pmGroup != null){
            idpmgroup = pmGroup.idpmgroup;
            pmGroupName = pmGroup.name;
            subscription_expiration = pmGroup.subscription_expiration;
            billing_interval = pmGroup.billing_interval;
        }

        this.updatePmGroup(idpmgroup, pmGroupName, undefined, subscription_expiration, billing_interval);
    }

	componentWillReceiveProps = async ({pmGroup}) => {
		let idpmgroup = null;
		let pmGroupName = null;
        let subscription_expiration = null;
        let billing_interval = null;

		if (pmGroup != null){
			idpmgroup = pmGroup.idpmgroup;
			pmGroupName = pmGroup.name;
            subscription_expiration = pmGroup.subscription_expiration;
            billing_interval = pmGroup.billing_interval;
		}

		this.updatePmGroup(idpmgroup, pmGroupName, undefined, subscription_expiration, billing_interval);
	}

	updatePmGroup = (idpmgroup, pmGroupName, useCallback = false, subscription_expiration, billing_interval) => {
        let {callback} = this.props;

        let newState = {idpmgroup, pmGroupName}

        if (subscription_expiration) newState.subscription_expiration = subscription_expiration;
        if (billing_interval) newState.billing_interval = billing_interval;

		this.setState(newState);
        if (callback && typeof callback=="function" && useCallback) callback(newState);
	}

    deletePmGroup = async (useCallback = false) => {
        let {idpmgroup} = this.state;
        let {callback} = this.props;

        let newState = {};

        newState.billing_interval = "deleted";

        await PMGroupsService.deletePmGroup(idpmgroup);

        this.setState(newState)

        if (callback && typeof callback=="function" && useCallback) callback(newState);


    }

    updateRejectedUsers = (rejectedUsers) => {
        this.setState({rejectedUsers});
    }

	render = () => {
		let {idpmgroup, rejectedUsers} = this.state;
        let {simpleMode} = this.props;
        if (idpmgroup === false) return null;
		return(<Fragment>
			<div className="col-12 border px-4 py-2 mx-auto mt-4">
                <div className="col-12 mr-auto alert-light p-1">
					{idpmgroup ? this.renderHasPmGroup() : this.renderNewPmGroupComponents()}

                    {rejectedUsers.length > 0 ? <Fragment>
                        <div className="alert alert-warning mt-4 mb-0" role="alert">
                            <button type="button" className="close" onClick={() => {this.setState({rejectedUsers: []})}} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <p>The following users are part of a different office group and could not be added: </p>
                            <ul>
                                {rejectedUsers.map((email, index) => { return(
                                    <li key={index}>{email}</li>
                                )})}
                            </ul>

                            
                        </div>
                    </Fragment> : null}

				</div>
			</div>
			{this.renderPopups()}
	    </Fragment>);
	}

	renderHasPmGroup = () => {
		let {pmGroupName, billing_interval, modalIsOpen, subscription_expiration} = this.state;
		return(<Fragment>
			<p>
                <i className="fa fa-check text-success strong mr-2"></i>
                You belong to the office group <em>{pmGroupName}</em>
            </p>
                
            {/*billing_interval == "trial" ? 
                    <>You've got a 60 day free trial.<br />Your trial will end on {moment(subscription_expiration).add(5, 'hours').format("MM-DD-YYYY")}</>
            : 
                (billing_interval == "annual" || billing_interval == "monthly") ?
                <>Your group is enrolled in a paid subscription, with <strong>{billing_interval}</strong> billing.<br />
                Your group will be billed next on {moment(subscription_expiration).add(5, 'hours').format("MM-DD-YYYY")}</> 
                    
            :
                (billing_interval == "deleted") ? 
                    <>Your group subscription has been cancelled and will end on {moment(subscription_expiration).add(5, 'hours').format("MM-DD-YYYY")}</>   
            :

                <>You will not be billed for your office group.</>
            */}

			<button className="btn btn-xs btn-outline-primary" 
                data-toggle="modal" 
                data-target="#edit-group"
                onClick={() => this.setState({showModal: !this.state.showModal})}>
                    Manage your Office Group
            </button>

            {/*billing_interval == "trial" || billing_interval == "deleted" ? <>
            <br />
                <a className="btn btn-xs btn-primary" 
                    href={"/checkout-group"}>
                        Start your Paid Subscription
                </a>
            </>: null*/}

            {/*(billing_interval == "annual" || billing_interval == "monthly") ? <>
            <br />
                <button className="btn btn-xs btn-outline-danger" 
                    onClick={() => this.setState({popupPmGroupRemove: true})}>
                        End your Subscription
                </button>
            </>: null*/}

             <br />
                <button className="btn btn-xs btn-outline-danger" 
                    onClick={() => this.setState({popupPmGroupRemove: true})}>
                        Delete Group
                </button>

            <PmForm
                closeModal={this.closeModal}
                updatePmGroup={this.updatePmGroup}
                newGroup={false}
                openPopup={this.openPopup}
                idpmgroup={this.state.idpmgroup}
                updateRejectedUsers={this.updateRejectedUsers}
                showModal={this.state.showModal}
                hideModal={() => this.setState({showModal: false})}
            />

        <SuccessPopup
            popupId="popupPmGroupRemove"
            title="Really end group?"
            style="danger"
            useButton={true}
            useCancelButton={true}
            buttonLabel={"Yes, close my group"}
            message={"Are you sure you want to close your office group? If you close your group, you'll lose the convenience of easily cross-checking materials within your office"}
            callback={() => {
                this.deletePmGroup();
                this.closePopup("popupPmGroupRemove")
            }}
            trigger={this.state.popupPmGroupRemove}
             />

	    </Fragment>);
	}

	renderNewPmGroupComponents = () => {
		let {modalIsOpen} = this.state;
		return(<Fragment>
            <p class="mb-2">Start an office group within your company to compare imbalances.</p>
            <button class="btn btn-xs btn-primary" 
                data-toggle="modal" 
                data-target="#edit-group"
                onClick={() => this.setState({showModal: !this.state.showModal})}>Start an Office Group
            </button>

            <PmForm
                closeModal={this.closeModal}
                updatePmGroup={this.updatePmGroup}
                updateRejectedUsers={this.updateRejectedUsers}
                openPopup={this.openPopup}
                idpmgroup={null}
                newGroup={true}
                showModal={this.state.showModal}
                hideModal={() => this.setState({showModal: false})}

            />
	    </Fragment>);
	}

	closeModal = () => {
		this.setState({modalIsOpen: false});
	}

	openPopup = (popupId) => {
		console.log("Calling openPopup with id " + popupId);
		this.setState({[popupId]: true, modalIsOpen: false});
	}

	closePopup = (popupId) => {
		console.log("Calling closePopup with id " + popupId + "; openPopup");
		this.setState({[popupId]: false})
	}

	renderPopups = () => {return(<Fragment>
		 <SuccessPopup
            popupId="popupPMGroupAdded"
            title="Success!"
            message="Group created!"
            callback={() => this.closePopup("popupTriggerGroupCreated")}
            trigger={this.state.popupTriggerGroupCreated}
             />
        <SuccessPopup
            popupId="popupPMUsersAdded"
            title="Success!"
            message="Users added!"
            callback={() => this.closePopup("popupTriggerUsersAdded")}
            trigger={this.state.popupTriggerUsersAdded}
             />
        <SuccessPopup
            popupId="popupPMUsersRemoved"
            title="Success!"
            message="Users removed!"
            callback={() => this.closePopup("popupTriggerUsersRemoved")}
            trigger={this.state.popupTriggerUsersRemoved}
             />
        <SuccessPopup
            popupId="popupPMUsersUpdated"
            title="Success!"
            message="Users updated!"
            callback={() => this.closePopup("popupTriggerUsersUpdated")}
            trigger={this.state.popupTriggerUsersUpdated}
             />
	</Fragment>)}

}