import React, {Component, Fragment}  from 'react';
import {url, earthidealAPIURL} from '../../../../constants';

import {
  //ApiService,
  HaulCheckService,
  //MatchesService,
  //ConversationsService
} from '../../../../services';
import Rollover from './Rollover';
import Radius from './Radius';
import ArchiveSite from './ArchiveSite';
import ArchiveMaterial from './ArchiveMaterial';
import UnarchiveMaterial from './UnarchiveMaterial';
import AddMonth from './AddMonth';
import GoInternal from './GoInternal';
import GoExternal from './GoExternal';
import OptInModal from '../../../SiteMaterials/OptInToPayment/OptInModal'
import ModalChangeQuantityAndAvailability from '../../../SiteMaterials/ModalChangeQuantityAndAvailability'
import ModalChangeSearchRadius from '../../../SiteMaterials/ModalChangeSearchRadius'


export default class SettingsModals extends Component {

  constructor(props){

        /*const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        let username = userDecoded['cognito:username'];
        let email = userDecoded['email'];*/

        super(props)

        this.state={
        	id: 0,
            modalType: "",
            trigger: false,
            params: null,
        }
	}


    componentWillReceiveProps = (newProps) => {
        let {trigger, modalType, id, params} = newProps;

        if (trigger != this.state.trigger){
            this.setState({trigger, modalType, id, params}, () => this.openModal());
        }
    }

    openModal = () => {
        let {id, modalType, trigger} = this.state;
        //console.log("open-"+modalType+"-site-setting-modal element:", document.getElementById("open-"+modalType+"-site-setting-modal"))
        let button = document.getElementById("open-"+modalType+"-site-setting-modal")

        if (button !== null) button.click();
    }

    render(){
        let {modalType, id, params} = this.state;
        let {callback, callbackReturnToSandbox, postMaterialCallback} = this.props;

        if (modalType =="rolloverbid"){
            return( <Rollover mode="bid" oppositeMode="Construction" idsite={id} callback={callback} /> );
        } else if (modalType == "rolloverpost"){
            return ( <Rollover mode="post" oppositeMode="Bid" idsite={id} callback={callback} /> );
        } else if (modalType == "radius"){
            return ( <Radius idsite={id} callback={callback} currentMaxDistanceNum={params.maxdistance} /> );
        } else if (modalType == "archiveSite"){
            return ( <ArchiveSite idsite={id} callback={callbackReturnToSandbox} />);
        } else if (modalType == "archiveMaterial"){
            return ( <ArchiveMaterial idsite_resource={id} idsite={params.idsite} callback={callbackReturnToSandbox} /> );
        } else if (modalType == "unarchiveMaterial"){
            return ( <UnarchiveMaterial idsite_resource={id} idsite={params.idsite} callback={callback} /> );
        } else if (modalType == "addMonth"){
            return ( <AddMonth idsite_resource={id} material={params} callback={callback} /> )
        } else if (modalType == "goInternal"){
            return ( <GoInternal idsite_resource={id} callback={callback} idsite={params.idsite} iddiscoverymode={params.iddiscoverymode} /> )
        } else if (modalType == "matchCriteria"){
            return ( <ModalChangeQuantityAndAvailability
                material={params}
                handleModalSave={async (material, idquantity, maxdistance, startdate, enddate, perennialEndDate)=>{
                    await HaulCheckService.updateMatchCriteria({idsite_resource: material.idsite_resource, idquantity, maxdistance, startdate, enddate, perennialEndDate});
                    callback();
                    document.getElementById('close-matchCriteria').click();
                    await HaulCheckService.getMatches(material.idsite);
                }}
                closeModal={()=>{
                    console.log("Calling matchCriteria closeModal")
                    document.getElementById('close-matchCriteria').click();
                    this.setState({modalType: ""});
                }}
            /> );
        } else if (modalType == "searchRadius"){
            return ( <ModalChangeSearchRadius
                material={params}
                handleModalSave={async (material, idquantity, maxdistance, startdate, enddate)=>{
                    await HaulCheckService.updateMatchCriteria({idsite_resource: material.idsite_resource, idquantity, maxdistance, startdate, enddate});
                    callback();
                    document.getElementById('close-searchRadius').click();
                    await HaulCheckService.getMatches(material.idsite);
                }}
                closeModal={()=>{
                    console.log("Calling matchCriteria closeModal")
                    document.getElementById('close-searchRadius').click();
                    this.setState({modalType: ""});
                }}
            /> );
        } else if (modalType == "goExternal"){
            return (
                <OptInModal
                    hideInfo={true}
                    idsite_resource={id}
                    iddiscoverymode={params.iddiscoverymode}
                    callback={async (goToBlastReferral) => {
                        console.log("PaymentBug", "Calling callback with goToBlastReferral value ", goToBlastReferral);
                        await HaulCheckService.toggleStatus(id);
                        await HaulCheckService.getMatches(params.idsite);
                        if (goToBlastReferral == true){
                            window.location.href = url.frontend + "/haul-check-invite/post";
                        } else {
                            callback();
                        }
                    }}
                />
            )
        } else return null;
    }

   
}