

export default function exportCSV(data, filename){
	let csvContent = "data:text/csv;charset=utf-8," + data;

	console.log("csvContent:", csvContent);

	let encodedUri = encodeURI(csvContent);
	let link = document.createElement("a");
	link.setAttribute("href", encodedUri);
	link.setAttribute("download", filename+"-"+(new Date()).toISOString().substr(0,10)+".csv");
	document.body.appendChild(link); 
	link.click();

}