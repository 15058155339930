import React, {Component} from 'react';
import StripeForm from './StripeComponents'
import {Elements, StripeProvider} from 'react-stripe-elements';
import {url as config} from '../../../constants';
import ajax, {Request} from 'superagent';

export default class CCForm extends Component {

  constructor(props){
      super(props);

      //let user = JSON.parse(localStorage.getItem('user'));
      this.state = {
        paymentMethod: null,
        //optingIn: !props.recipient,
        //optInLock: false,
        //userIsLeader: false,
        //leaderHasPaymentInfo: false,
        loading: true,
        //iduser: user.id
      };
    }

    /*componentWillMount(){
      if (this.props.idsite) this.getSiteLeader(this.props.idsite);
    }

    componentWillReceiveProps(newProps){

      if (this.props.newSite == false && newProps.newSite == true){
        this.getCurrentUser(this.state.iduser)
      }

      else if (this.props.idsite !== newProps.idsite){
        this.getSiteLeader(newProps.idsite);
      }
    }

    getSiteLeader(idsite){
      this.setState({loading: true});
      //console.log("Calling getSiteLeader with idsite " + idsite);
      ajax.get(config.apiUrl + '/sites/leader/' + idsite)
        .then((res) => {
          let leader = res.body.leader[0];
          this.setState({
            userIsLeader: leader.iduser == this.state.iduser,
            leaderHasPaymentInfo: !!(leader.stripe_token && leader.stripe_cus_id),
            loading: false
          })
          this.props.returnValidPaymentInfo({optingInToPayment: this.state.optingIn, validPaymentInfo: !!(leader.stripe_token && leader.stripe_cus_id)});
          //let leader = res.body.leader[0]
          //console.log("getSiteLeader Site Leader: ", leader);
          //console.log("getSiteLeader Am I the site leader?", leader.iduser == this.state.iduser);
        })
    }

    getCurrentUser(iduser){
      this.setState({loading: true});
      //console.log("Calling getSiteLeader with idsite " + idsite);
      ajax.get(config.apiUrl + '/users/id/' + iduser)
        .then((res) => {
          let leader = res.body.user[0];
          this.setState({
            userIsLeader: true,
            leaderHasPaymentInfo: !!(leader.stripe_token && leader.stripe_cus_id),
            loading: false
          })
          this.props.returnValidPaymentInfo({optingInToPayment: this.state.optingIn, validPaymentInfo: !!(leader.stripe_token && leader.stripe_cus_id)});
          //let leader = res.body.leader[0]
          //console.log("getSiteLeader Site Leader: ", leader);
          //console.log("getSiteLeader Am I the site leader?", leader.iduser == this.state.iduser);
        })
    }*/


    getStripePaymentMethod = (paymentMethod) => {
      this.setState({paymentMethod});
      this.props.returnValidPaymentInfo({
        //optingInToPayment: this.state.optingIn, validPaymentInfo: (paymentMethod || this.state.leaderHasPaymentInfo) 
        paymentMethod});
    }

    /*handleOptInToggle = (optingIn) => {
      if (!this.state.paymentMethod && !this.state.leaderHasPaymentInfo) return;
      else {
        console.log("Calling handleOptInToggle with val " + optingIn);
        this.setState({optingIn}, () =>
        this.props.returnValidPaymentInfo({optingInToPayment: optingIn, validPaymentInfo: (this.state.paymentMethod || this.state.leaderHasPaymentInfo)})
        );
      }
    }*/

    renderLoading = () => {
      return(
        /*(this.props.recipient ?
          <p>Payment method info will appear here when you select a site above</p>
        : */
          <p>Getting payment data...</p>)
      //)
    }

    /*renderLeaderInfo = () => {
      return(
        (this.state.leaderHasPaymentInfo ? 
          <p>Your Project Leader has payment info on file</p>
        :
          <p>Oops! Your Project Leader does not have payment info on file.</p>
        )
      )
    }*/

    renderStripeForm = () => {
      return(
        <StripeProvider apiKey={config.stripe_key}>
          <Elements>
            <StripeForm getStripePaymentMethod={this.getStripePaymentMethod} />
          </Elements>
        </StripeProvider>
      )
    }

    render(){
        return (
          <div class="border-primary p-4">
            {this.renderStripeForm() }
          </div>
        )
    }

}
