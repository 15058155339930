import { url, earthidealAPIURL } from '../constants'
import JwtDecode from 'jwt-decode'
import moment from 'moment'

export const SharesDetailsService = {
    addNewShareDetail,
    postSharedDetail,
    saveMaterialDetail,
    getMaterialDetail,
    saveDefaultMaterialDetail,
    getDefaultMaterialDetail,
    postThanksCard,
    postConnectCard,
    postSiteShared,
    postShareRouteDistance,
    getStartEndDateForSiteResource,
    setStartEndDateForSiteResource,
    getSitesFromIdConversation,
    getRouteDataFromIdConversation,
    sendDetailsToAllExternalMatches,
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
async function addNewShareDetail(newShareDetail){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            newShareDetail
        })

    }
    const resp = await fetch(`${url.backend}/sharesdetails/addsharedetail`, requestOptions)
        .then(handleResponse)

    return resp
}
async function postSharedDetail(newShareDetail){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            newShareDetail
        })


    }
    ////console.log(requestOptions)
    const resp = await fetch(`${url.backend}/sharesdetails/postshareddetail`, requestOptions)
        .then(handleResponse)

    return resp
}

async function saveMaterialDetail(data){
    //Format data for sending

    const userJWT = localStorage.getItem('token')
    const userDecoded = JwtDecode(userJWT)
    const username = userDecoded ? userDecoded['cognito:username'] : null
    data.startdate = moment(data.startdate).format("YYYY-MM-DD");
    data.enddate = moment(data.enddate).format("YYYY-MM-DD");
    if (data.trucking == 0){
        data.trucking = 4;
        data.truckingtype = "No info";
    }
    data.username = username;


    if (data.materialtipping == 0){
        data.materialtipping = 4;
        data.unitload = "TBD";
    }

    if (!data.cost){
        data.cost = 0;
    }

    if (typeof data.quantitydetail == "string"){
        data.quantitydetail = data.quantitydetail.replaceAll(",", ""); 
    }

    //if (data.sendDetailsToAllExternalMatches){
        //await SharesDetailsService.sendDetailsToAllExternalMatches(data);
    //}

    console.log("SharesDetailsService submitting with data:", data);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data
        })


    }
    ////console.log(requestOptions)
    const resp = await fetch(`${earthidealAPIURL}/sharesdetails/saveMaterialDetail`, requestOptions)
        .then(handleResponse)

    return resp.data
}

async function getMaterialDetail(idsite_resource){
    const userJWT = localStorage.getItem('token')
    const userDecoded = JwtDecode(userJWT)
    const username = userDecoded ? userDecoded['cognito:username'] : null
    const resp = await fetch(`${earthidealAPIURL}/sharesdetails/getMaterialDetail/${idsite_resource}/${username}`).then(handleResponse)
    return resp.data
}

async function saveDefaultMaterialDetail(data){

    if (data){

        const userJWT = localStorage.getItem('token')
        const userDecoded = JwtDecode(userJWT)
        const username = userDecoded ? userDecoded['cognito:username'] : null

        data.username = username;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data
            })


        }
        ////console.log(requestOptions)
        const resp = await fetch(`${earthidealAPIURL}/sharesdetails/saveDefaultMaterialDetail`, requestOptions)
            .then(handleResponse)

        return resp.data
    } else {
        return []
    }
}

async function getDefaultMaterialDetail(idsite_resource){
    const userJWT = localStorage.getItem('token')
    const userDecoded = JwtDecode(userJWT)
    const username = userDecoded ? userDecoded['cognito:username'] : null

    if (idsite_resource != undefined){
        const resp = await fetch(`${earthidealAPIURL}/sharesdetails/getDefaultMaterialDetail/${idsite_resource}/${username}`).then(handleResponse)
        return resp.data
    } else {
        return []
    }
}


async function postThanksCard(newShareDetail){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            newShareDetail
        })


    }
    ////console.log(requestOptions)
    const resp = await fetch(`${url.backend}/sharesdetails/postthankscard`, requestOptions)
        .then(handleResponse)

    return resp
}
async function postConnectCard(newShareDetail){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            newShareDetail
        })


    }
    ////console.log(requestOptions)
    const resp = await fetch(`${url.backend}/sharesdetails/postconnectcard`, requestOptions)
        .then(handleResponse)

    return resp
}

async function postSiteShared(newShareDetail){
     const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            newShareDetail
        })
    }
    
    ////console.log(requestOptions)
    const resp = await fetch(`${earthidealAPIURL}/matches/postsiteshared`, requestOptions)
        .then(handleResponse)

    return resp
}

async function postShareRouteDistance(newShareDetail){
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            newShareDetail
        })
    }
    
    ////console.log(requestOptions)
    const resp = await fetch(`${url.backend}/sharesdetails/postshareroutedistance`, requestOptions)
        .then(handleResponse)

    return resp

}

async function getStartEndDateForSiteResource(idsite_resource){
    const resp = await fetch(`${earthidealAPIURL}/sites/getStartEndDateForSiteResource/${idsite_resource}`).then(handleResponse)
    return resp.data
}

async function setStartEndDateForSiteResource(startDate, endDate, idsite_resource){
     const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            startDate, endDate, idsite_resource
        })
    }
    
    ////console.log(requestOptions)
    const resp = await fetch(`${earthidealAPIURL}/sites/setStartEndDateForSiteResource`, requestOptions)
        .then(handleResponse)

    return resp
}

async function getSitesFromIdConversation(idconversation){
    const resp = await fetch(`${url.backend}/sites/sitesFromIdConversation/${idconversation}`).then(handleResponse)
    return resp.sites
}

async function getRouteDataFromIdConversation(idconversation){
    const resp = await fetch(`${earthidealAPIURL}/sharesdetails/getRouteDataFromIdConversation/${idconversation}`).then(handleResponse)
    return resp.data
}

async function sendDetailsToAllExternalMatches(data){
    const userJWT = localStorage.getItem('token');
    const userDecoded = JwtDecode(userJWT);
    const name = userDecoded ? userDecoded['name'] : null;
    const user = userDecoded ? userDecoded['cognito:username'] : null;
    const mail = userDecoded ? userDecoded['email'] : null;
    const corporation = userDecoded ? userDecoded['custom:company'] : null;
    const phonenumber = userDecoded ? userDecoded['custom:phone'] : null;

    data.username = name;
    data.name = name;
    data.user = user;
    data.mail = mail;
    data.corporation = corporation;
    data.phonenumber = phonenumber;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            data
        })
    }
    
    ////console.log(requestOptions)
    const resp = await fetch(`${earthidealAPIURL}/matches/sendDetailsToAllExternalMatches`, requestOptions)
        .then(handleResponse)

    return resp   
}



