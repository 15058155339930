import React, { Component, Fragment } from 'react'
import MatchesList from '../MatchesList'
import moment from 'moment';
//import CalcRouteDistance from '../../InHouse/CalcRouteDistance'
import ModalShareDetails from '../../Conversations/ModalShareDetails'
import LetterAvatar from '../../common/LetterAvatar';

export default function MatchesInbox({title, idsite, matches, updateMatches, idsite_resource, sharedSites, showSharedSite, activeSite, expiration_date, switchSidePanel, groupsListMode}) { 

	let type = (title.type.split('/'))[0];
	//type = (type == "Shortage" ? "Shortage" : "Surplus");
	let resource = title.resource;
	let matchingType = (type == "Shortage" ? "Surplus" : "Shortage")

	let availableRange = () => {
		if (title.available_date){
			let start =  moment(title.available_date).add('days', 1).startOf("month");
			let endDate = (title.available_end_date ? title.available_end_date : title.available_date);
			let end = moment(endDate).add('days', 1).endOf("month");

			return start.format("MMM-DD") + " to " + end.format("MMM-DD")

		} else {
			return ""
		}
	}

	let renderBadge = () => {
		let {archived, archived_date, iddiscoverymode} = title;

		if (archived && archived_date){
			return <span className="badge badge-pale badge-secondary">Archived — {moment(archived_date).format("M/D/YY")}</span>;
		} else if (expiration_date){
			return <span className="badge badge-pale badge-info">Post expires in {expiryDiff()} — {moment(expiration_date).format("M/D/YY")}</span>
			//return <span className="badge badge-pale badge-info">Posted</span>
		} else if (iddiscoverymode == 1){
			return <span className="badge badge-pale badge-warning">Bidding</span>
		} else {
			return <span className="badge badge-pale badge-secondary">Not Posted</span>	
		}
	}


	let expiryDiff = () => {
		let expiration = moment(expiration_date);

		let daysDiff = expiration.diff(moment(), "days");

		return daysDiff + " days";
	}

	return ( <Fragment>

		<div className="tab-pane fade show">

            <div class="clearfix">  
                <h4 class="ml-2 float-left">Inbox — {title.resource}</h4>
				<p class="small-1 strong text-dark text-right pt-3 mb-0"><a class="text-dark" href="#" onClick={() => switchSidePanel("sandbox")}><i class="fa fa-caret-left mr-2" aria-hidden="true"></i>Back to Map</a></p>
			</div>

			<div class="row mb-2">



				<div class="col-12 mx-auto">  

					<div class="bg-white border-brand mb-3" style={{border: "1px solid #344ed0"}}>
                        <div class="row ml-lg-2 p-2">
                          	<div class="col-4 lh-2 px-0">
	                              <label class="fs-15"><strong>{title.name}</strong></label>
                        	</div>
                        	 <div class="col-4 text-center lh-2 px-0">
	                              <label class="fs-15"><strong>{title.resource} {type}</strong></label>
	                            </div>
	                          <div class="col-2 text-center lh-2 text-lg-left">
	                              <span class="fs-15 mb-2 pr-4 pr-md-0"><strong>{parseInt(title.quantity).toLocaleString()}s CY</strong></span>
	                            </div>
	                          <div class="col-2 lh-2 text-lg-right">
	                              <span class="fs-15 mb-2 pr-md-0"><strong>{availableRange()}</strong></span>
	                            </div>

	                          <div class="col-6 px-0">
	                              {/*<img class="round" width="18" height="18" avatar="L K">*/}
	                              <label class="ml-1 fw-400">{title.username}</label>
	                            </div>

	                          <div class="text-right lh-2 ml-auto">
	                               {/*<label class="small-4 text-light fw-400"><em>Updated Feb-7</em></label>*/}
	                            </div>

	                          {/*<div class="accordion text-right lh-2 mr-4 ml-3" id="accordion-details-0">
	                            <a class="small fw-400 cursor" data-toggle="collapse" data-target="#details-0">Details <i class="fa fa-caret-down ml-1" aria-hidden="true"></i></a>
	                          </div>

	                          <div id="details-0" class="collapse" data-parent="#accordion-details-0">
	                              <div class="card-body p-0">

	                                  <div class="section border-top border-bottom py-0">
	                                      <div class="container p-0 py-2 clearfix">

	                                        <div class="col-6 pt-1 pl-0 float-left">
	                                            <label class="small-3 fw-400 lh-2">Description of Material... Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</label>
	                                        </div>
	                                        <div class="col-6 float-right text-right">
	                                          <label class="small fw-400">Trucking by Them</label><br>
	                                          <label class="small fw-400">Charging $0.35 per CY</label>
	                                        </div>
	                                        </div>
	                                      </div>
	                                  </div>
	                              </div>
	                        */}

                          	<div class="col-6 ml-auto text-right">
                                <a class="small level-badge">
                                	{renderBadge()}
                                </a>
                            </div>

                    	</div>
                  	</div>


					{sharedSites.length ? 
						<div class="card border">
							<div class="row mb-2 ml-1 p-2">
								<div class="col-11 border-bottom mb-3">
									<label class="small">Known Match Locations:</label>
								</div>
								{sharedSites.map((site) => {
									return(<div class="col-10 mr-auto ml-6 px-0 clearfix" key={site.idsite}>
										<a class="fs-14 fw-400 text-dark" href="#" onClick={() => showSharedSite(site)}>
											<p class="my-1 ml-4 float-left"><img class="round mb-1 ml-1 mr-2" width="16" height="16" src={LetterAvatar(site.partner)} />{site.partner} - {moment(site.sharedTime).add(1, "days").format("MMM DD")}</p>
											<p class="my-1 mr-4 float-right text-right">
												 {/*<CalcRouteDistance
										            origins={[{lng: site.geom.y, lat: site.geom.x}]}
										            destinations={[{lng: activeSite.geom.y, lat: activeSite.geom.x}]}
										            suffix="mi Route"
										          />*/}
											</p>
										</a>
									</div>)
								})}
								
							</div>
						</div> : null}
				</div>

			</div>

		    {
		      matches.length === 0
		          ? <span><em>There are currently no matches for your imbalance</em></span>
		         : <MatchesList matches={matches} switchSidePanel={switchSidePanel} groupsListMode={groupsListMode} originSite={idsite} sitename={title.name} updateMatches={updateMatches} idsite_resource={idsite_resource} resource={resource + " " + matchingType} />
		    }

            <button className="btn btn-xs btn-outline-info m-1" type="button" data-toggle="modal" data-target={"#details-form-"+idsite_resource+"-inbox"}>Share Details</button>

	    
		    {/*<a href="/haul-check-invite" className="small pull-right">Tell more people about your posts on Haul Check...</a>*/}
	    </div>

	    <ModalShareDetails
	    	idsite_resource={idsite_resource}
	    	parent={"inbox"}
    	/>


	</Fragment> )
}