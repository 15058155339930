import React, {Fragment, useState, useEffect} from 'react';
import './adjustments.css'

export default function DisplayDataCell({children, title, width, className}){

	//let [divWidth, setDivWidth] = useState(0)

	{/*useEffect(() => {

	})*/}

	return(<div className={className + " mst-cell border fixed" + (width ? "-"+width:"")}>
		{/*<div className="d-block d-md-none bg-gray border-bottom">
			<strong>{title}</strong>
		</div>*/}
		{children}
	</div>)
}