import React from 'react'

const Footer = () => {
  return (
    <footer className="footer p-0">
      <div className="col-12 flexbox">
        <div className="col-md-6">
          <div className="nav nav-left">
          </div>
        </div>
        <div className="col-md-6 text-right">
          <span className="nav-link"><small>Haul Check by Earth Ideal ©{new Date().getFullYear()}</small></span>
        </div>
      </div>

    </footer>

  )
}

export default Footer
