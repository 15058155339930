import React, {Fragment, useState, useLayoutEffect, useEffect, useCallback} from "react"

import moment from "moment";
import _ from "lodash";
import jwtDecode from 'jwt-decode';

import ModalShareDetails from '../Conversations/ModalShareDetails';
import ModalDecline from '../Conversations/ModalDecline'
import ModalConnect from '../Conversations/ModalConnect'
import ModalPreview from '../Conversations/ModalPreview'
import ModalShareSite from '../Conversations/ModalShareSite'
import ModalShareRouteDistance from '../Conversations/ModalShareRouteDistance'


import {
	HaulCheckService, 
	MatchesService, 
	ConversationsService, 
	SharesDetailsService, 
	PMGroupsService
} from '../../services'
import {url, earthidealAPIURL} from "../../constants";


export default function useChatTools({onSend, onLoad}) {

	const [state, setStateDirect] = useState({
		loading: false, 
		old_idmatchsiteresource: 0,
		matchData: [{
			idmatchsiteresource: 0,
			idsite: 0
		}],
		newShareDetails: {
			matchsiteresource: 0,
		}
	})


	const setState = (passedState, callback) => {
		let newState = _.cloneDeep(state);
		_.merge(newState, passedState);

		console.log("Calling useChatTools setState with original state", state, "and passed state", passedState, "merged to create new state", newState)
		setStateDirect(newState);


		if (callback && typeof callback == "function"){
			callback()
		}
	}


  const initialize = async (idmatchsiteresource, originsite) => {
  	console.log("initializing useChatTools with idmatchsiteresource", idmatchsiteresource, "and originsite", originsite)
		const userJWT = localStorage.getItem('token');
		const userDecoded = jwtDecode(userJWT);
		const user = userDecoded ? userDecoded['cognito:username'] : null;
		const name = userDecoded ? userDecoded['name'] : null;
		const mail = userDecoded ? userDecoded['email'] : null;
		const corporation = userDecoded ? userDecoded['custom:company'] : null;
		const phonenumber = userDecoded ? userDecoded['custom:phone'] : null;

		let newState = {
			selectedMatch: null,
			newConversation: {message: ''},
			newShareDetails: {matchsiteresource: idmatchsiteresource},
			inputMatch: '',
			modalcontent: '',
			showConversation: false,
			conversation:[],
			results:[],
			originSite: originsite,
			previewDetails:[],
			detailsInConversation:[],
			count:0,
			modalIsOpen: false,//Modal
			user, mail,name,
			startDate: new Date(),
			endDate: new Date(),
			infoshared: 0,
			siteshared: 0,
			idsite_resource: 0,
			matchData: [],
			reloadShareDetails: false,
			idpmgroup: null,
			old_idmatchsiteresource: idmatchsiteresource,
			sendingMessage: false,
			email: mail,
			materialIsArchived: false,
		}

		if (idmatchsiteresource != 0 && idmatchsiteresource != state.old_idmatchsiteresource){

		let matchData = await ConversationsService.getMatchData(idmatchsiteresource);
		console.log("matchData:", matchData);
		if (!matchData || matchData.length == 0) return;

		let emailRecipient = matchData[0].emailRecipient;
		console.log("Found emailRecipient in matchData:", emailRecipient);
		matchData[0].emailRecipient = undefined;

		let selectedMatch = {
			site: originsite,
			match: matchData[0].idmatch,
			matchsiteresource: idmatchsiteresource,
		}

		let newConversation= {
			site: originsite,
			thread: 0,
			status: 0,
			date: new Date().toISOString().slice(0, 19).replace('T', ' '),
			message: '',
			origin: user,
			match: matchData[0].idmatch,
			matchsiteresource: idmatchsiteresource,
			hoursago:0,
			minutesago:0,
			discoverymode: matchData[0].iddiscoverymode
		}

		let newShareDetails = {
			availability: matchData[0].available_date,
			availabilityend: matchData[0].available_end_date,
			resourceid:matchData[0].idresource,
			resource: matchData[0].resource,
			resourcetype: matchData[0].type,
			quantitydetail: 0,
			quantitynoinfo:false,
			description:'',
			descriptionnoinfo:false,
			startdate: moment().format("YYYY-MM-DD"),
			enddate: moment().format("YYYY-MM-DD"),
			stringstartdate: '',
			stringenddate: '',
			timeframestart:'',
			timeframeend:'',
			timeframenoinfo:false,
			trucking:0,
			truckingtype:'',
			materialtipping: 0,
			materialstatus: '',
			perunitload: 0,
			unitload: '',
			cost:0,
			user:user,
			username:name,
			mail:mail,
			corporation:corporation,
			phonenumber:phonenumber,
			sharedetail:0,
			site: originsite,
			match: matchData[0].idmatch,
			matchsiteresource: matchData[0].idmatchsiteresource,
			sharedetailid:1,
			saveasdefault:false,
			discoverymode: matchData[0].iddiscoverymode,
			thread:0,
			sitename: matchData[0].sitename,
		}

		newState.selectedMatch = selectedMatch;
		newState.newConversation = newConversation;
		newState.newShareDetails = newShareDetails;
		newState.matchData = matchData;
		newState.infoshared = matchData[0].infoshared;
		newState.siteshared = matchData[0].siteshared;
		newState.idsite_resource = matchData[0].idsite_resource;

		setState(newState,/* () => {


			//let body = document.querySelector("body");
			//body.appendChild(document.querySelector("#message-thread"));
			// body.appendChild(document.querySelector("#details-form-"+idmatchsiteresource+"-conversations"));
			//body.appendChild(document.querySelector("#business-card-"+idmatchsiteresource));
			//body.appendChild(document.querySelector("#route-distance-"+idmatchsiteresource));
			//body.appendChild(document.querySelector("#share-site-"+idmatchsiteresource));
			//body.appendChild(document.querySelector("#decline-"+idmatchsiteresource));

			fetch(`${url.backend}/conversations/maxconversationid/${newConversation.match}`)
				.then(res => res.json())
				.then(threads => {
					const {maxConversation, infoshared, siteshared} = threads
					setState({
						newConversation: {
							...state.newConversation,
							thread: maxConversation
						},
						newShareDetails: {
							...state.newShareDetails,
							thread: maxConversation
						},
						infoshared, siteshared
					}, () => {
						console.log("Set siteshared to " + siteshared)
					})

				})
				const {messagesList} = results
				setState({
				...state,
				showConversation: !state.showConversation,
				results: messagesList
				}, () => {
				//scrollToBeginning();
				//materialIsArchived();
				onLoad();

				})

			//doIntervalChanges()
			}*/);
		}
	}

	const openModalDecline = () =>{
		document.getElementById("open-modal-decline").click()
	}
	
	const openModalConnect = () =>{
		document.getElementById("open-modal-connect").click()
	}
	
/*	const openModalShareSite = () =>{
		document.getElementById("open-modal-share-site").click()
	}
	
	const openModalShareRouteDetails = () => {
		document.getElementById("open-modal-share-route-distance").click()
	}*/


	const renderMessageThreadModals = () => {
		return <>
		 	{/*<ModalShareDetails
				idsite_resource={(matchData.length > 0 ? matchData[0].idsite_resource : 0)}
				idpmgroup={state.idpmgroup}
				parent="conversations"
				callback={handleModalPost}
				forceReload={state.reloadShareDetails}
		    />*/}
		    <ModalDecline
				handleModalThanks={handleModalThanks}
				idmatchsiteresource = {state.newShareDetails.matchsiteresource}
		    />
		    <ModalConnect
				match={state.newShareDetails}
				handleModalConnect={handleModalConnect}
				idmatchsiteresource = {state.newShareDetails.matchsiteresource}
		    />
		    {/*<ModalShareSite
				handleModalShareSite = {this.handleModalShareSite}
				idmatchsiteresource = {this.state.newShareDetails.matchsiteresource}

		    />
		    <ModalShareRouteDistance
				handleModalShareRouteDistance = {this.handleModalShareRouteDistance}
				idmatchsiteresource = {this.state.newShareDetails.matchsiteresource}
		    />*/}
		</>
	}

	const runMatchingAlgorithm = async () => {
    await HaulCheckService.getMatches(state.matchData[0].idsite);
	}

	const createButtonJQueryData = (target, tip) => {

		let {buttonsDisabled} = state;

		let dataTarget = (buttonsDisabled ? "" : target)

		return {
			["data-toggle"]: "modal",
			["data-target"]: dataTarget,
			//["data-tip"]: tip
		}
	}

	const renderShareDetailsButton = (params = {}) => {
		let {render} = params;
		let {matchData, buttonsDisabled} = state;
		let {idsite_resource} = matchData[0];

		let jqueryData = createButtonJQueryData("#card-details-"+idsite_resource+"-conversations", "Share more info")
		
		let originalDisplayLogic = (
			!(matchData && matchData.length && 
				(matchData[0].isBlasted || matchData[0].partner_msrIsBlasted)
			)
		);

		let onClick= () => {
			setState({reloadShareDetails: !state.reloadShareDetails})
		}

		if (render){
			return render({matchData, onClick, jqueryData, originalDisplayLogic})
		}

		else {
        	return <button 
            	className={"btn btn-xs btn-round btn-info m-1 fs-11 " + 
            		(buttonsDisabled ? "disabled" : "cursor")
        		}
        		type="button" 
        		onClick={onClick} 
        		{...jqueryData}  
    		>
    			Share Details
			</button>
		}

	}

	const renderSwapContactInfoButton = (params = {}) => {
		let {render} = params;
		let {matchData, buttonsDisabled} = state;
		let {idmatchsiteresource} = matchData[0]

		console.log("renderSwapContactInfoButton state", state, "matchData", matchData)

		let jqueryData = createButtonJQueryData("#business-card-"+idmatchsiteresource, "Swap with mutual consent");

		const onClick= () => {console.log("clicking button from renderSwapContactInfoButton")}

		if (render){
			return render({buttonsDisabled, jqueryData, onClick})
		} else {
			return <button 
				className={"btn btn-xs btn-round btn-outline-info m-1 mt-4 fs-11 " 
					+ (buttonsDisabled ? "disabled" : "cursor")
				} 
				type="button" 
				id="open-modal-connect" 
				{...jqueryData}
			>
					Swap Contact Info
			</button>
		}

	}

	const renderDeclineButton = (params = {}) => {
		let {render} = params;
		let {buttonsDisabled, matchData} = state;
		let {idmatchsiteresource} = matchData[0];

		let jqueryData = createButtonJQueryData("#decline-"+idmatchsiteresource, "Decline");

		if (render){
			return render({buttonsDisabled, jqueryData})
		} else {
			return <button 
				className={"btn btn-xs btn-round btn-secondary m-1 mt-4 mr-4 fs-11" + 
					(buttonsDisabled ? "disabled" : "cursor")
				} 
				type="button" 
				id="open-modal-decline" 
				{...jqueryData}
			 >
			 	Respectfully Decline
		 	</button>

		}

	}

	const afterSend = () => {
		console.log("Calling useChatTools afterSend with onSend", onSend)
		if (onSend && typeof onSend == "function") {
			console.log("afterSend calling onSend")
			onSend();
		}
	}

  const postSharedInformation= async (detail) => {
    detail.name = state.name;
    detail.idpmgroup = state.idpmgroup;
    detail.emailRecipient = state.emailRecipient;
    await SharesDetailsService.addNewShareDetail(detail).then()
  }

  const postThanks= async (detail) => {
    detail.name = state.name;
    detail.idpmgroup = state.idpmgroup;
    detail.emailRecipient = state.emailRecipient;
    await SharesDetailsService.postThanksCard(detail).then()
  }

  const postConnect= async (detail) => {
    detail.name = state.name;
    detail.idpmgroup = state.idpmgroup;
    detail.emailRecipient = state.emailRecipient;
    detail.myEmail = state.email;
    return await SharesDetailsService.postConnectCard(detail).then()
  }

	const handleModalThanks = (usingMessageThread = false) => {
		//this.setState({sendingMessage: true});
		postThanks(state.newShareDetails).then(() => {
			//closeModal();
			//reloadConversation();
			afterSend();
		})
	}

	const handleModalConnect = () => {
		//setState({sendingMessage: true});
		postConnect(state.newShareDetails).then((res) => {
			//setState({infoshared: res.infoshared});
			//reloadConversation(true);
			afterSend();

		})
	}


  return {
  	initialize,
  	renderSwapContactInfoButton,
  	renderDeclineButton,
  	renderMessageThreadModals,
  }



	/*handleModalShareSite = () => {
	this.setState({sendingMessage: true});
	this.postSiteShared(state.newShareDetails).then((res) => {
	this.setState({infoshared: res.sharedMutually});
	this.reloadConversation();
	}).catch((e) => console.log(e));
	}
	handleModalShareRouteDistance = () => {
	this.setState({sendingMessage: true});
	this.postShareRouteDistance(state.newShareDetails).then((res) => {
	this.reloadConversation();
	this.setState({infoshared: 1});
	}).catch((e) => console.log(e));
	}

	  postSiteShared= async (detail) => {
    detail.name = state.name;
    detail.idpmgroup = state.idpmgroup;
    detail.emailRecipient = state.emailRecipient;
    return await SharesDetailsService.postSiteShared(detail).then()
  }

  postShareRouteDistance = async (detail) => {
    detail.name = this.state.name;
    detail.idpmgroup = this.state.idpmgroup;
    detail.emailRecipient = this.emailRecipient;
    return await SharesDetailsService.postShareRouteDistance(detail).then()
  }*/

                          
/* <button className={"btn btn-xs btn-round btn-info m-1 fs-11 " + (buttonsDisabled ? "disabled" : "cursor")} type="button" data-toggle="modal" data-target={(buttonsDisabled ? "" : "#route-distance-"+idmatchsiteresource)} id="open-modal-share-route-distance" data-tip="Send distance info">Share Route Distance</button>
<br />
<button className={"btn btn-xs btn-round btn-outline-info m-1 mt-4 fs-11 " + (buttonsDisabled ? "disabled" : "cursor")} type="button" data-toggle="modal" data-target={(buttonsDisabled ? "" : "#share-site-"+idmatchsiteresource)} id="open-modal-share-site" data-tip="Swap with mutual consent">Swap Site Location</button>*/



}