import React, { Fragment } from 'react'
import PropTypes from "prop-types";

export default function BaseShareDetails ({ name, inc, mail, phonenumber}) {

	return(<Fragment>
		<p className="my-0"><strong>{name}</strong></p>
	    <p className="my-0">{inc}</p>
	    <p className="my-0">
	        <a href={"mailto:" + mail+"?Subject=Haul%Check%20Connection"} target="_top">{mail}</a>
	    </p>
	    <p className="mt-0 mb-2"><a href={phonenumber}>{phonenumber}</a></p>
    </Fragment>)
}