import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  LoadScript,
  DistanceMatrixService
} from '@react-google-maps/api'
import { url } from '../../constants'
import jwtDecode from "jwt-decode"


const center = {
  lat: 0,
  lng: -180,
}

class ExampleDirections extends Component {
  state = {
    response: null,
    travelMode: 'DRIVING',
    origin: '43.066411, -87.873366',
    destination: '43.068731, -87.884053',
    origin2: '43.070643, -87.904877',
    destination2: '43.051611, -87.888599',
    results: [],
    directionsdata:[],
    title:[],
  }

  async componentDidMount() {
    let haul = localStorage.getItem('newHaulCheckID');
    let idsite = haul ? JSON.parse(haul).idsite : null;
    let idsiteresource = haul ? JSON.parse(haul).idsite_resource : null;
    let namesite = haul ? JSON.parse(haul).name : null;
    const userJWT = localStorage.getItem('token');
    const userDecoded = jwtDecode(userJWT);
    const user = userDecoded ? userDecoded['cognito:username'] : null;
    this.state = {
      user,
      idsite,
      idsiteresource,
      namesite,
    }
    if(idsiteresource){
      fetch(`${url.backend}/matches/getmatchsbyidsiteresource/${this.state.idsiteresource}`)
        .then(res =>res.json())
        .then(results => {
          const  { matchesList } = results
          this.setState({ results: matchesList }, console.log('State results',results))
        })
    }
  }

  containerStyle = {
    width: '100%',
    height: 'calc(100vh',
  };
  center = {
    lat: 43.03501925228261,
    lng: -87.90833392173009
  };



  directionsCallback = response => {
    console.log(response)

    if (response !== null) {
      if (response.status === 'OK') {
          this.setState({
          directionsdata: [...this.state.directionsdata, response]
          });
      } else {
        console.log('response: ', response)
      }
    }
  }

directionsData(direction){



}


  onMapClick = (...args) => {
    console.log('onClick args: ', args)
  }


  render = () => (
    <div className='map'>

      <div className='map-container'>

      {console.log('Directions data',this.state.directionsdata)}
      <LoadScript
        googleMapsApiKey="AIzaSyCRTgaqtoxDv1Uj23wnjgFG249oqCJ4uw4"
      >
          <GoogleMap
            id='direction-example'
            mapContainerStyle={this.containerStyle}
            zoom={2}
            center={center}
            onClick={this.onMapClick}
          >
          {
              this.state.results ?
              this.state.results.map((item,i) =>
                <DirectionsService
                  key={i}
                  options={{
                    destination: item.latitud_dest +','+item.longitud_dest,
                    origin: item.latitud +','+item.longitud,
                    travelMode: this.state.travelMode,
                  }}
                  callback={this.directionsCallback}
                />) :''
          }

          {
              this.state.directionsdata ?
              this.state.directionsdata.map((item,i) =>
              <DirectionsRenderer
                options={{
                  directions: item,
                }}
              />) :''
          }
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  )
}

export default ExampleDirections
