import _ from 'lodash'
import {isJsonString} from './isJsonString';

//This is meant to be used specifically with arrays of objects
//Do not make this function async... this is largely meant for state updates. Use a wrapper if it must be async

export function addChildToArray (previousArray, newChild, duplicatePreventionVar = null, sortVar = null){
  //console.log("Calling addChildToArray")

  let arr = _.cloneDeep(previousArray);
  if (isJsonString(newChild)){
    newChild = JSON.parse(newChild)
  }

  if (typeof newChild == 'object' || newChild instanceof Object){
    arr = addChildToArrayOfObjects(arr, newChild, duplicatePreventionVar, sortVar)
  } else {
    arr = addChildToArrayOfPrimitives(arr, newChild, duplicatePreventionVar, sortVar)
  }

  return arr;
}

function addChildToArrayOfObjects (arr, newChild, duplicatePreventionKey, sortByKey){
  console.log("addChildToArrayOfObjects preventDuplicates params:",arr,newChild,duplicatePreventionKey,"and index:",_.findIndex(arr, {[duplicatePreventionKey]: arr[duplicatePreventionKey]}));
   if (arr.push){
    if (duplicatePreventionKey == null || _.findIndex(arr, (e) => e[duplicatePreventionKey] === newChild[duplicatePreventionKey]) === -1){
      arr.push(_.cloneDeep(newChild));
    }
  }

  if (sortByKey !== null){
    arr = _.sortBy(arr, sortByKey)
  }

  return arr;
}

function addChildToArrayOfPrimitives (arr, newChild, preventDuplicates, sort){
  if (arr.push){
    //console.log("addChildToArrayOfPrimitives preventDuplicates params:",arr,newChild,"and index:",_.findIndex(arr, newChild));

    if (preventDuplicates == false || _.findIndex(arr, (e) => e === newChild) === -1){
      arr.push(newChild);
    }
  }

  if (sort == true){
    arr = _.sort(arr);
  }

  return arr;
}