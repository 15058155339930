export function moveElementToBody(elementId){
	let element, elementParent, body;

	console.log("moveElementToBody started with id " + elementId);

	//while (!element) {
		element = document.querySelector("#" + elementId);
		body = document.querySelector("body");
	//}

	if (!element) {
		return;
	};

	//let clone_element = element.cloneNode(true);
	elementParent = element.parentElement;
	
	body.appendChild(/*clone_element*/ element);


	console.log("moveElementToBody original element:", element);
	console.log("moveElementToBody elementParent:", elementParent);
	//console.log("moveElementToBody clone element:", clone_element);
	//console.log("moveElementToBody parent of clone element:", clone_element.parentElement);

	if (elementParent.contains(element)){
		elementParent.removeChild(element);
	}
}