import React, { Component, Fragment } from 'react'
import  PropTypes from 'prop-types'
import Match from "../Match";
//import Matches from "../index";

class  MatchsList extends Component{
    static  propTypes = {
        matchs: PropTypes.array,
        originSite:PropTypes.number.isRequired
    }
    render() {
        const  { matches, originSite, updateMatches, resource, sitename, idsite_resource, switchSidePanel, groupsListMode } = this.props
        return matches.map((match, index, matches) => {
            return (<Fragment>
                <div key={match.idmatchsiteresource} className="accordion mt-2 mx-auto" id={"accordion-"+match.idmatchsiteresource}>
                    <Match
                        idsite={match.idsite}
                        idsite_resource={idsite_resource}
                        idmatchsiteresource={match.idmatchsiteresource}
                        originSite={originSite}
                        idmatch={match.idmatch}
                        date={match.creation_date}
                        distance={match.distance}
                        quantity={match.quantity}
                        statusMatch={match.status}
                        message={match.message}
                        resource={match.idresource}
                        resourceName={resource}
                        wasread={match.wasread}
                        nameresource={match.resource}
                        typeresource={match.type}
                        discoverymode={match.iddiscoverymode}
                        infoshared={match.infoshared}
                        updateMatches={updateMatches}
                        sitename={sitename}
                        siteshared = {match.siteshared}
                        partnerarchived = {match.partner_archived}
                        labelInfo = {match.labelInfo}
                        isLabeled = {match.labeled_partner}
                        sharedDetails = {match.sharedDetails}
                        sharedRoute = {match.sharedRoute}
                        origins={[{lng: match.longitud, lat: match.latitud}]}
                        destinations={[{lng: match.longitud_dest, lat: match.latitud_dest}]}
                        switchSidePanel={switchSidePanel}
                        groupsListMode={groupsListMode}
                        availableDate={match.available_date}
                        availableEndDate={match.available_end_date}
                        everInteractedWith={match.ever_interacted_with}
                    />
                </div>
            </Fragment>)
        })
    }

}
export default MatchsList
