import React from 'react';
import _ from 'lodash';
import {useHistory, useLocation} from "react-router-dom"
import {removeChildFromArray} from "../../utils"

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

//Hardcoded list of valid URL pieces and where in the URL they can appear
const validUrlPieces = [
	["sites", "sidebar", "sandbox", "matches"],
	["my-sites", "group-sites", "materials"],
	["material-info"]
]

let newURL = [];

export const useMySitesPanelChange = () => {

	const history = useHistory();
	const location = useLocation();

	const add = (paramToAdd) => {
		let url = createMySitesURL({paramToAdd});
		console.log("useMySitesPanelChange add paramToAdd:", paramToAdd," url:", url);
		if (url !== null){
			history.push(url);
		}
	}

	const remove = (paramToRemove) => {
		let url = createMySitesURL({paramToRemove});
		console.log("useMySitesPanelChange.remove with paramToRemove:", paramToRemove, "and new URL", url);
		if (url !== null){
			history.push(url);
		}
	}

	const swap = (paramToAdd, paramToRemove) => {
		let url = createMySitesURL({paramToAdd, paramToRemove});
		if (url !== null){
			history.push(url);
		}
	}

	//Returns null when final URL is the same as current URL,
	//To prevent WSODs in cases where the function is called repeatedly,
	//such as when the sidebar is loading
	const createMySitesURL = ({paramToAdd, paramToRemove}) => {

		console.log("createMySitesURL being called with location", history, "paramToAdd", paramToAdd, "paramToRemove", paramToRemove);

		let originalPathname = location.pathname;
		let oldUrl = location.pathname.split('/').slice(2);

		console.log("createMySitesURL oldUrl", oldUrl)

		let data = {
			validUrlPieces, oldUrl, newUrl: _.cloneDeep(oldUrl)
		}

		if (paramToAdd !== undefined){
			data.newUrl = addUrlParam(paramToAdd, data);
		}

		if (paramToRemove !== undefined){
			console.log("createMySitesURL removeUrlParam being called with data", data, "paramToRemove", paramToRemove);
			data.newUrl = removeUrlParam(paramToRemove, data.newUrl);
		}

		let finalUrl = getFinalUrl(data);
		console.log("createMySitesURL finalUrl:", finalUrl, "and original pathname", originalPathname)
		if (finalUrl == originalPathname) return null;
		else return finalUrl;
	}

	const getFinalUrl = (data) => {

		let urlArr = _.cloneDeep(data.newUrl)
		console.log("getFinalUrl urlArr:", urlArr)

		//Remove sidebar settings from matches panel URLs
		if (urlArr.indexOf("matches") !== -1){
			console.log("getFinalUrl cleaning out sites info from matches panel");
			for (var i = 0; i < validUrlPieces[1].length; i++){
				let piece = validUrlPieces[1][i];
				console.log("getFinalUrl removing piece", piece)
				urlArr = removeUrlParam(piece, urlArr);
				console.log("getFinalUrl urlArr:", urlArr)
			}
		}

		console.log("createMySitesURL getFinalUrl final arr:", urlArr);

		//Finally, turn array back into string and return
		let finalUrl = "/app";

		for (var i = 0; i < urlArr.length; i++){
			finalUrl += "/" + urlArr[i] //[i]
		}

		return finalUrl;
	}

	const addUrlParam = (param, data) => {
		let {oldUrl, validUrlPieces, newUrl} = data;
		console.log("createMySitesURL addUrlParam param", param, "data", data)
		let toReturn = _.cloneDeep(newUrl);
		console.log("createMySitesURL addUrlParam init url:", newUrl);

		//Overwrite the appropriate spot in the new URL with the param
		let {x} = searchValidUrlPieces(param);
		console.log("createMySitesURL addUrlParam x:", x, "toReturn.length:", toReturn.length)
		if (x < toReturn.length){
			toReturn[x] = param;
		}
		console.log("createMySitesURL addUrlParam after pushing to index [x]:", toReturn);

		//Special handling for the last 'layer' of URL pieces, which is for overlay panels
		let finalIndex = (validUrlPieces.length - 1)
		if (validUrlPieces[finalIndex].indexOf(param) !== -1 && oldUrl.indexOf(param) === -1){
			console.log("createMySitesURL addUrlParam special overlay handling")
			toReturn.push(param)
		}

		console.log("createMySitesURL addUrlParam final url:", toReturn);

		return toReturn;
	}

	const removeUrlParam = (param, urlArr) => {
		console.log("Calling removeUrlParam with param", param, "and urlArr", urlArr);
		return removeChildFromArray(urlArr, param);
	}

	const searchValidUrlPieces = (param) => {
		var x = 0;
		var y = 0;
		for (var i = 0; i < validUrlPieces.length; i++){
			for (var j = 0; j < validUrlPieces[i].length; j++){
				let piece = validUrlPieces[i][j]

				if (piece !== undefined && piece == param){
					x = i;
					y = j;
				}
			}
		}

		return {x, y}
	}

	return {add, swap, remove}
}

export const getDerivedDisplayStateFromURL = (location, currentDisplayState) => {

	let currentURL = location.pathname.split('/').slice(2);
	let passedDisplayState = currentURL[0];
    let params = currentURL.splice(1);

    let newState = {
	    displayState: "",
	    panelProps: {},
	}

	let openMessageThread = false;

	console.log("getDerivedDisplayStateFromURL passedDisplayState:", passedDisplayState, "params:", params)


	if (passedDisplayState == "sites"){
		newState.displayState = (isMobile ? "sidebar" : "sandbox");

		let lastActiveSidebarTab = localStorage.getItem("lastActiveSidebarTab");

	   console.log("getDerivedDisplayStateFromURL lastActiveSidebarTab:", lastActiveSidebarTab, "currentDisplayState:", currentDisplayState)


		if (!params || _.isEmpty(params)){

			console.log("getDerivedDisplayStateFromURL no params, loading lastActiveSidebarTab if it exists?:", !!lastActiveSidebarTab)
			if (lastActiveSidebarTab){
				newState.panelProps.forceTab = lastActiveSidebarTab;
			}
		} else if (params[0] == "group-sites"){
		  	newState.panelProps.forceTab = "groupSites";
		} else if (params[0] == "materials"){
		  	newState.panelProps.forceTab = "material";
		} else if (params[0] == "my-sites"){
				newState.panelProps.forceTab="mySites"
		} else if (params[0] == "sandbox"){
			if (isMobile){
				newState.displayState = "sandbox";
			} else {
				//this.props.history.push(defaultFrontPagePath)
			}
		} else {
			console.log("getDerivedDisplayStateFromURL activating final 'else' clause")
			let lastActiveSidebarTab = localStorage.getItem("lastActiveSidebarTab");

			if (lastActiveSidebarTab) {
				newState.panelProps.forceTab = lastActiveSidebarTab;

			} else {
				newState.panelProps.forceTab="mySites";
			}

		}

	} else if (passedDisplayState == "matches"){

		newState.displayState="inHouse";
		newState.panelProps = JSON.parse(localStorage.getItem('matchesPanel'));

		console.log("getDerivedDisplayStateFromURL", "panelProps:", newState.panelProps)

	} else if (passedDisplayState == "messages") {

		/* if (this.state.displayState !== null){
		  newState.displayState = undefined;
		} else {
		  newState.displayState = "inHouse"
		}*/



	} else {
	//this.props.history.push(defaultFrontPagePath);
	}

	console.log("getDerivedDisplayStateFromURL", "About to check params for index of material-info:", params, "which should be at index", params.indexOf("material-info"));

	if (params.indexOf("material-info") !== -1){
		console.log("getDerivedDisplayStateFromURL", "material-info found, ensuring material info is open");
		let materialProps = JSON.parse(localStorage.getItem('matchesPanel'))
		newState.panelProps.idsite = materialProps.idsite;
		newState.panelProps.idsite_resource = materialProps.idsite_resource;
		newState.panelProps.idsiteresource = materialProps.idsiteresource;
		newState.panelProps.showMaterialInfo = true;
	} else {
		console.log("getDerivedDisplayStateFromURL", "material-info not found, ensuring material info is closed")
		newState.panelProps.showMaterialInfo = false;
	}

	return newState;

}

//export default {useMySitesPanelChange, getCurrentMySitesURL}