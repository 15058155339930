import _ from 'lodash'


export default function sortDataIntoTitles({rows, layers}){

	let newTitles = _.cloneDeep(layers);

	for (var i = 0; i < rows.length; i++){
		let keys = Object.keys(rows[i]);
		//console.log("sortDataIntoTitles keys for row:", keys, " with layers", layers);
		
		let newKeys = [];

		for (var j = 0; j < keys.length; j++){
			let titlesIndex = newTitles.indexOf(keys[j]);

			if (titlesIndex === -1 && keys[j] !== "key"){
				newKeys.push(keys[j])
			}
		}

		//console.log("sortDataIntoTitles newKeys:", newKeys)

		if (!_.isEmpty(newKeys)){
			newTitles = _.concat(newTitles, newKeys);
		}
	}

	//console.log("sortDataIntoTitles Titles:", newTitles);

	return newTitles;

}