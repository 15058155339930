import React from 'react';

import {
	hcDisplayPrice
} from '../../../utils';

export default function CartItem({item, description, price, style, onClick, className}){

	return <tr className={className ? className : ""} onClick={onClick ? onClick : null}>
		<td style={style ? style : null}>
			<p className="lead-1 text-dark mb-0 pt-2 pl-0 pr-2"><b>{item}</b></p>
			<p>{description}</p>
		</td>

		<td>
			<h4 className="price">{hcDisplayPrice(price,false)}</h4>
		</td>
	</tr>
}