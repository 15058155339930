import React, { Fragment } from 'react'
import moment from 'moment';
import _ from 'lodash'

let noInfoString = "No info"
let specificDateFormat = "MMMM DD";
let generalDateFormat = "MMM YYYY";
let shortDateFormat = "MMM DD";

export function hcDetailsDisplay(key, passedDetailsObj, params = {}){

	console.log("calling hcDetailsDisplay with params:", key, passedDetailsObj, params);

	let detailsObj = _.cloneDeep(passedDetailsObj);
	if (Array.isArray(detailsObj) && !_.isEmpty(detailsObj)) detailsObj = detailsObj[detailsObj.length-1];

	if (key == "availability"){
		return renderAvailability(detailsObj, params.dateFormat, params.availableLabels, params.emptyState)
	} else if (key == "timeframe"){
		return renderTimeframe(detailsObj, params.dateFormat || undefined, params.emptyState || undefined)
	} else if (key == "quantity"){
		return renderQuantity(detailsObj, params.emptyState || undefined)
	} else if (key == "trucking"){
		return renderTrucking(detailsObj, params.emptyState || undefined)
	} else if (key == "description"){
		return renderDescription(detailsObj, params.emptyState || undefined)
	} else if (key == "cost"){
		console.log("hcDetailsDisplay returning renderCost");
		return renderCost(detailsObj, params.emptyState, params.chargingLabels, params.per, params.unitLabels)
	}
}

function renderAvailability (detailsObj, dateFormat = generalDateFormat, availableLabels=["Available ", "Available Until"], emptyState = "Availability info not specified"){
	if (!detailsObj) return emptyState;

	let {availability, availabilityend, startdate, enddate} = detailsObj;

	if (startdate && !availability) availability = startdate;
	if (enddate && !availabilityend) availabilityend = enddate;

	let daysToAddToStart = 1;
	let daysToAddToEnd = 1;
	if (moment(availabilityend).date() > 20) daysToAddToEnd = 0;

	if (availability && ! availabilityend){
		return "Available " + moment(availability).add("days", daysToAddToStart).format(dateFormat);
	} else if (!availability && availabilityend){
		return "Available until " + moment(availabilityend).add("days", daysToAddToEnd).format(dateFormat);
	} else if (!availability && ! availabilityend){
		return emptyState;
	} else {
		return "Available " + moment(availability).add("days", daysToAddToStart).format(dateFormat) + " ‒ " + moment(availabilityend).add("days", daysToAddToEnd).format(dateFormat);
	}
}

function renderTimeframe (detailsObj, dateFormat = shortDateFormat, emptyState = "No specific time frame info given"){
	/*if (!detailsObj) return emptyState;

	let {startdate, startdatedetail, startDate, enddate, enddatedetail, endDate, fromFrontEnd} = detailsObj;

	let start = startdate || startdatedetail || startDate;
	let end = enddate || enddatedetail || endDate;

	let daysToAdd = 1;
	if (fromFrontEnd) daysToAdd = 0;

	if (start && !end){
        return "Starting " + moment(start).add("days", daysToAdd).format(dateFormat);
    } else if (!start && end){
        return "Until " + moment(end).add("days", daysToAdd).format(dateFormat)
  	} else if (start && end){
        return moment(start).add("days", daysToAdd).format(dateFormat) + " ‒ " + moment(end).add("days", daysToAdd).format(dateFormat);
    } else {
    	return emptyState
    }*/

    return null;
}

function renderQuantity (detailsObj, emptyState = "No quantity info given") {
	if (!detailsObj) return emptyState;

	let {quantitydetail, quantity} = detailsObj;

	if (!quantitydetail && quantity) quantitydetail = quantity;

	if (typeof quantitydetail == "string"){
        quantitydetail = quantitydetail.replaceAll(",", ""); 
    }

	if (quantitydetail){
		return parseInt(quantitydetail).toLocaleString() +' CY\'s';
	} else {
		return emptyState
	}
}

function renderTrucking (detailsObj, emptyState = "No trucking info given") {
	if (!detailsObj) return emptyState

	let {truckingdetail, trucking} = detailsObj;

	let truck = trucking || truckingdetail;

	if (!truck){
		return "No trucking info";
	} else if (truck == 1){
		return "Trucking by others"
	} else if (truck == 2){
		return "Trucking by Me"
	} else if (truck == 3){
		return "Trucking Shared"
	} else {
		return emptyState
	}
}

function renderDescription (detailsObj, emptyState="No additional description") {
	console.log("renderDescription emptyState:", emptyState);
	if (!detailsObj) return emptyState

	let {description, descriptiondetail} = detailsObj;

	let desc = description || descriptiondetail;

	if (desc){
		return desc
	} else {
		return emptyState
	}
}

function renderCost (detailsObj, emptyState = "No cost info given", 
	chargingLabels = ["I'm charging ", "I'm not charging"], 
	per = "per ", 
	unitLabels = ["Load", "Ton", "CY", "TBD"]
){
	if (!detailsObj) return emptyState

	console.log("Calling renderCost with detailsObj", detailsObj, "emptyState", emptyState, "chargingLabels", chargingLabels, "per", per, "unitLabels", unitLabels)

	let {materialtippingdetail, materialtipping, materialdescriptiondetail, materialstatus, cost, costdetail, unitloaddetail, unitload, perunitload} = detailsObj;

	let mtd = parseFloat(materialtippingdetail || materialtipping);
	let desc = materialdescriptiondetail || materialstatus;
	let c = parseFloat(costdetail || cost).toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
	let unit = unitloaddetail || unitload;

	console.log("renderCost final params: mtd:", mtd, "desc:", desc, "c:", c, "unit:", unit)

	if (!unit){
		if (perunitload <= 3){
			console.log("renderCost perunitload less than 4, using per label")
			unit = per + unitLabels[perunitload-1]
		} else {
			console.log("renderCost perunitload is 4, using TBD or equivilent")
			unit = unitLabels[3];
		}
	}

	if (mtd == 1){
		console.log("renderCost returning cost because MTD was 1")
		if (c === undefined) c = 0;
		return chargingLabels[0] + c + " " + unit;
	} else if (mtd == 2){
		console.log("renderCost returning charginglabels")
		return chargingLabels[1];
	} else {
		return emptyState
	}
}
