import React, {Fragment} from 'react';

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

/*
Custom display component that helps maintain best display/UI practices 
between the mobile and desktop versions of the matches panel.

Abstracted into its own file so it can be reused between different matches panel components.

This component is meant to be the parent to the "empty state" - 
empty MatchListDisplay lists that are nonetheless displayed.
*/

export default function EmptyStateWrapper (props) {

  if (isMobile){
    return <>
      <div className="card border">
        <div className="card-body px-4 pt-1 pb-0">
          {props.children}
        </div>
      </div>
    </>
  } else if (isBrowser){
    return <>{props.children}</>
  }

}