import React, { Component } from 'react'
import PropTypes from "prop-types";
import BaseMessageLayout from './BaseMessageLayout'

class Messages extends Component {
    static  propTypes ={
        idconversation: PropTypes.number,
        textmessage: PropTypes.string,
        resource: PropTypes.string,
        type: PropTypes.string,
        messagedate: PropTypes.string,
        originusermessage:PropTypes.string,
        username:PropTypes.string,

    }

    render() {
        const { idconversation, textmessage, resource, type, messagedate, hours, minutes, username, originusermessage, originname, displayoriginname} = this.props
        return (
            <BaseMessageLayout
                messagedate={messagedate}
                hours={hours}
                minutes={minutes}
                resource={resource}
                type={type}
                originname={originname}
                displayoriginname={displayoriginname}
                >
                <p class="mb-0">{textmessage}</p>
            </BaseMessageLayout>
        )
    }
}

export default Messages


