import React from 'react'
import './index.css'
import DatePicker from 'react-datepicker'


const ModalShareRouteDistance = ({handleModalShareRouteDistance, idmatchsiteresource}) => {

  return(
    <div className="modal fade" id={"route-distance-"+idmatchsiteresource} data-bs-backdrop="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header bg-gray px-4 pt-2 pb-0">
            <p className="modal-title strong" id="exampleModalLabel">Share Route Distance</p>
            <button type="button" className="close pt-5" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <p>Let your match see the trucking distance between your site and theirs, without disclosing your identity or location.</p>
            <p class="small">To protect their privacy, you will not see this distance unless they also share Route Distance or share their Conact Info & Site Location.</p>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal" onClick={handleModalShareRouteDistance}>Share Route Distance</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalShareRouteDistance;