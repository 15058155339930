import React, { Fragment } from 'react'
import moment from 'moment';

export default function SharedDetailsDisplay (props) {

	let {sharedDetailsObj, mode} = props;

	let noInfoString = "No info"
	let specificDateFormat = "MMMM DD";
	let generalDateFormat = "MMM YYYY";
	let shortDateFormat = "MMM DD";


	let renderAvailability = () => {
		if (!sharedDetailsObj) return "Availability info not specified";

		let {availability, availabilityend} = sharedDetailsObj;

		let daysToAddToStart = 1;
		let daysToAddToEnd = 1;
		 
		if (moment(availabilityend).date() > 20) daysToAddToEnd = 0;
		if (moment(availabilityend).isAfter("2221-01-01")){
			availabilityend = false;
		}

		if (availability && ! availabilityend){
			return "Available from " + moment(availability).add("days", daysToAddToStart).format(generalDateFormat) + " onward";
		} else if (!availability && availabilityend){
			return "Available until " + moment(availabilityend).add("days", daysToAddToEnd).format(generalDateFormat);
		} else if (!availability && ! availabilityend){
			return "Availability info not specified"
		} else {
			return "Available " + moment(availability).add("days", daysToAddToStart).format(generalDateFormat) + " ‒ " + moment(availabilityend).add("days", daysToAddToEnd).format(generalDateFormat);
		}

	}

	let renderTimeframe = (format) => {
		/*if (!sharedDetailsObj) return "No specific time frame info given";

		let {startdate, startdatedetail, startDate, enddate, enddatedetail, endDate, fromFrontEnd} = sharedDetailsObj;

		let start = startdate || startdatedetail || startDate;
		let end = enddate || enddatedetail || endDate;

		let daysToAdd = 1;
		if (fromFrontEnd) daysToAdd = 0;

		if (start && !end){
	        return "Starting " + moment(start).add("days", daysToAdd).format(format);
	    } else if (!start && end){
	        return "Until " + moment(end).add("days", daysToAdd).format(format)
      	} else if (start && end){
	        return moment(start).add("days", daysToAdd).format(format) + " ‒ " + moment(end).add("days", daysToAdd).format(format);
	    } else {
	    	return "No specific time frame info given"
	    }*/

	    return null;
	}

	let renderQuantity = () => {
		if (!sharedDetailsObj) return "No quantity info given";

		let {quantitydetail} = sharedDetailsObj;

		if (typeof quantitydetail == "string"){
            quantitydetail = quantitydetail.replaceAll(",", ""); 
        }

		if (quantitydetail){
			return parseInt(quantitydetail).toLocaleString() +' CY\'s';
		} else {
			return "No quantity info given"
		}
	}

	let renderGeneralQuantity = () => {
		if (!sharedDetailsObj) return "No quantity info found";

		let {originalQuantity} = sharedDetailsObj;

		return parseInt(originalQuantity).toLocaleString() +'s CY\'s';
	}

	let renderTrucking = () => {
		if (!sharedDetailsObj) return "No trucking info given";

		let {truckingdetail, trucking} = sharedDetailsObj;

		let truck = trucking || truckingdetail;

		if (!truck){
			return "No trucking info";
		} else if (truck == 1){
			return "Trucking by others"
		} else if (truck == 2){
			return "Trucking by Me"
		} else if (truck == 3){
			return "Trucking Shared"
		} else {
			return "No trucking info given";
		}
	}

	let renderDescription = () => {
		if (!sharedDetailsObj) return "No additional description";

		let {description, descriptiondetail} = sharedDetailsObj;

		let desc = description || descriptiondetail;

		if (desc){
			return desc
		} else {
			return "No additional description"
		}
	}

	let renderCost = () => {
		if (!sharedDetailsObj) return "No cost info given";

		let {materialtippingdetail, materialtipping, materialdescriptiondetail, materialstatus, cost, costdetail, unitloaddetail, unitload, perunitload} = sharedDetailsObj;

		let mtd = materialtippingdetail || materialtipping;
		let desc = materialdescriptiondetail || materialstatus;
		let c = costdetail || cost;
		let unit = unitloaddetail || unitload;

		if (!unit){
			if (perunitload == 1){
				unit = "per Load"
			} else if (perunitload == 2){
				unit = "per Ton";
			} else if (perunitload == 3){
				unit = "per CY";
			} else {
				unit = "TBD";
			}
		}

		if (mtd == 1){
			if (c == undefined) c = 0;

			return "I'm charging $" + c + " " + unit;
		} else if (mtd == 2){
			return "I'm not charging";
		} else {
			return "No cost info given"
		}
	}

	if (mode == "messagethread"){

		let timeframenoinfodetail = false;
		let timeframenoinfo = false;
		let quantitynoinfodetail = false;
		let quantitynoinfo = false;
		let descriptionnoinfodetail = false;

		if (sharedDetailsObj){
			timeframenoinfodetail = sharedDetailsObj.timeframenoinfodetail;
			timeframenoinfo = sharedDetailsObj.timeframenoinfo;
			quantitynoinfodetail = sharedDetailsObj.quantitynoinfodetail;
			quantitynoinfo = sharedDetailsObj.quantitynoinfo;
			descriptionnoinfodetail = sharedDetailsObj.descriptionnoinfodetail;
		}

		return (<Fragment>
			<p className="small-2 m-0 mt-2 ml-2 mr-4 p-0">
				<strong>{renderAvailability()}</strong>
			</p>
			{/*<p className="small-2 m-0 mb-2 ml-2 mr-4 p-0">
				{timeframenoinfodetail || timeframenoinfo ? "No specific time frame info given" : renderTimeframe(specificDateFormat)}
			</p>*/}
			<p className="small-2 m-0 mt-2 ml-2 mr-4 p-0">
				<strong>{ /*quantitynoinfodetail || quantitynoinfo ? "No quantity info given" : renderQuantity()*/ renderGeneralQuantity() } — {descriptionnoinfodetail ? "No description given": renderDescription() }</strong>
			</p>
			<p className="small-2 m-0 mb-4 ml-2 mr-4 p-0">
				{renderTrucking()}: {renderCost()}
			</p>
		</Fragment>)

	} else if (mode == "inboxcard") {
		let description = "No details yet.";
		if (sharedDetailsObj){
			description = sharedDetailsObj.description;
		}

		let {partnerName, resource, type, renderQuantity} = props;

		return(<Fragment>

			<div class="d-flex lh-1 mb-3">
		        <div class="mr-auto m-0 p-0"><label class="fw-400">{partnerName}</label></div>
		        {/*<div class="ml-auto m-0 p-0 mr-2"><label class="fw-400">{(!sharedDetailsObj ? "No details yet" : renderTimeframe(specificDateFormat))}</label></div>*/}
		      </div>

		      <div class="d-flex align-items-end flex-column lh-1 mr-3">
		        <div class="m-0 p-0"><label class="fs-12"><em>{renderTrucking()}</em></label></div>
		        <div class="m-0 p-0 mt-1"><label class="fs-13 strong">{renderCost()}</label></div>
		      </div>

		      <div class="d-flex lh-1">
		        <div class="m-0 p-0 mt-auto">
		          <label class="fs-20 strong mr-1">{resource}</label>
		          <label class="small-2 fw-200">— { renderDescription() }</label>
		        </div>
		        <div class="m-0 p-0 mt-2 mr-3 ml-auto"><label class="fs-18 strong">{type} {renderQuantity()}</label></div>                                            
		      </div>

		</Fragment>)

	} else if (mode=="matches"){
		let descriptionnoinfodetail = false;
		if (sharedDetailsObj){
			descriptionnoinfodetail = sharedDetailsObj.descriptiondetail;
		}

		return(<Fragment>
			<div className="container p-0 m-0 flexbox">
				<div className="col-3 lh-1 px-0 mr-0">
					<label className="small-3 fw-200"><em>{ (!sharedDetailsObj ? "No details yet" : descriptionnoinfodetail ? "No additional description": renderDescription()) }</em></label>
				</div>
				<div className="col-3 lh-1 px-0 mr-0 text-center">
					<label className="small-3 fw-200"><em>{renderTrucking()}</em></label>
				</div>
				<div className="col-3 lh-1 px-0 mr-0 text-center">
					<label className="small-3 fw-200"><em>{renderCost()}</em></label>
				</div>
				{/*<div className="col-3 lh-1 text-right">
					<label className="small-3 fw-200"><em>{renderTimeframe(specificDateFormat)}</em></label>
				</div> */}                                                                        
			</div>

		</Fragment>)
	} else if (mode== "matchesMobile"){
		let descriptionnoinfodetail = false;
		if (sharedDetailsObj){
			descriptionnoinfodetail = sharedDetailsObj.descriptiondetail;
		}

		return <div className="container p-0 m-0">
			<div className="col-12 lh-1 px-0 mr-0">
				<label className="small-3 fw-200"><em>{ (!sharedDetailsObj ? "No details yet" : descriptionnoinfodetail ? "No additional description": renderDescription()) }</em></label>
			</div>
			<div className="col-12 lh-1 px-0 mr-0">
				<label className="small-3 fw-200"><em>{renderTrucking()}</em></label>
			</div>
			<div className="col-12 lh-1 px-0 mr-0">
				<label className="small-3 fw-200"><em>{renderCost()}</em></label>
			</div>
			{/*<div className="col-12 lh-1 px-0 mr-0">
				<label className="small-3 fw-200"><em>{renderTimeframe(specificDateFormat)}</em></label>
			</div>*/}      
			<div className="col-12 lh-1 text-right pr-0">
				<label className="small-3 fw-200"><em></em></label>
			</div> 
		</div>

	} else if (mode=="externalMatches"){
		let descriptionnoinfodetail = false;
		if (sharedDetailsObj){
			descriptionnoinfodetail = sharedDetailsObj.descriptiondetail;
		}

		return(<Fragment>
			
			<div className="col-2 lh-1 px-0 mr-0">
			  <label className="small-3 fw-200"><em>{ (!sharedDetailsObj ? "No details yet" : descriptionnoinfodetail ? "No additional description": renderDescription()) }</em></label>
			</div>
			<div className="col-2 lh-1 px-0 mr-0 text-center">
			  <label className="small-3 fw-200"><em>{renderTrucking()}</em></label>
			</div>
			<div className="col-2 lh-1 px-0 mr-0 text-center">
			  <label className="small-3 fw-200"><em>{renderCost()}</em></label>
			</div>
			{/*<div className="col-2 lh-1 text-center">
			  <label className="small-3 fw-200"><em>{renderTimeframe(specificDateFormat)}</em></label>
			</div>*/}      

		</Fragment>)
	} else if (mode == "externalMatchesMobile"){

		let descriptionnoinfodetail = false;
		if (sharedDetailsObj){
			descriptionnoinfodetail = sharedDetailsObj.descriptiondetail;
		}

		return (<>
			<div class="col-12 lh-1 px-0 mr-0">
		     	<label class="small-3 fw-200"><em>{ (!sharedDetailsObj ? "No details yet" : descriptionnoinfodetail ? "No additional description": renderDescription()) }</em></label>
		    </div>
		    <div class="col-12 lh-1 px-0 mr-0">
		        <label class="small-3 fw-200"><em>{renderTrucking()}</em></label>
		    </div>
		    <div class="col-12 lh-1 px-0 mr-0">
		        <label class="small-3 fw-200"><em>{renderCost()}</em></label>
		    </div>
		    {/*<div class="col-12 lh-1 px-0 mr-0">
		        <label class="small-3 fw-200"><em>{renderTimeframe(specificDateFormat)}</em></label>
		    </div>*/} 

		</>)
	} else if (mode=="matchesMine"){

		let {partnerName, resource, type, renderQuantity} = props;

		let descriptionnoinfodetail = false;
		if (sharedDetailsObj){
			descriptionnoinfodetail = sharedDetailsObj.descriptiondetail;
		}

		return(<>
			<div class="d-flex lh-1 mb-3">
				<div class="mr-auto m-0 p-0"><label class="fw-400">{partnerName}</label></div>
				{/*<div class="ml-auto m-0 p-0 mr-2"><label class="fw-400">{(!sharedDetailsObj ? "No details yet" : renderTimeframe(specificDateFormat))}</label></div>*/}
			</div>

			<div class="d-flex align-items-end flex-column lh-1 mr-3">
				<div class="m-0 p-0"><label class="fs-12"><em>{renderTrucking()}</em></label></div>
				<div class="m-0 p-0 mt-1"><label class="fs-13 strong">{renderCost()}</label></div>
			</div>

			<div class="d-flex lh-1">
				<div class="m-0 p-0 mt-auto">
					<label class="fs-20 strong mr-1">{resource} {type}</label>
					<label class="small-2 fw-200">— { descriptionnoinfodetail ? "No additional description": renderDescription() }</label>
				</div>
				<div class="m-0 p-0 mt-2 mr-3 ml-auto"><label class="fs-18 strong">{type} {renderQuantity()}</label></div>                                            
			</div>
		</>)

	} else if (mode == "matchesMineMobile"){

		let descriptionnoinfodetail = false;
		if (sharedDetailsObj){
			descriptionnoinfodetail = sharedDetailsObj.descriptiondetail;
		}

		return(<>

	        <div class="m-0 p-0 text-right"><label class="fs-12"><em>{ (!sharedDetailsObj ? "No details yet" : descriptionnoinfodetail ? "No additional description": renderDescription()) }</em></label></div>
	        {/*<div class="m-0 p-0 text-right"><label class="fs-12"><em>{renderTimeframe(specificDateFormat)}</em></label></div>*/}

	        <div class="m-0 p-0 text-right"><label class="fs-12"><em>{renderTrucking()}</em></label></div>
	        <div class="m-0 p-0 text-right"><label class="fs-12"><em>{renderCost()}</em></label></div>

		</>)
	
	} else {
		return null;
	}
}