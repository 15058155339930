import React, {Component, Fragment, useState}  from 'react';

import {
  //ApiService,
  HaulCheckService,
  //MatchesService,
  //ConversationsService
} from '../../../../services';

export default function Rollover({idsite, callback}){

   let archiveSite = async () => {
        await HaulCheckService.setArchivedBySite(idsite);
        callback();
        closePopup();
    }

    let closePopup = () => {
        document.getElementById("close-archive-site").click();
    }

    return(<>
        <button id={"open-archiveSite-site-setting-modal"} data-toggle="popup" data-target={"#archivesite"} style={{display: "none"}}>Popup Trigger Button</button>
        <div id="archivesite" class="popup col-6 col-md-4 p-0" data-position="top-center" data-animation="slide-down">
            <button type="button" class="close" data-dismiss="popup" aria-label="Close" id="close-archive-site">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="media alert-info p-5">
                <i class="ti ti-archive lead pt-3"></i>
                <div class="media-body fw-200">
                    <p class="lead-3 strong ml-2">Archive Site?</p>
                    <p class="fw-400 lh-2 mb-4 pr-7">Are you sure you want to archive this site and all of its materials?</p>
                    <button type="button" class="btn btn-info float-right mx-2" onClick={archiveSite}>Yes, Archive</button>
                    <button type="button" class="btn btn-outline-info float-right mx-2" onClick={closePopup}>Cancel</button>
                </div>
            </div>
        </div>
    </>)
}