export function arrayParseInt(arr){

	let toReturn = [];

	for (var i = 0; i < arr.length; i++){
		let item = arr[i];
		if (!isNaN(parseInt(item))) toReturn.push(parseInt(item));
		else toReturn.push(item);
	}

	return toReturn;
}