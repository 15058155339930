import React, {useState, useEffect, Fragment} from 'react';
import {
  ApiService
} from '../../services';
import SuccessPopup from './Popups'


export default function EditSiteName({site, callback}){

    let minNameLength = 4;
    let maxNameLength = 140;
    let [newName, setNewName] = useState("");
    let [newNameValid, setNewNameValid] = useState(true);
    let [popup, triggerPopup] = useState(false);

    useEffect(() => {
        setNewName("");
        setNewNameValid(true);
        triggerPopup(false);
    }, [(site ? site.idsite : undefined)])

    let validateName = async () => {
        let validName = newNameValid;

        if (newName.length < minNameLength){
            validName = "New site name is too short";
        } else if (newName.length > maxNameLength){
            validName = "New site name is too long";
        } else {
            validName = true;
        }

        if (validName !== newNameValid){
            setNewNameValid(validName)
        }

        return validName;
    }

    let editName = async () => {
        console.log("Calling edit")

        let validName = await validateName();

        console.log("validName is true:", validName === true);

        if (validName === true){

            if (site != null && site.idsite != 0){
                await ApiService.renameSite({idsite: site.idsite, newName});
            }

            if (callback){
                callback(newName);
            }

            triggerPopup(true);
            closeModal();
        }
        
    }

    let closeModal = () => {
        document.getElementById('close-edit-site-name').click();
    }

    return(<>
        <button id={"open-edit-site-name"} 
            data-toggle="modal" 
            data-target={"#edit-site-name"} 
            style={{display: "none"}}>
            Popup Trigger Button
        </button>

        <div class="modal" id="edit-site-name" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <div class="alert alert-primary" role="alert">
                        <strong>Edit Site Name</strong>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id={'close-edit-site-name'}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body pt-0">
                        <div class="row m-0">
                            <div class="col-lg-12">
                                <div class="form-group row">
                                    <div class="col-12 px-0 mb-2 mx-auto">
                                        <label class="lead text-dark fw-600">{site != null ? site.name : ""}</label>
                                        <div class="contrast">
                                            <input
                                                className={"form-control" + (newNameValid !== true ? " is-invalid" : "")}
                                                id="nameProject"
                                                name="nameProject"
                                                type="text"
                                                placeholder="New Site Name"
                                                onChange={(e) => setNewName(e.target.value)}
                                                value={newName}
                                            />
                                            {newNameValid !== true ? 
                                                <span class="text-danger">{newNameValid}</span> 
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a class="btn btn-primary" onClick={editName}>Edit Site Name</a>
                </div>
            </div>
        </div>

        <SuccessPopup
            popupId="popupUsersAdded"
            title="Success!"
            message="Site name changed!"
            trigger={popup}
            callback={() => triggerPopup(false)}
        />
    </>)

}