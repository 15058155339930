//Created for FEA-1339 (archiving/deleting sponsored sites),
//To help keep Sponsored Site specific functionality separated from regular site management services.
//Please note that services relevant to sponsored site *creation and editing* are not currently present in this file.

import { url, earthidealAPIURL } from '../constants'
import {ApiService} from "../services"

export const SponsoredSitesService = {
  archiveSponsoredSite,
  unarchiveSponsoredSite,
  deleteArchivedSponsoredSite
}

async function archiveSponsoredSite(idsite) {
  console.log("Calling archiveSponsoredSite with idsite", idsite);
  return await sponsoredSitesPostRequest(idsite, "archiveSponsoredSite")
}

async function unarchiveSponsoredSite(idsite) {
  return await sponsoredSitesPostRequest(idsite, "unarchiveSponsoredSite")
}

async function deleteArchivedSponsoredSite(idsite) {
  return await sponsoredSitesPostRequest(idsite, "deleteArchivedSponsoredSite")
}

async function sponsoredSitesPostRequest(idsite, service){
  let username = ApiService.getJWTUsername();
  let email = ApiService.getJWTEmail();

  let data = {username, email, idsite}
  console.log("Calling sponsoredSitesPostRequest with data", data)

  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data
    })
  }

  const resp = await fetch(`${url.backend}/sponsored/${service}`, requestOptions)
    .then(handleResponse)
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}