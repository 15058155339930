import { url, earthidealAPIURL } from '../constants'

export const StatesService = {
  getAllStates,
  setUserStates,
  getMultiStateBounds
}

async function getAllStates() {
  const resp = await fetch(`${earthidealAPIURL}/api/getAllStates`).then(handleResponse)
  //console.log('Messages from', id, ':', resp.messagesCount)
  return resp.data
}

async function getMultiStateBounds(states) {
  const resp = await fetch(`${earthidealAPIURL}/api/getMultiStateBounds/${states}`).then(handleResponse)
  //console.log('Messages from', id, ':', resp.messagesCount)
  return resp.data
}



function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
async function setUserStates(data){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data
    })
  }

  const resp = await fetch(`${url.backend}/conversations/addmessage`, requestOptions)
      .then(handleResponse)

  return resp
}
