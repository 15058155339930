import React, {Fragment, Component} from 'react';
import BaseGuideModal from '../../Event/BaseGuideModal';

export default class SupportGuide extends Component {

    constructor(props){

  	super(props);

	  	this.state = {
	  		modals: [
	  			//Getting Set Up
	  			{
	  				id: "account-help",
	  				pages: [
	  					{
							img: null,
							title: <strong>Account Help</strong>,
							text: <><p>Need help with your account? Give us a call or send us an email!</p>
									<p>Contact via Email: <a href="mailto:success@earthideal.com">success@earthideal.com</a><br />
									Phone number: <a href="tel:2626821146">(262) 682-1146</a></p></>
				        }
	  				],
	  			},

	  			//Site Based Field Use
	  			{
	  				id: "payment",
	  				pages: [
	  					{
							title: <strong>Payment</strong>,
							text: <><p>Haul Check is a no-cost application when used within an Office Group. You will only be required to enter a credit card when you Post or Search externally.</p> 
							<p>If you have any questions about payments, please give us a call!</p></>,
							img: null,
				        }
	  				],
	  			},

	  			{
	  				id: "privacy",
	  				pages: [
	  					{
							title: <strong>Your Privacy</strong>,
							text: <>For Privacy Information please refer to our <a href="https://haulcheck.com/terms" target="_blank">Terms & Privacy</a>.</>,
							img: null,
				        },
	  				],
	  			},

	  			{
	  				id: "feedback",
	  				pages: [
	  					{
							title: <strong>Feedback</strong>,
							text: <>Got something to say? If you have an issue with our app and want to suggest something, please drop us a line at: <a href="mailto:success@earthideal.com">success@earthideal.com</a></>,
							img: null,
				        }
	  				],
	  			}

	  		]
	  	}

    }

    render(){
    	return(<Fragment>
    		{ this.state.modals.map((modal, key) => {
    			return  <BaseGuideModal key={key} id={modal.id} markAsSeen={() => {return true}} pages={modal.pages} suppressOpen={true} />
    		}) }
		</Fragment>)
    }


}