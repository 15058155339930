import React from 'react'

const Archive = ({haul}) => {
  return (
    <tr>
      <td scope="row">{ haul.name }</td>
      <td>{ haul.lastMatchDate }</td>
      <td>{ haul.resource }</td>
      <td>{ (haul.quantity || 0).toLocaleString('en') }s of CY's</td>
      <td><button className="btn btn-xs btn-outline-secondary" data-toggle="modal" data-target="#modal-closed">&nbsp;VIEW RECORDS&nbsp;</button></td>
    </tr>
  )
}

export default Archive
