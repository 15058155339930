import React, {Component, Fragment, useState}  from 'react';

import {
  //ApiService,
  HaulCheckService,
  //MatchesService,
  //ConversationsService
} from '../../../../services';

export default function Radius({idsite, currentMaxDistanceNum, callback}){

	let [newMaxDistance, setNewMaxDistance] = useState(currentMaxDistanceNum);
	let [validNum, setValidNum] = useState(true);

	let updateSiteMaxDistance = async () => {

        newMaxDistance = parseInt(newMaxDistance);

        if (newMaxDistance && !isNaN(newMaxDistance)){
            await HaulCheckService.updateSiteMaxDistance({distance: newMaxDistance, idsite})
            setValidNum(true);
            callback();
            document.getElementById("close-radius").click();
        } else {
            setValidNum(false);
        }  
    }

	return(<>
        <button id={"open-radius-site-setting-modal"} data-toggle="modal" data-target={"#radius"} style={{display: "none"}}></button>
		<div class="modal" id="radius" tabindex="-1" role="dialog" aria-labelledby="radius" aria-hidden="true">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">

					<div class="alert alert-primary" role="alert">
						<strong>Update Search Radius</strong>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close-radius">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>

					<div class="modal-body pt-0">
						<div class="row mt-0 mb-2 ml-0">
							<div class="col-11 px-1 pb-2 ml-4 mb-4">
								<p class="mb-0 pt-2 pb-2 pl-lg-4 pr-2"><b>Current Search Radius: {(typeof currentMaxDistanceNum === "number" ? currentMaxDistanceNum : 40)} miles</b></p>
								<div class="form-group contrast col-5 px-5">
									 <input 
	                                        className={"form-control bg-gray" + (validNum ? "" : " is-invalid")} 
	                                        type="text" 
	                                        placeholder="Enter new Max Distance"
	                                        onChange={e => {
	                                            setNewMaxDistance(e.target.value)
	                                        }}
	                                        value={newMaxDistance}
	                                     />
	                                     {validNum ? null : <small className="text-danger">Must enter a valid number</small>}
								</div>
								{/*<p class="small mt-4 ml-3"><em>Use Match Criteria from the pencil menus to change the search radius for a single material.</em></p>*/}
							</div>
						</div>


					</div>

					<a class="btn btn-primary" href="#" onClick={updateSiteMaxDistance}>Update Radius for all Materials</a>
				</div>
			</div>
		</div>
		</>
	)
}