import React, { Component } from 'react'
import PropTypes from "prop-types";
import BaseMessageLayout from './BaseMessageLayout';

class ShareLocationMessage extends Component {
    static  propTypes ={
        idconversation: PropTypes.number,
        textmessage: PropTypes.string,
        resource: PropTypes.string,
        type: PropTypes.string,
        messagedate: PropTypes.string,
        originusermessage:PropTypes.string,
        username:PropTypes.string,
        quantitydetail: PropTypes.number,
        quantitynoinfodetail: PropTypes.number,
        descriptiondetail:PropTypes.string,
        descriptionnoinfodetail:PropTypes.string,
        startdatedetail:PropTypes.string,
        enddatedetail:PropTypes.string,
        timeframenoinfodetail: PropTypes.number,
        truckingdetail: PropTypes.number,
        truckingdescriptiondetail:PropTypes.string,
        materialtippingdetail: PropTypes.number,
        materialdescriptiondetail:PropTypes.string,
        unitloaddetail:PropTypes.string,
        costdetail: PropTypes.number
    }

    render() {
        const { idconversation, textmessage, resource, type, messagedate, hours, minutes, username, originusermessage, quantitydetail, quantitynoinfodetail,
            descriptiondetail, descriptionnoinfodetail, startdatedetail, enddatedetail, timeframenoinfodetail, truckingdescriptiondetail, materialtippingdetail,
            materialdescriptiondetail, unitloaddetail, costdetail, availability } = this.props


        return(
            <BaseMessageLayout
                messagedate={messagedate}
                hours={hours}
                minutes={minutes}
                resource={resource}
                type={type}
                originname={this.props.originname}
                displayoriginname={this.props.displayoriginname}
                >
                    <p class="mb-0">Your partner has shared their site with you.</p>
            </BaseMessageLayout>
        )
    }
}

export default ShareLocationMessage


