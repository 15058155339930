import { earthidealAPIURL } from '../constants'

export const SiteTeamsService = {
  addSiteTeamMembers,
  removeSiteTeamMembers,
  getSiteTeamMembers,
  setSiteManager,
}


async function addSiteTeamMembers(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  const resp = await fetch(`${earthidealAPIURL}/siteteams/addSiteTeamMembers`, requestOptions)
    .then(handleResponse)

  return resp
}

async function removeSiteTeamMembers(data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  const resp = await fetch(`${earthidealAPIURL}/siteteams/removeSiteTeamMembers`, requestOptions)
    .then(handleResponse)

  return resp
}

async function getSiteTeamMembers(idsite) {
  if (idsite){
    const resp = await fetch(`${earthidealAPIURL}/siteteams/getSiteTeamMembers/${idsite}`).then(handleResponse)
    //console.log("getSiteTeamMembers resp:", resp);
    return resp.data
  } else return [];
}

async function setSiteManager(data) {
   const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }

  const resp = await fetch(`${earthidealAPIURL}/siteteams/setSiteManager`, requestOptions)
    .then(handleResponse)

  return resp
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
