import React, { Component, Fragment } from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import jwtDecode from 'jwt-decode'
import ResourceTable from './ResourceTable'
import PMGroup from '../common/PMGroup'
//import ModalChangeQuantityAndAvailability from './ModalChangeQuantityAndAvailability'
//import Modal from 'react-modal'
import {
  //ApiService,
  //HaulCheckService,
  //MatchesService,
  //ConversationsService,
  PMGroupsService
} from '../../services'

import './style.css'


export default class CompanyImbalances extends Component {

    constructor(props) {
        super(props);

        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        const user = userDecoded ? userDecoded['cognito:username'] : null;
        const email = userDecoded ? userDecoded['email'] : null;

        this.state = {
            user,
            email,
            resourcesSitesList: [],
            allExpanded: false,
            pmGroupName: "",
        }
    }

    componentWillMount = async () => {
        let resourcesSitesList = await PMGroupsService.getCompanyImbalances(this.state.email);
        //let pmGroup = await PMGroupsService.getPmGroupByEmail(this.state.email);

        //console.log("resourcesSitesList", resourcesSitesList)

        this.setState({resourcesSitesList, /*pmGroupName: (pmGroup ? pmGroup.name : "My Imbalances")*/ });
    }


    render(){

        let {resourcesSitesList, pmGroupName} = this.state;

        return(<Fragment>
            <Navbar showSandbox={true} />
            <main className="main-content">
                <header className="section-header mt-5 mb-1">
                    <h2><strong>My Office Group's Sites</strong></h2>
                </header>

                <div className="section py-2">
                    <div className="container">

                    <div class="row">
                        <div class="col-12">
                            <a className="small ml-auto mr-6 pt-3 mb-0 pull-right" href="#" onClick={() => this.setState({allExpanded: !this.state.allExpanded})}>Expand / Close All</a>
                        </div>
                    </div>

                        {/* Tab Bar */}  
                        {/*<div className="col-11 mx-auto mb-6 p-0">  
                            <ul className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="../02-haul-check/company-imbalances.html">Office Group Imbalances</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/matches-inbox">Matches Inbox</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="../02-haul-check/archived-haul-checks.html">Archive</a>
                                </li>
                                <a className="small ml-auto mr-2 pt-3 mb-0" href="#" onClick={() => this.setState({allExpanded: !this.state.allExpanded})}>Expand / Close All</a>
                                <li className="nav-item ml-4">
                                    <div className="dropdown mt-3 mr-4">
                                    <span className="dropdown-toggle no-caret" data-toggle="dropdown"><i className="ti ti-settings text-lighter" aria-hidden="true"></i></span>
                                        <div className="dropdown-menu dropdown-menu-right">
                                            <a className="dropdown-item cursor" data-toggle="modal" data-target="#">Option</a>
                                            <a className="dropdown-item cursor" href="#" style={{display: 'block'}}>Option</a>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>*/}
                        {/* tab bar end*/}  


                        {resourcesSitesList.map((resource, i) => {
                            if (resource.sites.length > 0){
                                return (<ResourceTable
                                    resource={resource}
                                    index={i}
                                    key={i}
                                    expanded={this.state.allExpanded}
                                 /> )
                            }
                        })}

                        <PMGroup />

                    </div>
                </div>
            </main>
            <Footer />

        </Fragment>);
    }

}
