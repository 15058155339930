import React, {Component, Fragment, useState}  from 'react';

import {
  //ApiService,
  HaulCheckService,
  //MatchesService,
  //ConversationsService
} from '../../../../services';

export default function ArchiveMaterial({idsite_resource, idsite, callback}){

    let archiveMaterial = async () => {
        await HaulCheckService.setArchived(idsite_resource);
        await HaulCheckService.getMatches(idsite);
        callback();
        closePopup();
    }

    let closePopup = () => {
        let closeButton = document.getElementById("close-archive-material");
        if (closeButton) closeButton.click();
    }

    return(<>
        <button id={"open-archiveMaterial-site-setting-modal"} data-toggle="popup" data-target={"#archive-material"} style={{display: "none"}}>Popup Trigger Button</button>
        <div id="archive-material" class="popup col-6 col-md-4 p-0" data-position="top-center" data-animation="slide-down">
            <button type="button" class="close" data-dismiss="popup" aria-label="Close" id="close-archive-material">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="media alert-info p-5">
                <i class="ti ti-envelope lead pt-3"></i>
                <div class="media-body fw-200">
                    <p class="lead-3 strong ml-2">Archive Material</p>
                    <p class="fw-400 lh-2 mb-4 pr-7">Are you sure you want to archive this material?</p>
                    <button type="button" class="btn btn-info float-right mx-2" onClick={archiveMaterial}>Yes, Archive</button>
                    <button type="button" class="btn btn-outline-info float-right mx-2" onClick={closePopup}>Cancel</button>
                </div>
            </div>
        </div>
    </>)
}