import React, {Fragment, useState, useRef, useEffect} from 'react'
import moment from 'moment'
import OptButton from './OptInToPayment/OptButton';
import CheckoutInterface from '../Checkout/CheckoutInterface';

const Material = ({material, index, toggleStatus, type, toggleArchive, openModal, openRadiusModal, openModalInvite, addMonth, siteArchived, canEditSite, deleteMaterial, isSiteOwner, hasExternalAccess}) => {

    let [showDropdown, toggleDropdown] = useState(false);
    const dropdown = useRef(null);

    useEffect(() => window.addEventListener('click', ev => {
        if(dropdown.current && dropdown.current.contains(ev.target)) {toggleDropdown(!showDropdown)}
        else {toggleDropdown(false)}
    }));

    let setStatus = async (goToBlastReferral) => {
        toggleStatus(material.idsite_resource, index, goToBlastReferral);
        toggleDropdown(false);
    }

    let setArchive = async () => {
        toggleArchive(material.idsite_resource, material.archived, index);
        toggleDropdown(false);
    }

    let showModal = async () => {
        console.log("Calling openModal from material");
        openModal(material);
        toggleDropdown(false);
    }

    let showRadiusModal = async () => {
        openRadiusModal(material);
        toggleDropdown(false);
    }

    let expiryDiff = () => {
        let expiration_date = moment(material.expiration_date);

        let daysDiff = expiration_date.diff(moment(), "days");

        return daysDiff + " days";
    }

    let addMonthToPost = async () => {
        addMonth(material.idsite_resource, index);
        toggleDropdown(false);
    }

    let availableRange = () => {
        if (material.available_date){
            let start =  moment(material.available_date).add('days', 1).startOf("month");

            if (material.perennialEndDate === 0 || material.perennialEndDate === "0"){
                let endDate = (material.available_end_date ? material.available_end_date : material.available_date);
                let end = moment(endDate).add('days', 1).endOf("month");

                return start.format("MM/DD/YY") + " to " + end.format("MM/DD/YY")
            } else {
                return start.format("MM/DD/YY") + " onward" 
            }
            
        } else {
            return ""
        }
    }

    if (!material) return null;

    else return(<Fragment>
        <tr className={material.archived ? "tr-archived" : "" }>
            <td className="strong">
                {material.resource}
             </td>
            <td>
                {material.type == "Surplus/Export" ? "+" : "-"} {parseInt(material.quantity).toLocaleString()}s CY
            </td>
            <td>{availableRange()}</td>
            {hasExternalAccess ? <>
                <td>
                    {material.archived && material.archived_date ? 
                        <span className="badge badge-pale badge-secondary">Archived — {moment(material.archived_date).format("M/D/YY")}</span>
                    :
                        (canEditSite ?
                            material.status == "external" ?
                                <OptButton
                                    checked={material.status != "internal"}
                                    label={type.verb + " Externally"}
                                    openModalInvite={openModalInvite}
                                    setStatus={setStatus}
                                    idsite_resource={material.idsite_resource}
                                    iddiscoverymode={material.iddiscoverymode}
                                />
                                :
                                <CheckoutInterface
                                    material={material}
                                    render={(goToBlastReferral, goToCheckout, state, setState) => {
                                        if (material.iddiscoverymode == 2) return <div class="btn-group mx-auto">
                                            <button type="button" class="btn btn-xs btn-round btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                              Post to get matches
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right">
                                              <a class="dropdown-item" href="#" onClick={goToBlastReferral}>Post & Tell Others</a>
                                              <a class="dropdown-item" href="#" onClick={goToCheckout}>Post Only</a>
                                            </div>
                                        </div>

                                        else if (material.iddiscoverymode == 1) return <div class="btn-group mx-auto">
                                            <button type="button" class="btn btn-xs btn-round btn-primary" onClick={goToCheckout}>
                                              Search to get matches
                                            </button>
                                        </div>
                                    }}
                                />
                            
                        : (material.status == "internal") ? "Internally "+ type.verb  : "Externally "+ type.verb )
                    }
                </td>
                <td>
                    {material.status == "external" && material.iddiscoverymode == 2 ? 
                        <span className="badge badge-pale-primary">Post Expires in {expiryDiff()} — {moment(material.expiration_date).format("M/D/YY")}</span>
                    : material.has_ever_been_external == 1 && material.status == "internal" && material.iddiscoverymode == 2 ?
                       <span className="badge badge-pale badge-warning">Post Expired {moment(material.expiration_date).format("M/D/YY")}</span>
                     :material.status == "external" && material.iddiscoverymode == 1 ? 
                        <span className="badge badge-pale-primary">Bid Search Expires in {expiryDiff()} — {moment(material.expiration_date).format("M/D/YY")}</span>
                    : material.has_ever_been_external == 1 && material.status == "internal" && material.iddiscoverymode == 1 ?
                       <span className="badge badge-pale badge-warning">Search Expired {moment(material.expiration_date).format("M/D/YY")}</span>
                    : null }
                </td>
            </>: null}
            <td>
                {!siteArchived && canEditSite ?
                <div className="dropdown">
                    <span className=" no-caret p-3 cursor" ref={dropdown}>
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </span>
                    <div className={"dropdown-menu dropdown-menu-right " + (showDropdown ? "show" : "")} tabIndex="0" onBlur={() => toggleDropdown(false)}>
                        {(material.status == "internal" || material.archived || siteArchived) ? null
                        : <>
                            <div className="dropdown-item hover" onClick={addMonthToPost}>Add 1 month to {type.noun} Duration</div>
                        </> }
                        {material.archived ? 
                            <div className="dropdown-item hover" onClick={setArchive}>Recover from Archive</div>
                        :
                            <div className="dropdown-item hover" onClick={setArchive}>Archive Material</div>
                        }
                        <div className="dropdown-item hover" onClick={showModal}>General Info</div>
                        {/*<div className="dropdown-item hover" onClick={showRadiusModal}>Search Radius</div>*/}
                        <div className="dropdown-item hover" onClick={() => {deleteMaterial(material); toggleDropdown(false);}}>Delete Material</div>
                    </div>
                </div> : null}
            </td>
        </tr>
    </Fragment>)
}

export default Material