import React, {Component, Fragment, useState}  from 'react';

import {
  //ApiService,
  HaulCheckService,
  //MatchesService,
  //ConversationsService
} from '../../../../services';

export default function Rollover({mode, oppositeMode, idsite, callback}){

    let [loading, setLoading] = useState(false);

    let rollPhase = async () => {
        setLoading(true);
        if (mode == "bid"){
            await HaulCheckService.setConstructionProject(idsite);
        } else {
            await HaulCheckService.unsetConstructionProject(idsite);
        }

        setLoading(false);
        closePopup();
        callback();
    }

    let closePopup = () => {
        document.getElementById("close-rollover-"+mode+"-site-setting-modal").click();
    }

    return(<>
        <button id={"open-rollover"+mode+"-site-setting-modal"} data-toggle="popup" data-target={"#rollover"+mode} style={{display: "none"}}>Popup Trigger Button</button>
        <div id={"rollover"+mode} className="popup col-6 col-md-4 p-0" data-position="top-center" data-animation="slide-down">
            <button type="button" className="close" id={"close-rollover-"+mode+"-site-setting-modal"} data-dismiss="popup" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div className="media alert-primary p-5">
                <i className="ti ti-control-forward lead pt-3"></i>
                <div className="media-body fw-200">
                    <p className="lead-3 strong ml-2">Roll-over to {oppositeMode}?</p>
                    <p className="fw-400 mb-4 pr-7 lh-2">Are you sure you want to roll this site into the {oppositeMode} Phase?</p>
                    <button type="button" className="btn btn-primary float-right mx-2" onClick={rollPhase} disabled={loading}>  {loading ? <img src="../img/app/photoswipe/preloader.gif" /> : null}Yes</button>
                    <button type="button" className="btn btn-outline-primary float-right mx-2" onClick={closePopup} disabled={loading}>Cancel</button>
                </div>
            </div>
        </div>
    </>)
}