import React, { Component, Fragment } from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import EditSiteName from '../common/EditSiteName';
//import CCForm from '../common/CCForm'
import Resource from './Resource'
//import PMGroup from '../common/PMGroup'
import SuccessPopup from '../common/Popups'
import {
  ApiService,
  HaulCheckService,
  StripeService } from '../../services'
import DatePicker, { registerLocale } from 'react-datepicker'
import enUS from 'date-fns/locale/en-US'
import moment from 'moment'

import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'
import { url } from '../../constants'
//import "react-datepicker/dist/react-datepicker.css"
import '../../assets/css/page.css'
import '../../assets/css/style.min.css'
import '../../assets/css/ei-style.css'

import './datepickeradjust.css'

import {deleteSidebarPositionData, setSidebarPositionData} from "../MySites/Sidebar/utils/positionSidebar"


registerLocale("en-US", enUS);

class Setup extends Component {

  constructor(props){
    super(props);

    let defaultResourceObj = {
      resource: '',
      type: '',
      idquantity: "0",
      availabledate: null,
      availableenddate: null,
      maxdistance: 40,
      perennialEndDate: 0,
      valid_resource: true,
      valid_type: true,
      valid_idquantity: true,
      valid_availabledate: true,
      valid_availableenddate: true,
      valid_maxdistance: true,
  }


    this.state = {
      loading: false,
      successPopup: false,
      newIdHaul:0,
      newHaulCheck: {
        name: JSON.parse(localStorage.getItem('site')).name,
        idsite: JSON.parse(localStorage.getItem('site')).idsite,
        username: '',
        startDate: '',
        endDate: '',
        maxDistance: '',
        resources: [JSON.parse(JSON.stringify(defaultResourceObj))],
        /*geometry: {
          lat: 0,
          lng: 0
        },*/
        iddiscoverymode: 1,
        archived: false,
        autoRenewal: false
      },
      idsource: 'tok_visa',
      email: '',
      //startDate: new Date(),
      //endDate: new Date(),
      resourcesList: [],
      imbalancesList: [],
      quantitiesList: [],
      discoveryModesList: [],
      error: false,
      //markerPosition: null,
      defaultResourceObj,
    }
  }

  handleChange = e => {
    this.setState({
      newHaulCheck: {
        ...this.state.newHaulCheck,
        [e.target.name]: e.target.value
      }
    })

    e.preventDefault()
  }

  /*handleOptionChange = e => {
    this.setState({
      newHaulCheck: {
        ...this.state.newHaulCheck,
        autoRenewal: e.target.value === "on"
      }
    })
  }*/

  handleDateChange = (date, d) => {
    const newDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    if (d === 0) {
      this.setState({
        newHaulCheck: {
          ...this.state.newHaulCheck,
          startDate: newDate
        },
        startDate: date
      })
    }
  }

  handleSubmit = async () => {
    this.setState({loading: true});
    const { error, newHaulCheck } = this.state
    console.log(error)

    if (!error) {
      if (this.validateResources()) {

        if (!newHaulCheck.maxDistance){
          this.setState({
            newHaulCheck: {
              ...this.state.newHaulCheck,
              maxDistance: 40
            }
          })
        }

        let resources = newHaulCheck.resources.map(resource => {
          if (resource.resource !== '' && resource.type !== '' && resource.idquantity !== 0 && resource.availabledate !== null) {
            return resource
          }
        })
        this.setState({
          newHaulCheck: {
            ...this.state.newHaulCheck,
            resources
          }
        })

        //let response =

        let response = await HaulCheckService.setup(this.state.newHaulCheck)
        if (response && !response.error) {
          /*await StripeService.makeCharge(this.state.idsource, this.state.email).then(() => {
            const newHaul=response.newSite;//idsite
            this.setState({
              newIdHaul:newHaul.idsite
            },() => {this.getMatchesByHaulCheck();}
          )*/
          //localStorage.setItem('newHaulCheckID', JSON.stringify(response.newSite))
          await HaulCheckService.getMatches(this.state.newHaulCheck.idsite);
          this.setState({successPopup: true, loading: false});
          //})
        } else {
          alert('Error trying to create a new HaulCheck:', response.message)
           this.setState({loading: false});
        }

      } else {
        //alert('All fields are required in order to create a new HaulCheck.')
         this.setState({loading: false});
        return
      }
    } else {
      alert('Error trying to create a new HaulCheck.')
       this.setState({loading: false});
    }

  }

  async getMatchesByHaulCheck() {
    fetch(`${url.backend}/matches/getmatches/${this.state.newIdHaul}`)
      .then(res =>res.json())
      .then(results => {
        const  { matchesList } = results
        this.setState({ results: matchesList })
      })
  }

  validateIsReady = () => {
    const {
      name,
      startDate,
      endDate,
      resources,
      maxDistance,
      discoveryMode,
      perennialEndDate,
      //geometry
    } = this.state.newHaulCheck
    //const {lat, lng} = geometry
    if (name !== '' && startDate !== '' && (endDate !== '' || (perennialEndDate === 1 || perennialEndDate === "1")) && resources.length > 0
        && maxDistance !== '' && discoveryMode !== ''/* && lat !== 0 && lng !== 0*/) {
      return true
    } else {
      let fields = ''
      if (name === '') {
        fields = '- Site Name '
      }
      if (startDate === '') {
        fields = `${fields}- Start Date `
      }
      if (endDate === '') {
        fields = `${fields}- End Date `
      }
      //if (maxDistance === '') {
        //fields = `${fields}- New Max Distance `
      //}
      if (resources.length === 1 && resources[0].resource === '') {
        fields = `${fields}- Resources `
      }
      if (resources.length === 1 && resources[0].type === ''){
        fields = `${fields}- Imbalance`
      }
      if (resources.length === 1 && resources[0].availabledate === ''){
        fields = `${fields}- Date Available`
      }
      /*if (lat === 0 && lng === 0) {
        fields = `${fields}- Map Location `
      }*/
      fields = `${fields}-`
      alert(`The next field(s) is/are missing: ${fields}`)
      console.log(resources)
      return false
    }
  }

  validateResources = () => {

    let {resources} = this.state.newHaulCheck;
    let valid = true;    

    for (var i = 0; i < resources.length; i++){
      let r = resources[i];
      r.valid_resource = (r.resource !== '');
      r.valid_type = (r.type !== '');
      r.valid_idquantity = (r.idquantity !== 0 && r.idquantity !== "0");
      r.valid_availabledate = (r.availabledate !== null && r.availabledate !== undefined && r.availabledate instanceof Date);
      r.valid_availableenddate = (r.perennialEndDate === 1 || r.perennialEndDate === "1") || (r.availableenddate !== null  && r.availabledate !== undefined && r.availableenddate instanceof Date);
      r.valid_maxdistance = (!isNaN(parseInt(r.maxdistance)));
      valid = (valid && r.valid_resource && r.valid_type && r.valid_idquantity && r.valid_availabledate && r.valid_availableenddate && r.valid_maxdistance)
      //console.log("valid in validateResources", valid);
    }

    this.setState({
      newHaulCheck: {
        ...this.state.newHaulCheck,
        resources
      }
    })

    return valid;

  }

  renderQuantitiesCombo = () => {
    let { quantitiesList } = this.state
    return quantitiesList.map(item => {
      return (
        <option value={ item.idquantity } key={ item.idquantity }>
          { (item.quantity).toLocaleString('en') }s of CY's
        </option>
      )
    })
  }

  renderResourceCombo = () => {
    let { resourcesList } = this.state
    return resourcesList.map(({resource}) => {
      return (
        <option value={ resource } key={ resource }>
          { resource }
        </option>
      )
    })
  }

  renderImbalanceCombo = () => {
    let { imbalancesList } = this.state
    return imbalancesList.map(({type}) => {
      return (
        <option value={ type } key={ type }>
          { type == "Surplus/Export" ? "Surplus" : "Shortage" }
        </option>
      )
    })
  }

  renderDiscoveryModesCombo = () => {
    let { discoveryModesList } = this.state

    return(
      /*<div className="custom-controls-stacked mb-4 pl-lg-4 pb-2">
      
      </div>*/

      <div className="custom-controls-stacked ml-4 p-4 border-primary">
      {
        discoveryModesList.map(item => {
          return (
            <div className="custom-control custom-radio" key={item.iddiscoverymode} onClick={ ()=> this.updateDiscoveryMode({target: {value:item.iddiscoverymode}}) }>
              <input type="radio" className="custom-control-input" 
                value={ item.iddiscoverymode } 
                name="discoveryMode" 
                
                checked={this.state.newHaulCheck.iddiscoverymode == item.iddiscoverymode}
                id={"discoveryMode" + item.iddiscoverymode} />
              <label className="custom-control-label" htmlFor={"discoveryMode" + item.iddiscoverymode}><b>{item.mode}</b></label>
            </div>
          )
        })
      }   
      </div>
    )
     
  }

  renderResource = () => {
    const { resources } = this.state.newHaulCheck

    return resources.map((resource, index) => <Resource
      key={index}
      id={index}
      resources={resources}
      updateResource={this.updateResource}
      updateQuantity={this.updateQuantity}
      quantitiesList={this.state.quantitiesList}
      deleteResource={this.deleteResource}
      resourcesList={this.state.resourcesList}
    />)
  }

   renderAddResourceRow = (key) => {
    let resource = this.state.newHaulCheck.resources[key];

    let CustomInput = React.forwardRef(({ value, onClick, onChange, className, disabled }, ref) => (
      <input type="text" className={className} disabled={disabled} onClick={onClick} onChange={onChange} ref={ref} value={value} />
    ))

    return(
      <div className={"form-group row gap-y ml-1 fs-13 " + (key > 0 ? "mt-6" : "mt-0")} key={key}>

        <div className="col-9 p-4 border-primary">

          <div className="row pl-4 pb-4">
            <div className="col-4 p-0 mr-4">
              <label className="text-dark">Material</label>
              <select className={"form-control" + (resource.valid_resource ? '' : " is-invalid" )}
                  value={resource.resource}
                  onChange={ e => { this.updateResourceVal(key, e.target.value, 'resource') } }>
                <option value = ''>Material Type</option>
                { this.renderResourceCombo() }
              </select>
            </div>
            <div className="col-4 p-0">
              <label className="text-dark">Imbalance</label>
              <select className={"form-control" + (resource.valid_type ? '' : " is-invalid" )}
                value={resource.type}
                onChange={ e => { this.updateResourceVal(key, e.target.value, 'type') } }>
                <option value = ''>Imbalance</option>
                {this.renderImbalanceCombo() }
              </select>
            </div>
          </div>

          <div className="row pl-4">
            <div className="col-3 p-0 mr-4">
              <div className="form-group mb-0">
                <label className="text-dark">General Quantity</label>
                <select className={"form-control" + (resource.valid_idquantity ? '' : " is-invalid" )}
                   value={resource.idquantity}
                   onChange={ e => { this.updateResourceVal(key, e.target.value, 'idquantity') } }>
                   <option value={0} >Quantity</option>
                  {this.renderQuantitiesCombo() }
                </select>
              </div>
            </div>
            <div className="col-7 p-0">
              <label className="text-dark">General Availability</label>
              <div className={"input-daterange input-group mr-2" + ((resource.valid_availabledate && resource.valid_availableenddate) ? '' : " is-invalid")} id="datepicker">
                <span className="input-group-addon pt-2 pl-4">From</span>
                <DatePicker
                  dateFormat="MM/yyyy"
                  locale="en-US"
                  selected = {resource.availabledate}
                  startDate = {resource.availabledate}
                  endDate = {resource.availableenddate}
                  minDate={new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)}
                  onChange={ date => this.updateResourceVal(key, date, 'availabledate') }
                  customInput={
                    <CustomInput className={"input-sm form-control text-center " + (resource.valid_availabledate ? '' : " is-invalid")} />
                  }
                  selectsStart
                  showMonthYearPicker
                />
                <span className="input-group-addon pt-2">to</span>
                  <DatePicker
                    dateFormat="MM/yyyy"
                    locale="en-US"
                    selected = {resource.availableenddate}
                    startDate = {resource.availabledate}
                    endDate = {resource.availableenddate}
                    minDate={new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)}
                    onChange={ date => this.updateResourceVal(key, date, 'availableenddate') }
                    placeholderText="End Availability Range"
                    disabled={resource.perennialEndDate == 1}
                    customInput={ 
                      <CustomInput className={"input-sm form-control text-center " + (resource.valid_availableenddate ? '' : " is-invalid")} />
                    }
                    selectsEnd
                    showMonthYearPicker
                  />
                  <div class="input-group-append custom-control custom-checkbox p-1 text-right" onClick={(e) => {
                      e.preventDefault();
                      this.updateResourceVal(key, (resource.perennialEndDate ? 0 : 1), 'perennialEndDate')}
                    }>
                     <input className="custom-control-input" type="checkbox" id={"perennial-checkbox-"+key} checked={resource.perennialEndDate === 1 || resource.perennialEndDate === "1"} />
                     <label className="custom-control-label" htmlFor={"perennial-checkbox-"+key}>No End Date</label>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-1 mt-0 p-0">
          <a href="#" className="btn btn-xs fw-400" onClick={() => this.removeResourceRow(key)}><i className="ti ti-close"></i></a>
        </div>

      </div>

    );
   }

   renderAddResources = () => {
     return this.state.newHaulCheck.resources.map((item, index) => {
       return this.renderAddResourceRow(index)
     })
   }

   removeResourceRow = (rowKey) => {
    const { resources } = this.state.newHaulCheck;
    //if (resources.length == 1) return;
    resources.splice(rowKey, 1);
    this.setState({
      newHaulCheck: {
        ...this.state.newHaulCheck,
        resources
      }
    });
  }

  addNewResourceRow = () => {
    const { resources } = this.state.newHaulCheck
    let { defaultResourceObj } = this.state;
    defaultResourceObj = JSON.parse(JSON.stringify(defaultResourceObj));
    //const last = resources.length - 1

   // console.log(resources, last)
    if (this.validateResources()) {
      console.log("defaultResourceObj", defaultResourceObj)

      resources.push(defaultResourceObj);
      this.setState({
        newHaulCheck: {
          ...this.state.newHaulCheck,
          resources
        }
      })
    } else {
      alert('You need to set the previous resources before adding a new one')
    }
  }

  updateResourceVal = (index, value, property) => {
    console.log(index, value, property)
    const { resources } = this.state.newHaulCheck;

    resources[index][property] = value;
   
    this.setState({
      newHaulCheck: {
        ...this.state.newHaulCheck,
        resources
      }
    })
  
  }

  updateDiscoveryMode = e => {
    console.log(e.target, e.target.value)
    this.setState({
      newHaulCheck: {
        ...this.state.newHaulCheck,
        iddiscoverymode: e.target.value
      }
    })
  }

  deleteResource = key => {
    const resources = [...this.state.newHaulCheck.resources]
    const removed = resources.splice(key, 1)
    console.log(resources, key)
    this.setState({
      newHaulCheck: {
        ...this.state.newHaulCheck,
        resources
      }
    })
  }

  async componentDidMount() {
    const date = new Date()
    const newDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    const resourcesList = await ApiService.getCreateableResources()
    const imbalancesList = await ApiService.getUniqueResourceTypes()
    const quantitiesList = await ApiService.getQuantities()
    const discoveryModesList = await ApiService.getDiscoveryModes()
    const username = ApiService.getJWTUsername()
    const email = ApiService.getJWTEmail()

    this.setState({
      newHaulCheck: {
        ...this.state.newHaulCheck,
        username, email,
        startDate: newDate,
        endDate: newDate
      },
      email,
      resourcesList,
      imbalancesList,
      quantitiesList,
      discoveryModesList
    })

    deleteSidebarPositionData();

  }


  /*onMapClick = (e) => {
    console.log(e.latlng);
    const lat = e.latlng.lat
    const lng = e.latlng.lng

    this.setState({
      markerPosition: e.latlng,
      newHaulCheck: {
        ...this.state.newHaulCheck,
        geometry: {
          lat,
          lng
        }
      }
    })
  }

  mapClicked(mapProps, map, clickEvent) {
    const { latLng } = clickEvent;
    const lat = latLng.lat();
    const lng = latLng.lng();
    console.log('Evento -->',lat,lng);
    this.setState({
      markerPosition: { lat, lng },
      newHaulCheck: {
        ...this.state.newHaulCheck,
        geometry: {
          lat,
          lng
        }
      }
    },console.log('Geometry -->',this.state.newHaulCheck))
  }*/

  render(){
    let { newHaulCheck, startDate, endDate, loading } = this.state
    const { markerPosition } = this.state
    return (
      <Fragment>
        <Navbar showSandbox={true} />

        {/*
                    <div className="row mt-6 mb-2 ml-0 bg-pale-primary">


                    <div className="row mt-0 mb-2 ml-0">
                      <div className="col-lg-7 mr-auto px-1 m-2">
                        <p className="lead-1 mb-0 pt-2 pb-2 pl-4 pr-2"><b>What's your max distance for finding a match?</b></p>
                        <div className="form-group contrast px-5">
                          <input 
                            className="form-control" 
                            id="maxDistance"
                            name="maxDistance"
                            onChange={ this.handleChange }
                            value={ newHaulCheck.maxDistance }
                            placeholder="40 miles (default)" />
                        </div>
                        <p className="small-2 mb-0 pl-4 pb-3">Actual driving distance may exceed this straight-line distance.</p> 
                      </div>
                    </div>

                    <div className="row mt-0 mb-2 ml-0">
                      <div className="col-lg-11 mr-auto pl-4 m-2">
                        <p className="lead-1 mb-0 pt-3 pb-2 pl-0 pr-2"><b>List your material imbalances:</b></p>
                        

                      </div>
                    </div>


                  </form> 
                  <hr className="mt-6 mb-4" />

                </div>

                <div className="col-lg-10 mx-auto mb-6 flexbox pt-4">
                  <a className="btn btn-secondary" href="/my-sites"><i className="fa fa-angle-left ml-1"></i> CANCEL</a>
                  <button className="btn btn-primary" disabled={loading} onClick={ this.handleSubmit }>{loading ? <img src="../img/app/photoswipe/preloader.gif" /> : null}SAVE</button>
                </div>

              </div>
            </div>
          </div>
        </main>*/}

        <main className="main-content">
          <header className="section-header mt-5 mb-0">
            <h2><strong>Haul Check Setup</strong></h2>
          </header>
          <div className="section pt-0">
            <div className="container">
              <div className="row">

                <div className="col-lg-10 mx-auto mb-6"> 

                  <form className="">

                    <div className="row mt-6 mb-2 ml-0 bg-pale-primary">
                      <div className="col-12 mr-auto px-1 m-2">
                        <p className="lead strong text-dark mb-0 pt-2 pb-1 pl-4">{newHaulCheck.name}
                          <a className="small-2 strong text-primary cursor float-right mt-1 mr-5" data-toggle="modal" data-target="#edit-site-name"><i className="ti ti-pencil mr-1" aria-hidden="true"></i>Edit Site Name</a>
                        </p>
                      </div>
                    </div>

                    <div className="row mt-0 mb-2 ml-0 mt-5">
                      <div className="col-lg-5 mr-auto px-1 m-2">
                        <p className="lead-1 mb-0 pt-2 pb-2 pl-4 pr-2">
                          <b>What is the current phase for this site?</b>
                          <a tabindex="0" className="small text-primary cursor ml-1" data-toggle="popover" data-trigger="hover" title="Site Phase" data-content="Organize your sites by Site Phase and get options & settings tailored for Bidding or Construction.">
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                          </a>
                        </p>
                        {this.renderDiscoveryModesCombo()}
                        
                      </div>
                    </div>


                    <div className="row mt-0 mb-2 ml-0">
                      <div className="col-11 mr-auto pl-4 m-2">
                        <p className="lead-1 mb-0 pt-2 pb-2 pl-1 pr-2">
                          <b>What materials do you have or need?</b>
                          <a tabindex="0" className="small text-primary cursor ml-1" data-toggle="popover" data-trigger="hover" title="General Info" data-content="This general info will be made visible to all matches. General Availability is used to filter the matches you see.">
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                          </a>
                        </p>
                        {this.renderAddResources()}

                        <button type="button" className="btn btn-xs btn-round btn-outline-primary strong mt-6 mb-2" disabled={loading} onClick= {this.addNewResourceRow}><i className="ti ti-plus strong pr-1"></i>Another</button>

                      </div>
                    </div>

                    <div className="row mt-0 mb-2 ml-0">
                      <div className="col-lg-7 mr-auto px-1 m-2">
                        <p className="lead-1 mb-0 pt-2 pb-2 pl-4 pr-2">
                          <b>How far would you like to look for matches?</b>
                          <a tabindex="0" className="small text-primary cursor ml-1" data-toggle="popover" data-trigger="hover" title="Search Radius" data-content="This straight-line distance is used to filter matches.">
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                          </a>
                        </p>
                        <div className="form-group ml-4 p-4 border-primary">
                          <div className="col-4 p-0 m-0 pr-5">
                            <label className="text-dark">Search Radius</label>
                              <div className="input-group">
                              <input 
                                className="form-control" 
                                id="maxDistance"
                                name="maxDistance"
                                onChange={ this.handleChange }
                                value={ newHaulCheck.maxDistance }
                                placeholder="40"
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">mi</span>
                              </div>
                            </div>
                          </div>
                          <p className="small-2 mb-0 pl-4 pb-3">Actual driving distance may exceed this straight-line distance.</p> 
                        </div>

                      </div>
                    </div>                                   

                  </form> 

                  <hr className="mt-6 mb-2" />

                </div>

                <div className="col-lg-10 mx-auto mb-6 flexbox">
                  <a className="btn btn-secondary" href="/my-sites">CANCEL</a>
                  <button className="btn btn-primary" disabled={loading || this.state.successPopup} onClick={ this.handleSubmit }>{loading ? <img src="../img/app/photoswipe/preloader.gif" /> : null}SAVE & COMPLETE</button>
                </div>

              </div>

            </div>
          </div>
        </main>

        <SuccessPopup
          popupId="successPopup"
          title="Success!"
          message="Your site setup is complete!"
          trigger={this.state.successPopup}
          callback={() =>  { this.props.history.push("/app/sites") }}
          useButton = {true}
          disallowClose = {true}
          />

        <EditSiteName
          site={newHaulCheck}
          callback={(newName) => this.setState({
            newHaulCheck: {
              ...this.state.newHaulCheck,
              name: newName
            }
          })} />

        <Footer />
      </Fragment>
    )
  }
}

export default Setup
/*export default GoogleApiWrapper({
  apiKey: 'AIzaSyCRTgaqtoxDv1Uj23wnjgFG249oqCJ4uw4'
})(Setup)*/
