import React, {useState, useEffect, useLayoutEffect, Fragment}  from 'react';
import ExternalMatchRow from '../ExternalMatchRow';
import MaterialRow from '../MaterialRow';
import RowWrapper from "./RowWrapper"
import EmptyStateWrapper from "./EmptyStateWrapper"
import HeaderLabel from "./HeaderLabel"
import _ from 'lodash';

export default function MatchList({
	children, 
	title, 
	matches, 
	index, 
	isMobile, 
	isBrowser, 
	switchSidePanel, 
	groupsListMode, 
	parentMaterial,
	idsite_resource, 
	idsite,
	refreshPanel,
	toggleRouteVisible,
	getRouteExistsAndIsVisible,
	history,
	openMessages,
}) {


  const renderExternalMatchRow = (match, list, key) => {
    
    return <ExternalMatchRow
      passedMatch={match}
      key={match.idmatchsiteresource}
      list={list}
      switchSidePanel={switchSidePanel}
      groupsListMode={groupsListMode}
      parentMaterial={parentMaterial}
      refreshPanel={() => refreshPanel(idsite_resource, idsite)}
      isMobile={isMobile}
      isBrowser={isBrowser}
      history={history}
      openMessages={openMessages}
      getRouteExistsAndIsVisible={getRouteExistsAndIsVisible}
			toggleRouteVisible={toggleRouteVisible}
      isUnavailableMaterial={(!_.isEmpty(parentMaterial) && parentMaterial.isUnavailableMaterial == 1) ? true : false}
    />

	}

	const renderInternalMatchRow = (match, list, key) => {
		return <MaterialRow
			passedMaterial={match}
			key={match.idsiteresource}
			list={list}
			switchSidePanel={switchSidePanel}
			groupsListMode={groupsListMode}
			refreshPanel={() => refreshPanel(idsite_resource, idsite)}
	    isMobile={isMobile}
	    isBrowser={isBrowser}
			toggleRouteVisible={toggleRouteVisible}
			getRouteExistsAndIsVisible={getRouteExistsAndIsVisible}
			hasExternalAccess={true}
		/>

	}


	return <div>

		<HeaderLabel
			labelText={title}
			isTopHeader={index == 0}
			showToggle={false}
			tally={matches.length}
			tallyColor={undefined}
			showTally={true}
		/>

		{!_.isEmpty(children) ? children : null}

		<RowWrapper>
			{matches.length ? <Fragment>
				{matches.map((match, key) => {
					if (match.internal_match == 0){
						return renderExternalMatchRow(match, title, key)
					} else {
						return renderInternalMatchRow(match, title, key)
					}
				})}
				</Fragment> : 
				<EmptyStateWrapper><em>No matches were found.</em></EmptyStateWrapper>
			}
		</RowWrapper>
	</div>



}