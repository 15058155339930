import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {
	HaulCheckService, SharesDetailsService
} from "../../services";
//import MyMaterial from "../../InHouse/MyMaterial"
import SuccessPopup from '../common/Popups'
import useDetails from "../FormHooks/useDetails"
import useMatchCriteria from "../FormHooks/useMatchCriteria"
import {moveElementToBody} from "../../utils/moveElementToBody";


export default function MaterialInfo({onLoad, onUnload, onSubmit, buttonLabel, footerButton}) {
	//let {idsite_resource, idsite} = localStorage.getItem("matchesPanel")

	const [loading, setLoading] = useState(true);
	const [criteriaLoading, setCriteriaLoading] = useState(true);
	const [detailsLoading, setDetailsLoading] = useState(true);

	useEffect(() => {

		console.log("MaterialInfo Loading","Calling MaterialInfo Loading with values detailsLoading", detailsLoading, "criteriaLoading", criteriaLoading, "loading", loading)

		if (detailsLoading == false && criteriaLoading == false && loading == true){
			console.log("MaterialInfo Loading setting overall loading to false")
			setLoading(false);
		}

	}, [criteriaLoading, detailsLoading])

	const [submitting, setSubmitting] = useState(false);

	const [detailsSubmitted, setDetailsSubmitted] = useState(false);
	const [criteriaSubmitted, setCriteriaSubmitted] = useState(false);

	const [material, setMaterial] = useState(null);
	const [criteriaButtonId, setCriteriaButtonId] = useState("")
	const [detailsButtonId, setDetailsButtonId] = useState("")
	const [success, setSuccess] = useState(false);


	const {loadDetailsFormMaterial, detailsForm} = useDetails({
		onLoad: () => {
			console.log("MaterialInfo Loading","calling details onload")
			setDetailsLoading(false)
		},
		onSubmit: () => {
			setDetailsSubmitted(true)
		}
	});

	const {loadMatchCriteriaMaterial, matchCriteriaForm} = useMatchCriteria({
		onSubmit: () => {
			console.log("MaterialInfo Loading","calling match criteria onload")
			setCriteriaSubmitted(true)
		},
		onLoad: () => setCriteriaLoading(false)

	})
	//const broker = useMQTT({topic: "", origin: "HC/MySites/MaterialInfo"})

	useEffect(() => {

		if (criteriaSubmitted && detailsSubmitted && submitting){
			setSuccess(true)
			setCriteriaSubmitted(false);
			setDetailsSubmitted(false);
			setSubmitting(false);
		}

	}, [criteriaSubmitted, detailsSubmitted])

	const getMaterial = async () => {
		setLoading(true)
		setDetailsSubmitted(false);
		setCriteriaSubmitted(false);
		let {idsiteresource, idsite} = JSON.parse(localStorage.getItem("matchesPanel"))
		setCriteriaButtonId("match-criteria-special-submit-"+idsiteresource);
		setDetailsButtonId("material-details-special-submit-"+idsiteresource)

		console.log("loadDefaultDetails from getMaterial with idsiteresource", idsiteresource)

		loadDetailsFormMaterial(idsiteresource)
		loadMatchCriteriaMaterial(idsiteresource)
		
		//setLoading(false)
	}

	useEffect(() => {
		getMaterial();
		if (onLoad && typeof onLoad == "function") onLoad();
		return () => {
			if (onUnload && typeof onUnload == "function") onUnload();
			setTimeout(() => {
				setMaterial(null);
			}, 500)
		}

	}, [])


	const submit = async () => {

		setSubmitting(true);

		let criteriaButton = document.getElementById(criteriaButtonId)
		let detailsButton = document.getElementById(detailsButtonId)

		if (criteriaButton !== null) criteriaButton.click();
		if (detailsButton !== null) detailsButton.click();

	}


	return(<>
		
		{!loading ? <>

			{matchCriteriaForm()}

			<hr className="my-4 w-80" />

			{detailsForm()}

			<div className="row">
				<div className="col">
					{footerButton ? footerButton() : null}

					<button 
						disabled={submitting}
						className="rounded-pill btn btn-primary mt-5" 
						onClick={submit}>
							{loading ? <img src="/img/app/photoswipe/preloader.gif" className="pr-1" /> : null}
							{buttonLabel ? buttonLabel : "Save"}
						</button>
				</div>
			</div>

			<SuccessPopup
	          popupId="popupMaterialInfoForm"
	          title="Success!"
	          message="Material updated!"
	          trigger={success}
	          callback={() => {
	          	setSuccess(false)
	          	if (onSubmit && typeof onSubmit == "function") onSubmit();
	          }}
	        />

		</> : <>
			<div className="row">
				<div className="col">
					<p className="fw-500">Loading...</p>
				</div>
			</div>

		</>}

	</>)
			



}