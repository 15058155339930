import _ from 'lodash'

export default function sortDataIntoLayers({rows, layers}){

	console.log("Calling sortDataIntoLayers with rows", rows, "and layers", layers);

	if (_.isEmpty(rows)){
		return null;
	} else if (_.isEmpty(layers)){
		return rows;
	} else {

		let formatted = [];
		let colTitles = [];

		if (!_.isEmpty(rows) && !_.isEmpty(layers)){

			console.log("sortDataIntoLayers initial rows", rows)

			formatted = sortRowsIntoLayer(rows, layers, formatted, 0)

			console.log("sortDataIntoLayers formatted data", formatted)

			return formatted;

		}

	}


}

function sortRowsIntoLayer(rows, layers, dataArr, layerIndex){




	let layerValues = [];
	let layer = layers[layerIndex]
	let nextLayer = null;

	if (layerIndex < layers.length){
		nextLayer = layers[layerIndex+1]
	} else {
		nextLayer = "rows"
	}

	for (var i = 0; i < rows.length; i++){

		let row = _.cloneDeep(rows[i]);
		let rowToAdd = _.omit(_.cloneDeep(rows[i]), [layer]);
		let layerValue = row[layer]
		let indexOfCurrentLayer = layerValues.indexOf(layerValue);
		
		//console.log("sortDataIntoLayers layerIndex", layerIndex," Current layerValue:", layerValue, " from row", row, " with layer key", layer, "full layer values", layerValues, " with current layer at index", indexOfCurrentLayer, " and nextLayer", nextLayer)

		//console.log("sortDataIntoLayers dataArr before push:", dataArr)


		//console.log("sortDataIntoLayers rowToAdd to sort next:", rowToAdd)


		if (indexOfCurrentLayer === -1){

			layerValues.push(layerValue);
			let newObj = {[layer]: row[layer]}

			if (nextLayer !== null && nextLayer !== undefined){
				newObj[nextLayer] = [rowToAdd];
			} else {
				_.merge(newObj, rowToAdd)
			}

			dataArr.push(newObj)

		} else {

			if (nextLayer !== null && nextLayer !== undefined){
				dataArr[indexOfCurrentLayer][nextLayer].push(rowToAdd)
			} else {
				_.merge(dataArr[indexOfCurrentLayer], rowToAdd)
			}
		}


		//console.log("sortDataIntoLayers dataArr after push:", dataArr)

	}

	//console.log("sortDataIntoLayers data[arr]", dataArr)

	if (nextLayer !== null && nextLayer !== undefined){

		for (var i = 0; i < dataArr.length; i++){

			let nextLayerData = dataArr[i][nextLayer];

			//console.log("sortDataIntoLayers Data["+layer+"]:", nextLayerData)

			dataArr[i][nextLayer] = sortRowsIntoLayer(_.cloneDeep(nextLayerData), layers, [], layerIndex + 1)

		}

	} 

	//console.log("sortDataIntoLayers final unique values for layer " + layer + " at index " + layerIndex, layerValues)


	return _.cloneDeep(dataArr);
}


