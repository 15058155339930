import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react'

import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import SuccessPopup from '../common/Popups';
import CCForm from '../common/CCForm';
import MaterialInfo from '../MaterialInfo';

import {
  ApiService,
  HaulCheckService,
  //MatchesService,
  //ConversationsService
} from '../../services'

import {
  hcDisplayPrice
} from '../../utils';

import {
  url, 
  //earthidealAPIURL
} from '../../constants';



export default function CheckoutDetails(){

  return(<>

    <Navbar />

    <header className="header bg-gray pt-6 pb-4">
      <div className="container">
        <h1 className="display-4">Checkout - Update Details</h1>
        <p className="lead-2 mt-6">Update your details to help you find the perfect match.</p>
      </div>
    </header>

    <main className="main-content">

      <section className="section py-4">
        <div className="container">

          <div className="row gap-y">
            <div className="col-lg-7 mx-auto">
                <MaterialInfo
                  onSubmit={() => {
                    let nextLocation = localStorage.getItem("checkoutDetailsNext");

                    if (nextLocation){
                      window.location.href = nextLocation;
                    } else {
                      window.location.href = "/checkout"
                    }

                  }}
                  buttonLabel={"Next"}
                  footerButton={() => {
                    return <button
                      className = "rounded-pill btn btn-outline-primary mt-5 mr-1"
                      onClick = {() => {
                        window.location.href = "/app/matches"
                      }}
                    >
                    Cancel 
                    </button>
                  }}
                />
            </div>
          </div>
        </div>
      </section>
    </main>

    <Footer />

    </>)
}