import React, { Component, Fragment } from 'react'
import moment from 'moment';

const SiteRow = ({site, index, allSites}) => {

    let renderBlankRow = () => {
        let nextSite = allSites[index + 1];

        if (nextSite == undefined || nextSite.type !== site.type ){
            return(<thead className="bg-light">
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>)
        }
    }

    return (<Fragment>

        <tr className={site.mode == "Bidding" ? "tr-bid" : null}>
            <td className="strong text-left">
                <i className={"ti " + (site.mode == "Bidding" ? "ti-notepad mr-2" : "ti-location-pin mr-1")} aria-hidden="true"></i>
                <a className="text-dark" href="../02-haul-check/site-materials.html"><u>{site.name}</u></a>
            </td>
            <td className={site.type == "Surplus/Export" ? "surpl" : "short"}>
                {site.mode == "Bidding" ? "(" : null}
                {site.type == "Surplus/Export" ? "+" : "-"} {site.quantity.toLocaleString()}s CY
                {site.mode == "Bidding" ? ")" : null}
            </td>
            <td></td>
            <td>{site.available_date ? moment(site.available_date).add('days',1).format("MM/YY") : null}</td>
            <td>
                {site.status == "internal" ? "Internal" :
                    (site.mode == "Bidding" ? "Bid Search"
                    : "Posted")
                }

            </td>
            <td></td>
            <td>{site.matchesCount > 0 ? site.matchesCount + " Matches" : null}</td>
                
        </tr>

        {renderBlankRow()}

    </Fragment>)

}

export default SiteRow;