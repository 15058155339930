import React, {Fragment}  from 'react';
import moment from 'moment';

export default function Match({match, openMessageThread, idsite, idsite_resource}){

	let {type, available_date, labelInfo, labeled_partner, geom, distance, idmatchsiteresource, details, ever_interacted_with, wasread} = match;

	let renderQuantity = () => {
		let {details, quantity} = match;
	    let q = quantity;
	    let displayS = true;

	    if (details && details.quantity && parseInt(details.quantity) != "NaN"){
	      q = details.quantity;
	      displayS = false;
	    } else if (quantity === "---"){
	      return "---"
	    }

	    return (parseInt(q).toLocaleString()) + (displayS ? "s " : " ") + "CYs"
	}

	let renderDistance = () => {
		let {routeData} = match;

	    if (routeData != undefined && routeData.length != undefined && routeData[0].distance != undefined){
	      return routeData[0].distance + "mi"
	    } else {
	      return match.distance;
	    }
	}

	let renderTime = () => {
		let {routeData} = match;

	    if (routeData != undefined && routeData.length != undefined && routeData[0].time != undefined){
	      return routeData[0].time + " min"
	    } else {
	      return ""
	    }
	}

	let renderAvailability = () => {
		 if (match.available_date){
	      let start =  moment(match.available_date).add('days', 1).startOf("month");
	      let endDate = (match.available_end_date ? match.available_end_date : match.available_date);
	      let end = moment(endDate).add('days', 1).endOf("month");

	      return start.format("MMM") + "-" + end.format("MMM")

	    } else {
	      return ""
	    }
	}

	let openMessage = () => {
		openMessageThread(match)
	}

	return(

	    <li className="border-bottom flexbox">
	      <span className="col-2">{labeled_partner ?
              labelInfo.partner.name
              : "Anonymous Match"
            }</span>
	      <span className="col-3">{type.split[0]} {renderQuantity()}</span>
	      <span>{renderDistance()}</span>
	      <span>{renderTime()}</span>
	      <span>{renderAvailability()}</span>
	      <span><a href="#" className="text-light" onClick={openMessage}><i className={"fa fa-envelope-square fs-20"} aria-hidden="true"></i></a></span>
	    </li>

	)

}