import React, {useState, useEffect, Fragment} from 'react';
import StateSelector from "./StateSelector"
import SandboxMap from '../SandboxWrapper';
import _ from 'lodash';
import {isJsonString} from "../../../utils"

export default function StateMap(props) {

	let [states, setStates] = useState([])

	let processStateCoords = (states) => {
		localStorage.setItem("defaultStates", states.toString());
		setStates(states)
	}

	return <div className="row">

		<div className="col-4 p-7">

			<StateSelector
				onChange={processStateCoords}
			/>

		</div>

		<div className="col-8">
			<SandboxMap
				states={states}
			 />

		</div>
	</div>
}