import React, { useState, useEffect, Fragment } from "react";
import ListItem from './AddResource/ListItem';
import Form from './AddResource/Form';
import './index.css';
import {
  HaulCheckService
} from '../../services';


  //
const ModalAddResources = ({ haul, onRequestClose, closeModal, refreshResources,  resourcesList, quantitiesList, idResource }) => {

/***/
const [todos, setTodos] = useState([]);
const [inputValue, setInputValue] = useState(0);
const [quantityValue, setQuantityValue] = useState(0);
const [idSite] = React.useState(idResource[0].idsite)

useEffect(() => {

});

const _handleSubmit = e => {
  if (e){
    e.preventDefault();
  }
  if (inputValue === "" || quantityValue === ""){
    return alert("Resource and Quantity is required");
  }else{
    const resource1 = 'Clean Earth';
    const resource2 = 'Topsoil';
    const resource3 = 'Clay';
    const resource4 = 'Rock';
    const resource5 = 'Asphalt';
    const resource6 = 'Concrete';
    const resource7 = 'Masonry';
    const resource8 = 'Crushed Agg.';
    const resource9 = 'Scrap';
    const resource10 = 'Impacted Soil';
    const typeExport = 'Export';
    const typeImport = 'Import';
    let description='';
    let desquantity='';
    if(inputValue === '1'){
      description=resource1+' '+typeExport;
    }
    if (inputValue === '2') {
      description=resource2+' '+typeExport;
    }
    if (inputValue === '3') {
      description=resource3+' '+typeExport;
    }
    if (inputValue === '4') {
      description=resource4+' '+typeExport;
    }
    if (inputValue === '5') {
      description=resource5+' '+typeExport;
    }
    if (inputValue === '6') {
      description=resource6+' '+typeExport;
    }
    if (inputValue === '7') {
      description=resource7+' '+typeExport;
    }
    if (inputValue === '8') {
      description=resource8+' '+typeExport;
    }
    if (inputValue === '9') {
      description=resource9+' '+typeExport;
    }
    if (inputValue === '10') {
      description=resource10+' '+typeExport;
    }
    if (inputValue === '11') {
      description=resource1+' '+typeImport;
    }
    if (inputValue === '12') {
      description=resource2+' '+typeImport;
    }
    if (inputValue === '13') {
      description=resource3+' '+typeImport;
    }
    if (inputValue === '14') {
      description=resource4+' '+typeImport;
    }
    if (inputValue === '15') {
      description=resource5+' '+typeImport;
    }
    if (inputValue === '16') {
      description=resource6+' '+typeImport;
    }
    if (inputValue === '17') {
      description=resource7+' '+typeImport;
    }
    if (inputValue === '18') {
      description=resource8+' '+typeImport;
    }
    if (inputValue === '19') {
      description=resource9+' '+typeImport;
    }
    if (inputValue === '20') {
      description=resource10+' '+typeImport;
    }
    console.log('Description',description,'value',quantityValue);
    if(quantityValue === 1 || quantityValue === '1'){
      desquantity = "100s of CY's";
    }else if (quantityValue === 2 || quantityValue === '2') {
      desquantity = "1,000s of CY's";
    }else{
      desquantity = "10,000s of CY's";
    }

    //{ idresource: 'No identify', name: "task 1", quantity: 0, done: false }
    if(inputValue>0 & inputValue<21 ){
      console.log('desquantity',desquantity);
      const newArr = todos.slice();
      newArr.splice(0, 0, {idresource: inputValue , name: description, idquantity: quantityValue, quantity: desquantity, done: false });
      setTodos(newArr);
      setInputValue("");
      setQuantityValue("");
      return {idresource: inputValue , name: description, idquantity: quantityValue, quantity: desquantity, done: false };
    }
  }

};

//
const _handleBntClick = ({ type, index }) => {
  const newArr = todos.slice();
  if (type === "remove") newArr.splice(index, 1);
  else if (type === "completed") newArr[index].done = true;

  return setTodos(newArr);
};
//Initial tasks

const handleModalSave = () => {
  let val = null;
  if (inputValue !== "" && quantityValue !== ""){
    val = _handleSubmit();
  }
  if(idSite > 0 && (todos.length > 0 || val !== null)){
    //document.title = `Disposal Agreements`;
    if (val !== null) todos.push(val);
    updateResources(idSite,todos);
    setTodos([]);
    refreshResources();
    closeModal();

  }else {
    return alert('You must add a resource');
  }

};


  async function updateResources(id,resourcesAdd) {
    try {
      let response =  HaulCheckService.addNewResources({idsite:id,addresources:resourcesAdd})
      return response
    } catch (error) {
      return error
    }
  }

  return (
    <div id="resource-settings" tabIndex="-1" role="dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="settingsModal">Add resources</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span onClick={closeModal} >&times;</span>
            </button>
          </div>
          <div className="modal-body">
          <Fragment>
            <Form
              onSubmit={_handleSubmit}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              resourcesList={resourcesList}
              quantityValue={quantityValue}
              onChangeQuantity={e => setQuantityValue(e.target.value)}
              quantitiesList={quantitiesList}
            />
            <ul>
              {todos.map((todo, index) => (
                <ListItem
                  key={index}
                  todo={todo}
                  remove={() => _handleBntClick({ type: "remove", index })}
                  completed={() => _handleBntClick({ type: "completed", index })}
                />
              ))}
            </ul>
          </Fragment>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => {handleModalSave()}}>Add Resources</button>
          </div>
        </div>
    </div>
  )
}
export default ModalAddResources
