import React, { Component } from 'react'
import PropTypes from "prop-types";
import moment from 'moment'

export default function OwnerBaseMessageLayout ({ messagedate, hours, minutes, children, originname, displayoriginname}) {

  return(
    <div class="float-right pb-0 px-0 mr-4 mb-2">
      <div class="card">
        <div class="card-body bg-secondary rounded p-2">
          <div class="media">
            <div class="media-body ml-1">
              {children}
              <div class="small-1 float-right">
                <time class="mr-1 pt-4 opacity-70 small-3" dateTime={messagedate}>{(moment(messagedate)).add('minutes', (moment().utcOffset())).fromNow()}{displayoriginname && originname ?" - " + originname : null}</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}