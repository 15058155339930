import React, {Fragment, useState, useEffect} from 'react';
import TableData from './DisplayComponents/TableData'
import TableRow from './DisplayComponents/TableRow'
import _ from 'lodash'
import MaterialTableRow from './MaterialTableRow'

import {
	PublishSubscribe,
	HaulCheckService,
	userService
} from '../../services'
import {
	addChildToArray, 
	removeChildFromArray, 
	updateObjectBasedOnKey, 
	usePrevious, 
	//hcGetIconForSiteOrMatch
} from '../../utils'


export default function SiteTableRow({passedSite, removeSelf, passedManagerName, passedManagerUsername, updateSite, updateMaterial, hasExternalAccess, isMobileOnly, isMobile, isBrowser}){

	let [site, setSite] = useState(_.cloneDeep(passedSite))
	let [materials, setMaterials] = useState(_.cloneDeep(passedSite.materials));
	let [shouldBeHidden, setShouldBeHidden] = useState(false);
	let [managerName, setManagerName] = useState(passedManagerName);
	let [managerUsername, setManagerUsername] = useState(passedManagerUsername);

	let PS = PublishSubscribe.usePublishSubscribe({
		origin: "HC-frontend/MasterSetup/SiteTableRow",
    	topic: "HC/site-materials",
    	identifier: {idsite: site.idsite},
		callback: (message) => {
			message = JSON.parse(message);

			console.log("handleMessage message:", message);

			//Add new materials in my sites and group sites
			if (message.type == "new-material"){
				setMaterials((previousState) => addChildToArray(previousState, message.material, "idsite_resource", /*"resource"*/));
			} else if (message.type == "updated-site"){
				setSite((previousState) => updateObjectBasedOnKey(previousState, message.data))
			}

		}
	})


	useEffect(() => {
		setManagerName(passedManagerName)
	}, [passedManagerName])

	useEffect(() => {
		setManagerUsername(passedManagerUsername)
	}, [passedManagerUsername])

	useEffect(() => {
		let hideSite = false;

		if (site.deleted == 1 || site.archived == 1){
			hideSite = true;
		}

		if (hideSite){
			removeSelf(site.idsite);
			setShouldBeHidden(hideSite);
			PS.unmountPS();
		}

	}, [site.deleted, site.archived]);

	useEffect(() => {
		getManagerInfo()
	}, [site.primaryContact])

	useEffect(() => {
		setSite(_.cloneDeep(passedSite))
		setMaterials(_.cloneDeep(passedSite.materials));
	}, [passedSite.idsite])

	let goToSetup = () => {
	    localStorage.setItem('site', JSON.stringify({idsite: site.idsite, name: site.name}));
	    window.location.href = "/resource-check-setup";
	}

	let getManagerInfo = async () => {
		let {primaryContact, idsite} = site;

		console.log("getManagerInfo site:", site);

    	let userData = await userService.getUserInfo(primaryContact)
    	console.log("fetchSiteInfo userData:", userData);
    	if (userData.user){
    		setManagerName(userData.user)
    	} else {
    		setManagerName(primaryContact)
    	}

    	setManagerUsername(primaryContact)
	    
	}

	/*if (!site) return null;
	else */if (shouldBeHidden) return null;
	else return(<TableRow className="border-dark mb-sm-2 mb-md-0">

		<TableData
			title="Primary Contact"
			width="2"
		>
			<span className="cursor mr-1" onClick={() => updateSite("team", site)}>
				{site.manager ? <>👥</> : <>👤</>}
			</span>
			<span className="strong">{managerName}</span>
			
		</TableData>

		<TableData
			title="Site Name"
			width="3"
			className="d-flex justify-content-between"
		>
			<p className="strong fixed-2" style={{overflowWrap: "break-word"}}>{site.name}</p>
			<p className="cursor fixed-1" onClick={() => updateSite("name", site)}>✏️</p>
		</TableData>


		<TableData
			title="Site Phase"
			className="d-flex justify-content-between"
		>
			{site.iddiscoverymode == null ? "Setup" : site.iddiscoverymode == 1 ? "Bid" : "Project"}
			{site.iddiscoverymode != 2 ?
				<span className="cursor" onClick={
					site.iddiscoverymode == 1 ?
						() => updateSite("rolloverbid", site)
					:
						goToSetup
				}>{site.iddiscoverymode == null ? <>🏗️</> :<>✏️</>}</span>
			: null }
		</TableData>

		<TableData
			title="Archive Site"
		>
			{site.iddiscoverymode == null ? "N/A" : <button className="btn btn-link" onClick={() => updateSite("archiveSite", site)}>🗄️</button>}
		</TableData>

		<TableData
			title="Delete Site"
		>
			<button className="btn btn-link" onClick={() => updateSite("delete", site)}>🗑️</button>
		</TableData>

		<TableData
			title="Add Material"
		>
			<button className="btn btn-link" onClick={site.iddiscoverymode !== null ? () => updateSite("addAMaterial", site) : goToSetup}>{site.iddiscoverymode == null ? <>🏗️</> :<>➕</>}</button>
		</TableData>


		<div className="mst-col">
			{!_.isEmpty(materials) ? 
				materials.map((material, index) => {
					return <MaterialTableRow
						passedMaterial={material}
						key={material.idsite_resource}
						updateMaterial={updateMaterial}
						hasExternalAccess={hasExternalAccess}
						removeSelf={(idsite_resource) => setMaterials(previousState => removeChildFromArray(previousState, idsite_resource, "idsite_resource"))}
					/>
				})
			: <TableRow>
				<TableData width="1-5">
					No materials
				</TableData>
			</TableRow>}
		</div>


	</TableRow>)


}