import React, { Component, Fragment } from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import Material from './Material'
import ModalChangeQuantityAndAvailability from './ModalChangeQuantityAndAvailability'
import ModalChangeSearchRadius from './ModalChangeSearchRadius'
import ModalInvite from './ModalInvite'
import Modal from 'react-modal'
import SuccessPopup from '../common/Popups';
import SiteTeamModal from '../common/SiteTeam/Modal'
import {
  ApiService,
  HaulCheckService,
  MatchesService,
  ConversationsService
} from '../../services'
import {url, earthidealAPIURL} from '../../constants';
import AddAMaterial from '../MySites/Sidebar/AddAMaterial'
import DeleteAMaterial from '../common/DeleteAMaterial'
import EditSiteName from '../common/EditSiteName'

export default class SiteMaterials extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sitename: JSON.parse(localStorage.getItem('site')).name,
            idsite: JSON.parse(localStorage.getItem('site')).idsite,
            mode: "Bidding",
            type: {noun: "Search", verb: "Searching"},
            materials: [],
            listResources: [],
            quantities: [],
            modalIsOpen: false,
            modalRadiusIsOpen: false,
            modalInviteIsOpen: false,
            activeMaterial: {idquantity: 1, idsite_resource: 0},
            currentMaxDistance: 40,
            newMaxDistance: null,
            valid_newMaxDistance: true,
            confirmArchiveSite: false,
            confirmRecoverSite: false,
            siteArchived: false,
            canEditSite: true,
            managerName: "",
            creatorName: "",
            hasSiteTeam: false,
            isSiteOwner: false,
            rolloverDisabled: false,
            rolloverTakingAWhile: false,
            hasExternalAccess: false,
        }
    }

    componentWillMount = async () => {

        const username = ApiService.getJWTUsername();
        const resourcesUnasigned = await ApiService.getResources();
        const quantitiesList = await ApiService.getQuantities();
        const currentMaxDistance = await HaulCheckService.getMaxDistanceBySite(this.state.idsite)
        let hasExternalAccess = await ApiService.getUserHasExternalAccess();

        console.log("Max distance from db:", currentMaxDistance)

        this.setState({
            username,
            listResources:resourcesUnasigned,
            quantities:quantitiesList,
            currentMaxDistance, hasExternalAccess
        }, () => this.getResources())
    }

    getResources = async () => {
        let {idsite, username} = this.state;
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false,});

        await HaulCheckService.getResourcesBySite(idsite).then(async materials => {
            this.setState({
                materials,
                mode: (materials.length > 0 ? materials[0].mode : "Bidding"),
                siteArchived: (materials.length > 0 ? materials[0].site_archived : false),
                canEditSite: (/*username == materials[0].username || username == materials[0].manager*/ true),
                hasSiteTeam: materials[0].hasSiteTeam,
                sitename: materials[0].name,
                isSiteOwner: (username == materials[0].username)
            }, () => {
                let type = {noun: "Search", verb: "Searching"};
                if (this.state.mode != "Bidding") type = {noun: "Post", verb: "Posting"}
                this.setState({type, rolloverDisabled: false, rolloverTakingAWhile: false});
                this.getUserFullName(materials[0].username, "creatorName");
                this.getUserFullName(materials[0].manager, "managerName");
            })
        })
    }

    getUserFullName = (name, stateVariable) => {
        fetch(`${earthidealAPIURL}/users/getUserInfo/${name}`)
            .then(res => res.json())
            .then(results => {
                this.setState({[stateVariable]: results.user});
            })
    }

    archiveSite = async () => {
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
        let {idsite} = this.state;

        await HaulCheckService.setArchivedBySite(idsite);
        this.getResources();
    }

    recoverSite = async () => {
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
        let {idsite} = this.state;

        await HaulCheckService.recoverArchivedSite(idsite);
        this.getResources();
    }

    toggleStatus = async (idsite_resource, index, goToBlastReferral) => {
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
        let {materials, idsite} = this.state;
        let oldStatus = materials[index].status;

        let updated = await HaulCheckService.toggleStatus(idsite_resource);

        materials[index].status = updated.siteResource[0].status;
        materials[index].expiration_date = updated.siteResource[0].expiration_date;
        await HaulCheckService.getMatches(idsite);
        if (goToBlastReferral == true && oldStatus == "internal"){
            window.location.href = url.frontend + "/haul-check-invite/post";
        } else {
            this.setState({materials});
        }
    }

    toggleArchive = async (idsite_resource, archived, index) => {
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
        let {materials, idsite} = this.state;

        if (archived == 0 || archived == false){
            await HaulCheckService.setArchived(idsite_resource);
        } else {
            await HaulCheckService.unsetArchived(idsite_resource);
        }

        materials[index].archived = !materials[index].archived;
        materials[index].archived_date = new Date().toISOString();
        await HaulCheckService.getMatches(idsite);
        this.setState({materials});
    }

    rollPhase = async () => {
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false, rolloverDisabled: true, rolloverTakingAWhile: false});
        let {mode, idsite} = this.state;

        setTimeout(() => {
            this.setState({rolloverTakingAWhile: true})
        }, 10*1000)

        if (mode == "Bidding")
            await HaulCheckService.setConstructionProject(idsite);
        else
            await HaulCheckService.unsetConstructionProject(idsite);

        this.getResources();
    }

    addMonth = async (idsite_resource, index) => {
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
        let {materials} = this.state;

        let updated = await HaulCheckService.addMonthToPostPeriod(idsite_resource);

        materials[index].expiration_date = updated.siteResource[0].expiration_date;
        this.setState({materials});
    }

    updateSiteMaxDistance = async () => {
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
        let {newMaxDistance, idsite} = this.state;

        newMaxDistance = parseInt(newMaxDistance);

        console.log("New max distance:", newMaxDistance)
        console.log("result of check: ", newMaxDistance && !isNaN(newMaxDistance))

        if (newMaxDistance && !isNaN(newMaxDistance)){
            await HaulCheckService.updateSiteMaxDistance({distance: newMaxDistance, idsite})
            this.setState({valid_newMaxDistance: true, newMaxDistance: null, currentMaxDistance: newMaxDistance})
        } else {
            this.setState({valid_newMaxDistance: false})
        }  
    }

    render(){
        let {idsite, sitename, materials, listResources, quantities, mode, type, currentMaxDistance, valid_newMaxDistance, siteArchived, canEditSite, creatorName, managerName, hasSiteTeam, rolloverDisabled, rolloverTakingAWhile} = this.state;

        return(<Fragment>
            <Navbar showSandbox={true} />
                <main className="main-content">
                    <header className="section-header mt-7 mb-0">
                        {siteArchived? <div className="row"><div className="col-12"><div className="alert alert-info" role="alert">
                            This site is archived, and can only be edited when recovered from the archive.
                        </div></div></div> : null}

                        <h2><strong>{sitename} Settings</strong></h2>

                    </header>

                    <div className="section pt-2">
                        <div className="container">

                            <div className="row mb-3">
                                <div className="ml-9">
                                    <p className="small lh-1 mb-3">Created by: <strong>{creatorName}</strong></p>
                                    <p className="small lh-1 mb-3">Primary Contact: <strong>{managerName}</strong></p>
                                </div>
                                <div className="ml-auto mr-9">
                                    <p className="small lh-1 mb-3"><a className="link small strong text-primary cursor" data-toggle="modal" data-target="#edit-site-name"><u>Edit Site Name</u></a> </p>
                                    <p className="small lh-1 mb-3"><a className="link small strong text-primary cursor" data-toggle="modal" data-target="#edit-site-team" href="#"><u>{hasSiteTeam ? "Edit" : "Start" } Site Team</u></a></p>
                                </div>
                            </div>

                            <div className="row">

                            <div className="table-responsive col-lg-10 mx-auto mb-0" style={{minHeight: "175px", overflow: "visible"}}>  

                                <table className="table table-hover" style={{position: "relative"}}>
                                    <thead className="bg-gray">
                                        <tr>
                                            <th>Materials</th>
                                            <th>Imbalance</th>
                                            <th>Availability</th>
                                            {this.state.hasExternalAccess ? <>
                                                <th>Matching Status</th>
                                                <th>{type.noun} Duration</th>
                                            </> : null}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            materials.map((material, index) => 
                                                <Material 
                                                material={material}
                                                index={index}
                                                type={type}
                                                toggleStatus={this.toggleStatus}
                                                toggleArchive = {this.toggleArchive}
                                                openModal={this.openModal}
                                                openRadiusModal={this.openRadiusModal}
                                                openModalInvite={this.openModalInvite}
                                                addMonth={this.addMonth}
                                                siteArchived={siteArchived}
                                                deleteMaterial={this.deleteMaterial}
                                                canEditSite={canEditSite}
                                                isSiteOwner={this.state.isSiteOwner}
                                                hasExternalAccess={this.state.hasExternalAccess}
                                                />
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div> 
                            <div className="col-lg-10 mt-4 mx-auto flexbox">
                                <button className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target="#add-material" disabled={siteArchived || !canEditSite}>+ Add a Material</button>
                            </div>

                        </div>

                        {/*<div className="row">
                            <div className="col-12">
                                <a href="/haul-check-invite" className="small ml-lg-9">Tell more people about your posts on Haul Check...</a>
                            </div>
                        </div>*/}

                        <div className="row mt-8 mb-4">

                            <div className="col-11 col-lg-4 border-primary bg-gray px-1 mb-4 mr-6 ml-4 ml-lg-9">
                                <p className="mb-0 pt-2 pb-1 pl-lg-4 pr-2"><b>Current Phase: {mode}</b></p>
                                <div className="col-12 mt-2">
                                    <div className="switch mb-4 special-toggle" onClick={this.rollPhase}>
                                        <input type="checkbox" className="switch-input" checked={mode !== "Bidding"} disabled={siteArchived || !canEditSite || rolloverDisabled} />
                                        <label className="switch-label cursor"><strong>{rolloverDisabled? <img className="mx-2" src="/img/app/photoswipe/preloader.gif" /> : null}Roll over to Construction Project</strong></label>
                                        <p className="small mt-4"><em>Use the Site Team link above to hand over controls to another user.</em></p>
                                        {rolloverTakingAWhile && rolloverDisabled ? <p className="small mt-4"><em>This may take a while, please be patient...</em></p> : null}
                                    </div>
                                </div>
                            </div>

                            {/*<div className="col-11 col-lg-4 border-primary bg-gray px-1 pb-2 ml-4 mb-4">
                                <p className="mb-0 pt-2 pb-2 pl-lg-4 pr-2"><b>Current Search Radius: {currentMaxDistance} miles</b></p>
                                <div className="form-group contrast px-5">
                                    <input 
                                        className={"form-control bg-gray" + (valid_newMaxDistance ? "" : " is-invalid")} 
                                        type="text" 
                                        placeholder="Enter new Max Distance"
                                        onChange={e => {
                                            this.setState({newMaxDistance: e.target.value})
                                        }}
                                        value={this.state.newMaxDistance}
                                        disabled={siteArchived || !canEditSite}
                                     />
                                     {valid_newMaxDistance ? null : <small className="text-danger">Must enter a valid number</small>}
                                </div>
                                <button className="btn btn-xs btn-secondary float-right mr-2 my-2" onClick={this.updateSiteMaxDistance} disabled={siteArchived || !canEditSite}>Update for all Materials</button><br />
                                {/*<p className="small mt-4 ml-3"><em>Use Match Criteria from the menus above to change the search radius for a single material.</em></p>*
                            </div>*/}


                            <div className="col-11 col-lg-4 border-primary bg-gray px-1 ml-4 mb-4 mr-6 ml-lg-9">
                                <p className="mb-0 pt-2 pl-4 pr-2 pb-0"><b>{siteArchived ? "Recover Site from Archive" : "Archive Site in Haul Check"}</b></p> 
                                <small className="ml-4 mb-4"><em>{siteArchived ? "Recover Site from Archive" : " Message records will be saved."}.</em></small>
                                <div className="col-10 mb-4 mt-4">
                                    {siteArchived? 
                                        <button className="btn btn-xs btn-info" id="recover-site" onClick={() => this.setState({confirmRecoverSite: true, confirmArchiveSite: false})} disabled={!canEditSite}>Recover Site</button>
                                    :
                                        <button className="btn btn-xs btn-outline-info" id="recover-site" onClick={() => this.setState({confirmArchiveSite: true, confirmRecoverSite: false})} disabled={!canEditSite}>Archive Site</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {this.renderModal()}

            <AddAMaterial idsite={this.state.idsite} callback={this.getResources} />

            <SiteTeamModal idsite={this.state.idsite} callback={this.getResources} />

            <DeleteAMaterial material={this.state.activeMaterial} callback={this.getResources} />

            <EditSiteName site={{idsite, name: sitename}} callback={this.getResources} />

            <SuccessPopup
                popupId="confirmRecoverSite-UniqueId"
                title="Recover Site"
                message="Are you sure you want to recover this site?"
                trigger={this.state.confirmRecoverSite}
                callback={this.recoverSite}
                useButton = {true}
                useCancelButton = {true}
                style="info"
                buttonLabel = "Yes"
                />

            <SuccessPopup
                popupId="confirmArchiveSite-UniqueId"
                title="Archive Site"
                message="Are you sure you want to archive this site and all of its materials?"
                trigger={this.state.confirmArchiveSite}
                callback={this.archiveSite}
                useButton = {true}
                useCancelButton = {true}
                style="info"
                buttonLabel = "Yes"
                />

            <Footer />
        </Fragment>)
    }

    renderModal = () => {

        let {modalIsOpen, modalRadiusIsOpen, modalInviteIsOpen, activeMaterial, quantities} = this.state;
        let {closeModal, handleModalSave} = this;


        return(<Fragment>
            {modalIsOpen ?
                <ModalChangeQuantityAndAvailability
                    closeModal={this.closeModal}
                    handleModalSave = {handleModalSave}
                    material={activeMaterial}
                    quantities={quantities}
                />
            : null}
            {modalRadiusIsOpen ?
                <ModalChangeSearchRadius
                    closeModal={this.closeModal}
                    handleModalSave = {handleModalSave}
                    material={activeMaterial}
                    quantities={quantities}
                />
            : null}
            <ModalInvite  />
        </Fragment>);
    }

    openModal = (activeMaterial) => {
        this.setState({activeMaterial, modalIsOpen: true}, () => {
            let elem = document.getElementById("open-matchCriteria-site-setting-modal");
            elem.click();
        });
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
    }

    openRadiusModal = (activeMaterial) => {
        this.setState({activeMaterial, modalRadiusIsOpen: true}, () => {
            let elem = document.getElementById("open-searchRadius-site-setting-modal");
            elem.click();
        });
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
    }

    deleteMaterial = (activeMaterial) => {
        this.setState({activeMaterial}, () => {
            let elem = document.getElementById("open-delete-material-popup");
            elem.click();
        });
    }

    openModalInvite = () => {
        document.getElementById("open-modal-invite").click();
    }

    closeModal = () => {
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
        this.setState({modalIsOpen: false, modalRadiusIsOpen: false, modalInviteIsOpen: false})
    }

    handleModalSave = async (material, idquantity, maxdistance, startdate, enddate, perennialEndDate) => {
        this.setState({confirmRecoverSite: false, confirmArchiveSite: false});
        //console.log("handleModalSave Params", material, idquantity, availableDate)
        await HaulCheckService.updateMatchCriteria({idsite_resource: material.idsite_resource, idquantity, maxdistance, startdate, enddate, perennialEndDate})
        this.getResources()
        this.closeModal()
        await HaulCheckService.getMatches(this.state.idsite);
    }
}
