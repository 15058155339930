import React, { Component, Fragment } from 'react'
import  PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import LetterAvatar from '../../common/LetterAvatar';
//import CalcRouteDistance from '../../InHouse/CalcRouteDistance'
import SharedDetailsDisplay from '../../common/SharedDetailsDisplay'
import {MatchesService, ConversationsService, SharesDetailsService} from '../../../services'
import {hcDetailsDisplay} from '../../../utils'
import _ from 'lodash'
import { Popover, OverlayTrigger } from 'react-bootstrap';


/*const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '600px', // <-- This sets the height
    overlfow: 'scroll' // <-- This tells the modal to scrol
  }
}*/

//Modal.setAppElement('#root')

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
class Match extends Component {
  static  propTypes ={
    idmatchsiteresource: PropTypes.number,
    idmatch: PropTypes.number,
    idsite: PropTypes.number,
    idsite_resource: PropTypes.number,
    originSite: PropTypes.number,
    date: PropTypes.string,
    distance: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    quantity: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    message: PropTypes.string,
    wasread: PropTypes.number,
    discoverymode: PropTypes.number,
    nameresource:PropTypes.string,
    typeresource:PropTypes.string,
    infoshared: PropTypes.number,
    sitename: PropTypes.string,
  }
  //estados inicales del componente

  constructor(props) {
    super(props)
      const userJWT = localStorage.getItem('token');
      const userDecoded = jwtDecode(userJWT);
      const user = userDecoded ? userDecoded['cognito:username'] : null;

    this.state = {
      newConversation: this.initialNewConversation(props,user),
      selectedMatch: this.initialSelectedMatch(props,user),
      inputMatch: '',
      modalcontent: '',
      showConversation: false,
      conversation:[],
      results:[],
      originSite:props.originSite,
      previewDetails:[],
      detailsInConversation:[],
      count:0,
      modalIsOpen: false,//Modal
      user,
      infoshared: props.infoshared,
      siteshared: props.siteshared,
    }
  }

  componentWillMount = async () => {
    if (this.props.origin && this.props.origin == "conversations"){
      this.setReadStatus(true);
    }
  }

  calculateNoAvailabilityOverlap = () => {

    let {parentAvailableDate, parentAvailableEndDate, availableDate, availableEndDate} = this.props;
    let {noAvailabilityOverlap} = this.state;

    let parentAvailabilityStart = moment(parentAvailableDate).add('days', 1).startOf("month");
    let parentEnd = (parentAvailableEndDate ? parentAvailableEndDate : parentAvailableDate)
    let parentAvailabilityEnd = moment(parentEnd).add('days', 1).endOf("month");

    let start =  moment(availableDate).add('days', 1).startOf("month");
    let endDate = (availableEndDate ? availableEndDate : availableDate);
    let end = moment(endDate).add('days', 1).endOf("month");

    return parentAvailabilityStart.isAfter(end) || parentAvailabilityEnd.isBefore(start)
  }

  toggleConversation = async () => {
    let {switchSidePanel, idmatchsiteresource, groupsListMode} = this.props;
    ConversationsService.updateMessagesStatus(this.state.newConversation).then(() => {})          
  
    if (!groupsListMode){
      await this.setReadStatus(true);
    }

    switchSidePanel("conversations", {idmatchsiteresource, groupsListMode});
  }

  setReadStatus = async (wasread) => {
    this.setState({
      selectedMatch: {
        ...this.state.selectedMatch,
        wasread
      }
    }, async ()=>{
        await MatchesService.updateReadStatusMatch(this.state.selectedMatch)
        let {updateMatches} = this.props;
        if (updateMatches != undefined){
          console.log("calling updateMatches");
           updateMatches(); 
         } else {
          console.log("updateMatches undefined");
         }
      }
    )
  }

  renderIsSuperBlast = () => {
    let {isBlasted} = this.props;
    
    if (isBlasted == 1){
      return <small><span className="badge badge-success ml-1"><em><strong>SuperBlast</strong></em></span></small>
    }
    else return null;
  }

   initialNewConversation = (props, user) => {
    return {
      site: props.idsite,
      thread: 0,
      status: 0,
      date: props.date,
      message: '',
      origin: user,
      match: props.idmatch,
      matchsiteresource: props.idmatchsiteresource,
      hoursago:0,
      minutesago:0,
      discoverymode: props.discoverymode
    }
  }

  initialSelectedMatch = (props, user) => {
    return {
      site: props.idsite,
      match: props.idmatch,
      matchsiteresource: props.idmatchsiteresource,
      wasread:props.wasread,
    }
  }

  renderQuantity = () => {
    let {quantity, sharedDetails} = this.props;

    if (quantity == '---'){
      return '---';
    } else if (sharedDetails.length > 0 && sharedDetails[sharedDetails.length - 1].quantity != null && sharedDetails[sharedDetails.length - 1].quantity != 0 && sharedDetails[sharedDetails.length - 1].quantitynoinfo != 1){
      let q = sharedDetails[sharedDetails.length - 1].quantity;
      return (parseInt(q).toLocaleString()) + "CYs"
    } else {
      return (parseInt(quantity).toLocaleString()) + "s CYs"
    }
  }

  renderDistance = () => {
    let {routeData, distance} = this.props;

    if (routeData != undefined && routeData.length != undefined && routeData[0].distance != undefined){
      return routeData[0].distance + "mi"
    } else {
      return distance;
    }
  }

   renderRouteTime = (textStyle) => {
    let {routeData, isMobile} = this.props;
    let routeDataExists = routeData != undefined && routeData.length != undefined && routeData[0].time != undefined;

    if (routeDataExists){
      let toReturn = (routeDataExists ? routeData[0].time + " min" : "--");
      if (isMobile){
        return toReturn;
      } else {
        return <label className="fs-14 strong text-dark">{toReturn}</label>
      }
    } else {
      if (isMobile){
        return null;
      } else {
        return <a className="link fs-12 text-light cursor"><i className="fa fa-lock" aria-hidden="true"></i> <u>Route Distance & Time</u></a>
      }
    }
  }

  renderAvailability = () => {
    let {availableDate, availableEndDate} = this.props;
   if (availableDate){
      let start =  moment(availableDate).add('days', 1).startOf("month");
      let endDate = (availableEndDate ? availableEndDate : availableEndDate);
      let end = moment(endDate).add('days', 1).endOf("month");
      let noEndDate = moment(endDate).isAfter("2221-01-01");
      let formattedDate = start.format("MMM 'YY") + " to " + end.format("MMM 'YY");

      if (noEndDate){
        formattedDate = start.format("MMM 'YY") + " onward"
      }


      if (this.calculateNoAvailabilityOverlap()){
        return <s>{formattedDate}</s>
      } else {
        return formattedDate
      }
      
    } else {
      return ""
    }
  }


  renderDescription = () => {
    let {sharedDetails} = this.props;

    if (!sharedDetails || !sharedDetails.length){
      return  <a className="link fs-12 text-light cursor"><i className="fa fa-lock" aria-hidden="true"></i> <u>Details</u></a>
    } else {
      return hcDetailsDisplay("description", sharedDetails)
    }
  }

  renderTrucking = () => {
    let {sharedDetails} = this.props;

    if (!sharedDetails || !sharedDetails.length){
      return  null;
    } else {
      return hcDetailsDisplay("trucking", sharedDetails)
    }
  }

  renderPreciseAvailableRange = () => {
    /*let {sharedDetails} = this.props;

    if (!sharedDetails || !sharedDetails.length){
      return  null;
    } else {
      return hcDetailsDisplay("timeframe", sharedDetails, {dateFormat: "M/D/YY"})
    }*/ return null;
  }

  renderCost = () => {
    let {sharedDetails} = this.props;

    if (!sharedDetails || !sharedDetails.length){
      return <a className="link fs-12 text-light cursor"><i className="fa fa-lock" aria-hidden="true"></i> <u>Material & Tipping Fees</u></a>;
    } else {
      return hcDetailsDisplay("cost", sharedDetails, {
        emptyState: "No cost info given",
        per: "/",
        chargingLabels: ["", "Not charging"]
      })
    }
  }

  renderContactInfo = () => {
    let {labeled_partner, labelInfo, isBrowser} = this.props;
    
    if (labeled_partner){
      let {name, phone} = labelInfo.partner;

      name = (name ? name : "No name given");
      phone = (phone ? phone : "No phone number on file")
      return <span className="small">{name} <br /> {phone}</span>
    } else {         
      if (isBrowser){ 
        return <a className="link fs-12 text-light cursor"><i className="fa fa-lock" aria-hidden="true"></i> <u>Contact Info</u></a>
      } else {
        return null;
      }
    }
  }


  renderTrucking = () => {
    let {sharedDetails} = this.props;

    if (!sharedDetails || !sharedDetails.length){
      return  null;
    } else {
      return hcDetailsDisplay("trucking", sharedDetails)
    }
  }

  renderPreciseAvailableRange = () => {
    /*let {sharedDetails} = this.props;

    if (!sharedDetails || !sharedDetails.length){
      return  null;
    } else {
      return hcDetailsDisplay("timeframe", sharedDetails, {dateFormat: "M/D/YY"})
    }*/

    return null;
  }

  render() {
    const { date, distance, message, nameresource, typeresource, partnerarchived, resourceName, type, isLabeled, labelInfo, idmatchsiteresource, sharedDetails, everInteractedWith } = this.props

    let match_was_read = this.state.selectedMatch.wasread ? "read" : "unread";
    let match_was_open = (this.state.selectedMatch.wasread ? "fa fa-envelope-open-o" : "fa fa-envelope-o");
    let bookmark_color = (everInteractedWith ? "text-info" : "text-primary")
    let match_is_archived = this.props.partnerarchived ? " archived" : "";
    let preview_color = (message === "Start the conversation..." || !message ? "text-primary" : "text-info");
 
    if (this.state.selectedMatch.wasread == false) preview_color += " fw-800";

    /*const popover = (
          <Popover>
            <Popover.Header as="h3">External Matching</Popover.Header>
            <Popover.Body>
              External Matches are made when two sites have complementary needs and an overlap in general availability. External matches are filtered by Search Radius.
            </Popover.Body>
          </Popover>
        )*/

  if (this.props.isBrowser){
    return(
      <div class={"card border-brand p-2 mb-4"  + ((this.calculateNoAvailabilityOverlap() || partnerarchived) ? " bg-light" : " bg-pale-primary")}>
        <div className="card-body px-4 pt-1 pb-0">

          <div className="row pt-1 pb-0">

            <div className="col-5 pl-3 lh-2 clearfix">
              <label className="fs-16 strong text-dark">
                {isLabeled ?
                  (labelInfo.site.name ? labelInfo.site.name : "Disclosed Match")
                  : "Anonymous Match"
                }
              </label>
              {this.renderIsSuperBlast()}
            </div>

            <div className="col-7 text-right flexbox" style={{whiteSpace: 'nowrap'}}>
              <label className="fs-14 strong text-dark">{this.renderAvailability()}</label>
              <label className="fs-14 strong text-dark mr-4">{this.renderDistance()}</label>
              {this.renderRouteTime()}
            </div>

            <div className="col-5 pb-1 pl-3 lh-1">
              <label className="fw-300">Matched on {moment(this.props.date).format('M/D/YY')}</label>                        
            </div>

            <div className="col-4 lh-1">
              <label className="fs-13 text-dark">{typeresource == "Shortage/Import" ? "Shortage" : "Surplus"} {this.renderQuantity()}</label> 
            </div>

            <div className="col-3 lh-1 text-right">
              <label className="fs-13 text-dark">{this.renderCost()}</label>               
            </div> 

            <div className="col-8 py-3 lh-2">
              <label className="small">{this.renderDescription()} {this.renderTrucking()} {this.renderPreciseAvailableRange()}</label>                        
            </div>                            

            <div className="col-4 lh-2 mt-1 mb-1 text-right">
              {this.renderContactInfo()}
            </div>

          </div>
        </div>
      </div>
    );
  } else {
    return(<>
      <div className={"card border shadow-2 hover-shadow-6 p-2 mb-4 " + ((this.calculateNoAvailabilityOverlap() || partnerarchived) ? " bg-light" : " bg-pale-primary")}>
        <div className="card-body px-4 pt-1 pb-0">
          <div className="row pt-1 pb-0">

            <div className="col-12 pl-3 clearfix" style={{ whiteSpace: 'nowrap'}}>
              <label className="fs-16 strong text-dark mb-0">{isLabeled ?
                  (labelInfo.site.name ? labelInfo.site.name : "Disclosed Match")
                  : "Anonymous Match"
                }</label>
              {this.renderIsSuperBlast()}

              <label className="fs-14 strong text-dark float-right">{this.renderDistance()}</label>
            </div>

            <div className="col-12 mb-2 pb-1 pl-3 lh-1">
              <label className="fw-300">{date ? "Matched on "+ moment(this.props.date).format('M/D/YY') : null}</label>                        
            </div>

            <div className="col-12 text-right flexbox" style={{ whiteSpace: 'nowrap'}}>
              <label className="fs-14 strong text-dark">{this.renderAvailability()}</label>
              <label className="fs-14 strong text-dark mr-4">{this.renderRouteTime()}</label>
            </div>

            <div className={"lh-1 col-" + (_.isEmpty(sharedDetails) ? "12" : "6")}>
              <label className="fs-13 text-dark">{typeresource == "Shortage/Import" ? "Shortage" : "Surplus"} {this.renderQuantity()}</label> 
            </div>

            {_.isEmpty(sharedDetails) ? 
              null

            :<>

              <div className="col-6 lh-1 text-right">
                <label className="fs-13 text-dark">{this.renderCost()}</label>               
              </div> 

              <div className="col-12 py-3 lh-2">
                <label className="small mb-0">{this.renderDescription()} {this.renderTrucking()} {this.renderPreciseAvailableRange()}</label>                        
              </div>                            
                                      
              <div className="col-6 lh-2 mb-2 text-right">
                {this.renderContactInfo()}
              </div>

            </>}


          </div>
        </div>
      </div>
    </>)
  }

    /*return(<>
      <div className="d-flex lh-2">
        <div className="mr-auto m-0 p-0">
          <label className="fs-20 strong">
            {isLabeled ?
              (labelInfo.site.name ? labelInfo.site.name : "Disclosed Match")
              : "Anonymous Match"
            }
          </label>
        </div>
        <div className="m-0 p-0"><span className="fs-18 strong mr-2">Available {this.renderAvailability()}</span>
         <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
          <a className="small text-light cursor mr-1">
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </a>
          </OverlayTrigger>
        </div>
      </div>

      <SharedDetailsDisplay 
        sharedDetailsObj={sharedDetails && sharedDetails.length > 0 ? sharedDetails[(sharedDetails.length-1)] : null} 
        partnerName={labelInfo && labelInfo.partner ? labelInfo.partner.name : ""}
        resource={nameresource}
        type={typeresource == "Shortage/Import" ? "Short" : "Surplus"}
        renderQuantity={this.renderQuantity}
        mode="inboxcard" />

    </>)


    /*return(<Fragment>  
      <div className="pb-2 mx-auto">
        <div className="card border bg-light hover-shadow-5">
            <div className="card-body px-3 pt-2 pb-0 readstate">
              <div className={match_was_read + " pb-2 clearfix " + match_is_archived}>

                <div className = "row">
                  <div className="col-4 col-sm-3 px-0 mb-0 lh-1 mt-1" style={{whiteSpace: "nowrap"}}>
                    <label className="fs-14 ml-4 mb-2">
                      {isLabeled ?
                        (labelInfo.site.name ? labelInfo.site.name : "Disclosed Match")
                        : "Anonymous Match"
                      }
                    </label>
                  </div>
                  <div className="col-4 col-sm-3 col-md-4 col-lg-3 text-center px-0 mb-0 lh-1 mt-1" style={{whiteSpace: "nowrap"}}>
                    <label className="small ml-4 mb-2"></label>
                  </div>
                  <div className="col-4 col-sm-2 col-md-4 col-lg-2 text-right text-sm-center text-md-right text-lg-center lh-1 mt-1" style={{whiteSpace: "nowrap"}}>
                    <label className="small"></label>
                  </div>
                  <div className="col-6 col-sm-1 col-md-6 col-lg-1 text-left text-sm-center text-md-left text-lg-center lh-1 mt-1" style={{whiteSpace: "nowrap"}}>
                    <label className="small">{ this.renderDistance() }</label>
                  </div>
                  <div className="col-6 col-sm-3 col-md-6 col-lg-3 text-right lh-1 mt-1" style={{whiteSpace: "nowrap"}}>
                    <label className="small">Available </label>
                  </div> 
                </div>                                                    

                {isLabeled ? <div className="row">
                  <div className="col-6 lh-2">
                    <label className="small-4 text-light fw-400">{ date }</label>
                  </div>

                  <div className="text-right lh-2 ml-auto">
                   
                  </div> 
                  
                  {/* sharedDetails.length > 0 ?
                  <div className="accordion text-right lh-2 mr-4 ml-3" id={"accordion-details-"+idmatchsiteresource}>
                      <a className="small cursor" data-toggle="collapse" href={"#details-"+idmatchsiteresource}>Details <i className="fa fa-caret-down ml-1" aria-hidden="true"></i></a>                  
                  </div> : null 
                
                {/*sharedDetails.length > 0 ?
                  <div className="row">

                    <div id={"details-"+idmatchsiteresource} className="collapse" style={{width: "100%"}} data-parent={"#accordion-details-"+idmatchsiteresource}>
                    
                      <div className="card-body p-0">
                        <div className="section border-top border-bottom py-0">
                            <div className="container p-0 py-2 clearfix">

                              
                             
                            </div>
                          </div>
                        </div> 
                    </div>
                  </div>
                : null 

                 {isLabeled ? <div className="row">

                 <div className="col-6">
                     <img className="round mb-1 ml-1" width="24" height="24" src={LetterAvatar(labelInfo.partner ? labelInfo.partner.name || labelInfo.partner.username : "Partner")}/>
                    <label className="ml-1 fw-400"></label>
                  </div> 

                  <div className="col-6 ml-auto text-right">
                    <a class={"small strong " + preview_color} href="#" onClick={e => this.toggleConversation.bind(this)(e)}><em>''</em></a>

                  </div>

                </div>:

                <div className="row">

                  <div className="col-6 lh-2">
                    <label className="small-4 text-light fw-400">{ date }</label>
                  </div>

                  <div className="col-6 ml-auto text-right">
                    {/*<a class={"small strong " + preview_color} href="#" onClick={this.toggleConversation}><em>{message ? message : 'Start the conversation...'}</em></a>


                  </div>

                </div>}

              </div> 

            </div>
         </div>
      </div>

    </Fragment>)*/

  }
}

export default Match
