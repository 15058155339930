import { url, earthidealAPIURL } from '../constants'
import JwtDecode from 'jwt-decode'

import { hcDisplayPrice } from '../utils'


export const ApiService = {
  getResources,
  getUniqueResources,
  getUniqueResourceTypes,
  getCreateableResources,
  getQuantities,
  getDiscoveryModes,
  getJWTUsername,
  getJWTEmail,
  getJWTInfo,
  sendInviteEmails,
  checkEmailsExist,
  getEveryUserMaterial,
  getEveryExternalPost,
  getAllSiteInfo,
  getAllSitesForImport,
  bulkSetSiteVisibility,
  renameSite,
  getHCPrice,
  getHCBlastPrice,
  getHCSubscriptionMonthlyPrice,
  getHCSubscriptionYearlyPrice,
  getUserHasExternalAccess,
  setUserStates,
  bulkCreateSponsoredSites
}

async function getResources() {
  const resp = await fetch(`${url.backend}/api/getresources`).then(handleResponse)
  return resp.resourcesList
}

async function getUniqueResources() {
  const resp = await fetch(`${url.backend}/api/getuniqueresources`).then(handleResponse)
  return resp.uniqueResourcesList
}

async function getUniqueResourceTypes() {
  const resp = await fetch(`${url.backend}/api/getuniqueresourcetypes`).then(handleResponse)
  return resp.uniqueResourceTypesList
}

async function getCreateableResources() {
  const resp = await fetch(`${url.backend}/api/getCreateableResources`).then(handleResponse)
  return resp.uniqueResourceTypesList
}

async function getQuantities() {
  const resp = await fetch(`${url.backend}/api/getquantities`).then(handleResponse)
  return resp.quantitiesList
}

async function getDiscoveryModes() {
  const resp = await fetch(`${url.backend}/api/getdiscoverymodes`).then(handleResponse)
  return resp.discoveryModesList
}

function getJWTUsername() {
  console.log("Calling getJWTUsername");
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null

  if (username === null) {
    console.log("getJWTUsername no username found")
    localStorage.removeItem('token')
    window.location.href = `${url.frontend}/login`
    return
  } else {
    console.log("getJWTUsername returning username", username)
    return username
  }
}

function getJWTEmail() {
  console.log("Calling getJWTEmail");

  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const email = userDecoded ? userDecoded['email'] : null

  if (email === null) {
    console.log("getJWTEmail no username found")

    localStorage.removeItem('token')
    window.location.href = `${url.frontend}/login`
    return
  } else {
    console.log("getJWTEmail returning email", email)

    return email
  }
}

function getJWTInfo() {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['username'] : null

  if (username === null) {
    localStorage.removeItem('token')
    window.location.href = `${url.frontend}/login`
    return
  } else {
    return userDecoded
  }
}

async function sendInviteEmails(data){
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data
    })
  }
  const resp = await fetch(`${earthidealAPIURL}/users/sendInviteEmails`, requestOptions)
      .then(handleResponse)

  return resp.success
}

async function checkEmailsExist(emailsList, checkPreviousInvites) {
  const resp = await fetch(`${earthidealAPIURL}/users/checkEmailsExist/${emailsList}/${checkPreviousInvites}`).then(handleResponse)
  return resp
}

async function getEveryUserMaterial(email) {
  const resp = await fetch(`${earthidealAPIURL}/users/getEveryUserMaterial/${email}`).then(handleResponse)
  return resp.materials
}

async function getEveryExternalPost(email) {
  const resp = await fetch(`${earthidealAPIURL}/users/getEveryExternalPost/${email}`).then(handleResponse)
  return resp.materials
}

async function getAllSiteInfo(idsite) {
  if (idsite != 0 && idsite != null && !isNaN(parseInt(idsite))){
    const resp = await fetch(`${earthidealAPIURL}/sites/getAllSiteInfo/${idsite}`).then(handleResponse)
    return resp.data
  } else {
    const userJWT = localStorage.getItem('token')
    const userDecoded = JwtDecode(userJWT)
    const username = userDecoded ? userDecoded['cognito:username'] : null

    return [{
      name: "",
      idsite: 0,
      geom: {geom: {x: null, y: null}},
      username
    }]
  }
}

async function getAllSitesForImport() {
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null

  if (username){
    const resp = await fetch(`${url.backend}/sites/getAllSitesForImport/${username}`).then(handleResponse)
    return resp.sitesList
  } else {
    return [];
  }
}

async function bulkSetSiteVisibility(data){
  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const email = userDecoded ? userDecoded['email'] : null

  data.username = username;
  data.email = email;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data
    })
  }
  const resp = await fetch(`${url.backend}/sites/bulkSetSiteVisibility`, requestOptions)
      .then(handleResponse)

  return resp.success
}


async function renameSite({idsite, newName}){

  const userJWT = localStorage.getItem('token')
  const userDecoded = JwtDecode(userJWT)
  const username = userDecoded ? userDecoded['cognito:username'] : null
  const email = userDecoded ? userDecoded['email'] : null

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      idsite, newName, origin: "haulcheck", username, email
    })
  }
  const resp = await fetch(`${earthidealAPIURL}/sites/renameSite`, requestOptions)
      .then(handleResponse)

  return resp.success
}

async function getHCPrice(returnPreFormatted = true){
  const resp = await fetch(`${earthidealAPIURL}/api/getHCPrice`).then(handleResponse)
  if (returnPreFormatted){
    return "$" + (resp.hcPrice/100)
  } else {
    return resp.hcPrice;
  }
}

async function getHCBlastPrice(returnPreFormatted = true){
  const resp = await fetch(`${earthidealAPIURL}/api/getHCBlastPrice`).then(handleResponse)
  if (returnPreFormatted){
    return "$" + (resp.hcPrice/100)
  } else {
    return resp.hcPrice;
  }
}

async function getHCSubscriptionMonthlyPrice(returnPreFormatted = true, showDecimalsIfWholeNumber = false){
  const resp = await fetch(`${earthidealAPIURL}/api/getHCSubscriptionMonthlyPrice`).then(handleResponse)
  if (returnPreFormatted){
    return hcDisplayPrice(resp.hcPrice, showDecimalsIfWholeNumber)
  } else {
    return resp.hcPrice;
  }
}

async function getHCSubscriptionYearlyPrice(returnPreFormatted = true, showDecimalsIfWholeNumber = false){
  const resp = await fetch(`${earthidealAPIURL}/api/getHCSubscriptionYearlyPrice`).then(handleResponse)
  if (returnPreFormatted){
    return hcDisplayPrice(resp.hcPrice, showDecimalsIfWholeNumber)
  } else {
    return resp.hcPrice;
  }
}

async function getUserHasExternalAccess(){
  let username = getJWTUsername();
  console.log("Calling getUserHasExternalAccess with username", username)
  const resp = await fetch(`${earthidealAPIURL}/api/getUserHasExternalAccess/${username}`).then(handleResponse)
  console.log("getUserHasExternalAccess resp:", resp);
  return resp.hasExternalAccess;
}

async function setUserStates(states){
  let username = getJWTUsername();
  let data = {states, username}
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data
    })
  }
  const resp = await fetch(`${earthidealAPIURL}/api/setUserStates`, requestOptions)
      .then(handleResponse)

  return resp.success
}

async function bulkCreateSponsoredSites(data){

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      data
    })
  }
  const resp = await fetch(`${url.backend}/sponsored/bulkCreateSponsoredSites`, requestOptions)
      .then(handleResponse)

  return resp

}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
