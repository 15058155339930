import _ from 'lodash';
import csvformat from './validCSVFormat.json';

export default function validateCSV(csv) {
	let res = "";

	console.log(".csv results:", csv);
	let errors = [];
	let format = _.cloneDeep(csvformat);


	let passedData = _.cloneDeep(csv.data)

	console.log("passedData:", passedData);

	if (passedData.length < 2){
		errors.push("Invalid CSV file; missing data and/or headers")
		return {errors, data: []};
	} else {

		let passedHeaders = Object.keys(csv.data[1]);
		let passedHeadersValid = Array(passedHeaders.length);
		console.log("passedHeaders:", passedHeaders);
		console.log("passedHeadersValid:", passedHeadersValid);

		let finalData = Array(passedData.length);

		//Check for required headers

		let requiredHeaders = Object.keys(format.required);
		

		console.log("requiredHeaders:", requiredHeaders);


		for (var r = 0; r < requiredHeaders.length; r++){
			let required = requiredHeaders[r];
			let headerData = format.required[required];
			let headerFound = false;
			
			for (var h = 0; h < passedHeaders.length; h++){
				let header = passedHeaders[h];
				if (matchHeader(header, required, headerData)){
					passedHeadersValid[h] = true;
					headerFound = true;
					finalData = addColumnToData(header, required, passedData, finalData, headerData)
				}
			}

			if (!headerFound){
				errors.push("Missing required info: " + required)
			}

		}


		//Check for optional headers (to normalize optional data) 
		let optionalHeaders = Object.keys(format.optional);
		console.log("optionalHeaders:", optionalHeaders);

		for (var o = 0; o < optionalHeaders.length; o++){
			let optional = optionalHeaders[o];
			let headerData = format.optional[optional];
			let headerFound = false;

			for (var h = 0; h < passedHeaders.length; h++){
				let header = passedHeaders[h];

				if (matchHeader(header, optional, headerData)){
					passedHeadersValid[h] = true;
					headerFound = true;
					finalData = addColumnToData(header, optional, passedData, finalData, headerData)
				}

			}

			if (!headerFound && headerData.default){
				finalData = addColumnToData(null, optional, null, finalData, headerData);
			}

		}




		for (var i = 0; i < passedHeaders.length; i++){
			let header = passedHeaders[i]
			if (!passedHeadersValid[i]){
				errors.push("Invalid column: " + header)
			}
		}

		console.log("errors:", errors);
		console.log("finalData:", finalData)

		return {errors, data: finalData}
	}
}

function matchHeader (passedHeader, correctHeader, headerData) {

	if (clean(passedHeader) === clean(correctHeader)) return true;
	else {

		if (!_.isEmpty(headerData.synonyms)){
			for (var i = 0; i < headerData.synonyms.length; i++){
				if (clean(headerData.synonyms[i]) === clean(passedHeader)) { return true; }
			}
		}

		return false;
	}

}

function clean (string) {
	return string.toLowerCase().trim();
}

function addColumnToData (originalHeader, newHeader, oldData, finalData, newHeaderData){

	let newData = _.cloneDeep(finalData)

	for (var i = 0; i < newData.length; i++){

		let val = undefined;

		if (originalHeader){
			val = oldData[i][originalHeader];
		} else {
			if (newHeaderData.default){
				val = newHeaderData.default;
			}
		}

		if (newHeader == "Surplus/Shortage" && val == "Surplus/Shortage"){
			val = newHeaderData.default;
		}


		if (typeof newData[i] == "object"){
			newData[i][newHeader+""] = val;
		} else {
			newData[i] = {[""+newHeader]: val}
		}


	}

	return newData;
}