import React, {useState, Fragment, useEffect, useLayoutEffect} from 'react';
import moment from 'moment';
import AddMonth from '../MySites/Sidebar/SettingsModals/AddMonth';
import GoInternal from '../MySites/Sidebar/SettingsModals/GoInternal';
import CheckoutInterface from '../Checkout/CheckoutInterface';
import {HaulCheckService} from '../../services'
import {url, earthidealAPIURL} from '../../constants';
import {moveElementToBody} from '../../utils'
import './style.css'

export default function StatusBar({material, isBlasted, idsite_resource, idsite, iddiscoverymode, status, has_ever_been_external, external_start_date, expiration_date, finalExternalMatchTally, refreshPanel, isMobile, isBrowser}){

	let [bgColors, setBgColors] = useState({alert: "primary", button: "primary"});
	let [openGoInternal, setOpenGoInternal] = useState(false);
	let [openAddMonth, setOpenAddMonth] = useState(false);

	useLayoutEffect(() => {

		if (status == "internal"){
			if (has_ever_been_external == 1){
				setBgColors({alert: "warning", button: "warning"});
			} else {
				setBgColors({alert: "internal", button: "primary"});
			}

		} else {
			if (isBlasted && material.isSponsoredMaterial){
				setBgColors({alert: "warning pale", button: "warning pale"});
			} else if (isBlasted && !material.isSponsoredMaterial){
				setBgColors({alert: "success pale", button: "success pale"});
			} else {
				setBgColors({alert: "primary", button: "primary"});
			}
		}

	}, [ status, has_ever_been_external, material.isSponsoredMaterial, isBlasted])

	useEffect(() => {
		//applyOverlayToBodyGoExternal();
		applyOverlayToBodyGoInternal()
	}, [idsite_resource, status])

	let applyOverlayToBodyGoExternal = () => {
		console.log("Running applyOverlayToBodyGoExternal")
		moveElementToBody("premium-"+idsite_resource);
		moveElementToBody("premium-"+idsite_resource+"-2");
	}

	let applyOverlayToBodyGoInternal = () => {
		moveElementToBody("remove-post-status-bar-"+idsite_resource)
		moveElementToBody("add-month-status-bar-"+idsite_resource)
	}

	let renderGoInternalButton = () => {
		return <button type="button" className={"btn btn-xs btn-round btn-outline-"+bgColors.button+" ml-auto float-right" + (isMobile ? " mt-3 mr-2" : "")} onClick={() => setOpenGoInternal(!openGoInternal)}>
			<i className="ti ti-close" aria-hidden="true"></i> {iddiscoverymode == 1 ? "End Search" : isBlasted ? "End Blast" : "Remove Post"}
		</button>	
	}

	let renderGoInternalControl = () => {
		return <GoInternal 
			idsite_resource={idsite_resource} 
			idsite={idsite} 
			iddiscoverymode={iddiscoverymode} 
			customid={"remove-post-status-bar-"+idsite_resource}
			openControl={openGoInternal}
			callback={() => {
				refreshPanel();
			}} />
	}

	let renderGoExternalButtonLabel = () => {
		if (material.isSponsoredMaterial){
			return "This material is sponsored"
		}else if (iddiscoverymode == 1 && status == "internal" && has_ever_been_external == 0 && finalExternalMatchTally == 0){
			return "Search For Free"
		} else if (iddiscoverymode == 1 && status == "internal" && has_ever_been_external == 0 && finalExternalMatchTally > 0){
			return "Start Bid Search"
		} else if (iddiscoverymode == 1 && status == "internal" && has_ever_been_external == 1 && finalExternalMatchTally == 0){
			return "Search Again For Free"
		} else if (iddiscoverymode == 1 && status == "internal" && has_ever_been_external == 1 && finalExternalMatchTally > 0){
			return "Get New Matches"
		} else if (iddiscoverymode == 2 && status == "internal" && has_ever_been_external == 0 && finalExternalMatchTally == 0){
			return "Post For Free"
		} else if (iddiscoverymode == 2 && status == "internal" && has_ever_been_external == 0 && finalExternalMatchTally > 0){
			return "Post To Get Matches"
		} else if (iddiscoverymode == 2 && status == "internal" && has_ever_been_external == 1 && finalExternalMatchTally == 0){
			return "Post Again For Free"
		} else if (iddiscoverymode == 2 && status == "internal" && has_ever_been_external == 1 && finalExternalMatchTally > 0){
			return "Get New Matches"
		}
	}

	let renderGoExternalButton = () => {
		let externalClassName = "btn btn-xs btn-round btn-"+((has_ever_been_external == 1 && finalExternalMatchTally == 0) ? "outline-" : "") +bgColors.button+" ml-auto float-right" + (isMobile ? " mt-3 mr-2" : "") + (iddiscoverymode == 2 ? " dropdown-toggle" : "")

		return  <CheckoutInterface
			material={material}
			render={(goToReferral, goToPost, goToBlast, goToBlastReferral) => {

			if (iddiscoverymode == 2) return <div>
				<button type="button" className={externalClassName} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
	              {renderGoExternalButtonLabel()}
	            </button>
	            <div class="dropdown-menu dropdown-menu-right">
	              <a class="dropdown-item" href="#" onClick={goToPost}>Post</a>
	              <a class="dropdown-item" href="#" onClick={goToReferral}>Post & Tell Others</a>
	              <a class="dropdown-item" href="#" onClick={goToBlast}>SuperBlast Post</a>
	              <a class="dropdown-item" href="#" onClick={goToBlastReferral}>SuperBlast & Tell Others</a>

	            </div>
            </div>

            else if (iddiscoverymode == 1) return <div>
            	<button type="button" className={externalClassName} onClick={goToPost}>
	              {renderGoExternalButtonLabel()}
	            </button>
            </div>

            else return null;

			}}
		 />

	}

	let renderAddMonthControl = () => {
		return <AddMonth 
			idsite_resource={idsite_resource} 
			customid={"add-month-status-bar-"+idsite_resource}
			openControl={openAddMonth}
			callback={() => {
				refreshPanel();
			}}
		/>
	}

	let renderAddMonthButton = () => {			
		return <button className={"btn btn-xs btn-round btn-"+bgColors.button+" ml-auto " + (isBrowser ? "float-right mr-2" : "float-left mt-3")} onClick={() => setOpenAddMonth(!openAddMonth)}>
			<i className="ti ti-plus" aria-hidden="true"></i> Add a Month
		</button>
	}

	let renderTitle = () => {

		if (material.isSponsoredMaterial){
			return "Sponsored Material"
		}else if (iddiscoverymode == 1 && status == "internal" && has_ever_been_external == 0){
			return "Not Searching"
		} else if (iddiscoverymode == 2 && status == "internal" && has_ever_been_external == 0){
			return "Not Posted"
		} else if (iddiscoverymode == 1 && status == "internal" && has_ever_been_external == 1){
			return "Bid Search expired on " + moment(expiration_date).format("MM/DD/YY")
		} else if (iddiscoverymode == 2 && status == "internal" && has_ever_been_external == 1){
			return "Post expired on "+ moment(expiration_date).format("MM/DD/YY")
		} else if (iddiscoverymode == 1 && status == "external"){
			return "Bid Search started on " + moment(external_start_date).format("MM/DD/YY")
		} else if (iddiscoverymode == 2 && status == "external" && isBlasted){
			return "SuperBlast started on " + moment(external_start_date).format("MM/DD/YY")
		} else if (iddiscoverymode == 2 && status == "external" && !isBlasted){
			return "Posted on " + moment(external_start_date).format("MM/DD/YY")
		} else {
			return "Haul Check is built to remove the hassle and find better hauls"
		}

	}

	let xMatchingPosts = () => {
		return <strong>{finalExternalMatchTally + " matching post" + (finalExternalMatchTally != 1 ? "s" : "")}</strong>;
	}

	let renderSubtitle = () => {

		//Sponsored site
		if (material.isSponsoredMaterial){
			return <>This is a special site to help grow the network.</>

		/*Internal, never external, zero potential externals found*/
		} else if (iddiscoverymode == 1 && status == "internal" && has_ever_been_external == 0 && finalExternalMatchTally <= 0){
			return <>There are currently {xMatchingPosts()}. <strong>Search for free</strong> to get notified of new matches.</>
		} else if (iddiscoverymode == 2 && status == "internal" && has_ever_been_external == 0 && finalExternalMatchTally <= 0){
			return <>There are currently {xMatchingPosts()}. <strong>Post for free</strong> to get notified of new matches.</>

		/*Internal, never external, potential externals found*/
		} else if (iddiscoverymode == 1 && status == "internal" && has_ever_been_external == 0){
			return <>There are currently {xMatchingPosts()} outside your group that match your criteria.</>
		} else if (iddiscoverymode == 2 && status == "internal" && has_ever_been_external == 0){
			return <>There are currently {xMatchingPosts()} outside your group that match your criteria.</>
		

		/*Internal, expired*/

		} else if (status == "internal" && has_ever_been_external == 1){
			return <>There are currently {xMatchingPosts()} since your search expired.</>

		/*External*/
		} else if (iddiscoverymode == 1 && status == "external"){
			return <>Set to <strong>end on {moment(expiration_date).format("MM/DD/YY")}</strong></>
		} else if (iddiscoverymode == 2 && status == "external"){
			return <>Set to <strong>expire on {moment(expiration_date).format("MM/DD/YY")}</strong></>
		} else {
			return "For estimators and project managers who move earth";
		}
	}

	if (isBrowser){
		return <>

			<div className={"alert alert-"+bgColors.alert+" mt-0 py-3 px-4 clearfix"}>
				<strong className="mr-3">{renderTitle()}</strong>
				<span className="d-none d-md-inline-block fs-13">{renderSubtitle()}</span>

				{status == "external" ? <>
					{!material.isSponsoredMaterial ? <>
						{renderGoInternalButton()}
						{renderAddMonthButton()}	
					</> : null}				
				</> : <>
					{renderGoExternalButton()}
				</>}

			</div>

			{renderGoInternalControl()}
			{renderAddMonthControl()}
				
		</>
	} else if (isMobile){
		return <>
			
			<div class={"alert alert-"+bgColors.alert+"  mt-2 pt-3 pb-4 px-4 clearfix"}>
				<strong class="mr-3">{renderTitle()}</strong>
				<br />
				<span class="fs-13">{renderSubtitle()}</span>
				<br/>

				{status == "external" ? <>
					{!material.isSponsoredMaterial ? <>
						{renderAddMonthButton()}
						{renderGoInternalButton()}	
					</> : null}	
				</> : <>
					{renderGoExternalButton()}
				</>}
         	</div>

			{renderGoInternalControl()}
			{renderAddMonthControl()}

		</>
	}
}