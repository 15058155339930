import React, {useState, Fragment} from 'react';
import _ from 'lodash';

export default function MobileDisplaySwitcher({buttons, changeDisplayState}){

	/*
		Buttons is an array of objects with the following properties:
			icon
			label
			displayState
			isActive
	*/

	return(<>
		<div class="btn-group border-bottom" role="group" aria-label="Site View" style={{width: '100%',position:'sticky',top:0,zIndex:20}}>

	      {!_.isEmpty(buttons) ? buttons.map(({icon, label, displayState, isActive}) => 
	      	{return  <button type="button" 
	      	className={"btn btn-light " + (isActive ? "active" : "")}
	      	onClick={() => changeDisplayState(displayState)}>
	      		<i class={"fs-18 " +icon} aria-hidden="true"></i> {label}
	      	</button>
	      }) : null}
	    </div>

	</>)

}