import React, {Fragment, Component, useState} from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ajax from 'superagent';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

export default function NewSite ({backendURL, refreshSites, location, username, closeModal, onCloseDialog}) {

  const [loading, setLoading] = useState(false);
  let minNameLength = 4;
  let maxNameLength = 140;
  let [newNameValid, setNewNameValid] = useState(true);

  let validateName = async () => {
    let validName = newNameValid;
    let newName = formik.values.nameProject;

    if (newName.length < minNameLength){
        validName = "New site name is too short";
    } else if (newName.length > maxNameLength){
        validName = "New site name is too long";
    } else {
        validName = true;
    }

    if (validName !== newNameValid){
        setNewNameValid(validName)
    }

    return validName;
  }

  const formik = useFormik({
    initialValues: {
      nameProject: '',
    },
   /* validationSchema: Yup.object({
      nameProject: Yup.string()
        //.max(25, 'Must be 25 characters or less')
        .required("Name of project can't be empty"),
    }),*/
    onSubmit: values => {
      //alert(JSON.stringify(values, null, 2));
      updateSiteHandler(values)
    },
  });


 const updateSiteHandler = async (formValues) => {

  let validName = await validateName();

  if (validName === true){

    setLoading(true);

    let data = {
      formValues,
      userId: username,
      geometry: location,
      appOfOrigin: "haulcheck"
    }

    ajax.post(`${backendURL}/sites/add`)
    .send(data)
    .then(async response => {
        let newSite = response.body.newSite;
        refreshSites(newSite);
        closeModal(response.body);
        setLoading(false);
      }
    );
  }
}

/*const validateTime = (time) =>{
  moment(time, [
    "Hmm", "HHmm",
    "h:mm a", "h:mm A", "h:mma", "h:mmA",
    "h:m a", "h:m A", "h:ma", "h:mA",
    "hh:mm a", "hh:mm A", "hh:mma", "hh:mmA",
    "hh:mm a", "hh:mm A", "hh:mma", "hh:mmA", 
    
  ])
}*/


  return (

    <form onSubmit={formik.handleSubmit}>
        <div id="add-site" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="" role="document">
                <div class="modal-content">

                    <div class="alert alert-primary" role="alert">
                        <strong>New Site</strong>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={onCloseDialog}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body pt-0">
                        <div class="row m-0">
                            <div class="col-lg-12">
                                <div class="form-group row">
                                    <div class="col-12 px-0 mb-2 mx-auto">
                                        <label class="lead text-dark fw-600">Site Name</label>
                                        <div class="contrast">
                                          <input
                                            className={"form-control" + (newNameValid !== true ? " is-invalid" : "")}
                                            id="nameProject"
                                            name="nameProject"
                                            type="text"
                                            placeholder="Name of Project"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.nameProject}
                                          />
                                          {newNameValid !== true ? 
                                            <span class="text-danger">{newNameValid}</span> 
                                          : null}
                                          {formik.touched.nameProject && formik.errors.nameProject ? (
                                            <div className="error-message">{formik.errors.nameProject}</div>
                                          ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class="btn btn-primary" type="submit" disabled={loading}>
                      {loading ? <img src="../img/app/photoswipe/preloader.gif" /> : null}
                      Add Site
                    </button>
                </div>
            </div>
        </div>
    </form>
  );
};
