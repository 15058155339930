   
import React, { useState, useEffect, Fragment } from 'react'
import Navbar from '../common/Navbar'
import Footer from '../common/Footer'
import ModalChangeQuantityAndAvailability from '../SiteMaterials/ModalChangeQuantityAndAvailability'
import ModalChangeSearchRadius from '../SiteMaterials/ModalChangeSearchRadius'
import SuccessPopup from '../common/Popups';
import DeleteASite from '../common/DeleteASite'
import SiteTeamModal from '../common/SiteTeam/Modal'

import _ from 'lodash'

import {
  ApiService,
  HaulCheckService,
  PMGroupsService,
} from '../../services'

import MasterSetupTable from './MasterSetupTable';

import {url, earthidealAPIURL} from '../../constants';
import AddAMaterial from '../MySites/Sidebar/AddAMaterial'
import DeleteAMaterial from '../common/DeleteAMaterial'
import EditSiteName from '../common/EditSiteName'
import SettingsModals from '../MySites/Sidebar/SettingsModals'


import jwtDecode from 'jwt-decode'


export default function MasterSetup(props){

        const [initSettingsModal] = useState({type: "", trigger: false, params: {idsite: 0, idsite_resource: 0}});
        const [initSiteUpdate] = useState({activeSite: {idsite: 0}, typeOfUpdate: "", trigger: false});
        const [initMaterialUpdate] = useState({activeMaterial: {idsite_resource: 0}, typeOfUpdate: ""});

        let [table, setTable] = useState(null)
        let [loading, setLoading] = useState(true);
        let [hasExternalAccess, setHasExternalAccess] = useState(null);
        let [siteUpdate, setSiteUpdate] = useState(_.cloneDeep(initSiteUpdate));
        let [materialUpdate, setMaterialUpdate] = useState(_.cloneDeep(initMaterialUpdate));
        let [email, setEmail] = useState(jwtDecode(localStorage.getItem('token'))['email'])
        let [settingsModal, setSettingsModal] = useState(_.cloneDeep(initSettingsModal));

        useEffect(() => {
           loadSites();
           getUserHasExternalAccess();
        }, [])

        let loadSites = async () => {
            if (loading !== true) setLoading(true);
            setTable(await PMGroupsService.getMasterTableInfo());
            setLoading(false);
        }

        let getUserHasExternalAccess = async () => {
            setHasExternalAccess(await ApiService.getUserHasExternalAccess());
        }

        let callback = (updateType, updateKey, updateVal) => {
            if (updateType == "params" && updateKey && updateVal){
                setSettingsModal((prev) => resetUpdateObj(prev, initSettingsModal, updateKey, updateVal));
            } else {
                setSettingsModal((prev) => resetUpdateObj(prev, initSettingsModal));
            }

            if (updateType == "activeSite" && updateKey && updateVal){
                setSiteUpdate((prev) => resetUpdateObj(prev, initSiteUpdate, updateKey, updateVal));
            } else {
                setSiteUpdate((prev) => resetUpdateObj(prev, initSiteUpdate));
            }

            if (updateType == "activeMaterial" && updateKey && updateVal){
                setMaterialUpdate((prev) => resetUpdateObj(prev, initMaterialUpdate, updateKey, updateVal));
            } else {
                setMaterialUpdate((prev) => resetUpdateObj(prev, initMaterialUpdate));
            }

        }

        let resetUpdateObj = (prev, init, updateType = null, updateKey = null, updateVal = undefined) => {
            let toReturn = _.cloneDeep(init)
            toReturn.trigger = prev.trigger;
            if (updateType = null && updateKey != null && updateVal){
                toReturn[updateType][updateKey] = updateVal;
            }

            return toReturn;
        }

        useEffect(() => {
            console.log("idsite and type of update in update effect:", siteUpdate);

            if (siteUpdate.typeOfUpdate == "delete"){
                document.getElementById('open-delete-site-popup').click();
            }

            else if (siteUpdate.typeOfUpdate == "name"){
                document.getElementById('open-edit-site-name').click();
            }

            else if (siteUpdate.typeOfUpdate == "team"){
                document.getElementById("open-edit-site-team-modal").click();
            }

            else if (siteUpdate.typeOfUpdate == "addAMaterial"){
                document.getElementById("open-add-a-material").click();
            }

            else {
                setSettingsModal((prev) => {return {
                    type: siteUpdate.typeOfUpdate,
                    params: siteUpdate.activeSite,
                    trigger: !prev.trigger,
                    id: siteUpdate.activeSite.idsite
                }})
            }

        }, [siteUpdate.trigger]);

        useEffect(() => {
            console.log("materialUpdate:", materialUpdate);

            if (materialUpdate.typeOfUpdate == "delete"){
                document.getElementById('open-delete-material-popup').click();
            }


            else {
                setSettingsModal((prev) => {return {
                    type: materialUpdate.typeOfUpdate,
                    params: materialUpdate.activeMaterial,
                    trigger: !prev.trigger,
                    id: materialUpdate.activeMaterial.idsite_resource
                }})
            }

        }, [materialUpdate.trigger]);


        return(<Fragment>
            <Navbar showSandbox={true} />
            <main className="main-content" style={{overflowY: 'scroll'}}>
                    
                {loading ? <div className="loader">Loading...</div> : 
                    <MasterSetupTable
                        table={table}
                        updateSite={(site, typeOfUpdate, useTrigger = true) => setSiteUpdate((prev) => {
                            let toReturn = {
                                activeSite: site,
                                typeOfUpdate,
                            }

                            if (useTrigger){
                                toReturn.trigger = !prev.trigger
                            }

                            console.log("MasterSetupTable updateSite data:", toReturn)
                            return toReturn;
                        })}
                        updateMaterial={(typeOfUpdate, material, useTrigger = true) => setMaterialUpdate((prev) => {
                            let toReturn = {
                                activeMaterial: material,
                                typeOfUpdate,
                            }

                            if (useTrigger){
                                toReturn.trigger = !prev.trigger
                            }

                            console.log("MasterSetupTable updateMaterial data:", toReturn)
                            return toReturn;
                        })}
                        hasExternalAccess={hasExternalAccess}
                    /> 
                }

            </main>

            {table !== null && table.length ? <> 

                <DeleteASite site={siteUpdate.activeSite} callback={callback} /> 

                <SettingsModals id={settingsModal.id ? settingsModal.id : 0} 
                    trigger={settingsModal.trigger} 
                    modalType={settingsModal.type} 
                    params={settingsModal.params} 
                    callback={callback} 
                    callbackReturnToSandbox={callback} 
                    postMaterialCallback={callback}
                />
                

                <DeleteAMaterial material={materialUpdate.activeMaterial} callback={callback} />
                
                <EditSiteName site={siteUpdate.activeSite} callback={(newName) => {
                    callback("activeSite", "name", newName);
                }} />

                <SiteTeamModal idsite={siteUpdate.activeSite.idsite} callback={callback} />

                <button id="open-add-a-material" data-toggle="modal" data-target="#add-material" style={{display: 'none'}}></button>
                <AddAMaterial idsite={siteUpdate.activeSite.idsite} callback={callback} />



            </> : null}


            {/*<

            <DeleteAMaterial material={this.state.activeMaterial} callback={this.getResources} />


            <SuccessPopup
                popupId="confirmRecoverSite-UniqueId"
                title="Recover Site"
                message="Are you sure you want to recover this site?"
                trigger={this.state.confirmRecoverSite}
                callback={this.recoverSite}
                useButton = {true}
                useCancelButton = {true}
                style="info"
                buttonLabel = "Yes"
                />

            <SuccessPopup
                popupId="confirmArchiveSite-UniqueId"
                title="Archive Site"
                message="Are you sure you want to archive this site and all of its materials?"
                trigger={this.state.confirmArchiveSite}
                callback={this.archiveSite}
                useButton = {true}
                useCancelButton = {true}
                style="info"
                buttonLabel = "Yes"
                />*/}

            <Footer />
        </Fragment>)
    }
