import React, {Fragment, Component} from 'react';
//import ajax from 'superagent';
import CCForm from '../../../common/CCForm'
import {ApiService} from '../../../../services'


export default class OptInModal extends Component {

	constructor(props){
		super(props);

		this.state={
			info: {},
			goToBlastReferral: (props.iddiscoverymode == 2 ? true : false),
			loadingBlastReferral: false,
			price: "$"
		};

	}

	componentWillMount = async () => {
		let price = await ApiService.getHCPrice();
		this.setState({price});
	}

	componentWillReceiveProps = (newProps) => {
		if (newProps.idsite_resource != this.props.idsite_resource){
			this.setState({goToBlastReferral: (newProps.iddiscoverymode == 2 ? true : false)})
		}
	}

	setPaymentInfo = (info) => {
		this.setState({info});
		//this.props.returnValidPaymentInfo(info);
	}

	closeModal = () => {
		let {idsite_resource, callback} = this.props;
		let {goToBlastReferral} = this.state;
	
		if (callback){
			if (goToBlastReferral){
				this.setState({loadingBlastReferral: true})
				localStorage.setItem("blastReferralKey", JSON.stringify({idsite_resource}));
				callback(goToBlastReferral);
			} else {
				console.log("PaymentBug", "Closing modal and calling callback");
				callback(goToBlastReferral);
				console.log("PaymentBug", "Callback called, clicking close button");
				document.getElementById('close-opt-in-modal-2-'+idsite_resource).click();
			}			
		}
	}

	applyOverlayToBody = () => {
		let {idsite_resource} = this.props;
		let modal= document.querySelector("#premium-"+idsite_resource);
		let body = document.querySelector("body");
		body.appendChild(modal);
	}

	render(){

		let {idsite_resource, iddiscoverymode, callback} = this.props;
		let {info, loadingBlastReferral, goToBlastReferral, price} = this.state;

		return(<div>

		<button style={{display: "none"}} id={"open-goExternal-site-setting-modal"} data-toggle="modal" data-target={"#premium-"+idsite_resource} onClick={this.applyOverlayToBody}/>
        <button style={{display: "none"}} id={"open-premium-"+idsite_resource} data-toggle="modal" data-target={"#premium-"+idsite_resource} onClick={this.applyOverlayToBody} />

		<div className="modal float-left text-left text-dark" style={{zIndex: 5000}} id={"premium-"+idsite_resource} tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">{iddiscoverymode == 1 ? "Search" : "Post"} Externally</h5>
						<button type="button" className="close" data-dismiss="modal" aria-label="Close" id={"close-opt-in-modal-1-"+idsite_resource}>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>

					<div className="modal-body">

						{iddiscoverymode == 1 ? this.renderSearchCopy() : this.renderPostCopy()}

						{iddiscoverymode == 2 ? 
							<div className="custom-control custom-checkbox mb-4 pt-5" onClick={(e) => {
							e.preventDefault();
							this.setState({goToBlastReferral: !this.state.goToBlastReferral});
						}}>
							<p><strong>Share via Referral</strong></p>
							<input type="checkbox" className="custom-control-input" checked={this.state.goToBlastReferral} />
							<label className="custom-control-label text-dark strong mb-3"><strong>I’d like to email a few people about my needs</strong></label>
							<p className="small mt-3 mb-0"><em>Posts are free if there are 0 external matches at the time of posting or if you send at least two Post Referrals.</em></p>
						</div>
						:
							<p className="small mt-3 mb-0"><em>Searches are free if there are 0 external matches at the time of starting the search.</em></p>
						}
					</div>

					<div className="modal-footer flexbox">
						<a className="nav-link cursor" data-dismiss="modal">Cancel</a>
						<button className="btn btn-primary mt-4" data-toggle="modal" data-target={"#premium-"+idsite_resource+"-2"} data-dismiss="modal">Next</button>
					</div>
				</div>
			</div>
		</div>

		<div className="modal float-left text-left text-dark" style={{zIndex: 5000}} id={"premium-"+idsite_resource+"-2"} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title" id="exampleModalLabel">{iddiscoverymode == 1 ? "Search" : "Post"} Externally</h5>
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" id={"close-opt-in-modal-2-"+idsite_resource}>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>

				<div className="modal-body">
					<p className="" style={{fontSize: "95%"}}>Your card will be charged {price} for this 30-day {iddiscoverymode == 1 ? "Search" : "Post"}.</p>
					<p className="" style={{fontSize: "95%"}}>This non-recurring charge will appear on your next monthly invoice, labeled by site.</p>
					<p className="" style={{fontSize: "95%"}}>Invoices are charged to your credit card automatically at the end of the month.</p>
					<CCForm 
						hideInfo={this.props.hideInfo}
						returnValidPaymentInfo={this.setPaymentInfo} 
					/>
				</div>

				<div className="modal-footer flexbox">
					<button className="btn btn-secondary mt-4" data-dismiss="modal" data-toggle="modal" data-target={"#premium-"+idsite_resource}>Back</button>
					{/*<a className="btn btn-outline-primary mt-4" href="haul-check-referral-blast.html">Temp Btn</a>*/}
					<button className="btn btn-primary mt-4" disabled={info.paymentMethod == null || loadingBlastReferral} onClick={() => {
							this.closeModal();
						}}>
						{loadingBlastReferral ? <img src="/img/app/photoswipe/preloader.gif" /> : null}
						Agree
					</button>
					</div>
				</div>
			</div>
		</div>

		</div>)
	}

	renderSearchCopy = () => {
		return(<>
			<p className=""><strong>Searching Externally</strong> allows you to find matches when you are putting together a bid.</p>
			<p className="">Searches are <strong>completely confidential</strong> and <strong>cannot be discovered</strong> by anyone outside your Office Group.</p>
		</>)
	}

	renderPostCopy = () => {
		return(<>
			<p className=""><strong>Posting Externally</strong> allows you to find and appear to matches when you have an active or soon-to-be-active project.</p>
			<p className="">Posts are <strong>always anonymous</strong> and <strong>can be discovered</strong> by any matching Search or Post outside your Office Group.</p>
		</>)
	}

}