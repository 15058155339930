import React, {Component, Fragment} from 'react'
import {
    ApiService
} from '../../../services'

export default class ImportSites extends Component {

	 constructor(props){
        super(props);

        this.state = {
        	sitesList: null,
        	loading: false,
        	siteSelected: false,
        }
    }

    componentWillMount = async () => {
    	this.getSitesList();
    }

    getSitesList = async () => {
    	let sitesList = await ApiService.getAllSitesForImport();
    	console.log("SitesList:", sitesList)
    	this.setState({sitesList});
    }

    toggleVisible = (e, key) => {
    	let {sitesList} = this.state;
		sitesList[key].visible = !sitesList[key].visible;
    	this.setState({sitesList, siteSelected: true});
    }

    submitImport = async () => {
    	this.setState({loading: true})
    	let res = await ApiService.bulkSetSiteVisibility(this.state);
    	console.log("submitImport res:", res);
        if (res == true){
          this.setState({siteSelected: false, loading: false}, () => {
          	if (this.props.callback) this.props.callback();
          	this.closeModal();
          	this.getSitesList();
          });
        }
    }

    closeModal = () => {
    	document.getElementById("close-modal-import-hc-sites").click();
    }

    render = () => {
    	let {sitesList, loading, siteSelected} = this.state;

    	if (sitesList == null || sitesList.length == 0) return null;

	    return(<>

	    	<div class="col-12 border px-4 pt-2 pb-2 mx-auto mt-4 mb-4">
				<div class="col-12 mr-auto alert-light p-1">
					<p class="mb-0">Import from other apps

	                  	<button className="btn btn-xs btn-glass btn-primary px-2 py-0 mt-1 float-right" 
	                  		data-toggle="modal" data-target={"#modal-import-hc-sites"} >Import Sites</button>
					</p>
				</div>
			</div>

    		<div className="modal fade" style={{zIndex: 5000}} id="modal-import-hc-sites" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	            <div className="modal-dialog" role="document">
	                <div className="modal-content">
	                    <div className="modal-header">
	                        <h5 className="modal-title" id="exampleModalLabel">Import Sites</h5>
	                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-modal-import-hc-sites">
	                            <span aria-hidden="true">&times;</span>
	                        </button>
	                    </div>

	                    <div className="modal-body">

							{sitesList == null ? <p className="mb-0 pb-0">Getting your sites...</p>
								 :
								 (sitesList.length > 0 ? <Fragment>
									<div className="row m-0">
										<div className="col-12 mt-3 p-0">
											<label className="strong p-0 m-0 mb-6">Select the site you would like to import:</label>
											<div className="form-group row gap-y">

												{sitesList && sitesList.length > 0 ? 
													(sitesList.map((site, key) => { return(<Fragment key={key}>
													<div className="col-2 py-0 mb-0 mt-1">

														<button className={"btn btn-sm " + (site.visible == 0 ? "btn-outline-primary" : "btn-outline-secondary")} onClick={e => this.toggleVisible(e, key)}>
															{site.visible == 0 ? "SELECT" : "DESELECT"}
														</button>
													</div>
													<div className="col-10 py-0 mb-0 mt-1">
														<p className="p-0 m-0 ml-5">{site.name}</p>
													</div>
												</Fragment>)}))
											: null }


											</div>
											<button className="btn btn-sm btn-primary float-right mt-6 mr-4 mr-lg-8" onClick={this.submitImport} disabled={loading || !siteSelected}>
												{loading ? <img src="../img/app/photoswipe/preloader.gif" /> : null}
												IMPORT SITES
												
											</button>
										</div>
									</div>
								</Fragment> 
								: <p>You have no sites that can be imported into Haul Check</p>
								)
							}
						</div>
		        	</div>
		        </div>
	        </div>
        </>)
	}

}