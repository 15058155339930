import React, { Component } from 'react'
import PropTypes from "prop-types";
import BaseMessageLayout from './BaseMessageLayout'
import {SharesDetailsService} from '../../../services'

class ShareRouteDistance extends Component {
    static  propTypes ={
        idconversation: PropTypes.number,
        textmessage: PropTypes.string,
        resource: PropTypes.string,
        type: PropTypes.string,
        messagedate: PropTypes.string,
        originusermessage:PropTypes.string,
        username:PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.state = {
            routeDistanceInfo: {
                distance: 0,
                time: 0,
                noroute: true
            }
        }

    }

    componentWillMount = async () => {
        let routeDistanceInfo = await SharesDetailsService.getRouteDataFromIdConversation(this.props.idconversation);
        routeDistanceInfo = routeDistanceInfo[0];
        this.setState({routeDistanceInfo});
    }

    
    render() {
        const { idconversation, textmessage, resource, type, messagedate, hours, minutes, username, originusermessage} = this.props
        return (
            <BaseMessageLayout
                messagedate={messagedate}
                hours={hours}
                minutes={minutes}
                resource={resource}
                type={type}
                originname={this.props.originname}
                displayoriginname={this.props.displayoriginname}
                >
               The Route Distance between our two sites is <strong>{
                    this.state.routeDistanceInfo.noroute == false ? this.state.routeDistanceInfo.distance + " miles"
                    : "unable to be calculated"
               }</strong>
            </BaseMessageLayout>
        )
    }
}

export default ShareRouteDistance


