import JwtDecode from 'jwt-decode'
import { url } from '../../constants'


const userJWT = localStorage.getItem('token')
let loggedInUsername = "";

console.log("userJWT before 2pm:", userJWT)

if (userJWT !== "null" && !!userJWT && userJWT !== undefined && userJWT !== null ){ //This is genuinely supposed to look for a string with the value null, not actual null
	let userDecoded = JwtDecode(userJWT);
	loggedInUsername = (userDecoded ? userDecoded['cognito:username'] : "");
}

console.log("hcGetIconForSiteOrMatch loggedInUsername:", loggedInUsername)


export function hcGetIconForSiteOrMatch(obj, type, params = undefined){

	let toReturn = ""

	if (type == "match"){
		toReturn = getIconForMatch(obj, params);
	} else if (type == "site"){
		if (typeof obj == "object"){
			toReturn = getIconForSite(obj);
		} else {
			toReturn = img("sponsored", true)
		}
	} else if (type == "myMaterial" || params == "originSite"){
		toReturn = getIconForMyMaterial(obj);
	} else if (type == "map"){
		toReturn = getIconsForMap();
	}

	
	return toReturn;

}

function getIconForMyMaterial({type}){

	//if (type == "Surplus/Export"){
		return img('selected')
	/*} else if (type == "Shortage/Import"){
		return '../../img/map-markers/map-marker-my-material-short.svg'
	}*/

}

function getIconForSite({iddiscoverymode, manager, username}){

	let isMySite = false;
	if ((manager == null && username == loggedInUsername) || (loggedInUsername == manager)){
		isMySite = true;
	}

	if (isMySite && iddiscoverymode == 1){
		return img('bid')
	} else if (isMySite && iddiscoverymode == 2){
		return img('construction');
	} else if (!isMySite && iddiscoverymode == 1){
		return img('bid');
	} else if (!isMySite && iddiscoverymode == 2){
		return img('construction');
	} else if (isMySite) {
		return img('pending')
	} else {
		return img('pending')
	}

}

function getIconForMatch({iddiscoverymode, username, isSponsoredMatch, internal_match}, list){

	if (isSponsoredMatch){
		return img('sponsored')
	} else if (internal_match == 0){
		return img('external')
	} else if (iddiscoverymode == 0){
		return img('pending');
	} else if ((iddiscoverymode == 2 && internal_match == 1) || list=="regularSiteMarkerIcon") {
		return img('construction')
	} else {
		return img('bid')
	}
}

function getIconsForMap(){
	let arr = ['bid', 'construction', 'external', 'external-circle', 'pending', 'selected', 'sponsored', 'superblast'];
	let toReturn = [];

	for (var i = 0; i < arr.length; i++){
		toReturn.push({
			key: arr[i],
			val: /*convertSVGToImage(img(arr[i]))*/ img(arr[i], true)
		})
	}

	return toReturn;

}

function convertSVGToImage(imgUrl){
	let imageObj = new Image();
	imageObj.classList.add('marker');
	imageObj.src = imgUrl;
	return imageObj;	
}

function img(uniqueURL, useFixedsSizeMarkers = false){
	return '/img/map-markers'+(useFixedsSizeMarkers ? '-25' : '')+'/'+uniqueURL+'.svg';
}