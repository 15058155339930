import React, { Component } from 'react'
import PropTypes from "prop-types";
import OwnerBaseMessageLayout from './OwnerBaseMessageLayout';

class OwnerShareLocationConsentMessage extends Component {
    static  propTypes ={
        messagedate: PropTypes.string,
        hours: PropTypes.number,
        minutes: PropTypes.number,
    }

    render() {
        const { messagedate, hours, minutes} = this.props
        return (
          <OwnerBaseMessageLayout
              messagedate={messagedate}
              hours={hours}
              minutes={minutes}
              originname={this.props.originname}
              displayoriginname={this.props.displayoriginname}
              >
                <p class="mb-0"><strong>Let's share site locations!</strong></p>
                <em class="text-dark">Waiting for match to agree...</em>
          </OwnerBaseMessageLayout>
        )
    }
}

export default OwnerShareLocationConsentMessage
