import React, {Fragment} from 'react'
import './index.css'
import jwtDecode from 'jwt-decode'

const ModalConnect = ({ closeModal, match, handleModalConnect, idmatchsiteresource }) => {


    let userDecoded = jwtDecode(localStorage.getItem('token'));
    let contactInfoPrivate = userDecoded["custom:private"];
    //console.log("ModalConnect contactInfoPrivate:", contactInfoPrivate);
    let phone = userDecoded["custom:phone"];
    //contactInfoPrivate = 1;

    return (
        <div className="modal fade" id={"business-card-"+idmatchsiteresource} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">

                    <div className="modal-header bg-gray px-4 pt-2 pb-0">
                        <h5 className="modal-title" id="exampleModalLabel">Share Contact Info</h5>
                        <button type="button" className="close pt-5" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <p>Swap contact info when you both consent:</p>
                        <div className="card border col-10 mx-auto bg-gray pr-0 pl-0">
                            <div className="card-body bg-gray">
                                <div className="media-body">
                                    <div className="mb-2">
                                        <p className="small-2 mb-0">Let's talk! Here's my contact info:</p>
                                    </div>
                                    <p className="my-0"><strong>{match.username}</strong></p>
                                    <p className="my-0">{match.corporation}.</p>
                                    <p className="my-0"><a
                                        href={"mailto:"+match.mail}
                                        target="_top">{match.mail}</a></p>
                                    <p className="my-0"><a href={"tel:"+phone}>{phone}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {contactInfoPrivate == 1 ? <Fragment>
                        <div className="modal-footer" style={{flexDirection: "column"}}>
                            <div className="row">
                                <div className="col-12 ml-3">
                                    <div className="alert alert-warning">
                                        <span>You have the privacy setting turned on to keep some of this information private. Are you sure you want to share your info?</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{width: "100%"}}>
                                <div className="col-12 pl-0" style={{display: "flex", justifyContent: "space-between"}}>
                                    <button type="button" className="btn btn-sm btn-outline-primary" data-dismiss="modal">Cancel</button>
                                    <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal" onClick={() => {handleModalConnect()}}>Share Contact Info</button>
                                </div>
                            </div>
                        </div>
                    </Fragment> : 
                        <div className="modal-footer">
                            <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal" onClick={ () => { handleModalConnect()}}>Share Contact Info
                            </button>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}

export default ModalConnect
