import React, {Component} from 'react';
import ajax from 'superagent';
//import config from '../../../../constants/config';
import moment from 'moment';
//import SuccessPopup from '../../../Popups';
import OptInModal from '../OptInModal';

export default class OptButton extends Component {

	constructor(props){
		super(props);


		this.state = {
		}

	}

	initiateOpt = (e) => {
		let {checked} = this.props; 
		if (!checked){
			e.preventDefault();
			this.openOptInModal();
		} else {
			this.completeOpt(true);
		}
	}

	openOptInModal = () => {
		console.log("OptButton", "openOptInModal is being called");
		this.setState({showInitiateOptPopup: false})
		let {idsite_resource} = this.props;
		document.getElementById('open-premium-'+idsite_resource).click();
	}

	completeOpt = (goToBlastReferral) => {
		let {setStatus, openModalInvite, checked} = this.props;

		setStatus(goToBlastReferral);
			/*if (!checked){
				openModalInvite();
			}*/
		
	}

	render(){

		let {checked, idsite_resource, label, className, labelClassName, displayAsButton, renderButton, iddiscoverymode} = this.props;

		if (displayAsButton){

			return(<>

				{renderButton((e) => this.initiateOpt(e))}

			  	<OptInModal 
			      	callback={this.completeOpt} 
			      	idsite_resource={idsite_resource}
			      	iddiscoverymode={iddiscoverymode}
		      	/>

			</>)

		} else {	

			return(<>

				<div className={"switch " + className} onClick={(e) => this.initiateOpt(e)}>
			        <input type="checkbox" 
			          name="optingIn" 
			          id="optingIn" 
			          className="switch-input" 
			          checked={checked}
			        />
			        <label className={"switch-label " + labelClassName}>{label ? label : null}</label>
		    	</div>

			    <OptInModal 
			      	callback={this.completeOpt} 
			      	idsite_resource={idsite_resource}
			      	iddiscoverymode={iddiscoverymode}
			  	/>
			</>
			)
		}

	}

}