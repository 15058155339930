import React, {Fragment, useState, useEffect} from 'react';

export default function NotInGroupYetAlert({markAsSeen, seen, display}){

  let [showAlert, setShowAlert] = useState((display && !seen) ? true : false);

  useEffect(() => {
    setShowAlert((display && !seen) ? true : false)
  }, [display, seen]);

  if (!showAlert) return null;
  else return (
    <div className="col-12 alert alert-light border-primary alert-dismissible fade show px-4 pt-2 pb-2 mx-auto mt-4" role="alert">
      <div className="col-12 mr-auto alert-light p-1">
        <p className="mb-0"><i className="fa fa-exclamation-triangle text-warning mr-2"></i>You do not belong to an office group yet.</p>
      </div>
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => {markAsSeen(); setShowAlert(false);}}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
	)
}