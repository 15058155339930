import React, { Component } from 'react'
import PropTypes from "prop-types";
import moment from 'moment';

export default function BaseMessageLayout ({ messagedate, hours, minutes, resource, type, children, originname, displayoriginname}) {

  return(
  	<div class="float-left pb-0 px-0 ml-4 mb-2">
      <div class="card"> 
        <div class="card-body bg-pale-primary p-2">
          <div class="media">
            <div class="media-body ml-1">
              <div class="small-1">
                {resource != null && type != null ? <strong>{resource} {type}</strong> : null}
                <time class="ml-4 opacity-70 small-3" dateTime={messagedate}>{(moment(messagedate)).add('minutes', (moment().utcOffset())).fromNow()}{displayoriginname && originname ?" - " + originname : null}</time>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
	)
}

