import React from 'react'
import './index.css'
import DatePicker from 'react-datepicker'

const ModalShareSite = ({ closeModal, handleModalShareSite, idmatchsiteresource }) => {

  return(
    <div className="modal fade" id={"share-site-"+idmatchsiteresource} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">

          <div className="modal-header bg-gray px-4 pt-2 pb-0">
            <p className="modal-title strong" id="exampleModalLabel">Share Site Location & Trucking Route</p>
            <button type="button" className="close pt-5" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
             <p>Swap site locations when you both consent.<br/>Your match will be prompted to Agree before locations are swapped.</p>
            <p class="fw-400">Then, compare routes in the map to determine the best trucking route out of all your known match locations.</p>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal" onClick={handleModalShareSite}>Share Site Location</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalShareSite;