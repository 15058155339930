import React from 'react'
import './index.css'

const Modal = ({ haul, closeModal, toggleHaulArchived, toggleHaulAutorenewal, handleModalSave }) => {

  return (
    <div id="resource-settings" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="settingsModal">Resource Settings</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span onClick={closeModal}>&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="switch mb-4">
              <input
                id={`renewal_${haul.idsite_resource}`}
                type="checkbox"
                className="switch-input"
                checked={haul.renewal}
                onChange={e => toggleHaulAutorenewal(e, haul)} />
              <label className="switch-label" htmlFor={`renewal_${haul.idsite_resource}`}>Auto-Renew (monthly)</label>
              <sub>Without Auto-Renewal, resource matching will go inactive, but messaging will still be available.</sub>
            </div>

            <hr className="mt-0 mb-4" />
            <div className="custom-control custom-checkbox mb-4">
              <input
                id={`archive_${haul.idsite_resource}`}
                type="checkbox"
                className="custom-control-input"
                onChange={e => toggleHaulArchived(e, haul.idsite_resource)}
              />
              <label className="custom-control-label" htmlFor={`archive_${haul.idsite_resource}`}>Move to Archive</label>
              <sub>Message records are saved once a resource has been moved to the Archive.</sub>
            </div>
          </div>


          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeModal}>Cancel</button>
            <button type="button" className="btn btn-primary" onClick={() => { handleModalSave(haul.name); closeModal() }}>Save changes</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
