import {getScrollPosition, setScrollPosition} from "../../../../utils";
import _ from "lodash";

const sidebarSelector = "#sidebarDiv"
const localStorageKey = "hcSidebarScrollPosition"

const setSidebarPositionData = (params) => {
	let scrollPosition = getScrollPosition(sidebarSelector, "top");
	let dataToStore = _.cloneDeep(params);
	dataToStore.scrollPosition = scrollPosition;

	console.log("positionSidebar", "data to store:", dataToStore);

	localStorage.setItem(localStorageKey, JSON.stringify(dataToStore))
}

const deleteSidebarPositionData = () => {
	localStorage.removeItem(localStorageKey)
}

const loadSidebarPositionData = () => {
	let data = localStorage.getItem(localStorageKey);
	console.log("positionSidebar", "data to load:", data);
	if (data !== null && data !== undefined){
		data = JSON.parse(data)
	}

	return setSidebarPosition(data)
}

const setSidebarPosition = (data) => {
	if (data !== null && data !== undefined){
		data = _.cloneDeep(data);

		console.log("positionSidebar loaded scroll position:", data.scrollPosition)
		console.log("positionSidebar element loaded:", document.querySelector(sidebarSelector))
		setScrollPosition(sidebarSelector, data.scrollPosition)
		//deleteSidebarPositionData();
		return data;
	}
}

const openSitePanel = (data) => {

}

export {setSidebarPositionData, deleteSidebarPositionData, loadSidebarPositionData, setSidebarPosition}