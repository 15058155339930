import React, { Component, Fragment } from 'react'
import SiteRow from './SiteRow'

const ResourceTable = ({resource, index, expanded}) => {

    return(
        <div className="row gap-y">
            <div className="col-11 mx-auto accordion accordion-light" id={"accordion-"+index}>
                <div className="card d-block border shadow-4 mb-3">
                    <div className="p-4">
                        <div className="flexbox">
                            <h5 className="mb-1">
                                <a className="text-dark text-uppercase">{resource.resource}</a>
                            </h5>
                            {/*<a className="badge badge-success text-white mr-auto mb-2">Example Badge</a>*/}
                            <div className="messages collapsed text-dark" data-toggle="collapse" data-target={"#collapse-"+index}>
                                <i className="fa fa-fw fa-angle-down" aria-hidden="true"></i>
                                <i className="fa fa-fw fa-angle-up" aria-hidden="true"></i>
                            </div>
                        </div>

                        <div id={"collapse-"+index} className={"collapse" + (expanded ? " show" : "")} data-parent={"#accordion-"+index}>
                            <div className="card-body">
                                <table className="table table-slim table-hover text-center">
                                    <thead className="bg-secondary">
                                    <tr>
                                        <th className="text-left">Site</th>
                                        <th className="text-right">Imbalance</th>
                                        <th></th>
                                        <th>Available On</th>
                                        <th>External Status</th>
                                        <th></th>
                                        <th>Matches</th>
                                    </tr>
                                    </thead>


                                    {
                                        resource.sites.map((site, i, allSites) => {
                                            return (<SiteRow
                                                site={site}
                                                key={i}
                                                index={i}
                                                allSites={allSites}
                                            />)
                                        })
                                    }
                                    
                                </table> 

                                <a href="/haul-check-invite" className="small pull-right">Tell more people about your posts on Haul Check...</a>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResourceTable