import React, {Fragment, useState, useEffect} from 'react';
import TableRow from './DisplayComponents/TableRow'
import ManagerTableSection from './ManagerTableSection';
import TableData from './DisplayComponents/TableData'
import _ from 'lodash';

import './adjustments.css'

export default function MasterSettingsTable({table, updateSite, updateMaterial, hasExternalAccess, isMobileOnly, isMobile, isBrowser}){

	//let [columns, setColumns] = useState([])
	let [siteBeingMoved, setSiteBeingMoved] = useState(null)

	return(<div className="section pt-2">
       	<div className="container-fluid">
            <div className="row">
            	<header className="section-header mt-7 mb-0 w-100">
                    <h2><strong>Master Setup Table</strong></h2>
                </header>

				<div className="mx-3 mb-0 mst-table-outer-div">  

					<div className="master-settings-table">
						<div className="bg-gray mst-container border-bottom mst-title">
							<div className="mst-row">
								<div className="mst-cell border fixed-2">Primary Contact</div>
								<div className="mst-cell border fixed-3">Site Name</div>
								<div className="mst-cell border fixed">Site Phase</div>
								<div className="mst-cell border fixed">Archive Site</div>
								<div className="mst-cell border fixed">Delete Site</div>
								<div className="mst-cell border fixed">Add Material</div>
								<div className="mst-cell border fixed-1-5">Materials</div>
								<div className="mst-cell border fixed-1-5">Imbalance</div>
								<div className="mst-cell border fixed-2">Qty OOM</div>
								<div className="mst-cell border fixed-3">General Availability</div>
								{hasExternalAccess ? <>
									<div className="mst-cell border fixed-1-5">Search/Post Status</div>
									<div className="mst-cell border fixed-2">Expiration Date</div>
									{/*<div className="mst-cell border fixed">Messages</div>*/}
								</> : <div className="mst-cell border fixed-2">Archived</div> }
								<div className="mst-cell border fixed">Archive Material</div>
								<div className="mst-cell border fixed">Delete Material</div>
							</div>
						</div>
						<div className="mst-container">
							<div className="mst-col">

								{ !_.isEmpty(table) ? table.map((primaryContact, index) => {
									return <ManagerTableSection
										passedContact={primaryContact}
										hasExternalAccess={hasExternalAccess}
										key={primaryContact.username}
										updateSite={updateSite}
										updateMaterial={updateMaterial}
										newSite={siteBeingMoved}
										moveSite={setSiteBeingMoved}
										clearNewSite={() => setSiteBeingMoved(null)}
									/>
									}) : null }
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>)

}