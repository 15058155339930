import React, {useState, Fragment} from 'react';
//import ajax from 'superagent';
//import _ from 'lodash';
//import jwtDecode from 'jwt-decode';
import {
  HaulCheckService
} from '../../services';
//import TextField from '@material-ui/core/TextField';
//import Autocomplete from '@material-ui/lab/Autocomplete';
//import { url, earthidealAPIURL } from '../../../constants'

export default function DeleteAMaterial({material, callback}){

    let [checkboxChecked, setCheckboxChecked] = useState(false);
    let [loading, setLoading] = useState(false);
    let hasMessages = (material.totalMessagesCount > 0);

    let deleteMaterial = async () => {
        setLoading(true);
        let {idsite_resource} = material;
        if (idsite_resource != 0){
            await HaulCheckService.deleteMaterial(idsite_resource);
        }
        setCheckboxChecked(false);
        if (callback){
            callback();
        }
        closePopup();
        setLoading(false);
    }

    let archiveMaterial = async () => {
        let {idsite_resource, idsite} = material;
        if (idsite_resource != 0){
            await HaulCheckService.setArchived(idsite_resource);
            await HaulCheckService.getMatches(idsite)
        }
        setCheckboxChecked(false);
        if (callback){
            callback();
        }
        closePopup();
    }

    let closePopup = () => {
        let {unreadExternalsCountNoArchive} = material;
        setCheckboxChecked(false);
        document.getElementById('close-deletematerial').click();
    }

    return(<>
        <button id={"open-delete-material-popup"} 
            data-toggle="popup" 
            data-target={"#deletematerial"} 
            style={{display: "none"}}>
            Popup Trigger Button
        </button>
        
        <div id="deletematerial" class="popup col-6 col-md-4 p-0" data-position="top-center" data-animation="slide-down">
            <button type="button" class="close" data-dismiss="popup" aria-label="Close" id="close-deletematerial">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="media alert-danger p-5">
                <i class="ti ti-archive lead pt-3"></i>
                <div class="media-body fw-200">
                    <p class="lead-3 strong ml-2">Delete Material?</p>
                    <p class="fw-400 lh-2 mb-4 pr-7">Are you sure you want to delete this material and lose access to your matches?</p>

                    {hasMessages ? <>

                        <p className="lh-2"> We found messages between you and one of your matches.</p>
                        <div className="custom-control custom-checkbox lh-2 mb-2" onClick={() => setCheckboxChecked(!checkboxChecked)}>
                            <input className="custom-control-input" 
                                type="checkbox" 
                                checked={checkboxChecked}
                            / >
                            <label className="custom-control-label">Delete this material and all associated messages and matches</label>
                        </div>
                    </> : null }

                    <button type="button" class="btn btn-danger float-right mx-2 mt-2" disabled={(hasMessages && !checkboxChecked) || loading} onClick={deleteMaterial}>
                        {loading ? <img src="../../img/app/photoswipe/preloader.gif" /> : null}
                        Yes, Delete
                    </button>

                    <button type="button" class="btn btn-outline-danger float-right mx-2 mt-2" onClick={closePopup}>Cancel</button>


                    {/*hasMessages || */false ?
                        <button type="button" class="btn btn-outline-info float-right mx-2 mt-2" onClick={archiveMaterial}>Archive Instead</button>
                    : null}

                </div>
            </div>
        </div>    
    </>)

}