import React from "react";

export default function ListItem(props) {
  const { name, quantity, done } = props.todo;
  return (
    <li style={{ textDecoration: done ? "line-through" : "" }}>
      {name +' '+ quantity+" "}

      {
        //!done ? <button onClick={props.completed}>Completed</button> : ""
      }
      <button onClick={props.remove}> Remove </button>
    </li>
  );
}
