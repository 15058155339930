import React, {Component, Fragment, useEffect, useLayoutEffect, useMemo, useState}  from 'react';
import moment from 'moment';
import LetterAvatar from '../common/LetterAvatar';
import {MatchesService, ConversationsService, SharesDetailsService, PublishSubscribe} from '../../services'
import {updateObjectBasedOnKey, hcDetailsDisplay, hcGetIconForSiteOrMatch} from '../../utils'
import SharedDetailsDisplay from '../common/SharedDetailsDisplay'
import _ from 'lodash'
import useChatTools from "../FormHooks/useChatTools"

export default function ExternalMatchRow({passedMatch, list, switchSidePanel, groupsListMode, refreshPanel, parentMaterial, isMobile, isBrowser, getRouteExistsAndIsVisible, toggleRouteVisible, history, openMessages, isUnavailableMaterial, reloadAllMatchesCallback}){

  //console.log("BUG-1046", "ExternalMatchRow rendering with match id " + (passedMatch.idmatchsiteresource ? passedMatch.idmatchsiteresource : "[not yet defined]"))

  let [noAvailabilityOverlap, setNoAvailabilityOverlap] = useState(false);
  let [routeDataExists, setRouteDataExists] = useState(false);
  let [routeIsValid, setRouteIsValid] = useState(true);
  let [routeIsVisible, setRouteIsVisible] = useState(false);
  let [idroutedistance, setidroutedistance] = useState(null);
  let [match, updateMatch] = useState(_.cloneDeep(passedMatch));

  let PS = PublishSubscribe.usePublishSubscribe({
      origin: "InHouse/ExternalMatchRow/"+match.partner_idsite_resource,
      topic: "HC/materials",
      identifier: {idsite_resource: match.partner_idsite_resource},
      callback: (message) => {
        console.log("Message recieved for idsite_resource " + match.idmatchsiteresource, message);
        message = JSON.parse(message);

        //Update stats of materials where needed
        if (message.type == "updated-details"){
          //let clone_material = _.cloneDeep(material);
          updateMatch((previousState) => {
            let newState = _.cloneDeep(previousState);
            newState.details =  _.merge(previousState, message.data);
            return newState;
          }); 
        } else if (message.type == "updated-material"){
          updateMatch((previousState) => updateObjectBasedOnKey(previousState, message.data)); 
        } else if (message.type == "new-messages" || message.type == "sent-messages"){
            console.log("AMQ calling InHouse handleMessage refresh handler for message FROM ExternalMatchRow")
            setTimeout(refreshPanel , 1000);
        }
      }
  })

  let chatTools = useChatTools({
    onSend: async () => {await refreshPanel()}
  });


  useEffect(() => {
    updateMatch(_.cloneDeep(passedMatch))
  }, [passedMatch])


    useMemo(() => {
      //console.log("BUG-1046", "calling useMemo", "externalMatchRow 1 for idmatchsiteresource " + match.idmatchsiteresource);
      if (!parentMaterial || !match) return;

      let parentAvailabilityStart = moment(parentMaterial.available_date).add('days', 1).startOf("month");
      let parentEnd = (parentMaterial.available_end_date ? parentMaterial.available_end_date : parentMaterial.available_date)
      let parentAvailabilityEnd = moment(parentEnd).add('days', 1).endOf("month");

      let start =  moment(match.available_date).add('days', 1).startOf("month");
      let endDate = (match.available_end_date ? match.available_end_date : match.available_date);
      let end = moment(endDate).add('days', 1).endOf("month");

      let newAvailabilityOverlap = parentAvailabilityStart.isAfter(end) || parentAvailabilityEnd.isBefore(start)

      if (newAvailabilityOverlap != noAvailabilityOverlap) setNoAvailabilityOverlap(newAvailabilityOverlap);
    }, [parentMaterial.available_date, parentMaterial.available_end_date, match.available_date, match.available_end_date]);

    useEffect(() => {
      //console.log("BUG-1046", "calling useMemo", "externalMatchRow 2 for idmatchsiteresource " + match.idmatchsiteresource);
      let {routeData} = match;

      let routeExists = (routeData != undefined && routeData.length != undefined);
      let routeid = (routeExists ? routeData[0].idroutedistance : null);
      let routeValid = (routeExists ? routeData[0].distance != undefined && routeData[0].time != undefined && !routeData[0].noroute : null)

      let routeInfoFromParent = getRouteExistsAndIsVisible(routeid);

      //console.log("BUG-1187 external " + match.idmatchsiteresource, 
      //  "routeInfoFromParent (calculated):", routeInfoFromParent.isVisible, "routeDataExists (existing state):", routeIsVisible);

      if (routeInfoFromParent.exists != routeDataExists) setRouteDataExists(routeInfoFromParent.exists);
      if (routeid != idroutedistance) setidroutedistance(routeid);
      if (routeInfoFromParent.isVisible != routeIsVisible) setRouteIsVisible(routeInfoFromParent.isVisible);
      if (routeIsValid != routeValid) setRouteIsValid(routeValid);

      //console.log("FEA-1220 isBlasted:", match.isBlasted)

    }, [_.cloneDeep(match.routeData)]);

    let markAsRead = async () => {
      console.log("Calling ExternalMatchRow markAsRead with wasread", match.wasread, "result of check:", !!(match.wasread != 1))
      if (match.wasread != 1){
        let data = {wasread: 1, matchsiteresource: match.idmatchsiteresource}
        console.log("ExternalMatchRow markAsRead called with data", data)
        await MatchesService.updateReadStatusMatch(data)
      }
    }

    useEffect(() => {
      markAsRead();
    }, [match.wasread])

    const initializeChatTools = async (idmatchsiteresource, idsite) => {
      await chatTools.initialize(idmatchsiteresource, idsite);
    }

    useEffect(() => {
      initializeChatTools(match.idmatchsiteresource, match.idsite);
    }, [match.idmatchsiteresource, match.idsite, passedMatch.wasread,])

  let availableRange = () => {
    if (match.available_date){
      let start =  moment(match.available_date).add('days', 1).startOf("month");
      let formattedDate = start.format("MMM 'YY");

      if (match.perennialEndDate === 0 || match.perennialEndDate === "0"){
        let endDate = (match.available_end_date ? match.available_end_date : match.available_date);
        let end = moment(endDate).add('days', 1).endOf("month");

        formattedDate +=" to " + end.format("MMM 'YY");
      } else {
        formattedDate += " onward"
      }

      if (noAvailabilityOverlap){
        return <s>{formattedDate}</s>
      } else {
        return formattedDate
      }
      
    } else {
      return ""
    }
  }

  let toggleConversation = async () =>{
    /*if (!groupsListMode){
      await MatchesService.updateReadStatusMatch({wasread: 1, matchsiteresource: match.idmatchsiteresource})
      //refreshPanel();
      match.wasread = 1;
      updateMatch(previousState => updateObjectBasedOnKey(previousState, {key: "wasread", value: 1}))
    }
    
    let matchesPanel = JSON.parse(localStorage.getItem("matchesPanel"))
    matchesPanel.idmatchsiteresource = match.idmatchsiteresource;
    localStorage.setItem("matchesPanel", JSON.stringify(matchesPanel));
    if (openMessages) openMessages();*/
    //history.push('/app/messages');
    return;
  }

  

  let renderBadge = () => {
    let {partner_archived} = match;


    if (partner_archived){
      return <span className="badge badge-pale badge-secondary">Archived</span>;
    }/* else if (iddiscoverymode == 2 && status == "external"){
      //return <span className="badge badge-pale badge-info">Post expires in {expiryDiff()} — {moment(expiration_date).format("M/D/YY")}</span>
      return <span className="badge badge-pale badge-info">Posted</span>
    } else if (iddiscoverymode == 1){
      return <span className="badge badge-pale badge-warning">Bidding</span>
    } else {
      return <span className="badge badge-pale badge-secondary">Not Posted</span> 
    }*/ else {
      return null;
    }
  }

  let renderQuantity = () => {
    let {details, quantity, isSponsoredMatch} = match;
    let q = quantity;
    let displayS = true;

    if (isSponsoredMatch) return "";
    else {
      if (details && details.quantity && parseInt(details.quantity) != "NaN"){
        q = details.quantity;
        displayS = false;
      } else if (quantity === "---"){
        return "---"
      }

      return (parseInt(q).toLocaleString()) + (displayS ? "s " : " ") + "CYs"
    }

  }


  let renderRouteDistance = () => {
    let {routeData, sharedRoute} = match;

    if (routeDataExists || sharedRoute){
      let toReturn = (routeIsValid ? Math.ceil(routeData[0].distance) + "mi" : "--");
      return toReturn;

    } else { /* Route Does Not Exist */
      return match.distance;
    }
  }

  /*let renderRouteTime = (textStyle) => {
    let {routeData, sharedRoute} = match;

    if (routeDataExists || sharedRoute){
      let toReturn = (routeIsValid && routeData && routeData.length ? routeData[0].time + " min" : "--");
      if (isMobile){
        return toReturn;
      } else {
        return <label className="fs-14 strong text-dark">{toReturn}</label>
      }
    } else {
      //if (isMobile){
        return null;
      //} else {
        //return <a className="link fs-12 text-light" href="#" onClick={toggleConversation}><i className="fa fa-lock" aria-hidden="true"></i> <u>Route Distance & Time</u></a>
      //}
    }
  }*/

  let renderRouteTime = () => {
    let toReturn = match.travelTime + " min";
    if (isMobile){
      return toReturn;
    } else {
      return <label className="fs-14 strong text-dark">{toReturn}</label>
    }
  }

  let renderRouteToggler = () => {
    if (routeIsValid === false){ console.log("renderRouteToggler route is not valid"); return null;}
    else if (!routeDataExists || !idroutedistance){
    console.log("renderRouteToggler not rendering with routeDataExists", routeDataExists, "idroutedistance", idroutedistance, "!routeDataExists", !routeDataExists, "!idroutedistance", !idroutedistance, "final comparison", !routeDataExists || !idroutedistance)
      //if (isBrowser){
      //  return <a className="link fs-12 text-light" href="#" onClick={toggleConversation}><i className="fa fa-lock" aria-hidden="true"></i> <u>Trucking Route</u></a>
      //} else {
        return null;
      //}
    } else {
      console.log("renderRouteToggler rendering route toggler")
        /*return <div className="custom-control custom-checkbox" onClick={(e) => {
          e.preventDefault();
          toggleRouteVisible(idroutedistance);
        }}>
            <input type="checkbox" className="custom-control-input" checked={routeIsVisible} />
              <label className="custom-control-label small">Compare Route</label>
            </div>
      }*/

      let btnClass = "btn btn-round btn-xs "
      let label = "Show Route"

      if (routeIsVisible){
        btnClass += "btn-outline-primary" 
        label = "Hide Route"
      } else {
        btnClass += "btn-primary"
      }

      return <button className={btnClass} onClick={() => toggleRouteVisible(idroutedistance)}>{label}</button>
    }

  }

  let renderDescription = () => {
    let {details, isSponsoredMatch} = match;

    if (isSponsoredMatch && !details.description){
      return null;
    } else {
      return hcDetailsDisplay("description", details)
    }
  }

  let renderTrucking = () => {
    let {details, isSponsoredMatch} = match;

    if (isSponsoredMatch){
      return  null;
    } else {
      return hcDetailsDisplay("trucking", details)
    }
  }

  let renderPreciseAvailableRange = () => {
    /*let {details} = match;

    if (!details){
      return  null;
    } else {
      return hcDetailsDisplay("timeframe", details, {dateFormat: "M/D/YY"})
    }*/

    return null;
  }

  let renderCost = () => {
    let {details, isSponsoredMatch} = match;

    //if (!details){
    //  return <a className="link fs-12 text-light" href="#"  onClick={toggleConversation}><i className="fa fa-lock" aria-hidden="true"></i> <u>Material & Tipping Fees</u></a>;
    //} else {
    if (isSponsoredMatch) {
      return null;
    } else {
      return hcDetailsDisplay("cost", details, {
        emptyState: "No cost info given",
        per: "/",
        chargingLabels: ["", "Not charging"]
      })
    }
    //}
  }

  let renderContactInfo = () => {
    let {labeled_partner, labelInfo, isSponsoredMatch} = match;

    console.log("renderContactInfo message:", match.message, "for idmatchsiteresource", match.idmatchsiteresource)
    console.log("renderContactInfo params labeled_partner:", labeled_partner, "labelInfo", labelInfo)

    
    if (labeled_partner || isSponsoredMatch){
      if (!_.isEmpty(labelInfo) && !_.isEmpty(labelInfo.partner)){
        let {name, phonenumber, phone} = labelInfo.partner;

        name = (name ? name : "No name given");
        phone = (phone ? phone : phonenumber ? phonenumber : "No phone number on file")
        return <><span className="small">{name} <br /> {phone}</span>{renderArcivedBadge()}</>
      } else {
        return renderNoContactInfo();
      }
    } else {         
      //if (isBrowser){ 
        return renderNoContactInfo();
      //} else {
      //  return null;
      //}
    }
  }

  let renderArcivedBadge = () => {
    let {partner_archived} = match;

    if (!partner_archived) return null;
    else return <span className="badge badge-secondary ml-1 mt-1 p-2">No longer available</span>
    
  }

  let renderNoContactInfo = () => {
    let {message, partner_archived} = match;
    console.log("getExternalMatches renderNoContactInfo message:", message, "for idmatchsiteresource", match.idmatchsiteresource)
    if (isUnavailableMaterial){
      return <span className="badge badge-secondary p-2">Update availability to swap contact info</span>
    } else if (partner_archived) {
      return renderArcivedBadge();
    } else if (message == "This match wants to swap contact info."){
      return renderRespondContactInfoRequest();
    } else if (message == "You offered to swap contact info."){
      return renderPendingContactRequest();
    } else if (message == "You declined this match." || message == "This match was declined.") {
      return renderDeclinedContactInfo(message);
    } else {
      return renderGetContactInfo();
    }
  }



  let renderGetContactInfo = () => {
    return chatTools.renderSwapContactInfoButton({
      render: ({jqueryData, onClick}) => {
        return <button 
          className="btn btn-primary btn-sm rounded-pill" 
          onClick={onClick}
          {...jqueryData}
        >
            Swap Contact Info
        </button>
      }
    })
  }

  let renderPendingContactRequest = () => {
    return <span className="badge badge-secondary p-2">Waiting for Response</span>
  }

  let renderRespondContactInfoRequest = () => {
    return <div className="d-flex">
      {chatTools.renderSwapContactInfoButton({
        render: ({jqueryData, onClick}) => {
          return <button 
            className="btn btn-info btn-sm rounded-pill mr-2" 
            onClick={onClick}
            {...jqueryData}
          >
              Contact Info Requested
          </button>
        }
      })}

      {chatTools.renderDeclineButton({
        render: ({jqueryData, onClick}) => {
          return <button 
            className="btn btn-outline-info btn-sm rounded-pill" 
            onClick={onClick}
            {...jqueryData}
          >
            Decline
          </button>
        }
      })}

    </div>
  }

  let renderDeclinedContactInfo = (message = "") => {
    let text = "";
    
    if (message == "You declined this match.") {
      text = "I Declined";
    } else if (message == "This match was declined.") {
      text = "They Declined";
    } else {
      text = "Declined";
    }

    return<span className="badge badge-secondary p-2">{text}</span>
  }


  let renderIsSuperBlast = () => {
    let {isBlasted, mIsBlasted, msrIsBlasted, isSponsoredMatch, idmatchsiteresource} = match;
    console.log("FEA-1220 data for idmsr"+idmatchsiteresource+": isBlasted", isBlasted, "mIsBlasted", mIsBlasted, "msrIsBlasted", msrIsBlasted)
    
    if (isSponsoredMatch){
      return <small><span className="badge badge-warning"><em><strong>Sponsored</strong></em></span></small>

    } else if (isBlasted == 1 || msrIsBlasted == 1){
      return <small><span className="badge badge-success"><em><strong>SuperBlast</strong></em></span></small>
    }
    else return null;
  }

  let renderTitle = () => {
    let {resource, type, name, isSponsoredMatch, labelInfo} = match;

    if (type == "Surplus/Export"){
      type = "Surplus"
    } else {
      type = "Shortage"
    }

    if (!isSponsoredMatch || _.isEmpty(labelInfo)){
      return resource + " " + type;
    } else {
      return labelInfo.site.name;
    }
  }

  let renderType = () => {
    let {resource, type, isSponsoredMatch} = match;

    if (type == "Surplus/Export"){
      type = "Surplus"
    } else {
      type = "Shortage"
    }

    if (!isSponsoredMatch){
      return type;
    } else {
      return resource + " " + type;
    }
  }

  if (match == null) return null;

  else{

    let {type, available_date, labeled_partner, labelInfo, partner_archived, geom, distance, resource, idmatchsiteresource, details, ever_interacted_with, wasread, message, date, distanceFromResource} = match;

    console.log("details for " + idmatchsiteresource, details);

    if (type == "Surplus/Export"){
      type = "Surplus"
    } else {
      type = "Shortage"
    }

    let match_was_open = (wasread ? "fa fa-comment-o fs-20 text-dark" : "fa fa-comment fs-20 text-primary");
    let bookmark_color = (ever_interacted_with ? "text-info" : "text-primary")
    let fadedRow = partner_archived || noAvailabilityOverlap;
    let showAccordion = details || (message && message != "No messages")
    let bgColorClass = (fadedRow ? "bg-light" : routeIsVisible ? "bg-pale-primary" : "" );

    if (isMobile){

      return(<>
        {chatTools.renderMessageThreadModals()}
        <div className={"card border shadow-2 hover-shadow-6 p-2 mb-4 " + bgColorClass}>
          <div className="card-body px-4 pt-1 pb-0">
            <div className="row pt-1 pb-0">

              <div className="col-12 pl-3 clearfix" style={{ whiteSpace: 'nowrap'}}>
                <figure class="marker float-left mr-2 mt-1 mb-0">
                  <img src={hcGetIconForSiteOrMatch(match, "match", list)} />
                </figure> 
                <label className="fs-16 strong text-dark mb-0">{renderTitle()} {renderIsSuperBlast()}</label>
                <label className="fs-14 strong text-dark float-right">{renderRouteDistance()}</label>
              </div>

              <div className="col-12 mb-2 pb-1 pl-3 lh-1">
                <label className="fw-300 ml-6">{date ? "Matched on "+ moment(date).format('M/D/YY') : null}</label>                        
              </div>

              <div className="col-12 text-right flexbox" style={{ whiteSpace: 'nowrap'}}>
                <label className="fs-14 strong text-dark">{availableRange()}</label>
                <label className="fs-14 strong text-dark mr-4">{renderRouteTime()}</label>
              </div>

              <div className={"lh-1 col-" + (_.isEmpty(details) ? "12" : "6")}>
                <label className="fs-13 text-dark">{renderType()} {renderQuantity()}</label> 
              </div>

              {_.isEmpty(details) ? 
                <div className="col-12 mt-5 mb-4 lh-1">
                  <button className="btn  btn-xs btn-round btn-outline-primary" onClick={toggleConversation}><i className="fa fa-comment-o mr-2" aria-hidden="true"></i>Request More Info</button>
                </div>

              :<>

                <div className="col-6 lh-1 text-right">
                  <label className="fs-13 text-dark">{renderCost()}</label>               
                </div> 

                <div className="col-12 py-3 lh-2">
                  <label className="small mb-0">{renderDescription()} {renderTrucking()} {renderPreciseAvailableRange()}</label>                        
                </div>                            

                <div className="col-6 pt-4" style={{ whiteSpace: 'nowrap'}}>
                  {renderRouteToggler()}
                </div>
                                        
                <div className="col-6 lh-2 mb-2 text-right">
                  {renderContactInfo()}
                </div>

                {/*<div className="col-12 ml-auto border-top pt-2 flexbox">
                  <label className="small-3 fw-200 mr-2"><em>{message ? "Recent Activity: " + message : 'No messages'}</em></label>
                    <a className="cursor" href="#" onClick={toggleConversation}><i className={match_was_open} aria-hidden="true"></i></a>
                </div>*/}
              </>}


            </div>
          </div>
        </div>
      </>)
    
    } else {

      return(<>
        {chatTools.renderMessageThreadModals()}
        <div className={"card border shadow-2 hover-shadow-6 p-2 mb-4 " + bgColorClass}>
          <div className="card-body px-4 pt-1 pb-0">
            <div className="row pt-1 pb-0">

              <div class="col-5 pl-3 lh-2 clearfix">
                <figure class="marker float-left mr-2">
                  <img src={hcGetIconForSiteOrMatch(match, "match", list)} />
                </figure>
                <label class="fs-16 strong text-dark">{renderTitle()} {renderIsSuperBlast()}</label>
              </div>

              <div className="col-7 text-right flexbox" style={{ whiteSpace: 'nowrap'}}>
                <label className="fs-14 text-dark">{availableRange()}</label>
                <label className="fs-14 strong mr-4 text-dark">{renderRouteDistance()}</label>
                {renderRouteTime()}
              </div>

              <div className="col-5 pb-1 pl-3 lh-1">
                <label className="fw-300 ml-6">{date ? "Matched on "+ moment(date).format('M/D/YY') : null}</label>                        
              </div>

              <div className="col-4 lh-1">
                <label className="fs-13 text-dark">{renderType()} {renderQuantity()}</label> 
              </div>

              <div className="col-3 lh-1 text-right">
                <label className="fs-13 text-dark">{renderCost()}</label>               
              </div> 

              <div className="col-8 py-3 lh-2">
                {renderDescription()} {renderTrucking()} {renderPreciseAvailableRange()}               
              </div>                            

              <div className="col-4 lh-2 mt-1 mb-1 text-right">
                {renderContactInfo()}
              </div>

              <div className="col-4 mr-0 pr-0" style={{ whiteSpace: 'nowrap'}}>
                {renderRouteToggler()}
              </div>

              {/*<div className="col-8 ml-auto text-right">
                <label className="small-3 fw-200 mr-2"><em>{message ? "Recent Activity: " + message : 'No messages'}</em></label>
                <a className="cursor" href="#" onClick={toggleConversation}><i className={match_was_open} aria-hidden="true"></i></a>
              </div>*/}

          </div>
        </div>
      </div>

      </>)
    }

  }


}
