import React, { useState, useEffect, Fragment } from "react";
import DatePicker, { registerLocale } from 'react-datepicker'
import moment from 'moment'
import enUS from 'date-fns/locale/en-US'

const ModalChangeQuantityAndAvailability = ({closeModal, handleModalSave, material}) => {

    console.log("debugging handleModalSave idquantity", material.idquantity);

    let quantities = [
        {idquantity: 1, quantity: 100},
        {idquantity: 2, quantity: 1000},
        {idquantity: 3, quantity: 10000},
    ]

    let startdate = new Date();
    let enddate = new Date();
    let today = new Date();

    if (material.available_date){
        startdate = new Date(material.available_date);
        startdate.setDate(startdate.getDate() + 1);

        if (material.available_end_date){
            enddate = new Date(material.available_end_date);
            enddate.setDate(enddate.getDate() + 1);
        } else if (material.available_date){
            enddate = new Date(material.available_date);
            enddate.setDate(enddate.getFullYear(), enddate.getMonth()+2, 0);
        }
    }

    const [startDate, setStartDate] = useState(startdate)
    const [endDate, setEndDate] = useState(enddate);
    const [idquantity, setIdQuantity] = useState(material.idquantity)
    const [maxdistance, setMaxDistance] = useState(material.maxdistance);

    return(<>
        <button id={"open-searchRadius-site-setting-modal"} data-toggle="modal" data-target={"#match-radius"} style={{display: "none"}}>Popup Trigger Button</button>
        <div class="modal" id="match-radius" onClick={closeModal} tabindex="-1" role="dialog" aria-labelledby="radius" aria-hidden="true">
            <div class="modal-dialog" role="document" onClick={(e) => {e.stopPropagation();}}>
                <div class="modal-content">

                    <div class="alert alert-primary" role="alert">
                        <strong>Adjust Search Radius</strong>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"  id={'close-searchRadius'} onClick={closeModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body py-0">
                        <p class="lead-1 strong ml-2 mb-2 border-bottom">{material.resource} {material.type.split('/')[0]}</p>
                        <div class="row mt-0 ml-0">

                            <div class="col-12 px-1 ml-4 mb-4">

                                <div class="form-group ml-2">
                                    <div class="col-6 p-0 m-0 pr-5">
                                        <label class="text-dark">Search Radius</label>
                                        <div class="input-group">
                                            <input type="text" className="form-control" placeholder={material.maxdistance}
                                                value={maxdistance}
                                                onChange={(e) => setMaxDistance(e.target.value)} 
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text">mi</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p class="small mt-4 ml-3">Actual driving distance may exceed this straight-line distance.
                                    {/*<a tabindex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Search Radius" data-content="This straight-line distance is used to filter external matches when you do a search or post your material.">
                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    </a>*/}
                                </p>
                            </div>
                        </div>

                    </div>

                    <a class="btn btn-primary" href="#" onClick={() => handleModalSave(material, idquantity, maxdistance, startDate, endDate)}>Refresh Matches</a>
                </div>
            </div>
        </div>
        {/*<div className="modal fade" id="match-criteria" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document" >
                <div className="modal-content">
                    <div className="alert alert-primary" role="alert">
                        <strong>Update Match Criteria</strong>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id={'close-matchCriteria'} onClick={closeModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-0">
                        <p className="strong ml-2">{material.name} </p>
                        <p className="small strong fw-400 ml-3 mb-4 pr-7">
                            Update the general criteria you want to use for finding matches.
                            <br />You can add details later on to share more specific information with other users.
                        </p>

                        <div className="row m-0 ml-2">

                            <div className="col-4 p-0 m-0 pr-5">
                                <div className="form-group">
                                <label className="text-dark">Quantity</label>
                                    <select className="form-control"
                                    value={idquantity}
                                    onChange={(e) => setIdQuantity(e.target.value)}>
                                        {quantities.map((item)=> { return(
                                             <option value={ item.idquantity } key={ item.idquantity }>
                                              { (item.quantity).toLocaleString('en') }s of CY's
                                            </option>
                                        )})}
                                    </select>
                                </div>
                            </div>

                            <div className="col-5 p-0 m-0 pr-5">
                                
                                <label className="text-dark">Availability</label>
                                <div className="input-daterange input-group mr-2" id="datepicker">
                                    <DatePicker
                                        locale="en-US"
                                        className="input-sm form-control text-center"
                                        selected={ startDate || today }
                                        selectsStart
                                        startDate={ startDate || today }
                                        endDate={ endDate || today }
                                        dateFormat="MM/yyyy"
                                        onChange={ setStartDate }
                                        showMonthYearPicker
                                    />
                                    <span className="input-group-addon">to</span>
                                    <DatePicker
                                        locale="en-US"
                                        className="input-sm form-control text-center"
                                        selected={ endDate || today }
                                        selectsEnd
                                        startDate={ startDate || today }
                                        endDate={ endDate || today }
                                        dateFormat="MM/yyyy"
                                        onChange={ setEndDate }
                                        showMonthYearPicker
                                    />
                                </div>
                            </div>

                            <div className="col-3 p-0 m-0 pr-5">
                                <label className="text-dark">Search Radius</label>
                                <div className="input-group">
                                    
                                    <div className="input-group-append">
                                        <span className="input-group-text">mi</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <a className="btn btn-primary text-white" href="#" }>Update Criteria</a>
                </div>
             </div>
        </div>*/}

    </>)


}

export default ModalChangeQuantityAndAvailability