import React, { Fragment } from 'react'

const renderResourceCombo = (resourcesList) => {
  let type
  return resourcesList.map(item => {
    if (type === 'Export' && item.type === 'Import') {
      type = item.type
      return (
        <Fragment key={item.idresource}>
          <option key='Separator' disabled={ true }>----------------</option>
          <option value={item.idresource} key={item.idresource}>{item.type}: {item.resource}</option>          
        </Fragment>
      )
    }
    type = item.type
    return <option value={item.idresource} key={item.idresource}>{item.type}: {item.resource}</option>
  })
}

const renderQuantitiesCombo = (quantitiesList) => {
  return quantitiesList.map(item => {
    return (
      <option value={ item.idquantity } key={ item.idquantity }>
        { (item.quantity).toLocaleString('en') }s of CY's
      </option>
    )
  })
}

const Resource = ({
  id,
  resources,
  updateResource,
  updateQuantity,
  quantitiesList,
  deleteResource,
  resourcesList
}) => {
  let deleteButton = ''
  if (resources.length > 1) {
    deleteButton = (
      <div className="col-3 mt-1">
        <button className="btn btn-xs fw-400" onClick={() => { deleteResource(id) }}><i className="ti ti-close"></i></button>
      </div>
    )
  }

  return (
    <div className="form-group row gap-y mt-1" key={ id }>
      <div className="col-5">
        <select
          className="form-control form-control-sm"
          value={resources[id].idresource || 0}
          onChange={ e => { updateResource(id, ~~e.target.value) } }
        >
          <option>Resource Type</option>
          { renderResourceCombo(resourcesList) }
        </select>
      </div>
      <div className="col-4">
        <select
          className="form-control form-control-sm"
          value={resources[id].idquantity || 0}
          onChange={ e => { updateQuantity(id, ~~e.target.value) } }
        >
          <option>Quantity Magnitude</option>
          { renderQuantitiesCombo(quantitiesList) }
        </select>
      </div>
      { deleteButton }
    </div>
  )
}

export default Resource
