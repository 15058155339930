import React, {Component} from 'react';
import ajax from 'superagent';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import {
  ApiService,
  PMGroupsService } from '../../../services';
import { url, earthidealAPIURL } from '../../../constants'

export default class ModalEditTeam extends Component{

    constructor(props){
        super(props);
        
        let idsite = 0;
        let siteName = "Default Site";
        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        let user = localStorage.getItem('user');
        let userId = user ? JSON.parse(user).id : null;
        let username = userDecoded['cognito:username'];

        this.state = {
            userId,
            idsite,
            users: [],
            invitedEmails: [{email: "", invalidEmail: false}],
            profilesList: [],
            usersList: [],
            invitedEmailsChanged: false,
            siteName,
            userDecoded,
            pmGroupName: null, 
            invalidGroupName: false,
            idpmgroup: props.idpmgroup,
    		invitingUserEmail: null, 
            waiting: false,
            loading: false,
            username,
            hcSubscriptionYearlyPrice: 0,
            hcSubscriptionMonthlyPrice: 0,
        }
    }

    componentWillMount = async () => {
        let invitingUserEmail = ApiService.getJWTEmail();
        let hcSubscriptionYearlyPrice = await ApiService.getHCSubscriptionYearlyPrice();
        let hcSubscriptionMonthlyPrice = await ApiService.getHCSubscriptionMonthlyPrice();
        this.setState({invitingUserEmail, hcSubscriptionMonthlyPrice, hcSubscriptionYearlyPrice});
    }

    componentWillReceiveProps = async (newProps) =>{
    	let {showModal} = newProps;
        
        if (newProps.showModal !== this.props.showModal){
            this.refreshModal();
        }
    }

    refreshModal = async () => {
        let invitingUserEmail = ApiService.getJWTEmail();
        this.setState({loading: true});
        let usersList = await PMGroupsService.getPmGroupMembersByEmail(invitingUserEmail, "PMGroupForm/componentWillReceiveProps");
        let pmGroupInfo = await PMGroupsService.getPmGroupByEmail(invitingUserEmail, "PMGroupForm/componentWillReceiveProps");
        let name = null;
        let idpmgroup = null;
        if (pmGroupInfo){
            name = pmGroupInfo.name;
            idpmgroup = pmGroupInfo.idpmgroup;
        }
        
        this.setState({
            usersList, 
            idpmgroup, 
            "pmGroupName": name, 
            loading: false, 
            invitedEmails: [{email: "", invalidEmail: false}], 
            invitedEmailsChanged: false
        });
    }

    toggleUserRemove = (user, index) =>{
        let usersList = this.state.usersList;
        usersList[index].willBeRemoved = !usersList[index].willBeRemoved;
        this.setState({usersList});

    }

    updateEmail = (key, value) => {
       let {invitedEmails} = this.state;
       invitedEmails[key].email = value;
       this.setState({invitedEmails, invitedEmailsChanged: true});
    }


    addNewUserRow = () => {
        this.setState(state => {
            const invitedEmails = [...state.invitedEmails, {email: "", invalidEmail: false}];
            return {invitedEmails}
        })
    }

    verifyUsers = async () => {
        //if no users are being added, just say all 0 of them are valid by default
        if (this.state.invitedEmailsChanged === false) return true;

        //Otherwise, check
        let validUsersList = [];
        let hasInvalidUsers = false;

        for (var i = 0; i < this.state.invitedEmails.length; i++){

            let userIsValid = await this.verifyIndividualUser(i);
            if (userIsValid === true){
                validUsersList.push(this.state.invitedEmails[i].email);
            } else if (userIsValid === false) {
                hasInvalidUsers = true;
            }
        }

        if (hasInvalidUsers){
            this.setState({hasInvalidUsers: true});
            return false;
        } else { 
            this.setState({hasInvalidUsers: false});
            return validUsersList;
        }
    }

    verifyIndividualUser = async (i) => {
    	let {invitedEmails} = this.state;
        let email = invitedEmails[i].email;
        let validUser = true;

        let emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        let validEmail = emailRegexp.test(email);

        if (validEmail && email != "") {

            let existingGroup = await PMGroupsService.getPmGroupByEmail(email);

            console.log("existingGroup for email " + email + ":", existingGroup);

            if (existingGroup !== null){
                validUser = false;
                invitedEmails[i].invalidEmail = true;
            } else {
                invitedEmails[i].invalidEmail = false;
            }
        } else if (email != "") {
            validUser = false;
            invitedEmails[i].invalidEmail = true;
        }

        this.setState({invitedEmails})

        console.log("existingGroup returning validUser value " + validUser);

        return validUser;
    }

    
    sendData = async () =>{
    	let {closeModal, updatePmGroup, newGroup, openPopup, updateRejectedUsers} = this.props;
    	let {invitingUserEmail, pmGroupName, idpmgroup} = this.state;

        if ((pmGroupName === "" || pmGroupName === null) && newGroup){
            this.setState({invalidGroupName: true});
            return;
        } else {

            this.setState({waiting: true, invalidGroupName: false});
      
            let verifiedUsers = await this.verifyUsers();
            if (verifiedUsers === false){
                this.setState({waiting: false});
                return;
            } else {

                const data = {
                    invitedEmails:       verifiedUsers,
                    pmGroupName,
                    idpmgroup,
                    invitingUserEmail,
                };

                const 
                removedEmails = []
                let usersList = this.state.usersList;
                for (var i = 0; i < usersList.length; i++){
                    if (usersList[i].willBeRemoved) removedEmails.push(usersList[i].email);
                }

                data.removedEmails = removedEmails;
                data.username = this.state.username;

                if (newGroup){
                	let newGroup = await PMGroupsService.addPmGroup({invitedEmails: verifiedUsers, invitingUserEmail, pmGroupName });
        	    	console.log("newGroup", newGroup);
        	    	if (newGroup.error){
        	    		alert(newGroup.message);
        	    		console.log(newGroup);
        	    	} 
        	    	else{
        	    		openPopup("popupTriggerGroupCreated");
        	    		updatePmGroup(newGroup.idpmgroup, newGroup.pmGroupName, true);
                        updateRejectedUsers(newGroup.rejectedUsers);
                        this.refreshModal();
                        this.closeModal();
        	    	} 
                }

                else if (removedEmails.length > 0){
                    ajax.post(`${earthidealAPIURL}/pmgroups/removeFromPmGroup`)
                    .send(data)
                    .then(res => {
                        if (verifiedUsers.length > 0){
                             ajax.post(`${earthidealAPIURL}/pmgroups/addToPmGroup`)
                            .send(data)
                            .then(res => {
                                openPopup("popupTriggerUsersUpdated");
                                updateRejectedUsers(res.body.rejectedUsers);
                                updatePmGroup(idpmgroup, pmGroupName, true);
                                this.refreshModal();
                            });
                        } else {
                            openPopup("popupTriggerUsersRemoved");
                            updateRejectedUsers([]);
                            updatePmGroup(idpmgroup, pmGroupName, true);
                            this.refreshModal();
                        }

                        if (removedEmails.indexOf(invitingUserEmail) != -1){
                            updatePmGroup(null, null, true)
                        }
                    });  
                    console.log("Deleting and/or adding users") 
                }

                else if (verifiedUsers.length > 0){
                    ajax.post(`${earthidealAPIURL}/pmgroups/addToPmGroup`)
                    .send(data)
                    .then(res => {
                        openPopup("popupTriggerUsersAdded");
                        updateRejectedUsers(res.body.rejectedUsers);
                        updatePmGroup(idpmgroup, pmGroupName, true);
                        this.refreshModal();
                    });

                    console.log("Jus t adding users")
                } else {
                    console.log("No changes made");
                }

                this.setState({waiting: false}, this.closeModal);
            }
        }
    }

    closeModal = () => {
         let body = document.body;
        console.log("DOM body:", body)
        let modalBackdrop = document.getElementsByClassName("modal-backdrop fade show");

        if (!_.isEmpty(modalBackdrop[0])) modalBackdrop[0].remove();
        body.classList.remove("modal-open");

        let closeButton = document.getElementById('close-the-edit-group-modal');
        if (closeButton) closeButton.click();

        if (this.props.hideModal && typeof this.props.hideModal == "function") this.props.hideModal();

    }


    render(){

    	let {newGroup} = this.props;
        let {waiting, hcSubscriptionMonthlyPrice, hcSubscriptionYearlyPrice} = this.state;

        return(<React.Fragment>
            <div id="edit-group" className="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="alert alert-primary" role="alert">
                            <strong>Edit Office Group</strong>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-the-edit-group-modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body pt-0">
                            <div className="row m-0">
                                {this.renderGroupName()}
                                <div className="col-12 mt-3">
                                <p>Office Groups allow you to cross-check sites for matches with others in your office. <br />
                                    Group members can update, search, post, and trade info on any site within the Group.</p>
                                </div>
                                {this.renderUsers()}
                                <div className="col-12 col-lg-11 mt-6 mx-lg-auto">
                                    <div className="form-group row gap-y">
                                        <div className="col-lg-12 mx-lg-auto px-0">
                                            <label className="lead fw-400">Add to Office Group</label>
                                        </div> 
                                        {this.renderAddUsers()}
                                        <button type="button" onClick={this.addNewUserRow} className="btn btn-outline-secondary mb-5 mb-lg-0 ml-lg-4 px-4"><i className="fa fa-plus fw-400"></i></button>
                                    </div>
                                </div>
                                {/*<hr className="col-11 mb-6" />
                                <h5 className="ml-lg-3">Disposal Partners</h5>
                                <div className="lead col-lg-11 mt-2 px-0 ml-lg-4">
                                    {this.renderDisposalPartners()}
                                </div>*/}
                            </div>
                        </div>
                        <button type="button" className="btn btn-lg btn-primary" onClick={this.sendData} >
                            {waiting ?  <img src="/img/app/photoswipe/preloader.gif" /> : null}
                            {newGroup ? "Create Office Group" : "Update Office Group"}
                        </button>
                    </div>
                </div>
            </div>

      </React.Fragment>)

    }

    renderGroupName = () => {
    	let {newGroup} = this.props;

    	if (newGroup){
    		return (<div className="col-lg-12 p-0 pl-lg-4">
	            <div className="">
	                <input className={"form-control" + (this.state.invalidGroupName ? " is-invalid" : "")} 
	                type="text"  placeholder="Name your Office Group"
                    maxLength={50}
	                value={this.state.pmGroupName} 
	                onChange={(e) => {
	                    this.setState({pmGroupName: e.target.value})
	                }} />
	            </div>
	        </div>)
    	} else {
    		let {pmGroupName} = this.state;
            if (!pmGroupName) return null;
	    	return (
	    		<h5 className="ml-lg-3">{pmGroupName}</h5>
			)
		}
    }

    renderUsers = () =>{
    	let {usersList} = this.state;
    	let {newGroup} = this.props;

    	if (newGroup || usersList == undefined) return null;
    	else {
	        return ( <div className="lead col-lg-11 mt-5 px-0 ml-lg-4">
	            {this.state.usersList.length > 0 ? this.renderUserGroup(this.state.usersList) : <span>No Office Group Members</span>}
	        </div>)
        }
    }

    renderUserGroup = (group) =>{
        return(
            group.map((item, index) => {
                let willBeRemoved = this.state.usersList[index].willBeRemoved;
                return(
                    <div className="col-12 col-lg-10 px-0 mx-lg-auto clearfix mb-4" key={index}>
                        <div className="lead">

                            {willBeRemoved ? 
                                <i className="ti-minus text-danger mt-2 mr-2 float-left"></i>
                                 : <i className="ti-check text-success mt-2 mr-2 float-left"></i>
                            }
                            {item.name ? 
                                <React.Fragment>
                                    <strong className={willBeRemoved ? ' text-danger' : 'text-dark'}>{item.name}</strong> <span className={willBeRemoved ? ' text-danger' : 'text-dark'}> - {item.email}</span>
                                </React.Fragment>
                            : 
                                <strong className={willBeRemoved ? ' text-danger' : 'text-dark'}>{item.email}</strong>
                            }

                            <a className="small item-remove text-dark float-right clearfix mt-1" href="#"
                            onClick={() => this.toggleUserRemove(item, index)}>
                                <i className={"ti-close" + (willBeRemoved ? ' text-danger' : '') }></i>
                            </a>

                        </div>
                    </div>
                )
            })
        )
    }

    renderAddUsers = () => {
        return this.state.invitedEmails.map((item, index) => {
            return this.renderAddUserRow(index)
        })
    }

    renderAddUserRow = (key) => {

        return(
            <React.Fragment key={key}>
                <div className="col-lg-12 p-0 pl-lg-4">
                    <div className="">
                        <input className={"form-control" + (this.state.invitedEmails[key].invalidEmail ? " is-invalid" : "")} 
                        type="email"  placeholder="name@company.com"
                        value={this.state.invitedEmails[key].email || ""} 
                        onChange={(e) => {
                            this.updateEmail(key, e.target.value)
                        }} />
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
