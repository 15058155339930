import React, { Component, Fragment } from 'react'
import DatePicker from 'react-datepicker' 

import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react'
import { url } from '../../../constants'
import SuccessPopup from '../../common/Popups'

import "react-datepicker/dist/react-datepicker.css"

import {
  ApiService,
  HaulCheckService,
  StripeService } from '../../../services'

export default class AddAMaterial extends Component {

    constructor(props){
    super(props);

        let defaultResourceObj = {
            resource: '',
            type: '',
            idquantity: "0",
            availabledate: null,
            availableenddate: null,
            maxdistance: 40,
            perennialEndDate: 0,
            valid_resource: true,
            valid_type: true,
            valid_idquantity: true,
            valid_availabledate: true,
            valid_availableenddate: true,
            valid_maxdistance: true,
        }

        this.state = {
            newIdHaul:0,
            newHaulCheck: {
                username: '',
                startDate: '',
                endDate: '',
                maxDistance: '',
                resources: [JSON.parse(JSON.stringify(defaultResourceObj))],
                iddiscoverymode: 1,
                archived: 0,
                autoRenewal: false
            },
            email: '',
            resourcesList: [],
            imbalancesList: [],
            quantitiesList: [],
            discoveryModesList: [],
            error: false,
            defaultResourceObj,
            loading: false,
        }
    }

    componentDidMount = async () => {
        const resourcesList = await ApiService.getCreateableResources()
        const imbalancesList = await ApiService.getUniqueResourceTypes()
        const quantitiesList = await ApiService.getQuantities()
        const discoveryModesList = await ApiService.getDiscoveryModes()

        this.setState({
            newHaulCheck: {
              ...this.state.newHaulCheck,
            },
            resourcesList,
            imbalancesList,
            quantitiesList,
            discoveryModesList
        })
    }

    componentWillMount = async () => {
        if (this.props.idsite != undefined && this.props.idsite != 0){
            this.loadSite(this.props.idsite);
        }
    }

    componentWillReceiveProps = async (newProps) => {
        if (!this.props.idsite || (this.props.idsite != newProps.idsite && newProps.idsite != 0)){
            this.loadSite(newProps.idsite);
        }
    }

    loadSite = async (idsite) => {
        //console.log("Calling loadSite");

        const username = ApiService.getJWTUsername()
        const email = ApiService.getJWTEmail();
        const date = new Date()
        const newDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        const siteInfo = await ApiService.getAllSiteInfo(idsite);

        if (siteInfo && siteInfo.length){

          let defaultResourceObj = {
            ...this.state.defaultResourceObj,
            maxdistance: siteInfo[0].maxdistance
          }

          let newHaulCheck = {
              username,
              name: siteInfo[0].name,
              idsite,
              startDate: newDate,
              endDate: newDate,
              maxDistance: siteInfo[0].maxdistance,
              resources: [JSON.parse(JSON.stringify(defaultResourceObj))],
              iddiscoverymode: siteInfo[0].iddiscoverymode,
              archived: 0,
              autoRenewal: false,
              popupTrigger: false,
              email
          };


          this.setState({
              newHaulCheck, defaultResourceObj
          })
        }
    }

    updateResourceVal = (index, value, property) => {
        console.log("updateResourceVal", index, value, property)
        const { resources } = this.state.newHaulCheck;

        resources[index][property] = value;

        this.setState({
          newHaulCheck: {
            ...this.state.newHaulCheck,
            resources
          }
        })
    }

    handleChange = e => {
        this.setState({
          newHaulCheck: {
            ...this.state.newHaulCheck,
            [e.target.name]: e.target.value
          }
        })

        e.preventDefault()
    }

    handleDateChange = (date, d) => {
        const newDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
        if (d === 0) {
          this.setState({
            newHaulCheck: {
              ...this.state.newHaulCheck,
              startDate: newDate
            },
            startDate: date
          })
        }
    }

    removeResourceRow = (rowKey) => {
        const { resources } = this.state.newHaulCheck;
        //if (resources.length == 1) return;
        resources.splice(rowKey, 1);
        this.setState({
            newHaulCheck: {
                ...this.state.newHaulCheck,
                resources
            }
        });
    }

    addNewResourceRow = () => {
        const { resources } = this.state.newHaulCheck
        let { defaultResourceObj } = this.state;
        defaultResourceObj = JSON.parse(JSON.stringify(defaultResourceObj));
        //const last = resources.length - 1

       // console.log(resources, last)
        if (this.validateResources()) {
          //console.log("defaultResourceObj", defaultResourceObj)

          resources.push(defaultResourceObj);
          this.setState({
            newHaulCheck: {
              ...this.state.newHaulCheck,
              resources
            }
          })
        } else {
          alert('You need to set the previous resources before adding a new one')
        }
    }

    validateResources = () => {

        let {resources} = this.state.newHaulCheck;
        let valid = true;    

        for (var i = 0; i < resources.length; i++){
          let r = resources[i];
          r.valid_resource = (r.resource !== '');
          r.valid_type = (r.type !== '');
          r.valid_idquantity = (r.idquantity !== 0 && r.idquantity !== "0");
          r.valid_availabledate = (r.availabledate !== null && r.availabledate !== undefined && r.availabledate instanceof Date);
          r.valid_availableenddate = (r.perennialEndDate === 1 || r.perennialEndDate === "1") || (r.availableenddate !== null  && r.availabledate !== undefined && r.availableenddate instanceof Date);
          r.valid_maxdistance = (!isNaN(parseInt(r.maxdistance)));
          valid = (valid && r.valid_resource && r.valid_type && r.valid_idquantity && r.valid_availabledate && r.valid_availableenddate && r.valid_maxdistance)
          //console.log("valid in validateResources", valid);
        }

        this.setState({
          newHaulCheck: {
            ...this.state.newHaulCheck,
            resources
          }
        })

        return valid;
    }

    handleSubmit = async () => {
        this.setState({loading: true});
        const { error, newHaulCheck } = this.state
        console.log(error)

        if (!error) {
          if (this.validateResources()) {

            if (!newHaulCheck.maxDistance){
              this.setState({
                newHaulCheck: {
                  ...this.state.newHaulCheck,
                  maxDistance: 40
                }
              })
            }

            let resources = newHaulCheck.resources.map(resource => {
              if (resource.resource !== '' && resource.type !== '' && resource.idquantity !== 0 && resource.availabledate !== null) {
                return resource
              }
            })
            this.setState({
              newHaulCheck: {
                ...this.state.newHaulCheck,
                resources
              }
            })

            let response = await HaulCheckService.setup(this.state.newHaulCheck)
            if (response && !response.error) {
              console.log("Add a material response:", response);
              let newMaterialId = null;
              if (response.resources && response.resources.length && response.resources[0].insertId){
                newMaterialId = response.resources[0].insertId;
              }
              if (this.props.callback) this.props.callback(newMaterialId);
              await HaulCheckService.getMatches(this.state.newHaulCheck.idsite);
              let closeButton = document.getElementById("close-add-material");
              if (closeButton) closeButton.click();
              this.loadSite(this.props.idsite);
              this.setState({loading: false, popupTrigger: true});

            } else {
              this.setState({loading: false});
              alert('Error trying to create a new HaulCheck:', response.message)
            }

          } else {
            //alert('All fields are required in order to create a new HaulCheck.')
             this.setState({loading: false});
            return
          }
        } else {
          this.setState({loading: false});
          alert('Error trying to create a new HaulCheck.')
        }
    }

    render = () => {
      let {loading} = this.state;
        let siteInfo = this.state.newHaulCheck;
        /*return(<>
          <div className="modal" id="add-material" tabIndex="-1" role="dialog" aria-labelledby="addMaterial" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">

                    <div className="alert alert-primary" role="alert">
                        <strong>Add Materials</strong>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" id="close-add-material">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                <div className="modal-body pt-0">
                    <div className="row mt-0 mb-2 ml-0">
                        <div className="col-lg-12 mr-auto pl-4 m-2">
                            <div className="clearfix">
                                <p className="col-12 lead-1 fw-400 mb-0 pt-3 pl-0 pr-2"><b></b></p>
                                <p className="col-6 small mb-0 pl-0 pr-2 float-left">{siteInfo.iddiscoverymode == 1 ? "Bidding" : "Construction"} Phase</p>
                                <p className="col-5 small mb-0 pl-0 pr-2 mr-7 text-right float-right">Max distance: </p>
                            </div>
                            
                            {this.renderAddResources()}

                            <button type="button" className="btn btn-sm btn-outline-dark my-6" disabled={loading} onClick={this.addNewResourceRow}><i className="ti ti-plus"></i>&nbsp;&nbsp;Another</button>

                        </div>
                    </div>

                </div>

                <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={loading}>
                  {loading ? <img src="/img/app/photoswipe/preloader.gif" /> : null}
                  Add to Site
                </button>
                </div>
            </div>
        </div>

       
      </>)*/

      return(<>
        <div class="modal" id="add-material" tabIndex="-1" role="dialog" aria-labelledby="addMaterial" aria-hidden="true">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                
              <div class="alert alert-primary" role="alert">
                <strong>Add a Material</strong>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close-add-material">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
                  
              <div class="modal-body pt-0">
                <div class="row mt-0 mb-2 ml-0">
                  <div class="col-12 m-2 pl-0 pr-6">
                    <p class="lead-1 fw-400 mb-4 pl-0 pr-2 border-bottom"><b>{siteInfo.name}</b></p>

                    {this.renderAddResources()}

                    <p class="col-5 small mt-4 mb-0 pl-1 pr-2">Search Radius: {siteInfo.maxDistance}mi
                      {/*<a tabIndex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="Search Radius" data-content="This straight-line distance is used to filter external matches when you do a search or post your material.">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>*/}
                    </p>

                  </div>
                </div>
              </div>

              <button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={loading}>
                {loading ? <img src="/img/app/photoswipe/preloader.gif" /> : null}
                Add to Site
              </button>
            </div>
          </div>
        </div>

        <SuccessPopup
          popupId="popupMaterialAdded"
          title="Success!"
          message="Material added!"
          trigger={this.state.popupTrigger}
          callback={() => {this.setState({popupTrigger: false})}}
        />

      </>);
    }

    renderAddResources = () => {
        return this.state.newHaulCheck.resources.map((item, index) => {
            return this.renderAddResourceRow(index)
        })
    }

    CustomInput = React.forwardRef(({ value, onClick, onChange, disabled }, ref) => (
      <input type="text" class="input-sm form-control text-center" disabled={disabled} onClick={onClick} onChange={onChange} ref={ref} value={value} />
    ))

    renderAddResourceRow = (key) => {
      let resource = this.state.newHaulCheck.resources[key];

      return(
        <div className="form-group row gap-y ml-1 fs-13 pr-5" key={key}>

          <div className="col-11">

            <div className="row pb-4">
              <div className="col-4 p-0 mr-4">
                <label className="text-dark">Material</label>
                <select className={"form-control" + (resource.valid_resource ? '' : " is-invalid" )}
                    value={resource.resource}
                    onChange={ e => { this.updateResourceVal(key, e.target.value, 'resource') } }>
                  <option value = ''>Material Type</option>
                  { this.renderResourceCombo() }
                </select>
              </div>
              <div className="col-4 p-0">
                <label className="text-dark">Imbalance</label>
                <select className={"form-control" + (resource.valid_type ? '' : " is-invalid" )}
                  value={resource.type}
                  onChange={ e => { this.updateResourceVal(key, e.target.value, 'type') } }>
                  <option value = ''>Imbalance</option>
                  {this.renderImbalanceCombo() }
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-3 p-0 mr-4">
                <div className="form-group mb-0">
                  <label className="text-dark">General Quantity</label>
                  {/*<a tabIndex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="General Quantity" data-content="This general info will be made visible to all matches. General Quantity does not affect which matches you see. ">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </a>*/}
                  <select className={"form-control" + (resource.valid_idquantity ? '' : " is-invalid" )}
                     value={resource.idquantity}
                     onChange={ e => { this.updateResourceVal(key, e.target.value, 'idquantity') } }>
                     <option value={0} >Quantity</option>
                    {this.renderQuantitiesCombo() }
                  </select>
                </div>
              </div>
              <div className="col-7 p-0">
                <label className="text-dark">General Availability</label>
                {/*<a tabIndex="0" class="small text-light cursor ml-1" data-toggle="popover" data-trigger="hover" title="General Info" data-content="General Availability is used to filter the matches you see. This general info will be made visible to all matches.">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                </a>*/}
                <div className="input-daterange input-group mr-2" id="datepicker">
                  <span className="input-group-addon pt-2 pl-4">From</span>
                  <DatePicker
                    className={"form-control" + (resource.valid_availabledate ? '' : " is-invalid" )}
                    dateFormat="MM/yyyy"
                    locale="en-US"
                    selected = {resource.availabledate}
                    startDate = {resource.availabledate}
                    endDate = {resource.availableenddate}
                    minDate={new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)}
                    onChange={ date => this.updateResourceVal(key, date, 'availabledate') }
                    customInput={
                      <this.CustomInput />
                    }
                    selectsStart
                    showMonthYearPicker
                  />
                  <span className="input-group-addon pt-2">to</span>
                    <DatePicker
                      className={"form-control" + (resource.valid_availableenddate ? '' : " is-invalid" )}
                      dateFormat="MM/yyyy"
                      locale="en-US"
                      selected = {resource.availableenddate}
                      startDate = {resource.availabledate}
                      endDate = {resource.availableenddate}
                      disabled={resource.perennialEndDate === 1}
                      minDate={new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)}
                      onChange={ date => this.updateResourceVal(key, date, 'availableenddate') }
                      placeholderText="End Availability Range"
                      customInput={ <this.CustomInput />
                        
                      }
                      selectsEnd
                      showMonthYearPicker
                    />
                  <div class="input-group-append custom-control custom-checkbox p-1 text-right" onClick={(e) => {
                      e.preventDefault();
                      this.updateResourceVal(key, (resource.perennialEndDate ? 0 : 1), 'perennialEndDate')}
                    }>
                     <input className="custom-control-input" type="checkbox" id={"perennial-checkbox-"+key} checked={resource.perennialEndDate === 1 || resource.perennialEndDate === "1"} />
                     <label className="custom-control-label" htmlFor={"perennial-checkbox-"+key}>No End Date</label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="col-1 mt-0 p-0">
            <a href="#" className="btn btn-xs fw-400" onClick={() => this.removeResourceRow(key)}><i className="ti ti-close"></i></a>
          </div>*/}

        </div>

      );
    }

    renderQuantitiesCombo = () => {
        let { quantitiesList } = this.state
        return quantitiesList.map(item => {
          return (
            <option value={ item.idquantity } key={ item.idquantity }>
              { (item.quantity).toLocaleString('en') }s of CY's
            </option>
          )
        })
    }

  renderResourceCombo = () => {
        let { resourcesList } = this.state
        return resourcesList.map(({resource}) => {
          return (
            <option value={ resource } key={ resource }>
              { resource }
            </option>
          )
        })
    }

  renderImbalanceCombo = () => {
        let { imbalancesList } = this.state
        return imbalancesList.map(({type}) => {
          return (
            <option value={ type } key={ type }>
              { type == "Surplus/Export" ? "Surplus" : "Shortage" }
            </option>
          )
        })
    }   
}