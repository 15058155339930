import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Messages from './Messages/Messages'
import OwnerMessage from './Messages/OwnerMessage'
import OwnerDeclineMessage from './Messages/OwnerDeclineMessage'
import OwnerConnectMessage from './Messages/OwnerConnectMessage'
import OwnerShareDetailMessage from './Messages/OwnerShareDetailMessage'
import OwnerConnectConsentMessage from './Messages/OwnerConnectConsentMessage'
import OwnerShareLocationConsentMessage from './Messages/OwnerShareLocationConsentMessage'
import OwnerShareRouteDistanceMessage from './Messages/OwnerShareRouteDistanceMessage'
import ShareLocationConsentMessage from './Messages/ShareLocationConsentMessage'
import DeclineMessage from './Messages/DeclineMessage'
import ConnectMessage from './Messages/ConnectMessage'
import SharedetailMessage from './Messages/SharedetailMessage'
import ShareLocationMessage from './Messages/ShareLocationMessage'
import ShareRouteDistanceMessage from './Messages/ShareRouteDistanceMessage';
import OwnerShareLocationMessage from './Messages/OwnerShareLocationMessage';
import ConnectConsentMessage from './Messages/ConnectConsentMessage'

import ArchiveNoticeMessage from './Messages/ArchiveNoticeMessage'
import UnarchiveNoticeMessage from './Messages/UnarchiveNoticeMessage'
import OwnerArchiveNoticeMessage from './Messages/OwnerArchiveNoticeMessage'
import OwnerUnarchiveNoticeMessage from './Messages/OwnerUnarchiveNoticeMessage'

import AvailabilityChangedMessage from './Messages/AvailabilityChangedMessage'
import OwnerAvailabilityChangedMessage from './Messages/OwnerAvailabilityChangedMessage'

import {HaulCheckService, MatchesService, ConversationsService, SharesDetailsService, PMGroupsService} from '../../services'
import {url, earthidealAPIURL} from "../../constants";
import ModalShareDetails from './ModalShareDetails';
import ModalDecline from './ModalDecline'
import ModalConnect from './ModalConnect'
import ModalPreview from './ModalPreview'
import ModalShareSite from './ModalShareSite'
import ModalShareRouteDistance from './ModalShareRouteDistance'

import jwtDecode from 'jwt-decode';
import moment from 'moment';
import Match from  '../Matches/Match';
import ReactTooltip from 'react-tooltip';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import {
  isMobile,
  isBrowser
} from 'react-device-detect'


class Conversations extends Component {
    static  propTypes = {
        idmatchsiteresource: PropTypes.number,
    }

    constructor(props) {
        super(props)
        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        const user = userDecoded ? userDecoded['cognito:username'] : null;
        const name = userDecoded ? userDecoded['name'] : null;
        const mail = userDecoded ? userDecoded['email'] : null;
        const corporation = userDecoded ? userDecoded['custom:company'] : null;
        const phonenumber = userDecoded ? userDecoded['custom:phone'] : null;

        this.state = {
          selectedMatch: null,
          newConversation: {message: ''},
          newShareDetails: {matchsiteresource: props.idmatchsiteresource},
          inputMatch: '',
          modalcontent: '',
          showConversation: false,
          conversation:[],
          results:[],
          originSite:props.originSite,
          previewDetails:[],
          detailsInConversation:[],
          count:0,
          modalIsOpen: false,//Modal
          user, mail,name,
          startDate: new Date(),
          endDate: new Date(),
          infoshared: 0,
          siteshared: 0,
          idsite_resource: 0,
          matchData: [],
          reloadShareDetails: false,
          idpmgroup: null,
          old_idmatchsiteresource: null,
          sendingMessage: false,
          email: mail,
          materialIsArchived: false,
        }
        this.afterOpenModal = this.afterOpenModal.bind(this);//Modal
        this.closeModal = this.closeModal.bind(this);//Modal
      }

      componentWillReceiveProps = async (newProps) => {
        let {idmatchsiteresource} = newProps;
        let {old_idmatchsiteresource} = this.state;
        this.setState({old_idmatchsiteresource: idmatchsiteresource});

        const userJWT = localStorage.getItem('token');
        const userDecoded = jwtDecode(userJWT);
        const user = userDecoded ? userDecoded['cognito:username'] : null;
        const name = userDecoded ? userDecoded['name'] : null;
        const mail = userDecoded ? userDecoded['email'] : null;
        const corporation = userDecoded ? userDecoded['custom:company'] : null;
        const phonenumber = userDecoded ? userDecoded['custom:phone'] : null;

        //Get match data and initialise selectedMatch and newConversation items
        if (idmatchsiteresource != 0 && idmatchsiteresource != old_idmatchsiteresource){

         
          let matchData = await ConversationsService.getMatchData(idmatchsiteresource);
          console.log("matchData:", matchData);
          if (!matchData || matchData.length == 0) return;

          this.emailRecipient = matchData[0].emailRecipient;
          console.log("Found emailRecipient in matchData:", this.emailRecipient);
          matchData[0].emailRecipient = undefined;

          let selectedMatch = {
              site: newProps.originsite,
              match: matchData[0].idmatch,
              matchsiteresource: newProps.idmatchsiteresource,
          }

          let newConversation= {
              site: newProps.originsite,
              thread: 0,
              status: 0,
              date: new Date().toISOString().slice(0, 19).replace('T', ' '),
              message: '',
              origin: user,
              match: matchData[0].idmatch,
              matchsiteresource: newProps.idmatchsiteresource,
              hoursago:0,
              minutesago:0,
              discoverymode: matchData[0].iddiscoverymode
          }

          let newShareDetails = {
              availability: matchData[0].available_date,
              availabilityend: matchData[0].available_end_date,
              resourceid:matchData[0].idresource,
              resource: matchData[0].resource,
              resourcetype: matchData[0].type,
              quantitydetail: 0,
              quantitynoinfo:false,
              description:'',
              descriptionnoinfo:false,
              startdate: moment().format("YYYY-MM-DD"),
              enddate: moment().format("YYYY-MM-DD"),
              stringstartdate: '',
              stringenddate: '',
              timeframestart:'',
              timeframeend:'',
              timeframenoinfo:false,
              trucking:0,
              truckingtype:'',
              materialtipping: 0,
              materialstatus: '',
              perunitload: 0,
              unitload: '',
              cost:0,
              user:user,
              username:name,
              mail:mail,
              corporation:corporation,
              phonenumber:phonenumber,
              sharedetail:0,
              site: newProps.originsite,
              match: matchData[0].idmatch,
              matchsiteresource: matchData[0].idmatchsiteresource,
              sharedetailid:1,
              saveasdefault:false,
              discoverymode: matchData[0].iddiscoverymode,
              thread:0,
              sitename: matchData[0].sitename,
          }

          this.setState({selectedMatch, newConversation, newShareDetails, matchData,
              infoshared: matchData[0].infoshared,
              siteshared: matchData[0].siteshared,
              idsite_resource: matchData[0].idsite_resource
          }, () => {

              let body = document.querySelector("body");
              body.appendChild(document.querySelector("#message-thread"));
             // body.appendChild(document.querySelector("#details-form-"+idmatchsiteresource+"-conversations"));
              body.appendChild(document.querySelector("#business-card-"+idmatchsiteresource));
              body.appendChild(document.querySelector("#route-distance-"+idmatchsiteresource));
              body.appendChild(document.querySelector("#share-site-"+idmatchsiteresource));
              body.appendChild(document.querySelector("#decline-"+idmatchsiteresource));


              //load conversation data
              fetch(`${url.backend}/conversations/getallmessages/${newConversation.match}`)
              .then(res => res.json())
              .then(results => {
              /*Get thread*/
              fetch(`${url.backend}/conversations/maxconversationid/${newConversation.match}`)
                  .then(res => res.json())
                  .then(threads => {
                    const {maxConversation, infoshared, siteshared} = threads
                    this.setState({
                      newConversation: {
                        ...this.state.newConversation,
                        thread: maxConversation
                      },
                      newShareDetails: {
                        ...this.state.newShareDetails,
                        thread: maxConversation
                      },
                      infoshared, siteshared
                    }, () => {console.log("Set siteshared to " + siteshared)})
                    
                  })
              const {messagesList} = results
              this.setState({
                    ...this.state,
                    showConversation: !this.state.showConversation,
                    results: messagesList
                  }, () => {
                    this.scrollToBeginning();
                    this.materialIsArchived();
                  })
              })
              this.doIntervalChanges()
          });
        }

  }

  scrollToBeginning = () => {
    let messageContainer = document.getElementById('message-container');
    console.log("messageContainer:", messageContainer);
    messageContainer.scrollTop = messageContainer.scrollHeight;
  }

  /*openModalDetails = () =>{
    this.getDetail(() => {})

  }*/

  openModalDecline = () =>{
    document.getElementById("open-modal-decline").click()
  }
  openModalConnect = () =>{
    document.getElementById("open-modal-connect").click()
  }
  openModalShareSite = () =>{
    document.getElementById("open-modal-share-site").click()
  }
  openModalShareRouteDetails = () => {
    document.getElementById("open-modal-share-route-distance").click()
  }
 
  //guarda preview
  handleModalPost = (data) => {

    /*if (data.sendDetailsToAllExternalMatches == true){ //Depreciated
      this.closeModal();
      this.reloadConversation();
    } else { */
      data.matchsiteresource = this.state.newShareDetails.matchsiteresource;
      data.match = this.state.newShareDetails.match;
      data.thread = this.state.newShareDetails.thread;
      data.startdate = moment(data.startdate).add('days', 1).format("YYYY-MM-DD");
      data.enddate = moment(data.enddate).add('days', 1).format("YYYY-MM-DD");
      this.setState({sendingMessage: true});

      this.postSharedInformation(data).then(() => {
        this.closeModal();
        this.reloadConversation();
      })
      //console.log(this.state.newShareDetails)
    //}
  }
  handleModalThanks = () => {
    this.setState({sendingMessage: true});
    this.postThanks(this.state.newShareDetails).then(() => {
      this.closeModal();
      this.reloadConversation();
    })
  }
  handleModalConnect = () => {
     this.setState({sendingMessage: true});
    this.postConnect(this.state.newShareDetails).then((res) => {
      this.setState({infoshared: res.infoshared});
      this.closeModal();
      this.reloadConversation(true);
    })
  }
  handleModalShareSite = () => {
     this.setState({sendingMessage: true});
    this.postSiteShared(this.state.newShareDetails).then((res) => {
      this.setState({infoshared: res.sharedMutually});
      this.closeModal();
      this.reloadConversation();
    }).catch((e) => console.log(e));
  }
  handleModalShareRouteDistance = () => {
     this.setState({sendingMessage: true});
    this.postShareRouteDistance(this.state.newShareDetails).then((res) => {
      this.closeModal();
      this.reloadConversation();
      this.setState({infoshared: 1});
    }).catch((e) => console.log(e));
  }


  postSharedInformation= async (detail) => {
    detail.name = this.state.name;
    detail.idpmgroup = this.state.idpmgroup;
    detail.emailRecipient = this.emailRecipient;
    await SharesDetailsService.addNewShareDetail(detail).then(() => {})
  }
  postThanks= async (detail) => {
    detail.name = this.state.name;
    detail.idpmgroup = this.state.idpmgroup;
    detail.emailRecipient = this.emailRecipient;
    await SharesDetailsService.postThanksCard(detail).then()
  }
  postConnect= async (detail) => {
    detail.name = this.state.name;
    detail.idpmgroup = this.state.idpmgroup;
    detail.emailRecipient = this.emailRecipient;
    detail.myEmail = this.state.email;
    return await SharesDetailsService.postConnectCard(detail).then()
  }
  postSiteShared= async (detail) => {
    detail.name = this.state.name;
    detail.idpmgroup = this.state.idpmgroup;
    detail.emailRecipient = this.emailRecipient;
    return await SharesDetailsService.postSiteShared(detail).then()
  }
  postShareRouteDistance = async (detail) => {
    detail.name = this.state.name;
    detail.idpmgroup = this.state.idpmgroup;
    detail.emailRecipient = this.emailRecipient;
    return await SharesDetailsService.postShareRouteDistance(detail).then()
  }
  openModalPreview = () =>{
    this.setState({modalcontent : 'PREVIEW',modalIsOpen: true })
  }
  openModalBackDetail = () =>{
    this.setState({modalcontent : 'DETAILS',modalIsOpen: true })
  }


  afterOpenModal() {
    // references are now sync'd and can be accessed.
    //this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({modalIsOpen: false})
  }

  
  doIntervalChanges(){
    this.intervalConversationLoad = setInterval(() => {
      fetch(`${url.backend}/conversations/getallmessages/${this.state.newConversation.match}`)
          .then(res =>res.json())
          .then(results => {
            /*Get thread*/
            fetch(`${url.backend}/conversations/maxconversationid/${this.state.newConversation.match}`)
                .then(res =>res.json())
                .then(threads => {
                  const  { maxConversation, infoshared, siteshared } = threads
                  this.setState({
                    newConversation: {
                      ...this.state.newConversation,
                      thread:maxConversation
                    },
                    newShareDetails: {
                      ...this.state.newShareDetails,
                      thread: maxConversation
                    },
                    infoshared, siteshared
                  }, () => {console.log("Set infoshared to " + infoshared)})
                })
            let oldMessages = this.state.results;
            const  { messagesList } = results
            this.setState({
              ...this.state,
              results: messagesList
            }, () => {
              if (oldMessages.length != messagesList.length){
                this.scrollToBeginning();
              }
              this.materialIsArchived();
            })
          })
      //ConversationsService.updateMessagesStatus(this.state.newConversation).then(() => {})
    }, 60000)

  }
  reloadConversation(runMatchingAlgorithm){
    fetch(`${url.backend}/conversations/getallmessages/${this.state.newConversation.match}`)
        .then(res =>res.json())
        .then(results => {
          /*Get thread*/
          fetch(`${url.backend}/conversations/maxconversationid/${this.state.newConversation.match}`)
              .then(res =>res.json())
              .then(threads => {
                const  { maxConversation, infoshared, siteshared } = threads
                this.setState({
                  newConversation: {
                    ...this.state.newConversation,
                    thread:maxConversation
                  },
                  /*newShareDetails: {
                    ...this.state.newShareDetails,
                    thread: maxConversation
                  }*/
                   infoshared, siteshared
                  }, () => {console.log("Set infoshared to " + infoshared)})
                })
             
          const  { messagesList } = results
          this.setState({
            results: messagesList,
            sendingMessage: false
          }, async () => {
            this.scrollToBeginning();
            if (runMatchingAlgorithm){
              await HaulCheckService.getMatches(this.state.matchData[0].idsite);
            }
            this.props.updateMatches();
          })

        })
  }

  componentWillMount = async () => {
    let group = await PMGroupsService.getPmGroupByEmail(this.state.mail, "conversations/componentWillMount");
    this.setState({idpmgroup: (group && group.idpmgroup ? group.idpmgroup : null)})
  }

  componentWillUnmount() {
    clearInterval(this.intervalConversationLoad)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({
      sendingMessage: true,
      newConversation: {
        ...this.state.newConversation,
         name: this.state.name,
        idpmgroup: this.state.idpmgroup,
        date: new Date().toISOString().slice(0, 19).replace('T', ' '),
      }
    }, () => {
      if (this.validateIsReady) {
        let {newConversation} = this.state;
        newConversation.emailRecipient = this.emailRecipient;
        ConversationsService.addNewConversation(newConversation).then(() => {
          this.reloadConversation()
          this.setState({
            newConversation: {
              ...this.state.newConversation,
              message:'',
            },
            sendingMessage: false
          })
        })
      }
    })
  }

    handleChange = (e) => {
        this.setState({
          ...this.state,
          newConversation: {
            ...this.state.newConversation,
            message: e.target.value
          }
        })
    }

    validateIsReady = () => {
        const { message} = this.state.newMessage
        if ( message !== '') {
            return true
        } else {
            return false
        }
    }

    materialIsArchived = () => {
      let {results} = this.state;

      let materialIsArchived = false;
      
      if (results.length == 0) materialIsArchived = false;
      else if (results[results.length-1].typemesssage == "archiveNotice") materialIsArchived = true;
      else materialIsArchived = false;

      this.setState({materialIsArchived});
    }


    render = () => {
        let {idmatchsiteresource, groupsListMode} = this.props;
        let {matchData, sendingMessage, materialIsArchived} = this.state

        let buttonsDisabled = materialIsArchived || sendingMessage;

        let resourceName = "";
        let sitename = "";
        let idsite_resource = 0;

        //let isMobile = true; //For debugging
        //let isBrowser = false; //For debugging
        let resourceBackName = null;

        if (matchData.length > 0){
         resourceName = matchData[0].resource + " " + matchData[0].type.split('/')[0];
         resourceBackName = matchData[0].resource + " " + (matchData[0].type == "Surplus/Export" ? "Shortage" : "Surplus")
         sitename= matchData[0].sitename;
         idsite_resource= matchData[0].idsite_resource;
        }

        const chatToolsPopover = (
          <Popover>
            <Popover.Header as="h3">Chat Tools</Popover.Header>
            <Popover.Body>
              Use these tools to share and swap information with your match. The data unlocked with these tools will display in your list of matches to make it easier to compare with others.
            </Popover.Body>
          </Popover>
        )

         const nameInfoPopover = (
          <Popover>
            <Popover.Header as="h3">Who can see my name when I send messages?</Popover.Header>
            <Popover.Body>
              Your name will not appear to your match unless you use the Swap Contact Info Chat Tool and your match agrees to swap. Otherwise, only you and people in your office group can see each others' names when chatting.
            </Popover.Body>
          </Popover>
        )

        return(<Fragment>
          <div className="modal fade" id="message-thread" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-long modal-wide my-0" role="document">
              <div className="modal-content">

                <div class="modal-header bg-white px-4 pt-2 pb-0">
                  <p class="modal-title strong" id="exampleModalLabel">Send a message to your match...</p>
                  <button type="button" class="close pt-5" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body pt-4 pb-0">
                  <div id="thread-head">

                    {matchData.length > 0 ? 
                      
                         <Match
                            date={matchData[0].creation_date}
                            idsite={matchData[0].idsite}
                            idsite_resource={matchData[0].idsite_resource}
                            idmatchsiteresource={matchData[0].idmatchsiteresource}
                            originSite={this.state.originSite}
                            idmatch={matchData[0].idmatch}
                            date={matchData[0].date}
                            distance={matchData[0].distance}
                            quantity={matchData[0].quantity}
                            statusMatch={matchData[0].status}
                            message={matchData[0].message}
                            resource={matchData[0].idresource}
                            resourceName={matchData[0].resource + " " + (matchData[0].type.split('/')[0])}
                            wasread={matchData[0].wasread}
                            nameresource={matchData[0].resource}
                            typeresource={matchData[0].type}
                            discoverymode={matchData[0].iddiscoverymode}
                            infoshared={matchData[0].infoshared}
                            sitename={sitename}
                            siteshared = {matchData[0].siteshared}
                            partnerarchived = {matchData[0].partner_archived}
                            labelInfo = {matchData[0].labelInfo}
                            isLabeled = {matchData[0].labeled_partner}
                            sharedDetails = {matchData[0].sharedDetails}
                            sharedRoute = {matchData[0].sharedRoute}
                            origins={[{lng: matchData[0].longitud, lat: matchData[0].latitud}]}
                            destinations={[{lng: matchData[0].longitud_dest, lat: matchData[0].latitud_dest}]}
                            switchSidePanel={this.props.switchSidePanel}
                            groupsListMode={this.props.groupsListMode}
                            availableDate={matchData[0].available_date}
                            availableEndDate={matchData[0].available_end_date}
                            parentAvailableDate={matchData[0].parent_available_date}
                            parentAvailableEndDate={matchData[0].parent_available_end_date}
                            everInteractedWith={matchData[0].ever_interacted_with}
                            updateMatches={this.props.updateMatches}
                            origin="conversations"
                            routeData={matchData[0].routeData}
                            isMobile={isMobile}
                            isBrowser={isBrowser}
                            isBlasted={matchData[0].partner_isBlasted || matchData[0].msrIsBlasted}
                        /> 
                      : null}
                    </div>

                  <div className={"section py-0 bg-gray " + (materialIsArchived ? "messages-scroll-archived" : "messages-scroll")} id="message-container">
                      <div className="container px-0 clearfix" style={{minHeight: '200px'}}>
                          {this.renderMessageList()}
                      </div>
                  </div>
                  </div>

                

                 <div class="modal-footer bg-light">
                  <div class="container p-0 pt-2 pl-1 pb-2">

                    <form class="input-border clearfix">
                        <div class="input-border col-12 float-right px-0">
                          {materialIsArchived ?
                              <div className="col-10 float-left px-0">
                                <em>Messaging has been disabled by the user who manages this match</em>
                              </div>
                            : <>
                            <div class="form-group col-10 float-left px-0" style={{marginBottom: "0.5rem"}}>

                               <textarea className="form-control fs-14" placeholder="Type a message..." rows="2"
                                  id='message'
                                  name='userMessage'
                                  onChange={e => this.handleChange(e)}
                                  value={this.state.newConversation.message}
                                  ref={inputElement => this.inputMessage = inputElement }
                                  className="form-control fs-14" placeholder="Type a message..." rows="2"
                                  disabled={buttonsDisabled}>
                              </textarea>
                              {/*<input
                                id='site'
                                type='hidden'
                                name='idsite_resource'
                                ref={inputElement => this.inputSite = inputElement }
                              />*/}
                              </div>
                            <button class="btn btn-xs btn-round btn-primary float-left ml-3 ml-lg-4" disabled={buttonsDisabled || (this.state.newConversation.message === "")} onClick={this.handleSubmit}>Send{sendingMessage ? <img src="../../img/app/photoswipe/preloader.gif" /> : <i class="fa fa-paper-plane fs-14 fw-200 pl-2" aria-hidden="true"></i> }</button>
                            <OverlayTrigger trigger="hover" placement="right" overlay={nameInfoPopover}>
                              <a class=" fs-16 text-light float-right cursor ml-1">
                                <i class="fa fa-info-circle" aria-hidden="true"></i>
                              </a>
                              </OverlayTrigger>
                            </>}
                          </div> 
                      </form>

                      <OverlayTrigger trigger="hover" placement="right" overlay={chatToolsPopover}>
                        <a class=" fs-16 text-light float-right cursor ml-1" >
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </a>
                      </OverlayTrigger>

                      <div class="row pl-3">
                        <label class="small-1"><i class="fa fa-briefcase mr-1"></i>Chat Tools</label>
                        <div class="col-12 float-left px-0 mb-4">

                        {!(matchData && matchData.length && (matchData[0].isBlasted || matchData[0].partner_msrIsBlasted)) ?
                          <button className={"btn btn-xs btn-round btn-info m-1 fs-11 " + (buttonsDisabled ? "disabled" : "cursor")} type="button" data-toggle="modal" data-target={(buttonsDisabled ? "" : "#card-details-"+idsite_resource+"-conversations")} onClick={() => {this.setState({reloadShareDetails: !this.state.reloadShareDetails})}} data-tip="Share more info">Share Details</button>
                        : null}
                          <button className={"btn btn-xs btn-round btn-info m-1 fs-11 " + (buttonsDisabled ? "disabled" : "cursor")} type="button" data-toggle="modal" data-target={(buttonsDisabled ? "" : "#route-distance-"+idmatchsiteresource)} id="open-modal-share-route-distance" data-tip="Send distance info">Share Route Distance</button>
                          <br />
                          <button className={"btn btn-xs btn-round btn-outline-info m-1 mt-4 fs-11 " + (buttonsDisabled ? "disabled" : "cursor")} type="button" data-toggle="modal" data-target={(buttonsDisabled ? "" : "#business-card-"+idmatchsiteresource)} id="open-modal-connect" data-tip="Swap with mutual consent">Swap Contact Info</button>
                          <button className={"btn btn-xs btn-round btn-outline-info m-1 mt-4 fs-11 " + (buttonsDisabled ? "disabled" : "cursor")} type="button" data-toggle="modal" data-target={(buttonsDisabled ? "" : "#share-site-"+idmatchsiteresource)} id="open-modal-share-site" data-tip="Swap with mutual consent">Swap Site Location</button>
                          <button className={"btn btn-xs btn-round btn-secondary m-1 mt-4 mr-4 fs-11" + (buttonsDisabled ? "disabled" : "cursor")} type="button" data-toggle="modal" data-target={(buttonsDisabled ? "" : "#decline-"+idmatchsiteresource)} id="open-modal-decline" data-tip="Decline">Respectfully Decline</button>
                          {buttonsDisabled ? null : <ReactTooltip />}

                        </div>
                      </div>

                     
                      <small class="float-left">Learn more by clicking each button. You will have the ability to cancel before info is sent.</small>

                    </div>
                </div>


                  </div>
                </div>
              </div>

            <ModalShareDetails
              idsite_resource={(matchData.length > 0 ? matchData[0].idsite_resource : 0)}
              idpmgroup={this.state.idpmgroup}
              parent="conversations"
              callback={this.handleModalPost}
              forceReload={this.state.reloadShareDetails}
            />
            <ModalDecline
              handleModalThanks={this.handleModalThanks}
              idmatchsiteresource = {this.state.newShareDetails.matchsiteresource}
            />
            <ModalConnect
              match={this.state.newShareDetails}
              handleModalConnect={this.handleModalConnect}
              idmatchsiteresource = {this.state.newShareDetails.matchsiteresource}
            />
            <ModalShareSite
              handleModalShareSite = {this.handleModalShareSite}
              idmatchsiteresource = {this.state.newShareDetails.matchsiteresource}

            />
            <ModalShareRouteDistance
              handleModalShareRouteDistance = {this.handleModalShareRouteDistance}
              idmatchsiteresource = {this.state.newShareDetails.matchsiteresource}
            />

            <button type="button" style={{display:'none'}} id="open-message-thread" data-toggle="modal" data-target="#message-thread"></button>
        </Fragment>)
    }

    messageIsOnLeft = (originuser, origin, originidpmgroup) => {
      console.log("messageIsOnLeft Params: originuser", originuser, "origin:", origin, "originidpmgroup:", originidpmgroup);

      let {idpmgroup} = this.state; 
      if (originidpmgroup == null || idpmgroup == null || originidpmgroup == undefined || idpmgroup == undefined){
        return (originuser === origin)
      } else {
        return (originidpmgroup == idpmgroup)
      }
    }

    displayOriginNameRight = (originidpmgroup) => {
      let {idpmgroup} = this.state;
      return (originidpmgroup == idpmgroup) && (!!idpmgroup)
    }

    displayOriginNameLeft = (originidpmgroup) => {
      //let {idpmgroup} = this.state;
      //return (originidpmgroup == idpmgroup) && (!!idpmgroup)
      return false;
    }

    renderMessageList = () => {
        let  { results, user, infoshared, siteshared, idpmgroup } = this.state
        infoshared = parseInt(infoshared);
        siteshared = parseInt(siteshared);
        let {originsite} = this.props;
        let originuser = this.state.user;
        let contentmessage;
        //let isMobile = true; //For debugging
        //let isBrowser = false; //For debugging

        return results.map((message, index, messages) => {
            message.idsitebd = parseInt(message.idsitebd);
            message.type = (message.type.split('/'))[0];

            /*console.log("debug Messages map message at index "+index+":", message.typemesssage);
            console.log("debug message origin", message.origin);
            console.log("debug originuser", originuser);
            console.log("debug infoshared:", infoshared);
            console.log("debug siteshared:", siteshared)*/


            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='message' ) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerMessage
                            idconversation={message.idconversation}
                            idsitebd={message.idsitebd}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }
            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='message') {
                contentmessage =
                    <Messages
                        idsitebd={message.idsitebd}
                        idconversation={message.idconversation}
                        textmessage={message.message}
                        resource={message.resource}
                        type={message.type}
                        messagedate={message.messagedate}
                        hours={message.hoursago}
                        minutes={message.minutesago}
                        originusermessage={message.origin}
                        username={user}
                        originname={message.origin_name}
                       displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}

                    />
            }
            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='decline') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <DeclineMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            originname={message.origin_name}
                           displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }
            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='decline') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerDeclineMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }
            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='archiveNotice') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <ArchiveNoticeMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            originname={message.origin_name}
                           displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }
            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='archiveNotice') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerArchiveNoticeMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }
            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='unarchiveNotice') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <UnarchiveNoticeMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            originname={message.origin_name}
                           displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }
            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='unarchiveNotice') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerUnarchiveNoticeMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }
            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='availabilityChanged') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <AvailabilityChangedMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            originname={message.origin_name}
                           displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }
            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='availabilityChanged') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerAvailabilityChangedMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }
            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && (
                (message.typemesssage==='connect') || (message.typemesssage==='connectconsent' && infoshared===1))) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerConnectMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            name={message.name_connect}
                            mail={message.mail_connect}
                            inc={message.inc_connect}
                            phonenumber={message.phone_number_connect}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && ((message.typemesssage==='connect') || (message.typemesssage==='connectconsent' && infoshared===1))) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <ConnectMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            name={message.name_connect}
                            mail={message.mail_connect}
                            inc={message.inc_connect}
                            phonenumber={message.phone_number_connect}
                            originname={message.origin_name}
                           displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='sharedetail' ) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <SharedetailMessage
                            originalQuantity={this.state.matchData[0].quantity}
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            name={message.name_connect}
                            mail={message.mail_connect}
                            inc={message.inc_connect}
                            phonenumber={message.phone_number_connect}
                            quantitydetail={message.quantity}
                            quantitynoinfodetail={message.quantitynoinfo}
                            descriptiondetail={message.description}
                            descriptionnoinfodetail={message.descriptionnoinfo}
                            startdatedetail={message.timeframestart}
                            enddatedetail={message.timeframeend}
                            timeframenoinfodetail={message.timeframenoinfo}
                            truckingdetail={message.trucking}
                            truckingdescriptiondetail={message.truckingdescription}
                            materialtippingdetail={message.materialtipping}
                            materialdescriptiondetail={message.materialdescription}
                            unitloaddetail={message.unitload}
                            costdetail={message.cost}
                            availability={message.availability}
                            availabilityend={message.available_end_date}
                            originname={message.origin_name}
                           displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='sharedetail') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerShareDetailMessage
                            originalQuantity={this.state.matchData[0].quantity_origin}
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            name={message.name_connect}
                            mail={message.mail_connect}
                            inc={message.inc_connect}
                            phonenumber={message.phone_number_connect}
                            quantitydetail={message.quantity}
                            quantitynoinfodetail={message.quantitynoinfo}
                            descriptiondetail={message.description}
                            descriptionnoinfodetail={message.descriptionnoinfo}
                            startdatedetail={message.timeframestart}
                            enddatedetail={message.timeframeend}
                            timeframenoinfodetail={message.timeframenoinfo}
                            truckingdetail={message.trucking}
                            truckingdescriptiondetail={message.truckingdescription}
                            materialtippingdetail={message.materialtipping}
                            materialdescriptiondetail={message.materialdescription}
                            unitloaddetail={message.unitload}
                            costdetail={message.cost}
                            availability={message.availability}
                            availabilityend={message.available_end_date}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='connectconsent' && infoshared===0) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerConnectConsentMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='connectconsent' && infoshared===0) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <ConnectConsentMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            resource={message.resource}
                            type={message.type}
                            openModalConnect={this.openModalConnect}
                            originname={message.origin_name}
                           displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='sharelocationconsent' && siteshared===0 ) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerShareLocationConsentMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='sharelocationconsent' && siteshared===0) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <ShareLocationConsentMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            resource={message.resource}
                            type={message.type}
                            openModalShareSite={this.openModalShareSite}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

             if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='shareroutedistance') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerShareRouteDistanceMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && message.typemesssage==='shareroutedistance') {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <ShareRouteDistanceMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            resource={message.resource}
                            type={message.type}
                            originname={message.origin_name}
                           displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(!this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && (message.typemesssage==='sharelocation' || (message.typemesssage==='sharelocationconsent' && siteshared===1) )) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <ShareLocationMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            name={message.name_connect}
                            mail={message.mail_connect}
                            inc={message.inc_connect}
                            phonenumber={message.phone_number_connect}
                            quantitydetail={message.quantity}
                            quantitynoinfodetail={message.quantitynoinfo}
                            descriptiondetail={message.description}
                            descriptionnoinfodetail={message.descriptionnoinfo}
                            startdatedetail={message.timeframestart}
                            enddatedetail={message.timeframeend}
                            timeframenoinfodetail={message.timeframenoinfo}
                            truckingdetail={message.trucking}
                            truckingdescriptiondetail={message.truckingdescription}
                            materialtippingdetail={message.materialtipping}
                            materialdescriptiondetail={message.materialdescription}
                            unitloaddetail={message.unitload}
                            costdetail={message.cost}
                            availability={message.availability}
                            originname={message.origin_name}
                           displayoriginname={this.displayOriginNameRight(message.origin_idpmgroup)}
                        />
                    </Fragment>
            }

            if(this.messageIsOnLeft(originuser, message.origin, message.origin_idpmgroup) && (message.typemesssage==='sharelocation' || (message.typemesssage==='sharelocationconsent' && siteshared===1)) ) {
                contentmessage =
                    <Fragment key={message.idconversation} >
                        <OwnerShareLocationMessage
                            idsitebd={message.idsitebd}
                            idconversation={message.idconversation}
                            textmessage={message.message}
                            resource={message.resource}
                            type={message.type}
                            messagedate={message.messagedate}
                            hours={message.hoursago}
                            minutes={message.minutesago}
                            originusermessage={message.origin}
                            username={user}
                            name={message.name_connect}
                            mail={message.mail_connect}
                            inc={message.inc_connect}
                            phonenumber={message.phone_number_connect}
                            quantitydetail={message.quantity}
                            quantitynoinfodetail={message.quantitynoinfo}
                            descriptiondetail={message.description}
                            descriptionnoinfodetail={message.descriptionnoinfo}
                            startdatedetail={message.timeframestart}
                            enddatedetail={message.timeframeend}
                            timeframenoinfodetail={message.timeframenoinfo}
                            truckingdetail={message.trucking}
                            truckingdescriptiondetail={message.truckingdescription}
                            materialtippingdetail={message.materialtipping}
                            materialdescriptiondetail={message.materialdescription}
                            unitloaddetail={message.unitload}
                            costdetail={message.cost}
                            originname={message.origin_name}
                            displayoriginname={this.displayOriginNameLeft(message.origin_idpmgroup)}
                            availability={message.availability}
                        />
                    </Fragment>
            }

            return (
                <Fragment>
                    {index == 0 ? 
                      <div className="divider small-4 strong text-light pt-1 mt-0 pb-4 mb-0">Beginning of Messages</div>
                    : 
                      <div className="clearfix"></div>
                    }
                    {contentmessage}
                    {index == messages.length - 1 ? 
                      <div className="clearfix"></div>
                    :
                      null
                    }
                </Fragment>
            )
        })
    }

}

export default Conversations
