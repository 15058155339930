import React, {Fragment} from 'react';

export default function NoSiteAlert({markAsSeen}){


	return (
		<div class="col-12 alert alert-light border-primary alert-dismissible fade show px-4 pt-2 pb-2 mx-auto mt-4" role="alert">
          <div class="col-12 mr-auto alert-light p-1">
            <p class="mb-0"><i class="fa fa-map-marker text-danger fw-300 mr-2"></i>You do not have any sites yet.</p>
          </div>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close" onClick={markAsSeen}>
            <span aria-hidden="true">&times;</span>
          </button>
    </div>

	)
}