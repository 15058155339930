import React, {Fragment, useState, useEffect} from 'react';
import TableData from './DisplayComponents/TableData'
import TableRow from './DisplayComponents/TableRow'
import _ from 'lodash'
import moment from 'moment'
import CheckoutInterface from '../Checkout/CheckoutInterface';

import {
	PublishSubscribe,
	HaulCheckService,
	userService
} from '../../services'
import {
	addChildToArray, 
	arrayParseInt,
	removeChildFromArray, 
	updateObjectBasedOnKey, 
	usePrevious, 
	//hcGetIconForSiteOrMatch
} from '../../utils'

export default function MaterialTableRow({passedMaterial, updateMaterial, removeSelf, isMobileOnly, hasExternalAccess, isMobile, isBrowser}){

	const initializeArray = (arg) => {
		console.log("initializeArray", "=========================================")
		console.log("initializeArray arg:", arg, "typeof arg:", typeof arg)

		if (Array.isArray(arg)){
			console.log("initializeArray arg is array")
			return arrayParseInt(_.cloneDeep(arg));
		} else if (typeof arg == "string"){
			if (arg.indexOf(',') == -1){
				console.log("initializeArray arg is string without comma")
				return arrayParseInt([arg]);
			} 
			else {
				console.log("initializeArray arg is string WITH comma")
				return arrayParseInt(arg.split(','));
			}
		} else {
			console.log("initializeArray arg is invalid")
			return [];
		}

	}

	let [material, setMaterial] = useState(_.cloneDeep(passedMaterial));
	let [allThreads, updateAllThreads] = useState(initializeArray(passedMaterial.allMessageThreads))
	let [unreads, updateUnreads] = useState(initializeArray(passedMaterial.unreadExternalsCountNoArchive));
	let [sentThreads, updateSentThreads] = useState(initializeArray(passedMaterial.sentThread));

	useEffect(() => {
		console.log("Material.status updated to " + material.status);
		if (material.status == "deleted"){
			removeSelf(material.idsite_resource)
		}

	}, [material.status])


	let PS = PublishSubscribe.usePublishSubscribe({
		origin: "MySites/Sidebar/SiteMaterials/"+material.idsite_resource,
    	topic: "HC/materials",
    	identifier: {idsite_resource: material.idsite_resource},
		callback: (message) => {
			console.log("SiteMaterials handleMessage message:", message);
			message = JSON.parse(message);

			console.log("SiteMaterials handleMessage message:", message);

			//Update stats of materials where needed
		  if (message.type == "updated-material"){
				//let clone_material = _.cloneDeep(material);
				setMaterial((previousState) => updateObjectBasedOnKey(previousState, message.data));	
			} else if (message.type == "new-messages"){
				updateAllThreads(previousState => addChildToArray(previousState, message.data, true))
				updateUnreads((previousState) => addChildToArray(previousState, message.data, true));
			} else if (message.type == "read-message"){
				updateAllThreads(previousState => addChildToArray(previousState, message.data, true))
				updateUnreads(previousState => removeChildFromArray(previousState, message.data));
			} else if (message.type == "sent-messages"){
				updateAllThreads(previousState => addChildToArray(previousState, message.data, true))
				updateSentThreads(previousState => addChildToArray(previousState, message.data, true))
			}
		}
	})


	let renderMessagesStatus = () => {
		let badge = {
			text: "",
			className: ""
		}

		let noMessages = {
			text: "--",
			className: "light"
		}

		let sent = {
			text: "Sent",
			className: "secondary"
		}

		let read = {
			text: "Read",
			className: ""
		}

		let unread = {
			text: <strong>Unread</strong>,
			className: "primary"
		}

		if (allThreads.length == 0){
			badge = noMessages;
		} else if (allThreads.length == 1){
			if (unreads.length == 1){
				badge = unread;
			} else if (sentThreads.length == 1){
				badge = sent;
			} else {
				badge = read;
			}
		} else {
			if (unreads.length > 0) {
				badge = unread;
			} else {
				badge = sent;
			}
		}

		return <span className={"badge badge-"+badge.className}>{badge.text}</span>

	}

	let renderStatusDropdown = () => {

		let linkText = "";
		let dropdownClass = ""

		if (material.archived == 1){
			//linkText = "Archived"
			//dropdownClass = "info"
			return <div className="badge badge-pill badge-pale badge-info">Archived</div>
		} else if (material.status !== "external") {
			if (material.has_ever_been_external == 1){
				linkText = "Expired"
				dropdownClass = "warning"
			} else {
				linkText = "Not Yet"
				dropdownClass = "secondary"
			}
		} else if (material.iddiscoverymode == 1) {
			linkText = "Searching"
			dropdownClass = "danger"
		} else if (material.iddiscoverymode == 2 && !material.isBlasted){
			linkText = "Posted"
			dropdownClass = "primary"
		} else if (material.iddiscoverymode == 2 && material.isBlasted){
			linkText = "SuperBlast"
			dropdownClass = "success"
		}

		return <div class="dropdown">
			<div class={"dropdown-toggle cursor badge badge-pill badge-pale badge-" + dropdownClass} 
				data-toggle="dropdown" 
				aria-expanded="false"
			>
				{linkText}
			</div>

			<div class="dropdown-menu">
				{material.status == "external" ? 
					<a className="dropdown-item" href="#" onClick={() => updateMaterial("goInternal", material)}>Remove Post</a>
				:
					<CheckoutInterface
						material={material}
						render = {(goToReferral, goToPost, goToBlast, goToBlastReferral, state, setState) => {
							if (material.iddiscoverymode == 2) return <>
					            <a class="dropdown-item" href="#" onClick={goToPost}>Post</a>
					            <a class="dropdown-item" href="#" onClick={goToReferral}>Post & Tell Others</a>
					            <a class="dropdown-item" href="#" onClick={goToBlast}>SuperBlast Post</a>
					            <a class="dropdown-item" href="#" onClick={goToBlastReferral}>SuperBlast & Tell Others</a>
					        </>
				            else if (material.iddiscoverymode == 1) return <>
				            	<a 
								className="dropdown-item" 
								href="#" 
								onClick={goToPost}>
									{material.iddiscoverymode == 1 ? "Search" : "Post"} Externally
								</a>
				            </>
						}}
					/>
				}

				{/*material.archived == 0 ?
					<a className="dropdown-item" href="#" onClick={() => updateMaterial("archiveMaterial", material)}>Archive</a>
				:
					<a className="dropdown-item" href="#" onClick={() => updateMaterial("unarchiveMaterial", material)}>Remove from Archive</a>
				*/}
			</div>
		</div>
	}

	if (material == null) return null;
	else return <TableRow>

		<TableData
		title="Materials"
		width="1-5"
		>
			{material.resource}
		</TableData>


		<TableData
		title="Imbalance"
		width="1-5"
		>
			{material.type.split('/')[0]}
		</TableData>


		<TableData
		title="Qty OOM"
		width="2"
		className="d-flex justify-content-between"
		>
			{parseInt(material.quantity).toLocaleString() + "s CYs"}
			<span className="cursor" onClick={() => updateMaterial("matchCriteria", material)}>✏️</span>
		</TableData>

		<TableData
		title="General Availability Start Date"
		width="1-5"
		>
			{moment(material.available_date).add('days', 1).startOf('month').format("MM/DD/YY")}
		</TableData>

		<TableData
		title="General Availability End Date"
		width="1-5"
		className="d-flex justify-content-between"
		>
			{material.perennialEndDate == 1 ? "No end date" : moment(material.available_end_date).add('days', 1).endOf('month').format("MM/DD/YY")}
			<span className="cursor ml-1" onClick={() => updateMaterial("matchCriteria", material)}>📅</span>
		</TableData>

		{hasExternalAccess ? <>
			<TableData
			title="Search/Post Status"
			width="1-5"
			>
				{renderStatusDropdown()}
			</TableData>

			<TableData
			title="Expiration Date"
			width="2"
			>
				{material.expiration_date ? 
					material.status == "external" ?
						"Until " + moment(material.expiration_date).format("MMMM D")
					:
						"On " + moment(material.expiration_date).format("MMMM D")
				: "--"}
			</TableData>

			{/*<TableData
				title="Messages"
			>
				{renderMessagesStatus()}
			</TableData>*/}
		</> : 
			<TableData
			title="Archive Status"
			width="2"
			>
				{material.archived == 1 ? "Archived" : "--"}
			</TableData>
		}

		<TableData
			title="Archive Material"
		>
			{material.archived == 1 ?
				<button className="btn btn-link" onClick={() => updateMaterial("unarchiveMaterial", material)}>♻️</button>
			:
				<button className="btn btn-link" onClick={() => updateMaterial("archiveMaterial", material)}>🗄️</button>
			}
		</TableData>


		<TableData
			title="Delete Material"
		>
			<button className="btn btn-link" onClick={() => updateMaterial("delete", material)}>🗑️</button>
		</TableData>


	</TableRow>

}