import React, { useState, useEffect, useLayoutEffect, Fragment } from 'react'

import Navbar from '../Navbar'
import Footer from '../Footer'
import SuccessPopup from '../Popups';
import CCForm from '../CCForm';

import moment from "moment"

import {
  ApiService,
  HaulCheckService,
  PMGroupsService
  //MatchesService,
  //ConversationsService
} from '../../../services'

import {
	hcDisplayPrice
} from '../../../utils';

import {
	url, 
	//earthidealAPIURL
} from '../../../constants';

import CartTable from "../../Checkout/ui/CartTable";
import CartItem from "../../Checkout/ui/CartItem";
import CartTotal from "../../Checkout/ui/CartTotal";
import CartCancelButton from "../../Checkout/ui/CartCancelButton";
import CartCheckoutButton from "../../Checkout/ui/CartCheckoutButton";



export default function GroupCheckout(){

	let [billing_interval, setBillingInterval] = useState(false);
	let [hasValidPaymentInfo, setHasValidPaymentInfo] = useState(false);
	let [submitting, setSubmitting] = useState(false);
	let [successPopup, setSuccessPopup] = useState(false);
	let [newSubscriptionData, setNewSubscriptionData] = useState({
		monthlyPrice: null,
		annualPrice: null,
		endOfTrial: null
	})

	useLayoutEffect(() => {
		getNewSubscriptionData();
	}, [])

	let getNewSubscriptionData = async () => {
		setNewSubscriptionData(await PMGroupsService.getNewSubscriptionData())
	}

	let cancel = () => {
		goBack();
	}

	let goBack = () => {
		window.location.href = url.frontend + "/app/sites/group-sites";
	}

	let submit = async () => {
		setSubmitting(true);

		let newGroupData = {
			billing_interval, subscription_expiration: newSubscriptionData.endOfTrial
		}

		newGroupData.billing_interval = billing_interval;
		newGroupData.subscription_expiration = moment(newSubscriptionData.endOfTrial).format("YYYY-MM-DD");

		await PMGroupsService.setGroupSubscription(newGroupData);

		
		setSubmitting(false);
		setSuccessPopup(true);
	}

	return(<>

		<Navbar />

		<header className="header bg-gray pt-6 pb-4">
			<div className="container">
				<h1 className="display-4">Checkout</h1>
				<p className="lead-2 mt-6">After your free trial, your subscription charges will be applied to your invoice and charged to the credit card on file at the end of the month.</p>
			</div>
		</header>


		<main className="main-content">

			<section className="section">
				<div className="container">

					<div className="row gap-y">
						<div className="col-lg-7">

							<h5>Select your subscription</h5>
							<CartTable>

								<CartItem
								  onClick={() => setBillingInterval("annual")}
								  className={"cursor" + (billing_interval == "annual" ? " alert-primary" : " bg-white")}
									item={"Yearly Subscription"}
									description={`Subscription paid yearly`}
									price={newSubscriptionData.annualPrice}
								/>

								<CartItem
								  onClick={() => setBillingInterval("monthly")}
								  className={"cursor" + (billing_interval == "monthly" ? "  alert-primary" : " bg-white")}
									style={{width: 'auto'}}
									item={"Monthly Subscription"}
									description={`Subscription paid monthly`}
									price={newSubscriptionData.monthlyPrice}
								/>

							</CartTable>


							<small className="mb-6"><i>Your credit card will not be charged until {moment(newSubscriptionData.endOfTrial).format("MM-DD-YYYY")}.</i></small> 


								<hr className="my-8" />

								<h5 className={"mb-6"}>Credit Card</h5>

								<div className="form-row">
									<CCForm 
										returnValidPaymentInfo={({paymentMethod}) => setHasValidPaymentInfo(paymentMethod)} 
									/>									
								</div>

						</div>


						<div className="col-lg-5">
							<CartTotal
								titles={[
									(billing_interval == "monthly" ? "Monthly" : billing_interval == "annual" ? "Annual" : "Select your" ) + " Subscription"]}
								amounts={[
									(billing_interval == "monthly" ? newSubscriptionData.monthlyPrice : billing_interval == "annual" ? newSubscriptionData.annualPrice : ""),
								]}
							/>


							<div className="row">
								<div className="col-6">
									<CartCancelButton onClick={cancel} />
								</div>

								<div className="col-6">
									<CartCheckoutButton
									  onClick={submit}
									  disabled={(!hasValidPaymentInfo) || submitting || (billing_interval !== "monthly" && billing_interval !== "annual")}
										submitting={submitting}
										label={`Accept & Subscribe`}
									/>

								</div>
							</div>

						</div>



					</div>

				</div>
			</section>
		</main>


		<SuccessPopup
        popupId="checkout-complete"
        title="Checkout Complete"
        message="Your group has been upgraded!"
        trigger={successPopup}
        callback={() => {
        	setSuccessPopup(false);
        	goBack();
        }}
        style="success"
    />



		<Footer />

	</>)
}