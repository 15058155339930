import React, {useState, useEffect, Fragment} from 'react';
import {StatesService} from '../../../services';
import _ from 'lodash';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
//https://react-select.com/advanced

const animatedComponents = makeAnimated();

//Might make a higher-order component or use a render prop?
export default function StateSelector({onChange}) {

	const [states, setStates] = useState([])
	const [selected, setSelected] = useState([])


	useEffect(() => {
		getStates();
	}, [])

	useEffect(() => {
		console.log("Change in selected")
		if (onChange && typeof onChange == 'function'){
			if (!_.isEmpty(selected)){
				let val = [];
				for (var i = 0; i < selected.length; i++){
					let {name} = selected[i];
					val.push(name)
				}
				onChange(val)
			} else {
				onChange([])
			}
		}
	}, [selected])

	const getStates = async () => {
		setStates(await StatesService.getAllStates());
	}

	if (_.isEmpty(states)){
		return "Loading..."
	} else {
		return <Select 
	      	getOptionLabel={(state) => `(${state.shortcode}) ${state.name}`}
	        getOptionValue={(state) => `${state.name}`}
			options={selected.length < 4 ? states : []} 
			components={animatedComponents}
			isMulti
			value={selected}
			onChange={setSelected}
			closeMenuOnSelect={false}
			hideSelectedOptions={true}
			placeholder={"Select your states"}
			noOptionsMessage={() => "Maximum of 4 states allowed"}
		/>
	}

}