import React, {useState, useMemo, useEffect, Fragment}  from 'react';
import moment from 'moment';
import LetterAvatar from '../common/LetterAvatar';
import {MatchesService, ConversationsService, SharesDetailsService, PublishSubscribe} from '../../services'
import { updateObjectBasedOnKey,  hcDetailsDisplay, hcGetIconForSiteOrMatch} from '../../utils'
import _ from 'lodash';

export default function MaterialRow({passedMaterial, list, switchSidePanel, groupsListMode, refreshPanel, isMobile, isBrowser, toggleRouteVisible, getRouteExistsAndIsVisible, hasExternalAccess}){

 /* let sites = {
    destinations: ,
    origins: 
  }*/

  console.log("BUG-1046", "MaterialRow rendering with match id " + (passedMaterial.idsite_resource ? passedMaterial.idsite_resource : "[not yet defined]"))


  let [routeDataExists, setRouteDataExists] = useState(false);
  let [routeIsVisible, setRouteIsVisible] = useState(false);
  let [idroutedistance, setidroutedistance] = useState(null);
  let [material, updateMaterial] = useState(_.cloneDeep(passedMaterial));

   let PS = PublishSubscribe.usePublishSubscribe({
    origin: `InHouse/MaterialRow/${passedMaterial.idsite_resource}`,
    topic: "HC/materials",
    identifier: {idsite_resource: passedMaterial.idsite_resource},
    callback: (message) => {
      console.log("Message recieved for internal material " + passedMaterial.idsite_resource, message);
      message = JSON.parse(message);

      //Update stats of materials where needed
      if (message.type == "updated-details"){
        //let clone_material = _.cloneDeep(material);
        updateMaterial((previousState) => {
          let newState = _.cloneDeep(previousState);
          newState.details =  _.merge(previousState, message.data);
          return newState;
        }); 
      } else if (message.type == "updated-material"){
        updateMaterial((previousState) => updateObjectBasedOnKey(previousState, message.data)); 
      } 
    }
  });


  useEffect(() => {
      updateMaterial(_.cloneDeep(passedMaterial))
   }, [passedMaterial])


  useEffect(() => {
    let {routeData} = material;

    let routeExists = (routeData != undefined && routeData.length != undefined && routeData[0].distance != undefined && routeData[0].time != undefined);
    let routeid = (routeExists ? routeData[0].idroutedistance : null);

    let routeInfoFromParent = getRouteExistsAndIsVisible(routeid);

    console.log("results of getRouteExistsAndIsVisible:", routeInfoFromParent);

    console.log("BUG-1046", "calling useMemo", "internalMatchRow 1", passedMaterial.idsite_resource, routeid, routeData, routeExists, routeInfoFromParent);

    console.log("BUG-1187 internal " + material.idsite_resource, 
      "routeInfoFromParent (calculated):", routeInfoFromParent.isVisible, "routeIsVisible (existing state):", routeIsVisible);

    if (routeExists != routeDataExists) setRouteDataExists(routeExists);
    if (routeid != idroutedistance) setidroutedistance(routeid);
    if (routeInfoFromParent.isVisible != routeIsVisible) setRouteIsVisible(routeInfoFromParent.isVisible);

  }, [_.cloneDeep(passedMaterial.routeData), material.idsite_resource]);

  let availableRange = () => {
    if (material.available_date){
        let start =  moment(material.available_date).add('days', 1).startOf("month");

        if (material.perennialEndDate === 0 || material.perennialEndDate === "0"){
          let endDate = (material.available_end_date ? material.available_end_date : material.available_date);
          let end = moment(endDate).add('days', 1).endOf("month");

          return start.format("MMM 'YY") + " to " + end.format("MMM 'YY")
        } else {
          return start.format("MMM 'YY") + " onward"
        }

    } else {
      return ""
    }
  }

  let renderIsSuperBlast = () => {
    let {isBlasted, isSponsoredMaterial} = material;
    
    if (isSponsoredMaterial){
      return <small><span className="badge badge-warning"><em><strong>Sponsored</strong></em></span></small>

    } else if (isBlasted == 1){
      return <small><span className="badge badge-success"><em><strong>SuperBlast</strong></em></span></small>
    }
    else return null;
  }


  let renderBadge = () => {
    let {archived, archived_date, iddiscoverymode, status, has_ever_been_external} = material;


    if (archived && archived_date || list == "bidGraveyardList"){
      return <span className="badge badge-pale badge-secondary">Archived</span>;
     } else if (has_ever_been_external && status == "internal" && iddiscoverymode == 1){
      return <span className="badge badge-pale badge-warning">Search Expired</span>
    } else if (has_ever_been_external && status == "internal" && iddiscoverymode == 2){
      return <span className="badge badge-pale badge-warning">Post Expired</span>
    } else if (iddiscoverymode == 2 && status == "external"){
      //return <span className="badge badge-pale badge-info">Post expires in {expiryDiff()} — {moment(expiration_date).format("M/D/YY")}</span>
      return <span className="badge badge-pale badge-primary">Posted</span>
    } else if (iddiscoverymode == 1 && status == "external"){
      return <span className="badge badge-pale badge-primary">Bid Search</span>
      } else if (iddiscoverymode == 1 && status == "internal"){
      return <span className="badge badge-pale badge-secondary">Bid</span>
    } else {
      return <span className="badge badge-pale badge-secondary">Not Posted</span> 
    }
  }

  let renderQuantity = () => {
    let {details, quantity, idquantity} = material;
    let q = quantity;

    if (!quantity || isNaN(parseInt(quantity))){
      q = parseInt(10 + ("0".repeat(idquantity)))
    }

    let displayS = true;

    if (details && details.length > 0 && details[0].quantity){
      q = details[0].quantity;
      displayS = false;
    }

    return (parseInt(q).toLocaleString()) + (displayS ? "s " : " ") + "CYs"
  }


  let renderTypeAndQuantity = () => {
    let {type} = material;

    if (type == "Surplus/Export"){
      type = "Surplus"
    } else {
      type = "Short"
    }

    return <>
      {list != "sameList" ?
        <i className="ti ti-check text-success strong mr-1"></i>
        :
        <i className="ti ti-close text-danger strong mr-1"></i>
      }
      {type} {renderQuantity()}
    </>
  }

  let renderRouteDistance = () => {
    let {routeData} = material;

    if (routeDataExists && routeData[0].distance != 0){
      return Math.ceil(routeData[0].distance) + "mi"
    } else {
      return ""
    }
  }

  let renderRouteTime = () => {
    let {routeData} = material;

    if (routeDataExists && routeData[0].time != 0){
      let toReturn = routeData[0].time + " min";

      if (isMobile){
        return toReturn
      } else /* Not mobile */  {

        if (list == "internalsBid" || list == "internalsPost"){
          return <label className="fs-16">{toReturn}</label>
        } else /* Bid Graveyard / Similar Imbalances */ {
          return <label className="fw-400 text-dark">{toReturn}</label>
        }

      }

    } else { /* Route Does Not Exist */
      return ""
    }
  }

  let renderRouteToggler = () => {
    if (list != "sameList"){
       let {routeData} = material;
       let isSameLocation = (routeData[0].time == 0 && routeData[0].distance == 0)

      if (!routeDataExists || !idroutedistance || isSameLocation){
          return <span className="small">No route found{isSameLocation ? "; source and dump are the same location" : "; travel by truck may not be possible"}</span>
      } else { 
        /*return <div className="custom-control custom-checkbox" onClick={(e) => {
          e.preventDefault();
          toggleRouteVisible(idroutedistance);
        }}>
          <input type="checkbox" className="custom-control-input" checked={routeIsVisible} />
          <label className="custom-control-label small">Compare Route</label>
        </div>*/

        let btnClass = "btn btn-round btn-xs "
        let label = "Show Route"

        if (routeIsVisible){
          btnClass += "btn-outline-primary" 
          label = "Hide Route"
        } else {
          btnClass += "btn-primary"
        }

        return <button className={btnClass} onClick={() => toggleRouteVisible(idroutedistance)}>{label}</button>
      }
    } else {
      return null;
    }
  }

  let renderDescription = () => {
    let {details} = material;

    if (!details){
      return  null
    } else {
      return hcDetailsDisplay("description", details)
    }
  }

  let renderCost = () => {
    let {details} = material;

    if (!details){
      return null;
    } else {
      return hcDetailsDisplay("cost", details, {
        emptyState: "No cost info given",
        per: "/",
        chargingLabels: ["", "Not charging"]
      })
    }
  }

  let renderTrucking = () => {
    let {details} = material;

    if (!details){
      return  null;
    } else {
      return hcDetailsDisplay("trucking", details)
    }
  }

  let renderPreciseAvailableRange = () => {
    /*let {details} = material;

    if (!details){
      return  null;
    } else {
      return hcDetailsDisplay("timeframe", details, {dateFormat: "M/D/YY"})
    }*/

    return null;
  }


  if (material == null) return null;

  else{

    let {archived, name, quantity, type, available_date, partnerName, partnerPhone, username, geom, status, iddiscoverymode, matchesCount, idsite_resource, date, distanceFromResource} = material;
    let bgColorClass = ( routeIsVisible ? "bg-pale-primary" : archived || list == "sameList" ? "bg-light" : "" );

    if (isBrowser){

      return( <div class={"card border shadow-2 hover-shadow-6 p-2 mb-4 " + bgColorClass}>
        <div className="card-body px-4 pt-1 pb-0">
          <div className="row pt-1 pb-0">

            <div class="col-5 pl-3 lh-2 clearfix">
              {list !== "sameList" ? <figure class="marker float-left mr-2">
                <img src={hcGetIconForSiteOrMatch(material, "match", list)} />
              </figure> : null}
              <label class="fs-16 strong text-dark">{name} {renderIsSuperBlast()}</label>
             </div>


              <div className="col-7 text-right flexbox" style={{ whiteSpace: 'nowrap'}}>
                <label className="fs-14 strong text-dark">{availableRange()}</label>
                <label className="fs-14 strong text-dark mr-4" title={"Straight line distance: " + distanceFromResource.toFixed(2) + "mi"}>{renderRouteDistance()}</label>
                <label className="fs-14 strong text-dark">{renderRouteTime()}</label>
              </div>

              <div className="col-5 pb-1 pl-3 lh-1">
                <label className="fw-300 ml-6">{date ? "Matched on " + moment(date).format("M/D/YY") : null}</label>                        
              </div>

              <div className="col-4 lh-1">
                <label className="fs-13 text-dark">{renderTypeAndQuantity()}</label> 
                </div>

              <div className="col-3 lh-1 text-right">
                <label className="fs-13 text-dark">{renderCost()}</label>               
                </div> 

              <div className="col-8 py-3 lh-2">
                <label className="small">{renderDescription()} {renderTrucking()} {renderPreciseAvailableRange()}</label>                        
              </div>                            

              <div className="col-4 lh-2 mt-1 mb-1 text-right">
                <a className="small cursor">{partnerName || "No name on file"} <br /> {partnerPhone || "No phone number on file"}</a>
              </div>

              <div className={"mr-0 pr-0 col-" + (hasExternalAccess ? "4" : "12")}  style={{ whiteSpace: 'nowrap'}}>
                {renderRouteToggler()}
              </div>

              {hasExternalAccess ? <div className="col-8 ml-auto text-right">
                <label className="fs-16">
                  {renderBadge()}
                  </label>
              </div> : null}
          </div>
        </div>
      </div>)
    } else if (isMobile){

      return(<>

        <div className={"card border shadow-2 hover-shadow-6 p-2 mb-4 " + bgColorClass}>
          <div className="card-body px-4 pt-1 pb-0">
            <div className="row pt-1 pb-0">

            <div className="col-12 pl-3 clearfix" style={{ whiteSpace: 'nowrap'}}>
              {list !== "sameList" ? <figure class="marker float-left mr-2 mt-1 mb-0">
                <img src={hcGetIconForSiteOrMatch(material, "match", list)} />
              </figure> : null}
              <label className="fs-16 strong text-dark">{name} {renderIsSuperBlast()}</label>
              <label className="fs-14 strong text-dark float-right">{renderRouteDistance()}</label>
            </div>


            <div className="col-12 mb-2 pb-1 pl-3 lh-1 text-right flexbox">
              <label className="fw-300 ml-6">{date ? "Matched on " + moment(date).format("M/D/YY") : null}</label>
              <label className="fs-14 strong text-dark">{renderRouteTime()}</label>                        
            </div>

            <div className="col-12 text-right flexbox" style={{ whiteSpace: 'nowrap'}}>
              <label className="fs-14 strong text-dark">{availableRange()}</label>
            </div>

            <div className="col-6 lh-1">
              <label className="fs-13 text-dark">{renderTypeAndQuantity()}</label> 
            </div>

            <div className="col-6 lh-1 text-right">
              <label className="fs-13 text-dark">{renderCost()}</label>
            </div> 

            <div className="col-12 py-3 lh-2">
              <label className="small mb-0">{renderDescription()} {renderTrucking()} {renderPreciseAvailableRange()}</label>
            </div>                            

            <div className="col-6 pt-4" style={{ whiteSpace: 'nowrap'}}>
              {renderRouteToggler()}
            </div>

            <div className="col-6 lh-2 mb-2 text-right">
              <a className="small cursor">{partnerName || "No name on file"} <br /> {partnerPhone || "No phone number on file"}</a>
            </div>

            {hasExternalAccess ? <div className="col-12 border-top pt-2 text-right">
              <label className="fs-16">
                {renderBadge()}
              </label>
            </div> : null}

            </div>
          </div>
        </div>


      </>)
    }
  }


}
