import React, {Fragment, useState, useEffect, useLayoutEffect}  from 'react';
import Navbar from '../../common/Navbar'
import Footer from '../../common/Footer'
import Papa from "papaparse";
import jwtDecode from 'jwt-decode'
import validateCSV from "./utils/validateCSV"

import {
  ApiService,
  HaulCheckService,
  PublishSubscribe,
 } from '../../../services'

import "../styles.css"
import _ from "lodash";




export default function CSVImport({history}) {

	let [username, setUsername] = useState(jwtDecode(localStorage.getItem('token'))['cognito:username']);
	let [email, setEmail] = useState(jwtDecode(localStorage.getItem('token'))['email'])

	const [resourcesList, setResourcesList] = useState([])
	const [infoSubmitted, setInfoSubmitted] = useState([])
	const [showHelp, setShowHelp] = useState(false)
	const [isSeedUser, setIsSeedUser] = useState(null);

	const [validationErrors, setValidationErrors] = useState([]);
	const [importData, setImportData] = useState([])
	const [headers, setHeaders] = useState([])

	const [feedback, setFeedback] = useState([]);
	const [finalSuccessMessage, setFinalSuccessMessage] = useState("")
	const [importing, setImporting] = useState(false);

	let [client, setClient] = useState(null);

	useLayoutEffect(() => {
		getResources();
		getIsSeedUser();
		//setSubscription();
		//return () => {closeSubscription()}
	}, [])

	/*let setSubscription = async () => {
		let client = await PublishSubscribe.quickSubscribe(
	    	"MySites/SponsoredSites/CSVImport.js",
	    	"HC/sponsored/massimport/"+username,
	    	handleMessage
 		);

 		setClient(client);
	}

	let closeSubscription = () => {
		if (client !== null){
			setClient(PublishSubscribe.closeClient(client));
		}
	}


	let handleMessage = (message) => {
		console.log("handleMessage message:", message);
		message = JSON.parse(message);

		if (message.feedback){
			addToFeedback(message.feedback)
		}

		if (message.idsite && message.idsite !== -1 && message.idsite !== "-1"){
			HaulCheckService.getMatches(message.idsite)
		}
	}*/

	useEffect(() => {
		if (isSeedUser === false){
			goBack();
		}
	}, [isSeedUser])

	const getResources = async () => {
		let resources = await ApiService.getCreateableResources()
		setResourcesList(resources);
	}

	const getIsSeedUser = async () => {
		setIsSeedUser(await HaulCheckService.getIsSeedUser(username));
	}

	let goBack = () => {
      history.push("/app/sites");
    }

    const handleFileUpload = (e) => {

      	console.log("handleFileUpload e:", e.target.files[0]);
		Papa.parse(e.target.files[0], {
			header: true,
			skipEmptyLines: true,
			complete: function (file) {

				let {errors, data} = validateCSV(file);
				
				setValidationErrors(errors);
				setImportData(data);
				setHeaders(!_.isEmpty(data) ? Object.keys(data[0]) : [])
			},
		});
	};

	const addToFeedback = (addition) => {
		setFeedback((prev) => {
			console.log("Adding feedback", addition, " to ", prev);
			let arr = addition.concat(prev);
			return arr;
		})
	}

	const submit = async () => {
		setValidationErrors([]);
		setImportData([]);
		setHeaders([])
		setImporting(true);
		let res = await ApiService.bulkCreateSponsoredSites({data: importData, email, username});
		console.log("Submit results:",res);
		let {successMsg, feedback, sites} = res;
		HaulCheckService.bulkMatchingAlgorithm(sites);
		setFinalSuccessMessage(successMsg)
		addToFeedback(feedback);
		setImporting(false);
	}

	const cancel = () => {
		setValidationErrors([]);
		setImportData([]);
		setHeaders([])
		setFeedback([])
	}

	const help = () => {
		setShowHelp(true);
	}

	const hideHelp = () => {
		setShowHelp(false);
	}

	return <>
		<Navbar/>
		    <div className="bg-white border-bottom px-2">
                <p className="lead strong pt-1 pb-2 mb-0">
                    <a className="text-light strong" href="#" onClick={goBack}>
                        <i className="fa fa-caret-left mr-2" aria-hidden="true"></i>Sites
                    </a>
                </p>
            </div>
			<main className="p-4 my-sites-scroll">

				{showHelp ? <>
					<div className="d-flex"> 

						<button className="btn btn-outline-info btn-sm btn-pill ml-auto" onClick={hideHelp}>Hide Help</button>
					</div>

					<strong> Accepted Data </strong>
					<em>Note: If a column is marked as "optional", that is only referring to the technical aspects of the system and may not correctly reflect business rules</em>
					<ul>
						<li>Material Category</li>
							<ul>
								<li>
									Full list of accepted values: {
										!_.isEmpty(resourcesList) ?
											 resourcesList.map(({resource}, index, list) => {
											 	if (index == list.length - 1) return resource;
											 	else return resource + ", "
											})
										: null
									}
								</li>
							</ul>
						<li>Surplus/Shortage</li>
							<ul>
								<li>If this data is absent or ambiguous, the system will assume the site is a dump site</li>
							</ul>
						<li>State</li>
							<ul>
								<li>This field can accept both shortcodes ("WI") and state names ("Wisconsin")</li>
							</ul>
						<li>Site Name</li>
						<li>Company Name</li>
							<ul><li>Optional</li></ul>
						<li>Site Address</li>
							<ul><li>Optional</li></ul>
						<li>Latitude</li>
						<li>Longitude</li>
							<ul><li>Latitude and Longitude must be separate columns</li></ul>
						<li>Directions</li>
							<ul><li>Optional</li></ul>
						<li>Phone Number</li>
							<ul><li>Optional</li></ul>
					</ul>

					<hr />
				</> 
				: 
				<div className="d-flex">
					<button className="btn btn-outline-info btn-sm btn-pill ml-auto" onClick={help}>Show Help</button>
				</div>}

			    {!_.isEmpty(importData) && _.isEmpty(validationErrors) && _.isEmpty(feedback) ? <>
			    	<p>
			    		<strong>Please review the data for accuracy before submitting.</strong> <br />
			    		Ensure the headers correctly describe the contents of the column.
		    		</p>


			    	<table className="table table-bordered table-striped mt-3">
			    		<thead>
			    			<tr>
			    				{headers.map((header, index) => 
			    					<th key={"h-"+index}><strong>{header}</strong></th>
		    					)}
			    			</tr>
		    			</thead>

		    			<tbody>

		    				{importData.map((row, index) => {
		    					return <tr key={"r-"+index}>
			    					{headers.map((val, index2) => {
			    						return <td key={"c-"+index2}>{row[val]}</td>
			    					})}
			    				</tr>
		    				})}

		    			</tbody>

			    	</table>

			    	<div>
				    	<button className="btn btn-success" onClick={submit}>Submit</button>
				    	<button className="btn btn-danger ml-4" onClick={cancel}>Cancel</button>
			    	</div>
			    </> : !_.isEmpty(validationErrors) ? <>
			    	
			    	<table className="table table-bordered table-striped mt-3">
			    		<thead><tr><th>There are errors with your .csv file</th></tr></thead>
			    		<tbody>
			    			{validationErrors.map((error, index) => {
			    				return <tr key={error}><td>{error}</td></tr>
			    			})}
			    		</tbody>
			    	</table>

			    	<div>
				    	<button className="btn btn-outline-danger" onClick={cancel}>Back</button>
			    	</div>

			    </> 

			    : (!_.isEmpty(feedback) || importing) ? <>

			    	{!importing ? <div>
			    		<p>The site import process is complete. {finalSuccessMessage}</p>
			    		<p>Please see below for more detailed output logs.</p>
				    	<button className="btn btn-outline-primary" onClick={cancel}>Done</button>
			    	</div> :
			    		<p>
				    		The system is now importing your .csv file. This may take a while.
				    	</p>

			    	 }


			    	{!_.isEmpty(feedback) ? <table className="table table-bordered table-striped mt-3">
			    		<thead>
			    			<tr>
			    				<th>Import Progress</th>
		    				</tr>
	    				</thead>
			    		<tbody>
			    			{feedback.map((msg, index) => {
			    				return <tr key={msg}><td>{msg}</td></tr>
			    			})}
			    		</tbody>
			    	</table> : 
			    		<div className="loader">Loading...</div>
			    	}

			    </>
			    :
			    <>

				   	<label for="upload mr-2">Upload your .CSV file here:</label>
					<input
						type="file"
						name="file"
						accept=".csv"
						className="form-control"
						onChange={handleFileUpload}
				    />


				</>}




			    {/*<div className="progress" style={{"height": "40px"}}>
					<div className="progress-bar bg-success" 
						role="progressbar" 
						style={{width: "30%"}}
						aria-valuenow="30" 
						aria-valuemin="0" 
						aria-valuemax="100">
							<strong>30 Sites Created Successfully</strong>
					</div>
					<div className="progress-bar bg-warning" 
						role="progressbar" 
						style={{width: "20%"}}
						aria-valuenow="20" 
						aria-valuemin="0" 
						aria-valuemax="100">
							<strong>20 Errors</strong>
					</div>
				</div>*/}

			</main>
		<Footer />

	</>

}