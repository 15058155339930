import React, {useState, Fragment} from 'react';
//import ajax from 'superagent';
//import _ from 'lodash';
//import jwtDecode from 'jwt-decode';
import {
  HaulCheckService
} from '../../services';
//import TextField from '@material-ui/core/TextField';
//import Autocomplete from '@material-ui/lab/Autocomplete';
//import { url, earthidealAPIURL } from '../../../constants'

export default function DeleteASite({site, callback, hideArchiveButton}){

    let [checkboxChecked, setCheckboxChecked] = useState(false);
    let [loading, setLoading] = useState(false);

    let deleteSite = async () => {
        setLoading(true);
        let {idsite} = site;
        if (idsite != 0){
            await HaulCheckService.deleteSite(idsite);
            await HaulCheckService.deleteAllMaterials(idsite);
        }
        setCheckboxChecked(false);
        if (callback){
            callback();
        }
        closePopup();
        setLoading(false);
    }

    let archiveSite = async () => {
        let {idsite} = site;
        if (idsite != 0){
            await HaulCheckService.setArchivedBySite(idsite);
        }
        setCheckboxChecked(false);
        if (callback){
            callback();
        }
        closePopup();
    }

    let closePopup = () => {
        setCheckboxChecked(false);
        document.getElementById('close-deletesite').click();
    }

    let hasChildren = (site.children && site.children.length > 0)

    return(<>
        <button id={"open-delete-site-popup"} 
            data-toggle="popup" 
            data-target={"#deletesite"} 
            style={{display: "none"}}>
            Popup Trigger Button
        </button>
        
        <div id="deletesite" class="popup col-6 col-md-4 p-0" data-position="top-center" data-animation="slide-down">
            <button type="button" class="close" data-dismiss="popup" aria-label="Close" id="close-deletesite">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="media alert-danger p-5">
                <i class="ti ti-trash lead pt-3"></i>
                <div class="media-body fw-200">
                    <p class="lead-3 strong ml-2">Delete Site?</p>
                    <p class="fw-400 lh-2 mb-4 pr-7">Are you sure you want to delete this site and lose access to your Haul Check records?</p>

                    {site.children && site.children.length > 0?

                        <div className="custom-control custom-checkbox lh-2 mb-2" onClick={() => setCheckboxChecked(!checkboxChecked)}>
                            <input className="custom-control-input" 
                                type="checkbox" 
                                checked={checkboxChecked}
                            / >
                            <label className="custom-control-label">Delete this site and all associated materials, matches, and messages</label>
                        </div>
                    : null }

                    <button type="button" class="btn btn-danger float-right mx-2 mt-2" disabled={(!checkboxChecked && hasChildren) || loading} onClick={deleteSite}>
                        {loading ? <img src="../../img/app/photoswipe/preloader.gif" /> : null}
                        Delete
                    </button>

                    <button type="button" class="btn btn-outline-danger float-right mx-2 mt-2" onClick={closePopup}>Cancel</button>

                    {/*hasChildren || */false ? <button type="button" class="btn btn-outline-info float-right mx-2 mt-2" onClick={archiveSite}>Archive</button> : null}

                </div>
            </div>
        </div>    
    </>)

}