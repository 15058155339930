export function hcDisplayPrice(price, showDecimalsIfWholeNumber = true){

	if (isNaN(parseInt(price))) return price;

	if (!price && price !== 0) return "";

	price = parseInt(price);

	let isWholeNumber = (price % 100 == 0);
	let isNegativeNumber = (price < 0);

	console.log("hcDisplayPrice price: ",price,"isWholeNumber: ",isWholeNumber);
	let toReturn = "$";

	if (isNegativeNumber){
		toReturn = "-$";
		price = price * -1;
	}

	if (isWholeNumber){
		if (showDecimalsIfWholeNumber){
			toReturn += (price/100).toFixed(2);
		} else {
			toReturn += (price/100).toFixed(0);
		}
	} else {
		toReturn += (price/100).toFixed(2);
	}

	return toReturn;
}