import React, {Fragment}  from 'react';
import Material from './Material'
import moment from 'moment'

export default function Site({siteObj, initiateUnarchiveSite, initiateDeleteSite, openMessageThread, getMaterials, index}){

	let {name, idsite, children, loading, sponsoredSite} = siteObj;

  console.log("idsite:", idsite);

  let site_archived_date = children.reduce((currentArchivedDate, {archived_date}) => {
    if (new Date(archived_date) > currentArchivedDate){
      currentArchivedDate = archived_date;
    }

    return currentArchivedDate;
  }, new Date("2019-01-01"));

	return(

		<div className="accordion" id={"accordion-"+idsite} onClick={() => getMaterials(idsite, index)}>
      <div className="card mb-1">
        <p className="card-title">
          <a className="p-0 py-1 pl-4 ml-4" data-toggle="collapse" href={"#collapse-"+idsite}>
            <span className="strong">{name}</span>
            <span className="float-right ml-6 mt-1 badge badge-secondary">Archived {moment(site_archived_date).format("M/DD/YY")}</span>
          </a>
        </p>
        <div id={"collapse-"+idsite} className="collapse pb-3" data-parent={"#accordion-"+idsite}>
          <div className="card-body py-2 pl-4">

            <button type="button" class="btn btn-xs btn-outline-danger float-right py-0 px-1 mx-1" onClick={() => initiateDeleteSite(siteObj)}>Delete Site</button>
            <button type="button" class="btn btn-xs btn-info float-right py-0 px-1 mx-1" onClick={() => initiateUnarchiveSite(siteObj)}>Recover Site</button>

            {children.map((material) => {
	            return <Material material={material} sponsoredSite = {sponsoredSite} key={material.idsite_resource + "-material"} openMessageThread={openMessageThread} idsite={idsite} materialLoading={siteObj.loading} />
            })}

          </div>
        </div>

      </div>
    </div>
  )
}