import React, {Fragment, useState, useLayoutEffect, useEffect}  from 'react';
import SiteList from './SiteList';
import UnlockMapButton from './UnlockMapButton';
import PMGroup from '../../common/PMGroup';
import AddAMaterial from './AddAMaterial';
import Event from '../../Event'
import SettingsModals from './SettingsModals'
import ImportSites from './ImportSites';
import DeleteAMaterial from '../../common/DeleteAMaterial'
import jwtDecode from 'jwt-decode'
import EditSiteName from '../../common/EditSiteName'
import DeleteASite from '../../common/DeleteASite'
import {PublishSubscribe, PMGroupsService, HaulCheckService, ApiService} from '../../../services';
//import {usePrevious} from '../../../utils'
import {Link, useRouteMatch} from 'react-router-dom'
import {loadSidebarPositionData, setSidebarPosition, deleteSidebarPositionData} from "./utils/positionSidebar"
import _ from 'lodash';


export default function Sidebar({openSite, switchSidePanel, mapLocked, setMapLocked, showSiteRoutes, openSiteTeamModal, getSiteInfo, isMobile, isBrowser, setActiveSiteParent, forceTab}){

	let [activeSite, setActiveSite] = useState(null);


	let [sortMode, setSortMode] = useState("site");
	let [activeTab, setActiveTabState] = useState("mySites");
	let [isSeedUser, setIsSeedUser] = useState(false);
	let [group, setGroup] = useState(false);
	let [selectedMaterial, setSelectedMaterial] = useState(0);
	let [settingsModalId, setSettingsModalId] = useState(0);
	let [settingsModalType, setSettingsModalType] = useState("")
	let [settingsModalTrigger, setSettingsModalTrigger] = useState(false);
	let [settingsModalParams, setSettingsModalParams] = useState(null);
	let [materialToDelete, setMaterialToDelete] = useState({idsite_resource: 0, unreadExternalsCountNoArchive: 0})
	let [username, setUsername] = useState(jwtDecode(localStorage.getItem('token'))['cognito:username']);
	let [email, setEmail] = useState(jwtDecode(localStorage.getItem('token'))['email'])
	let [siteToDelete, setSiteToDelete] = useState({idsite: 0})
	let [siteBeingMoved, setSiteBeingMoved] = useState(null);
	let [client, setClient] = useState(null);

	let [sitesList, setSitesList] = useState({})
	let [groupSitesList, setGroupSitesList] = useState({})
	let [materialsList, setMaterialsList] = useState({})
	let [realSidebarLength, setRealSidebarLength] = useState(0);
	let [loadingCurrentList, setLoadingCurrentList] = useState(false);
	let [hasExternalAccess, setHasExternalAccess] = useState(hasExternalAccess);

	let PS = PublishSubscribe.usePublishSubscribe({
		origin: "MySites/Sidebar/index.js",
    	topic: "HC/groups",
    	identifier: {username},
    	callback: (message) => {
			console.log("handleMessage loading site objects");
			loadGroup("handleMessage");
			loadSiteObjects("fromHandleMessage");
		}

	})



	useLayoutEffect(() => {
		//loadSiteObjects(activeTab);
		loadGroup("useLayoutEffect");

		let lastActiveSidebarTab = localStorage.getItem("lastActiveSidebarTab");
		if (lastActiveSidebarTab){
			setActiveTab(lastActiveSidebarTab);
		}

	}, [])

	useEffect(() => {
		setActiveSiteParent(activeSite)
		console.log("Calling setActiveSite suseEffect with activeSite", activeSite)
	}, [activeSite])

	useEffect(() => {
		setActiveTab(forceTab)
	}, [forceTab])

	useEffect(() => {
		localStorage.setItem("lastActiveSidebarTab", activeTab)
	}, [activeTab])


	let loadGroup = async (origin) => {
		console.log("Calling loadGroup from origin", origin)
		setGroup(await PMGroupsService.getPmGroupByEmail(email));
		console.log("loadGroup after setGroup");
		setHasExternalAccess(await ApiService.getUserHasExternalAccess());
		console.log("loadGroup after setHasExternalAccess")
		setIsSeedUser(await HaulCheckService.getIsSeedUser(username));
		console.log("loadGroup after setIsSeedUser")

	}

	let loadSiteObjects = async (newActiveTab = null) => {

		if (newActiveTab == null){
			newActiveTab = activeTab;
		}

		console.log("loadSiteObjects newActiveTab:", newActiveTab);

		setLoadingCurrentList(true);

		let list = []

		if (newActiveTab == "mySites"){
			list = await HaulCheckService.getHCSidebarMySites(username);
			setSitesList(list) //Need to add callback after this
			setRealSidebarLength(list.length);
		} else if (newActiveTab == "groupSites" || newActiveTab == "fromHandleMessage"){
			list = await HaulCheckService.getHCSidebarGroupSites(email);
			setGroupSitesList(list);
			setRealSidebarLength(list.length);
		} else if (newActiveTab == "material" || newActiveTab == "fromHandleMessage"){
			list = await HaulCheckService.getHCSidebarByMaterials(username, email)
			setMaterialsList(list);
			setRealSidebarLength(list.length);
		}

		let data = loadSidebarPositionData();
		console.log("loadSiteObjects loaded sidebar position data:", data)

		if (data !== null && data !== undefined){
			if (newActiveTab == "groupSites" || newActiveTab == "mySites"){
				setActiveSite(data.idsite ? data.idsite : null);
			} else if (newActiveTab == "material"){
				let materialIndex = _.findIndex(list, {name: data.resource});
				console.log("loadSiteObjects materialIndex:",materialIndex,"based on data", data, "in list", materialsList)
				setActiveSite(() => {
					setTimeout(() => {
						setSidebarPosition(data);
					}, 1000)
					return (materialIndex !== -1 ? materialIndex : null)
				})
			}
		}
		setLoadingCurrentList(false);

		console.log("end of loadSiteObjects")
	}

	let setActiveTab = async (newActiveTab) => {
		//console.log("loadSiteObjects", "Calling setActiveTab with newActiveTab " + newActiveTab)
		await setActiveTabState(newActiveTab);
		loadSiteObjects(newActiveTab);
	}

	let openSettingsModal = (id, modalType, params) => {
		console.log("openSettingsModal params:", id, modalType, params, settingsModalTrigger);
		setSettingsModalId(id);
		setSettingsModalType(modalType);
		setSettingsModalTrigger(!settingsModalTrigger);
		setSettingsModalParams(params);
	}

	let editSiteName = (settingsModalParams) => {
		setSettingsModalParams(settingsModalParams);
		document.getElementById("open-edit-site-name").click();
	}

	let selectMaterial = (material) => {
		//showSiteRoutes(material);
		setSelectedMaterial(material.idsite_resource);
	}

	let deleteMaterial = (material) => {
		setMaterialToDelete(material);
		document.getElementById('open-delete-material-popup').click();
	}

	let deleteSite = (site) => {
		setSiteToDelete(site);
		document.getElementById('open-delete-site-popup').click();
	}

	let callback = () => {return null}
	let callbackReturnToSandbox = () => {switchSidePanel("sandbox")}

	let addAMaterialCallback = (idsite_resource) => {
		setTimeout(() => {
			let button = document.getElementById("view-matches-button-"+idsite_resource);
			if (button) button.click()
		}, 500);
	}

	let renderHeaderWrapper = (inside) => {
		if (isMobile){
			return <section class="bg-gray">
				{inside}
			</section>
		} else if (isBrowser){
			return <>{inside}</>
		}
	}

	let renderHeader = () => {
		return renderHeaderWrapper(<>
			
			<SettingsModals id={settingsModalId} trigger={settingsModalTrigger} modalType={settingsModalType} params={settingsModalParams} callback={callback} callbackReturnToSandbox={callbackReturnToSandbox} postMaterialCallback={addAMaterialCallback} />
			<DeleteAMaterial material={materialToDelete} callback={callbackReturnToSandbox} />
			<EditSiteName site={settingsModalParams} callback={callback} />
			<DeleteASite site={siteToDelete} callback={callbackReturnToSandbox} /> 

			<ul className={"nav nav-tabs-minimal" + (isBrowser ? " pt-md-3 pt-lg-4" : "")} role="tablist" style={isBrowser ? {position: 'sticky',top: '0px',zIndex: 500,backgroundColor: '#fff'} : null}>
				<li className="nav-item">
					<Link className={"nav-link " + (isMobile ? "pb-2 mb-0" : "pb-0") + (activeTab == "mySites" ? " text-dark active" : " text-light")} to="/app/sites/my-sites">My Sites</Link>
				</li>

				{!isSeedUser ? <li className="nav-item">
					<Link className={"nav-link " + (isMobile ? "pb-2 mb-0" : "pb-0") + (activeTab == "groupSites" ? " text-dark active" : " text-light")}  to="/app/sites/group-sites">Group Sites
						{/*<span className="badge badge-dot badge-warning ml-1 mb-3"></span><span className="badge badge-dot badge-success ml-1 mb-3"></span>*/}
					</Link>
				</li> : null}

				<li className="nav-item">
					<Link className={"nav-link " + (isMobile ? "pb-2 mb-0" : "pb-0") + (activeTab == "material" ? " text-dark active" : " text-light")}  to="/app/sites/materials">Materials
						{/*<span className="badge badge-dot badge-warning ml-1 mb-3"></span><span className="badge badge-dot badge-success ml-1 mb-3"></span>*/}
					</Link>
				</li>

            	<li className="nav-item">
					<a className={"nav-link text-light " + (isMobile ? "pb-2 mb-0" : "pb-0")} href="/haul-check-site-archive">Archive
						{/*<span className="badge badge-dot badge-warning ml-1 mb-3"></span><span className="badge badge-dot badge-success ml-1 mb-3"></span>*/}
					</a>
				</li>

				<li className="nav-item">
					<a className={"nav-link text-light " + (isMobile ? "pb-2 mb-0" : "pb-0")} href="/master-setup">Master Setup
						{/*<span className="badge badge-dot badge-warning ml-1 mb-3"></span><span className="badge badge-dot badge-success ml-1 mb-3"></span>*/}
					</a>
				</li>

				{isSeedUser ? 
					<li className="nav-item">
						<a className={"nav-link text-light " + (isMobile ? "pb-2 mb-0" : "pb-0")} href="/csv-import">.CSV Sponsored Site Import
							{/*<span className="badge badge-dot badge-warning ml-1 mb-3"></span><span className="badge badge-dot badge-success ml-1 mb-3"></span>*/}
						</a>
					</li>
				: null}

			</ul>
		</>)
	}

	let renderImportSites = () => {
		return(<>
			<ImportSites />
		</>)
	}

	let renderSitesList = (name, listObj, sites, emptyState) => {
		return <SiteList
			name={name}
			passedSites={sites ? sites : []}
			emptyState={emptyState}
			listObj={listObj}
			switchSidePanel={switchSidePanel}
			hasGroup={group !== null}
			activeSite={activeSite}
			setActiveSite={setActiveSite}
			setSelectedMaterial={setSelectedMaterial}
			selectedMaterial={selectedMaterial}
			openSiteTeamModal={openSiteTeamModal}
			getSiteInfo={getSiteInfo}
			openSettingsModal={openSettingsModal}
			deleteMaterial={deleteMaterial}
			editSiteName={editSiteName}
			deleteSite={deleteSite}
			activeTab={activeTab}
			idpmgroup={(group && group.idpmgroup) ? group.idpmgroup : null}
			newSite={siteBeingMoved}
			moveSite={setSiteBeingMoved}
			isMobile={isMobile}
			isBrowser={isBrowser}
			clearNewSite={() => setSiteBeingMoved(null)}
			addLength={() => setRealSidebarLength(prev => prev+1)}
			removeLength={() => setRealSidebarLength(prev => prev-1)}
			isBrowser={isBrowser}
			isMobile={isMobile}
			hasExternalAccess={hasExternalAccess}
			isSeedUser={isSeedUser}
		/>
	}

	let renderSidebarOuterWrapper = (children) => {
		console.log()
		if (isMobile){
			return <>
			{renderHeader()}
				<section class="section py-0 shadow-2">
		            <div class="row no-gutters">
		            	<div class="col-12 p-4">
		            		{children}
	            		</div>
	            	</div>
	        	</section>
        	</>
		} else {
			return <div className="col-12 col-md-6 col-lg-4 my-sites-scroll" id="sidebarDiv">
				{renderHeader()}
				{children}
			</div>
		}
	}

	if (loadingCurrentList){
		return renderSidebarOuterWrapper(<>
			<p>Loading...</p>
		</>)
	}
	else if (activeTab == "mySites") {

		return<>
		{renderSidebarOuterWrapper(<>
			
			{realSidebarLength == 0 ? <div className="my-4">
				<Event eventtype="noSiteAlert" />
			</div> : null}

	    	{renderSitesList("Projects", "postsList", sitesList.postsList, null)}
	    	{renderSitesList("Bids", "bidsList", sitesList.bidsList, null)}
	    	{renderSitesList("Setup", "setupList", sitesList.setupList, null)}

	        {/*<UnlockMapButton 
				mapLocked={mapLocked}
				setMapLocked={setMapLocked}
				callback={() => switchSidePanel("sandbox")} />*/}

			<div class="col-12 alert alert-light border-primary alert-dismissible fade show px-4 pt-2 pb-2 mx-auto mt-4" role="alert">
	          <div class="col-12 mr-auto alert-light p-1">
	            <p class="mb-0"><i class="ti ti-map-alt mr-2" aria-hidden="true"></i>Click in the map to add a new site.</p>
	          </div>
	    	</div>

			<div class="alert alert-primary" role="alert">
	      		We're here to help! <a href="tel:262-682-1146" class="alert-link">Give us a call at 262-682-1146</a> or <a href="mailto:support@earthideal.com" class="alert-link">Send us an email</a>
	    	</div>

	    	{renderImportSites()}

			<AddAMaterial idsite={activeSite} callback={addAMaterialCallback} />
		</>)}</>
	    
    } else if (activeTab == "groupSites"){
    	return renderSidebarOuterWrapper(<>

            {group !== null ? <>

            	{renderSitesList("Projects", "postsList", groupSitesList.postsList, null)}
	        	{renderSitesList("Bids", "bidsList", groupSitesList.bidsList, null)}
	        	{renderSitesList("Setup", "setupList", groupSitesList.setupList, null)}

		        <AddAMaterial idsite={activeSite} callback={addAMaterialCallback} />

	        </>:<>

	        	<Event eventtype="notInGroupYetAlert" />

	    	</>}

			<PMGroup 
				pmGroup={group} 
				simpleMode={true}
			 />

		</>)
    } else if (activeTab == "material"){
    	return renderSidebarOuterWrapper(<>

			{renderSitesList(null, "MaterialSort", materialsList, <p><label className="small-3"><i>No materials with this resource</i></label></p>)}

			{/*<UnlockMapButton 
				className="btn btn-sm btn-outline-primary mt-4 ml-4"
				mapLocked={mapLocked}
				setMapLocked={setMapLocked}
				callback={() => switchSidePanel("sandbox")}>
				+ Add a Site
			</UnlockMapButton>

			<hr className="mt-4" />

			<PMGroup idpmgroup={idpmgroup} simpleMode={true} />

            <hr className="mt-6" />*/}

		</>)
    } else {
    	//return null
    	return renderSidebarOuterWrapper(<>
    		<p>activeTab: {activeTab}</p>
    	</>)
    }
}