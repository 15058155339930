import React from 'react'
import './index.css'
import DatePicker from 'react-datepicker'

const ModalDecline = ({ handleModalThanks, idmatchsiteresource }) => {
    return ( 
        <div className="modal fade" id={"decline-"+idmatchsiteresource} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">

                    <div className="modal-header bg-gray px-4 pt-2 pb-0">
                        <h5 className="modal-title" id="exampleModalLabel">Decline Contact Info</h5>
                        <button type="button" className="close pt-5" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        {/*<p>Respectfully decline with this message:</p>
                        <div class="card border col-10 mx-auto bg-gray pr-0 pl-0">
                          <div class="card-body bg-gray">
                              <div class="media-body">
                                  <p class="small-2 mb-0">Thanks for taking the time to chat, but I don't think we have the best match right now. Please let me know if something changes in the future.</p>
                                </div>
                          </div>
                        </div>
                        <p class="mt-4">You will still be able to chat with this match in the future if something changes on your end.</p>*/}

                        <p>Are you sure you want to decline contact info with this match?</p>

                        <p>You will not be able to request their contact info in the future once you decline.</p>

                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-primary" data-dismiss="modal" onClick={ () => { handleModalThanks()}}>Decline Contact Info
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ModalDecline
