import React, { Component, Fragment } from 'react'
//import { url } from '../../constants'
//import jwtDecode from "jwt-decode";

class MatchesSidebar extends Component {

	constructor(props){
	   super(props);
	}

    renderSites = (site) => {
        let {idsite} = this.props;

        let renderclass = "nav-link";
        let renderstyle = {};

        if (idsite == site.idsite){
            renderclass += " active"
            renderstyle.display = "block";
        }

        return(
            <li className="nav-item">
                <a className={renderclass}>{site.name} 
            {/*<span className="badge badge-dot badge-success mt-1 mr-auto ml-1"></span>*/}
            <i className="nav-angle"></i></a>
                <div className="nav" style={renderstyle}>
                    {site.materialsList.map(this.renderMaterials)}
                </div>
            </li>
        )
    }

    renderMaterials = (material) => {
        let {idsiteresource, idsite} = this.props;
        let renderclass = "nav-link";
        let renderstyle = {};

        if (idsiteresource == material.idsite_resource){
            renderclass += " active";
        } 
        if (idsite == material.idsite){
            renderclass += " show";
        }

        return(
            <a className={renderclass} href="#" onClick={() => this.props.switchSiteResource(material.idsite, material.idsite_resource)}>
                {material.resource}
                {/*<span className="badge badge-pill badge-success p-1 ml-6">3</span>
                <span className="badge badge-pill badge-pale badge-success p-1 ml-6">1</span>*/}
            </a>
        )
    }

	render() {
        let {siteResourcesList} = this.props;
    	return (
        	<Fragment>
                <div className="col-3 mb-8">
                    <aside className="sidebar pt-0">
                        <ul className="nav nav-sidebar nav-sidebar-hero nav-sidebar-pill" data-accordion="true">

                            <h6>MY SITES</h6>

                            {
                                siteResourcesList.length > 0 ? siteResourcesList.map(this.renderSites) : null
                            }


                            {/*<h6 className="mt-7">[PM Group]</h6>
                            <li className="nav-item">
                            <a className="nav-link">Site D <i className="nav-angle"></i></a>
                            <div className="nav">
                            <a className="nav-link" data-toggle="tab" href="#tab-4">Material 1</a>
                            <a className="nav-link" data-toggle="tab" href="#tab-5">Material 2</a>
                            </div>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link">Site E <i className="nav-angle"></i></a>
                            <div className="nav">
                            <a className="nav-link" data-toggle="tab" href="#tab-6">Material 1</a>
                            </div>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link">Site F <i className="nav-angle"></i></a>
                            <div className="nav">
                            <a className="nav-link" data-toggle="tab" href="#tab-4">Material 1</a>
                            <a className="nav-link" data-toggle="tab" href="#tab-5">Material 2</a>
                            </div>
                            </li>
                            <li className="nav-item">
                            <a className="nav-link">Site G <i className="nav-angle"></i></a>
                            <div className="nav">
                            <a className="nav-link" data-toggle="tab" href="#tab-6">Material 1</a>
                            </div>
                            </li>*/}
                        </ul>
                    </aside>
                </div>

        	</Fragment>
    	)
	}
}

export default MatchesSidebar