import React, {Fragment} from 'react';

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

/*
Custom display component that helps maintain best display/UI practices 
between the mobile and desktop versions of the matches panel.

Abstracted into its own file so it can be reused between different matches panel components.

This component is meant to be the parent to the "rows" of the MatchListDisplay,
including the "rows" that contain the trucking disclaimer, the controls for posting externally, etc.
*/

export default function RowWrapper (props){

  if (isBrowser){
    return <>
      {/*<div className="card border">
        <div className="card-body px-4 pt-1 pb-0">*/}
          {props.children}
        {/*</div>
      </div>*/}
    </>
  } else if (isMobile){
    return <>{props.children}</>
  }

}