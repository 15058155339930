import React, { useState, useEffect, Fragment } from "react";
import DatePicker, { registerLocale } from 'react-datepicker'
import moment from 'moment'
import enUS from 'date-fns/locale/en-US'
import './datepickeradjust.css'
import _ from 'lodash'

const ModalChangeQuantityAndAvailability = ({closeModal, handleModalSave, material}) => {

    console.log("debugging handleModalSave idquantity", material.idquantity);

    let quantities = [
        {idquantity: 1, quantity: 100},
        {idquantity: 2, quantity: 1000},
        {idquantity: 3, quantity: 10000},
    ]

    let startdate = new Date();
    let enddate = null;
    let today = new Date();

    if (material.available_date){
        startdate = new Date(material.available_date);
        startdate.setDate(startdate.getDate() + 1);

        if (material.available_end_date && (material.perennialEndDate === 0 || material.perennialEndDate === "0")){
            enddate = new Date(material.available_end_date);
            enddate.setDate(enddate.getDate() + 1);
        } else if (material.available_date && (material.perennialEndDate === 0 || material.perennialEndDate === "0")){
            enddate = new Date(material.available_date);
            enddate.setDate(enddate.getFullYear(), enddate.getMonth()+2, 0);
        } else if (material.perennialEndDate === 1 || material.perennialEndDate === "1"){
            enddate = _.cloneDeep(startdate);
        }
    }

    const [startDate, setStartDate] = useState(startdate)
    const [endDate, setEndDate] = useState(enddate);
    const [idquantity, setIdQuantity] = useState(material.idquantity)
    const [maxdistance, setMaxDistance] = useState(material.maxdistance);
    const [perennialEndDate, setPerennialEndDate] = useState(material.perennialEndDate);

    let CustomInput = React.forwardRef(({ value, onClick, onChange, disabled }, ref) => (
      <input type="text" class="input-sm form-control text-center" disabled={disabled} onClick={onClick} onChange={onChange} ref={ref} value={disabled ? null : value} />
    ))

    return(<>
        <button id={"open-matchCriteria-site-setting-modal"} data-toggle="modal" data-target={"#match-criteria"} style={{display: "none"}}>Popup Trigger Button</button>

        <div class="modal" style={{backgroundColor: "rgba(84,84,84,0.3)"}} onClick={closeModal} id="match-criteria" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document" onClick={(e) => {e.stopPropagation();}}>
                <div class="modal-content">
                    <div class="alert alert-primary" role="alert">
                        <strong>Edit General Info</strong>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id={'close-matchCriteria'} onClick={closeModal}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body pt-0">
                        <p class="lead-1 strong ml-2 mb-2 border-bottom">{material.resource} {material.type.split('/')[0]}</p>

                        <div class="row m-0 ml-2">

                            <div class="col-12 px-4 pt-3 pb-2 mb-2">

                                <div class="row">
                                    <div class="col-4 p-0 mr-4">
                                        <div class="form-group mb-0">
                                            <label class="text-dark">General Quantity</label>
                                            <select className="form-control"
                                            value={idquantity}
                                            onChange={(e) => setIdQuantity(e.target.value)}>
                                                {quantities.map((item)=> { return(
                                                     <option value={ item.idquantity } key={ item.idquantity }>
                                                      { (item.quantity).toLocaleString('en') }s of CY's
                                                    </option>
                                                )})}
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-7 p-0">
                                        <label class="text-dark">General Availability</label>
                                        <div class="input-daterange input-group mr-2" id="datepicker">
                                            <DatePicker
                                                locale="en-US"
                                                className="input-sm form-control text-center"
                                                selected={ startDate || today }
                                                selectsStart
                                                startDate={ startDate || today }
                                                endDate={ endDate || today }
                                                dateFormat="MM/yyyy"
                                                onChange={ setStartDate }
                                                showMonthYearPicker
                                                customInput={
                                                    <CustomInput />
                                                }
                                            />
                                            <span className="input-group-addon pt-2">to</span>
                                            <DatePicker
                                                locale="en-US"
                                                className="input-sm form-control text-center"
                                                selected={ endDate || today }
                                                selectsEnd
                                                startDate={ startDate || today }
                                                endDate={ endDate || today }
                                                dateFormat="MM/yyyy"
                                                onChange={ setEndDate }
                                                disabled={perennialEndDate === 1 || perennialEndDate === "1"}
                                                showMonthYearPicker
                                                customInput={
                                                    <CustomInput />
                                                }
                                            />
                                            <div class="input-group-append custom-control custom-checkbox p-1 text-right" onClick={(e) => {
                                              e.preventDefault();
                                              setPerennialEndDate(perennialEndDate ? 0 : 1)
                                            }}>
                                             <input className="custom-control-input" type="checkbox" id={"perennial-checkbox"} checked={perennialEndDate === 1 || perennialEndDate === "1"} />
                                             <label className="custom-control-label" htmlFor={"perennial-checkbox"}>No End Date</label>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p class="small strong fw-400 ml-3 mb-3 pr-7">
                            Changes to this general information will be made visible to all matches.
                            <br />General Availability is used to filter the matches you see.
                        </p>

                    </div>
                    <a class="btn btn-primary text-white" href="#" onClick={() => handleModalSave(material, idquantity, maxdistance, startDate, endDate, perennialEndDate)}>Update Material</a>
                </div>
            </div>
        </div>

    </>)


}

export default ModalChangeQuantityAndAvailability