import React, {Fragment} from 'react';

import {
  isMobile,
  isBrowser
} from 'react-device-detect'

/*
Custom display component that helps maintain best display/UI practices 
between the mobile and desktop versions of the matches panel.

Abstracted into its own file so it can be reused between different matches panel components.

This component is meant to be the parent to the titles of each MatchesListDisplay, or any other
header that needs to be equivalent visually.

This file contains a lot of leftovers from previous iterations of the matches panel.
*/

export default function HeaderLabel ({showTally, tally, tallyColor, showToggle, isTopHeader, labelText, sideButton}) {
  

  const getBadgeColor = (number, toReturn) => {
    if (number == 0){
      return " badge-secondary"
    } else {
      if (toReturn) return " " + toReturn;
      else return " badge-success badge-glass"
    }
  }

  if (!showTally && !showToggle){
    return <label className="strong lead fs-17 mt-4" style={{width: '100%'}}>{labelText}</label>
  }

  else return <div className="d-flex mt-4">
    <label className="lead fs-17 my-3">{labelText}</label>
    {showTally ? 
      <a className="small level-badge mt-4 mr-auto">
      <span class={"badge badge-number fs-10 strong pt-1 px-1 ml-2 "+getBadgeColor(tally, tallyColor)}>{tally}</span>
      </a> 
    : null}

    {sideButton ? sideButton : null}

    {/*<a onClick={showToggle ? toggleAllShowDetails : null} href={showToggle ? "#" : ""} className="ml-auto p-2">
    {showToggle ? <label className="fs-11 fs-200 text-light cursor float-right mt-3">
    {toggleDetailsToHidden ? "Hide All Details" : "Show All Details"}
    </label> : null}
    </a>*/}
  </div>
}